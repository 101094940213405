import * as React from 'react';
import {
  Box,
  Button,
  Text,
  TextInput,
  Badge,
  Appear
} from '@hd/ui';
import { CCTVRecommendationCategory, CCTVRecommendationSubcategory, Repair } from '@hd/types';
import { Step } from '../CCTVRecommendationsRecommendationForm';
import useFirestoreCCTVProducts from '../../../utils/useFirestoreCCTVProducts';


type Props = {
  update: (updates: Partial<Repair>) => void;
  activeStepId?: Step;
  setStepId: (step: Step) => void;
  repair: Repair;
}

export default function ProductSelection(props: Props) {
  const { update, activeStepId, repair, setStepId } = props;

  const [categoryFilter, setCategoryFilter] = React.useState<CCTVRecommendationCategory>();
  const [subcategoryFilter, setSubategoryFilter] = React.useState<CCTVRecommendationSubcategory>();
  const [searchString, setSearchString] = React.useState<string>('');
  const products = useFirestoreCCTVProducts()

  console.log(Object.values(products.documents).map(e => ({
    id: e.cachedGrandParentId,
    name: e.cachedGrandparentName
  }) as CCTVRecommendationSubcategory))

  const subcategories = products.ids.length > 0 
  ? Array.from(new Map(products.ids.map(id => products.documents[id]).filter(e => e && e.parentId !== undefined).map(e => [e.parentId, { name: e.cachedParentName, id: e.parentId, parentId: e.cachedGrandParentId }])).values())
  : [];


  const categories = products.ids.length > 0 
  ? Array.from(new Map(products.ids.map(id => products.documents[id]).filter(e => e && e.cachedGrandParentId !== undefined).map(e => [e.cachedGrandParentId, { id: e.cachedGrandParentId, name: e.cachedGrandparentName }])).values())
  : [];


  React.useEffect(() => {
    if(activeStepId === undefined) {
      setSearchString('')
      setCategoryFilter(undefined)
      setSubategoryFilter(undefined)
    }

  }, [activeStepId])

  if(activeStepId !== 'Menu') {
    return null;
  }

  const getValidSubcategories = () => {
    if(!categoryFilter) {
      return [];
    }
    const productsCategory = Array.from(new Set(Object.values(products.documents).filter(e => e.sectionApplications.includes(repair.sectionApplication!)).map(e => e.parentId)))
    return subcategories.filter(e => e.parentId === categoryFilter.id &&  productsCategory.includes(e.id))
  }

  const getValidCategories = () => {
    const productsCategory = Array.from(new Set(Object.values(products.documents).filter(e => 
      e.sectionApplications.includes(repair.sectionApplication!)
  ).map(e => e.parentId)))
    const validSubcategories =  subcategories.filter(e => productsCategory.includes(e.id))
    return Array.from(new Set(validSubcategories.map(e => e.parentId)))
  }

  
  return (
    <Box flex='vertical' height='75vh' gap='x6'>
      <Box flex='vertical'>  
        <Box flex='vertical'>
          <Text strong size='x1'>Category: </Text>
          {
            categoryFilter !== undefined && 
              <Appear animation='FadeSlideUp'>
                <Badge backgroundColor='blue-light' onClick={() => setCategoryFilter(undefined)} size='x1'>
                  {categoryFilter?.name}
                </Badge>
              </Appear>
            }
        </Box>
        {
          categoryFilter === undefined && 
          <Appear animation='FadeSlideDown'>
          
          <Box flex='horizontal' gap='x1' style={{ overflow: 'scroll'}} padding='x2'>
        {
          categories.filter(e => getValidCategories().includes(e.id)).map(e => <Button size='small' key={e.id} onClick={() => {
            setCategoryFilter(e)
            setSubategoryFilter(undefined)
          }} 
          color='light'
          ><Text size='x1'>{e.name}</Text></Button>)
        }
        </Box>
</Appear>
        }
      </Box>

{
  categoryFilter !== undefined && 
  <Appear animation='FadeSlideDown'>
      <Box flex='vertical'>  
        <Box flex='vertical'>
          <Text strong size='x1'>Subcategory: </Text>
          {subcategoryFilter && 
          
          <Appear animation='FadeSlideUp'>
          <Badge backgroundColor='blue-light' onClick={() => setSubategoryFilter(undefined)} size='x1'>
            {subcategoryFilter?.name}
            </Badge>
            </Appear>
            }
        </Box>
        {
          subcategoryFilter === undefined && 
  <Appear animation='FadeSlideDown'>
        <Box flex='horizontal' gap='x2' style={{ overflow: 'scroll'}}>
          {
            getValidSubcategories().map(e => 
            <Button grow size='small' key={e.id} color='light' onClick={() => setSubategoryFilter(e) } wrap>
              <Text size='x1' wrap maxWidth='100%'>{e.name}</Text>
            </Button>)
          }
        </Box>
</Appear>
        }
      </Box>
</Appear>
}

      <Box>
        <TextInput autoFocus onChange={(e) => setSearchString((e.target as HTMLInputElement).value)} value={searchString} placeholder='Search...'/>
      </Box>

      <Box flex='vertical' gap='x2' shrink style={{ overflow: 'scroll'}} grow>
        {
        Object.values(products.documents)
          .filter(e => {
            if(categoryFilter === undefined && searchString === '') {
              return e.sectionApplications.includes(repair.sectionApplication!)
            }
            if(!e.sectionApplications.includes(repair.sectionApplication!)) {
              return false;
            }
            if(subcategoryFilter && e.parentId !== subcategoryFilter.id) {
              return false
            }
            if(categoryFilter && !getValidSubcategories().map(e => e.id).includes(e.parentId)) {
              return false;
            }
            if(searchString !== '' && !e.name.toLowerCase().startsWith(searchString.toLowerCase())) {
              return false;
            } else {
              return true;
            }
          })
          .map(e => <Button key={e.id} level='primary' onClick={() => update({ product: e})} color={repair.product?.id === e.id ? 'blue' : 'light'} width='100%'>
            <Box grow wrap width='100%'>
              <Text size='x1'> {e.name} </Text>
              <Text size='x1' textColor='gray'>{subcategories.filter(cat => cat.id === e.parentId).map(sub => categories.find(d => d.id === sub.parentId)?.name + ' > ' + sub.name)}</Text>
            </Box>
            </Button>)
        
        }
      </Box>

        <Box flex='horizontal' alignChildrenHorizontal='between'>
          <Button 
            color='blue'
            onClick={() => {
              update({ product: undefined })
              setCategoryFilter(undefined)
              setSubategoryFilter(undefined)
              setStepId('SectionApplicability')
            }}>{'<'}</Button>
          <Button disabled={repair.product === undefined} onClick={() => setStepId('ProductDetails')} color='blue'>{'>'}</Button>
        </Box>

    </Box>

  );

}
