import { JobType } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreJobTypes(jobTypeId?: string) {
  return useFirestoreDocument<JobType>('JobType', 'jobTypes', jobTypeId, {
    name: undefined,
    priceContractor1: undefined,
    priceContractor2: undefined,
    priceContractor3: undefined,
    priceCustomer: undefined,
    contractorNominalCode: undefined,
    customerNominalCode: undefined,
    isCCTV: false,
    isCommerical: false,
    isDomestic: false,
    isRepair: false,
    isUnblock: false,
    isFixedPrice: true,
  });
}
