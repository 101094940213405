import * as React from 'react';
import {
  Box,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
} from '@hd/ui';
import { useHistory } from 'react-router-dom';
import ProductAddOnList from './ProductAddOnList';

export default function ProductsAddOns() {
  const { push } = useHistory();

  return (
    <Page data-at-id="ProdutcsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Add ons</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <ProductAddOnList onClose={() => {}} isSearchable canAdd onClick={(id) => push(`/products/add-ons/${id}`)}/>
      </PageBody>
    </Page>
  )
}
