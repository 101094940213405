import * as React from 'react';
import {
  Box,
  Button,
  Text,
  TextInput,
  CheckBox,
  Appear
} from '@hd/ui';
import { Repair, SectionApplication } from '@hd/types';
import { Step } from '../CCTVRecommendationsRecommendationForm';
import { v4 } from 'uuid';


type Props = {
  activeStepId?: Step;
  onAdd: (repair: Repair) => void;
  update: (updates: Partial<Repair>) => void;
  setStepId: (step?: Step) => void;
  repair: Repair;
  onClose: () => void;
}


export default function ProductSelectionMetadata(props: Props) {
  const { activeStepId, onAdd, setStepId, onClose, repair, update } = props;

  
  if(activeStepId !== 'ProductMetadata') {
    return null;
  }


  return (
          <Box flex='vertical' gap='x3'>
            <Text size='x3' strong>{repair.product?.name}</Text>
            { repair.product?.requiresAtPosition && 
            <Box>

                <Text>At position</Text>
                  {Array.from({ length: repair.quantity! }, (_, index) => (

                    <TextInput key={index} width='100%' type='number' onChange={(e) => {
                      const copy = repair.positionAt || []
                      copy[index] = parseFloat((e.target as HTMLInputElement).value)
                      update({positionAt: copy})
                    }} value={(repair.positionAt || [])[index] || ''}/>
                  ))}

              </Box>
            }
            { repair.product?.requiresFromToPosition && 
              <Box flex='horizontal' gap='x3'>
                <Box grow>
                  <Text>From</Text>
                  <TextInput autoFocus width='100%' type='number' onChange={(e) => update({positionFrom: parseFloat((e.target as HTMLInputElement).value)})} value={repair.positionFrom}/>
                </Box>
                <Box grow>
                  <Text>To</Text>
                  <TextInput width='100%' type='number' onChange={(e) => update({positionTo: parseFloat((e.target as HTMLInputElement).value)})} value={repair.positionTo}/>
                </Box>
              </Box>
            }

        <Box flex='horizontal' alignChildrenHorizontal='between'>
            <Button onClick={() =>  setStepId('ProductDetails') } color='blue'>{'<'}</Button>
            {
              repair.product && repair.product.addOns && 
              repair.product?.addOns?.length > 0 ? 
            <Button onClick={() => setStepId('ProductAddOns')} color='blue' >{'>'}</Button> : 
            <Button 
            disabled={(repair.product?.requiresAtPosition && repair.positionAt === undefined) || (repair.product?.requiresFromToPosition && (repair.positionFrom === undefined) || (repair.product?.requiresFromToPosition && repair.positionTo === undefined))}
              onClick={() => { 
              onAdd(repair)
              onClose()
            }} color='blue' > +</Button>
            }
          </Box>
        </Box>

  );

}
