import { JobEmail } from '@hd/types';
import useFirestoreCollection, { Options, TypeOrderBy } from './useFirestoreCollection';

const orderBys: TypeOrderBy<JobEmail>[] = [['timestamp', 'asc']];

export default function useFirestoreQuoteEmails(quoteId: string, options?: Options<JobEmail>) {
  return useFirestoreCollection<JobEmail>('Email', `quotes/${quoteId}/emails`, {
    ...options,
    orderBys,
  });
}
