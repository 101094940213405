import * as React from 'react';
import { Box, BoxProps, Button, Card, CardRow, Text } from '@hd/ui';
import firebase from 'firebase/app';
import FirebaseList from '../Firebase/FirebaseList';
import ConfirmationModal from '../ConfirmationModal';
import useFirestoreJobEmails from '../../utils/useFirestoreJobEmails';
import toDateFormat from '../../utils/toDateFormat';
import toEmailTypeFormat from '../../utils/toEmailTypeFormat';
import { Check, Envelope, Refresh, X } from '@styled-icons/boxicons-regular';
import { TypeJobEmail } from '@hd/types';

interface Props extends BoxProps {
  jobId: string;
}

export default function JobNotesList(props: Props) {
  const { jobId, ...rest } = props;
  const [resendEmailType, setResendEmailType] = React.useState<
    undefined | string
  >();
  const data = useFirestoreJobEmails(jobId, { realtime: true });

  const handleResendEmail = async () => {
    setResendEmailType(undefined);
    await firebase.functions().httpsCallable('onResendEmail')({
      jobId: jobId,
      type: resendEmailType,
    });
  };

  const isLastEmailOfType = (type: TypeJobEmail, index: number): boolean => {
    for (let i = index + 1; i < data.ids.length; i++) {
      if (data.documents[data.ids[i]].type === type) {
        return false;
      }
    }

    return true;
  };

  return (
    <Box {...rest}>
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x2"
        margin="x3"
      >
        <Box>
          <Envelope size="1.5rem" />
        </Box>

        <Box grow>
          <Text strong>Emails</Text>
        </Box>
      </Box>

      <FirebaseList
        {...rest}
        {...data}
        backgroundColor="light"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Card>
          {data.ids.map((id, index) => (
            <CardRow
              alignChildrenVertical="middle"
              flex="horizontal"
              gap="x4"
              key={id}
              padding="x3"
            >
              {data.documents[id].sent ? (
                <Box textColor="green">
                  <Check size="2rem" />
                </Box>
              ) : (
                <Box textColor="red">
                  <X size="2rem" />
                </Box>
              )}

              <Box basis="none" grow>
                <Text>
                  <Text display="inline" strong>
                    {toEmailTypeFormat(data.documents[id].type)}
                  </Text>{' '}
                  ({toDateFormat(data.documents[id].timestamp, true)})
                </Text>
                <Text>{data.documents[id].recipient}</Text>

                {data.documents[id].error && (
                  <Text size="x2" textColor="red">
                    {data.documents[id].error}
                  </Text>
                )}
              </Box>

              {isLastEmailOfType(data.documents[id].type, index) ? (
                <Box>
                  <Button
                    onClick={() => setResendEmailType(data.documents[id].type)}
                  >
                    <Refresh size="1.25rem" />
                    <Text>Resend</Text>
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Button disabled>
                    <Refresh size="1.25rem" />
                    <Text>Resent</Text>
                  </Button>
                </Box>
              )}
            </CardRow>
          ))}
        </Card>
      </FirebaseList>

      <ConfirmationModal
        message="Are you sure you want to resend this email?"
        onCancel={() => setResendEmailType(undefined)}
        onConfirm={handleResendEmail}
        visible={!!resendEmailType}
      />
    </Box>
  );
}
