import { Box, Button, Page, PageBack, PageBody, PageHeader, PageTitle } from '@hd/ui';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormError, validateCCTVRecommendationProduct } from '../../utils/formValidators';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FormErrorMessage from '../FormErrorMessage';
import useFirestoreProduct from '../../utils/useFirestoreProduct';
import ProductForm from './ProductForm';
import { isPricingValid } from './util';
import ConfirmationModal from '../ConfirmationModal';

export default function ProducView() {
  const { push } = useHistory();
  const { productId } = useParams<{ productId: string }>();
  const [error, setError] = React.useState<FormError | null>(null);
  const data = useFirestoreProduct(productId);
  const { document, update, save, remove } = data
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)

  const handleDelete = () => {
    remove()
    push('/products')
  }

  const handleUpdate = async () => {
    const error = validateCCTVRecommendationProduct(document)
    if (error) {
      setError(error)
    } else if (!isPricingValid(document.pricing)) {
      setError({
        field: '',
        message: 'Contractor price exceed HD price'
      })
    }
    else {
      await save()
      push('/products')
    }
  }

  return (
    <>
      <FirebaseDocument {...data} allowNoDocument={!productId}>
        <Page>
          <PageHeader padding="x0" paddingHorizontal="x10">
            <Box alignChildrenVertical="end" flex='vertical' gap="x4" paddingVertical="x10">
              <Box basis="none" grow>
                <PageBack to={'/products'}>Products</PageBack>
              </Box>
              <Box
                alignChildrenVertical="middle"
                flex="horizontal"
                gap="x2">
                <PageTitle>{document.name} </PageTitle>
              </Box>
            </Box>
          </PageHeader>


          <PageBody>
            <ProductForm product={document} update={update} />

            {error && (
              <FormErrorMessage error={error} />
            )}
            <Box flex='horizontal' alignChildrenHorizontal='between'>
              <Button
                color="blue"
                level="primary"
                onClick={handleUpdate}>
                Update Product
              </Button>
              <Button
                color='red'
                level="primary"
                onClick={() => setDeleteModalVisible(true)}>
                Delete Product
              </Button>
              <ConfirmationModal
                message={'Are you sure you want to delete product'}
                onCancel={() => setDeleteModalVisible(false)}
                onConfirm={handleDelete}
                visible={deleteModalVisible}
              />
            </Box>
          </PageBody>
        </Page>
      </FirebaseDocument>
    </>
  )
}