import { Box, Card, CardProps, Progress, Text } from "@hd/ui";
import * as React from "react";
import { Data } from "../../utils/useFirestoreCollection";

interface Props<T> extends Data<T>, CardProps {
  onMore?: () => void;
}

export default function FirebaseList<T>(
  props: React.PropsWithChildren<Props<T>>
) {
  const { children, hasFailed, hasFetched, isEmpty, isFetching, onMore } =
    props;

  const refMoreTrigger = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (onMore && refMoreTrigger.current) {
      const target = refMoreTrigger.current;
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries.some((entry) => entry.isIntersecting)) {
            onMore();
          }
        },
        {
          threshold: [0.0],
        }
      );

      observer.observe(refMoreTrigger.current);

      return () => {
        observer.unobserve(target);
        observer.disconnect();
      };
    }
  }, [onMore]);

  if (hasFailed) {
    return (
      <Box alignChildren="middle" flex="vertical" padding="x6">
        <Text strong textColor="red">
          There was a problem
        </Text>
      </Box>
    );
  }

  if (isFetching) {
    return (
      <Box alignChildren="middle" flex="vertical" grow padding="x6">
        <Progress />
      </Box>
    );
  }

  if (hasFetched && isEmpty) {
    return (
      <Card alignChildren="middle" flex="vertical" padding="x6">
        <Text size="x2" strong>
          No records have been found.
        </Text>
      </Card>
    );
  }

  return (
    <Box container>
      {children}
      <Box
        absolute="bottom"
        height={0}
        ref={refMoreTrigger}
        style={{ transform: `translateY(-${window.innerHeight / 3}px)` }}
      />
    </Box>
  );
}
