import { CCTVRecommendationProduct, CCTVRecommendationProductAddOn } from "@hd/types";
import useFirestoreCCTVProducts from "../../utils/useFirestoreCCTVProducts";
import useFirestoreCollection, { Options, TypeFilter } from "../../utils/useFirestoreCollection";
import React from "react";
import useKeywordFilter from "../../utils/useKeywordFilter";
import useMergedFilters from "../../utils/useMergedFilters";
import { Card, Box, CardRow, Text, Button, FormInput, TextInput } from "@hd/ui";
import { Plus, X } from "@styled-icons/boxicons-regular";
import FirebaseList from "../Firebase/FirebaseList";
import ProductAddOnAddModal from "./ProductAddOnAddModal";

type Props = {
  onClose: () => void;
  onClick?: (id: string) => void;
  action?: (id: string) => void;
  options?: Options<CCTVRecommendationProductAddOn>;
  isSearchable?: boolean;
  canAdd?: boolean;
  actionIcon?: JSX.Element;
  // firestore limitation on 10 records screws us here
  filterIds?: string[];
  filterNotIds?: string[];
}


export default function ProductAddOnList(props: Props) {
  const { filterIds, filterNotIds, isSearchable, options, canAdd, action, actionIcon, onClick } = props;
  const [value, setValue] = React.useState('');
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters)
  const [productAddOnModalVisible, setProductAddOnModalVisible] = React.useState(false)
  const data = useFirestoreCollection<CCTVRecommendationProductAddOn>(
    'CCTVRecommendationProductAddOn',
    'product-add-ons',
    {
      filters,
      limit: 50,
    }
  );

  let filteredDocuments = Object.values(data.documents)
  if (filterIds) {
    filteredDocuments = filteredDocuments.filter(e => filterIds.includes(e.id))
  }
  if (filterNotIds) {
    filteredDocuments = filteredDocuments.filter(e => !filterNotIds.includes(e.id))
  }

  return (
    <>
      {
        (canAdd || isSearchable) &&
        <Card
          backgroundColor="light"
          flex="horizontal"
          gap="x4"
          margin="x3"
          padding="x3"
        >
          <Box grow>
            {isSearchable && (
              <FormInput margin="x3">
                <TextInput
                  onChange={(e) => setValue((e.target as HTMLInputElement).value)}
                  placeholder="Search products"
                  value={value}
                />
              </FormInput>
            )}
          </Box>

          {
            canAdd &&
            <Box>
              <Button
                color="blue"
                data-at-id="AddProductButton"
                level="tertiary"
                onClick={() => setProductAddOnModalVisible(true)}
              >
                <Plus size="1.5rem" />
                <Text>Add add-on</Text>
              </Button>
            </Box>
          }
        </Card>
      }
      {filteredDocuments.length === 0 ?
        <Card alignChildren="middle" flex="vertical" padding="x6">
          <Text size="x2" strong>
            No records have been found.
          </Text>
        </Card>


        :
        <Card> {filteredDocuments.map(e =>


          <CardRow
            key={e.id}
            padding="x4"
            onClick={onClick ? () => onClick(e._id) : undefined}
          >
            <Box alignChildrenVertical="middle" flex="horizontal" gap="x10">
              <Box basis="none" grow>
                <Text
                  alignChildrenVertical="middle"
                  flex="horizontal"
                  gap="x2"
                  strong
                >
                  {e.name}
                </Text>

                <Box margin="x3">
                </Box>
              </Box>


              <Box alignChildrenHorizontal="end" flex="vertical" gap="x2">
                {action && <Button onClick={() => action(e.id)}>{actionIcon}</Button>}
              </Box>
            </Box>
          </CardRow>

        )}
        </Card>
      }
      <ProductAddOnAddModal
        onClose={() => setProductAddOnModalVisible(false)}
        visible={productAddOnModalVisible}
      />
    </>

  )


}