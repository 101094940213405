import { CCTVRecommendationSubcategory } from '@hd/types';
import { Box, Text } from '@hd/ui';
import React from 'react';

export interface Props {
  subcategory: CCTVRecommendationSubcategory;
}

export default function SubcategorySelectionViewCategory(props: Props) {
  const { subcategory } = props;

  return ( 
    <Box grow alignChildrenHorizontal='middle' alignChildrenVertical='middle'>
      <Box>
        <Text strong size='x3' textColor='dark-gray'> {subcategory.cachedParentName} </Text>
      </Box> 
    </Box>
  );
}
