import * as React from 'react';
import {
  Box,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
} from '@hd/ui';
import { useHistory } from 'react-router-dom';
import CategoriesList from './CategoriesList';

export default function Categories() {
  const { push } = useHistory();


  return (
    <Page data-at-id="ProdutcsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Categories</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <CategoriesList
          isSearchable
          onSelect={(categories) => push(`/products/categories/${categories._id}`)}
        />
      </PageBody>
    </Page>
  );
}
