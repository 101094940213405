import * as React from 'react';
import {
  Box,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  TextInput,
  Text,
} from '@hd/ui';
import * as admin from 'firebase-admin';

import { Customer, Site } from '@hd/types';
import FormInputAddress from '../FormInputs/FormInputAddress';
import FormInputArray from '../FormInputs/FormInputArray';
import Dropdown from '../Dropdown/Dropdown';
import { customerVatTypes } from '../../constants/vatTypes';
import MapSearchInput from '../MapSearchInput';

interface Props {
  document: Customer;
  update: (updates: Partial<Customer>) => void;
  site?: Site;
}

export default function CustomerForm(props: Props) {
  const [isUsingSiteAddress, setUsingSiteAddress] = React.useState(false);
  const { document, update, site } = props;

  const handleUpdateManagementFee = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.value === '') {
      update({
        managamentFee: null,
      });
      return;
    }
    const value = parseFloat(target.value);

    if (!isNaN(value)) {
      update({
        managamentFee: value,
      });
    }
  };

  const handleUpdateFromSite = () => {
    setUsingSiteAddress(!isUsingSiteAddress);

    if (!isUsingSiteAddress && site) {
      update({
        addressLine1: site.addressLine1,
        addressLine2: site.addressLine2,
        addressLine3: site.addressLine3,
        addressPostcode: site.addressPostcode,
        name: site.name,
        contactName: site.contactName,
        contactPhoneNumbers: [site.contactPhoneNumber],
      });
    } else {
      update({
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressPostcode: '',
        name: '',
        contactName: '',
        contactPhoneNumbers: [],
      });
    }
  };

  return (
    <>
      {site && (
        <CheckBox
          checked={isUsingSiteAddress}
          data-at-id="CustomerSameSite"
          margin="x2"
          onChange={handleUpdateFromSite}
        >
          Customer is same as site?
        </CheckBox>
      )}
      {!isUsingSiteAddress && <MapSearchInput
              onChange={s => {
                update({
                    addressLine1: s.addressLine1,
                    addressLine2: s.addressLine2 || '',
                    addressLine3: s.addressLine3 || '',
                    addressPostcode: s.addressPostcode

                })
              }} value={{
                    _id: "",
                    _ref: '',
                    addressLine1: document.addressLine1 || '',
                    addressLine2: document.addressLine2 || '',
                    addressLine3: document.addressLine3 || '',
                    addressPostcode: document.addressPostcode || '' ,
                    name: '',
                    contactName: '',
                    contactPhoneNumber: ''
                  
                  }}/>
                }

      <FormInput margin="x6">
        <FormInputHeader>
          <FormInputLabel>Business/Customer Name</FormInputLabel>
        </FormInputHeader>

        <TextInput
          onChange={(e) =>
            update({ name: (e.target as HTMLInputElement).value })
          }
          required
          value={document.name}
        />
      </FormInput>

      <FormInput margin="x6">
        <FormInputHeader>
          <FormInputLabel>Contact Name</FormInputLabel>
        </FormInputHeader>

        <TextInput
          onChange={(e) =>
            update({ contactName: (e.target as HTMLInputElement).value })
          }
          required
          value={document.contactName}
        />
      </FormInput>

      <FormInput margin="x6">
        <FormInputHeader>
          <FormInputLabel>Phone Number(s)</FormInputLabel>
        </FormInputHeader>

        <FormInputArray
          onChange={(contactPhoneNumbers) => update({ contactPhoneNumbers })}
          type="phone number"
          values={document.contactPhoneNumbers}
        />
      </FormInput>

      <FormInput margin="x6">
        <FormInputHeader>
          <FormInputLabel>Email Address(es)</FormInputLabel>
        </FormInputHeader>

        <FormInputArray
          data-at-id="ContactEmails"
          onChange={(contactEmails) => update({ contactEmails })}
          type="email address"
          values={document.contactEmails}
        />
      </FormInput>

      <Box flex="horizontal" alignChildrenHorizontal="around" gap="x4" alignChildrenVertical='start'>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>VAT Status</FormInputLabel>
            </FormInputHeader>

            <Dropdown
              onChange={(vatType) => update({ vatType })}
              options={customerVatTypes}
              value={document.vatType}
            />
          </FormInput>

          <FormInput grow >
            <FormInputHeader>
              <FormInputLabel align='middle'>Account on hold</FormInputLabel>
            </FormInputHeader>

            <Box flex='horizontal' alignChildrenHorizontal='middle' grow>
              <CheckBox checked={document.accountOnHold} onChange={() => {
                update({
                  accountOnHold: !document.accountOnHold
                })
              }}/>
            </Box>
          </FormInput>


          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Management fee</FormInputLabel>
            </FormInputHeader>
            <Box flex="horizontal">
              <Box grow>
                <TextInput
                  onChange={handleUpdateManagementFee}
                  value={document.managamentFee || '0'}
                />
              </Box>
              <Box backgroundColor="light-gray" padding="x2">
                <Text strong textColor="dark-gray">
                  %
                </Text>
              </Box>
            </Box>
          </FormInput>
      </Box>

      <FormInputAddress document={document} margin="x6" update={update} />
    </>
  );
}
