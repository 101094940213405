import * as React from 'react';
import { Box, Button, Card, CardRow, FormInput, Text, TextInput } from '@hd/ui';
import { CCTVRecommendationSubcategory } from '@hd/types';
import { Plus } from '@styled-icons/boxicons-regular';
import FirebaseList from '../../Firebase/FirebaseList';
import useFirestoreCollection, {
  Options,
} from '../../../utils/useFirestoreCollection';
import useKeywordFilter from '../../../utils/useKeywordFilter';
import useMergedFilters from '../../../utils/useMergedFilters';
import AdminNoteOrQuoteModal from '../../Admin/AdminNoteOrQuoteModal';
import SubcategoryAddModal from './SubcategoryAddModal';

interface Props {
  isSearchable?: boolean;
  options?: Options<CCTVRecommendationSubcategory>;
  onSelect: (jobType: CCTVRecommendationSubcategory) => void;
}

const groupByCategory = (ids: string[], subcategories: Record<string, CCTVRecommendationSubcategory>) => {
  const groupedIds: Record<string, string[]> = {};

  for (const id of ids) {
    const subcategory = subcategories[id];

    if (subcategory) {
      const { cachedParentName, parentId } = subcategory;
      const key = cachedParentName || parentId;

      groupedIds[key] = groupedIds[key] || [];
      groupedIds[key].push(id);
    }
  }

  return groupedIds;
};

export default function SubcategoriesList(props: Props) {
  const { isSearchable, onSelect, options } = props;
  const [value, setValue] = React.useState('');
  const [isAddSubCategoryModal, setIsAddSubcategoryModalVisible] =
    React.useState(false);
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);

  const data = useFirestoreCollection<CCTVRecommendationSubcategory>(
    'CCTVRecommendationSubcategory',
    'product-subcategories',
    {
      filters: filters,
      limit: 50,
    }
  );

  const groupedIds = groupByCategory(data.ids, data.documents);


  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow>
          {isSearchable && (
            <FormInput margin="x3">
              <TextInput
                onChange={(e) => setValue((e.target as HTMLInputElement).value)}
                placeholder="Search products"
                value={value}
              />
            </FormInput>
          )}
        </Box>

        <Box>
          <Button
            color="blue"
            data-at-id="AddNoteOrQuoteButton"
            level="tertiary"
            onClick={() => setIsAddSubcategoryModalVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add Subcategory</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data} onMore={data.more}>
      {Object.entries(groupedIds).map(([category, ids]) => (
        <Box key={category} margin="x10">
          <Box alignChildren="middle" flex="horizontal" gap="x4" margin="x2">
            <Text size="x3" strong>
              {category} 
            </Text>

            <Box grow>
              <Text borderColor="gray" borderSides="T" borderSize="x1" />
            </Box>
          </Box>

          <Card>
          {ids.map((id) => (
            <CardRow
              alignChildrenVertical="middle"
              data-at-id="ContractorLisItem"
              flex="horizontal"
              key={id}
              onClick={() => onSelect(data.documents[id])}
              padding="x4"
            >
              <Box grow>
                <Text strong>{data.documents[id].name}</Text>
                <Text size="x1" weak>
                </Text>
              </Box>
            </CardRow>
          ))}
</Card>
        </Box>
      ))}
      </FirebaseList>

      <SubcategoryAddModal
        onClose={() => setIsAddSubcategoryModalVisible(false)}
        visible={isAddSubCategoryModal}
      />
    </>
  );
}
