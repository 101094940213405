import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import { Box, Button, Card, Page, PageBack, PageBody, PageDescription, PageHeader, PageTitle, Text } from '@hd/ui';
import { Save, Trash } from '@styled-icons/boxicons-regular';
import getAddress from '../../utils/getAddress';
import useFirestoreCustomer from '../../utils/useFirestoreCustomer';
import { validateCustomer, FormError } from '../../utils/formValidators';
import { TypeFilter } from '../../utils/useFirestoreCollection';
import CustomerForm from './CustomerForm';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FormErrorMessage from '../FormErrorMessage';
import ConfirmationModal from '../ConfirmationModal';
import SitesList from '../Sites/SitesList';
import useQueryParams from '../../utils/useQueryParams';

export default function Customer() {
  const { push } = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const data = useFirestoreCustomer(customerId);
  const [isRemoveCustomerModalVisible, setIsRemoveCustomerModalVisible] = React.useState(false);
  const [error, setError] = React.useState<FormError | null>(null);

  const filters = React.useMemo(() => (data.document._ref
    ? [['customer', '==', firebase.firestore().doc(data.document._ref)]]
    : []) as TypeFilter[], [data.document]);

  const handleRemoveCustomer = async() => {
    await data.remove();
    push('/customers');
  };
  const queryParams = useQueryParams();
  const redirectUrl = queryParams.get('redirect') as null | string;

  const handleSubmit = async() => {
    const error = validateCustomer(data.document);
    setError(error);

    if (!error) {
      await data.save();
      if (redirectUrl) {
        push('/jobs/' + redirectUrl);
      } else {
        push('/customers');
      }
    }
  };

  return (
    <FirebaseDocument { ...data }>
      <Page>
        <PageHeader>
          <Box alignChildrenVertical="end" flex="horizontal">
            <Box grow>
              <PageBack to="/customers">Customers</PageBack>
              <PageTitle>{ data.document.name }</PageTitle>
              <Text strong> { data.document.sageReference }</Text>
              <PageDescription>{ getAddress(data.document) }</PageDescription>
            </Box>

            <Box flex="horizontal" gap="x2">
              <Button
                  color="blue"
                  level="tertiary"
                  onClick={ handleSubmit }
                  type="submit">
                <Save size="1.25rem" />
                <Text>Save</Text>
              </Button>

              <Button
                  color="red"
                  disabled
                  level="tertiary"
                  onClick={ () => setIsRemoveCustomerModalVisible(true) }>
                <Trash size="1.25rem" />
                <Text>Remove (Disabled)</Text>
              </Button>
            </Box>
          </Box>
        </PageHeader>

        <PageBody>
          { error && (
            <FormErrorMessage error={ error } />
          ) }

          <Card backgroundColor="light" margin="x6" padding="x10">
            <CustomerForm
                document={ data.document }
                update={ data.update } />

          </Card>

          <Box margin="x16">
            <Box
                alignChildrenVertical="start"
                flex="horizontal"
                margin="x3">
              <Box grow>
                <Text size="x4">Sites</Text>
              </Box>
            </Box>

            <SitesList
                isResultsVisibleBeforeSearch
                onSelect={ (site) => push(`/sites/${site._id}`) }
                options={ { filters: filters, realtime: true } } />
          </Box>
        </PageBody>
      </Page>

      <ConfirmationModal
          message={ `Are you sure you want to remove the customer "${data.document.name}"?` }
          onCancel={ () => setIsRemoveCustomerModalVisible(false) }
          onConfirm={ handleRemoveCustomer }
          visible={ isRemoveCustomerModalVisible } />
    </FirebaseDocument>
  );
}
