import { CCTVReccomendation } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreCCTVReccomendations(id?: string) {
  return useFirestoreDocument<CCTVReccomendation>('CCTVReccomendation', 'cctv-reccomendations', id, {
    jobState: 'IN_PROGRESS',
    repairs: [],
    details: {},
    publicNotes: '',
    privateNotes: '',
    timestampResolved: null
  });
}
