import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardRow,
  FormInput,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
  Text,
  TextInput,
} from '@hd/ui';
import { useHistory } from 'react-router-dom';
import { Plus } from '@styled-icons/boxicons-regular';
import ProductCreateModal from './ProductCreateModal';
import { isAdmin } from '../../utils/authLevel';

type Product = {
  name: string;
  parentId: string;
  id: number;
};

export const parentIds: Record<string, string> = {
  '0': 'CCTV Surveys /Investigations',
  '11': 'Material costs for bends/junctions/cover and frames',
  '1': 'Survey Abandoned/Descale/Picote',
  '10': 'Remove interceptor/winser trap',
  '2': 'Confined Space/Second Man',
  '5': 'Rerounding including Polyester Drain Lining',
  '4': 'Patch Repairs',
  '7': 'Gullies excluding surround reinstatement',
  '12': 'Waste disposal',
  '3': 'Drain lining polyester',
  '8': 'Spot dig including Beds, Surrounds and Connetions',
  '9': 'Soakaways',
  '16': 'Lining 150mm polyester liner dia',
  '6': 'Reinstate reinforced concrete, maximum thickness 100mm.(m2)',
};

export default function Admin() {
  const { push } = useHistory();
  const [products, setProducts] = React.useState<Product[]>();
  const [loading, setLoading] = React.useState(true);
  const [groupedProducts, setGroupedProducts] =
    React.useState<Record<string, Product[]>>();
  const [value, setValue] = React.useState('');
  const [isAddProductVisible, setIsAddProductVisible] = React.useState(false);

  const test = async (searchString: string) => {
    const response: Product[] = await fetch(
      `https://drainreporter.com/list-products?searchString=${searchString}`,
      {
        method: 'GET',
        headers: {
          'x-happy-drains':
            '2dabfab0996332832f8adbf62d0eb3472358e96acf17e0d841ce951d66dd552a',
          'Access-Control-Allow-Origin': '*',
        },
      }
    ).then((e) => e.json());
    setProducts(response);
    setLoading(false);
  };

  React.useEffect(() => {
    test('');
  }, []);

  React.useEffect(() => {
    test(value);
  }, [value]);

  React.useEffect(() => {
    const a: Record<string, Product[]> = {};
    if (products) {
      for (const product of products) {
        a[parentIds[product.parentId]] = a[parentIds[product.parentId]] || [];
        a[parentIds[product.parentId]].push(product);
      }
      setGroupedProducts(a);
    }
  }, [products]);

  if (!isAdmin()) {
    return null;
  }

  return (
    <Page data-at-id="AdminPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Admin page</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <Card
          backgroundColor="light"
          flex="horizontal"
          gap="x4"
          margin="x3"
          padding="x3"
        >
          <Box grow>
            <FormInput margin="x3">
              <TextInput
                onChange={(e) => setValue((e.target as HTMLInputElement).value)}
                placeholder="Search products"
                value={value}
              />
            </FormInput>
          </Box>
          <Button
            color="blue"
            data-at-id="AddProductButton"
            level="tertiary"
            onClick={() => setIsAddProductVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add Product</Text>
          </Button>
        </Card>

        {loading && <Text>Loading</Text>}
        {groupedProducts &&
          Object.keys(groupedProducts).map((key, i) => (
            <Box key={i}>
              <Box margin="x4">
                <Text size="x4" strong>
                  {key}
                </Text>
              </Box>
              <Card>
                {groupedProducts[key].map((id) => (
                  <CardRow
                    padding="x4"
                    onClick={() => push(`/admin/products/${id.id}`)}
                    alignChildrenVertical="middle"
                    data-at-id="ContractorLisItem"
                    flex="horizontal"
                    key={id.name}
                  >
                    <Box grow>
                      <Text strong>{id.name}</Text>
                    </Box>
                  </CardRow>
                ))}
              </Card>
            </Box>
          ))}
      </PageBody>
      <ProductCreateModal
        onClose={() => setIsAddProductVisible(false)}
        visible={isAddProductVisible}
      />
    </Page>
  );
}
