import {
  Box,
  Button,
  Modal,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
} from '@hd/ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import useFirestoreQuote from '../../utils/useFirestoreQuote';
import QuoteForm from './QuoteForm';
import AdminJobsList from '../Admin/AdminJobsList';
import FormErrorMessage from '../FormErrorMessage';
import { FormError, validateQuote } from '../../utils/formValidators';
import firebase from 'firebase';

export default function QuoteCreate() {
  const { push } = useHistory();
  const quote = useFirestoreQuote();
  const [jobListVisible, setJobListVisible] = React.useState(false);
  const [error, setError] = React.useState<FormError | null>(null);

  const handleCreate = async () => {
    const error = validateQuote(quote.document);
    const price = parseFloat(quote.document.price.toString());

    if (isNaN(price)) {
      setError({
        field: '',
        message: 'Please ensure price is a number.',
      });
    } else {
      setError(error);
    }

    if (!error) {
      await quote.save();
      push('/quotes');
    }
  };

  return (
    <>
      <FirebaseDocument {...quote} allowNoDocument>
        <Page>
          <PageHeader>
            <PageTitle>New Quote</PageTitle>
          </PageHeader>

          <PageBody>
            <QuoteForm quote={quote.document} update={quote.update} />

            {error && <FormErrorMessage error={error} />}

            <Box
              alignChildrenHorizontal="end"
              flex="horizontal"
              gap="x2"
              margin="x4"
            >
              <Button
                color="blue"
                data-at-id="CreateJob"
                level="primary"
                onClick={handleCreate}
              >
                Create Quote
              </Button>
            </Box>
          </PageBody>
        </Page>
      </FirebaseDocument>
      <Modal
        maxWidth="600px"
        onClose={() => setJobListVisible(false)}
        visible={jobListVisible}
      >
        <AdminJobsList
          isResultsVisibleBeforeSearch={false}
          onSelect={(jobParent) => {
            quote.update({
              customer: jobParent.customer,
              customerSite: jobParent.customerSite,
              customerName: jobParent.customer?.name,
              siteAddressPostcode: jobParent.customerSite?.addressPostcode,
              customerVat: jobParent.vatRate,
              parent: jobParent,
            });
            setJobListVisible(false);
          }}
          visible={jobListVisible}
        />
      </Modal>
    </>
  );
}
