import { Quote } from '@hd/types';
import { Text, Box, Appear, Button } from '@hd/ui';
import { Check, X } from '@styled-icons/boxicons-regular';
import * as React from 'react';
import toDateFormat from '../../utils/toDateFormat';
import useFirestoreQuoteEmails from '../../utils/useFirestoreQuoteEmails';
import JobReportFollowUpQuoteManualSendEmail from './JobReportFollowUpQuoteManualSendEmail';

type Props = {
  quote: Quote;
  save: () => Promise<Quote>;
};

export default function JobReportFollowUpQuoteEmails({ quote, save }: Props) {
  const data = useFirestoreQuoteEmails(quote._id, { realtime: true });
  const [showLog, setShowLog] = React.useState(false)
  if (!data) {
    return null;
  }

  return (
    <>
      <Box flex='horizontal' grow>
          <JobReportFollowUpQuoteManualSendEmail quote={quote} save={save} />
          <Button shrink size='small' onClick={() => setShowLog(!showLog)}>{showLog ? 'Hide log' : `Show log (${data.ids.length})`}</Button>
      </Box>
      {showLog && data.ids.map((e) => (
        <Appear
          key={e}
          flex="horizontal"
          gap="x2"
          animation='FadeSlideDown'
          padding="x2"
          backgroundColor="light-gray"
        >
          <Text strong>{data.documents[e].recipient} </Text>
          <Text>{toDateFormat(data.documents[e].timestamp, true)}</Text>
          {data.documents[e].sent ? (
            <Text textColor="green">
              {' '}
              <Check size="2rem" />{' '}
            </Text>
          ) : (
            <Text textColor="red">
              {' '}
              <X size="2rem" />{' '}
            </Text>
          )}
        </Appear>
      ))}
    </>
  );
}
