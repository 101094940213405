import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Box, Card, CardRow, Text } from '@hd/ui';
import { Customer, Quote } from '@hd/types';
import FirebaseList from '../Firebase/FirebaseList';
import { Options, TypeOrderBy } from '../../utils/useFirestoreCollection';
import useFirestoreQuotes from '../../utils/useFirestoreQuotes';
import getAddress from '../../utils/getAddress';
import { MapPin, User } from '@styled-icons/boxicons-regular';

interface Props {
  options?: Options<Quote>;
}

const orderBys: TypeOrderBy<Quote>[] = [['createdAt', 'desc']];

export default function QuotesList(props: Props) {
  const { options } = props;
  const { push } = useHistory();
  const data = useFirestoreQuotes({
    ...options,
    orderBys,
    limit: 50,
  });

  return (
    <FirebaseList {...data} onMore={data.more}>
      <Card>
        {data.ids.map((id) => (
          <CardRow key={id} onClick={() => push(`/quotes/${id}`)} padding="x4">
            <Box alignChildrenVertical="middle" flex="horizontal" gap="x10">
              <Box basis="none" grow>
                <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
                  <Badge backgroundColor="dark-gray" textColor="light-gray">
                    {data.documents[id].quoteId}
                  </Badge>
                  <Badge backgroundColor="gray" textColor="dark-gray">
                    <Box flex="horizontal" gap="x3">
                      <MapPin size="0.75rem" />
                      <Box>
                        {data.documents[id].customerSite
                          ? getAddress(data.documents[id].customerSite)
                          : [
                              data.documents[id].siteAddressLine1,
                              data.documents[id].siteAddressLine2,
                              data.documents[id].siteAddressLine3,
                              data.documents[id].siteAddressPostcode,
                            ]
                              .filter((v) => v)
                              .join(', ')}
                      </Box>
                    </Box>
                  </Badge>
                  <Badge backgroundColor="light-gray" flex="horizontal">
                    <User size="1rem" />
                    <Text>
                      {data.documents[id].customer
                        ? (data.documents[id].customer as unknown as Customer)
                            .name
                        : data.documents[id].customerName}
                    </Text>
                  </Badge>
                  <Badge backgroundColor="light-gray" flex="horizontal">
                    <Text>{data.documents[id].status}</Text>
                  </Badge>
                </Box>
              </Box>
            </Box>
          </CardRow>
        ))}
      </Card>
    </FirebaseList>
  );
}
