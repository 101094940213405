import { JobFile } from '@hd/types';
import { File as FileIcon } from '@styled-icons/boxicons-regular';
import { Box, BoxProps, Link, Text } from '@hd/ui';
import * as React from 'react';

interface Props extends BoxProps {
  file: File | JobFile;
  name: string;
  onRemoveFile: () => void;
}

export default function FileItem(props: Props) {
  const { file, onRemoveFile, name, ...rest } = props;

  return (
    <Box alignChildrenVertical="middle" flex="vertical" gap="x3" {...rest}>
      <Box flex="horizontal" gap="x2" grow>
        <Box textColor="gray">
          <FileIcon size="1.25rem" />
        </Box>
        <Text basis="none" grow strong>
          <Text strong>{name}</Text>{' '}
        </Text>
      </Box>
      <Box flex="horizontal" gap="x6">
        {!(file instanceof File) && file.downloadUrl && (
          <Link
            onClick={() => window.open(file.downloadUrl, file.name)}
            borderSize="x1"
            borderColor="gray"
            padding="x3"
          >
            View
          </Link>
        )}
        {
          <Link
            onClick={() => onRemoveFile()}
            textColor="red"
            borderSize="x1"
            borderColor="gray"
            padding="x3"
          >
            Delete
          </Link>
        }
      </Box>
    </Box>
  );
}
