import * as React from 'react';
import {
  Box,
  Button,
  Text,
  TextInput,
  CheckBox,
  Appear,
  Badge
} from '@hd/ui';
import { AddOnRepair, CCTVRecommendationProduct, Repair, SectionApplication, UnitTypeType } from '@hd/types';
import { Step } from '../CCTVRecommendationsRecommendationForm';
import { v4 } from 'uuid';
import useFirestoreCCTVProducts from '../../../utils/useFirestoreCCTVProducts';
import { Check, X } from '@styled-icons/boxicons-regular';
import AddOnSelector from './AddOnSelector';
import useFirestoreCCTVProductAddOns from '../../../utils/useFirestoreCCTVProductAddOns';


type Props = {
  activeStepId?: Step;
  onAdd: (repair: Repair) => void;
  update: (updates: Partial<Repair>) => void;
  setStepId: (step?: Step) => void;
  repair: Repair;
  onClose: () => void;
}

const unitTypeToHumanReadable: Record<UnitTypeType, string> = {
  Quantity: '#',
  Meterage: 'M',
  HourRate: 'Hrs',
  HalfDayRate: 'Half days',
  DayRate: 'Days'
}

export default function ProductSelectionAddOns(props: Props) {
  const { activeStepId, onAdd, setStepId, repair, onClose, update } = props;
  const { product } = props.repair
  const addOns = useFirestoreCCTVProductAddOns()
  const [active, setActive] = React.useState<string | undefined>()

  if (activeStepId !== 'ProductAddOns') {
    return null;
  }

  const add = (addOn: AddOnRepair) => {
    update({
      addOns: [
        ...(repair.addOns || []), addOn
      ]
    })
  }

  const remove = (removeId: string) => {
    update({
      addOns: repair.addOns?.filter(e => e.product?.id !== removeId)
    })
  }


  return (<Box flex='vertical' gap='x3'>
    <Text strong>Add ons</Text>
    <Box flex='vertical' gap='x2'>

      {
        Object.values(addOns.documents).filter(addOn => product?.addOns?.includes(addOn.id)).map(addOn =>
          <AddOnSelector
            key={addOn.id}
            onAdd={add}
            onRemove={remove}
            onSelect={(id) => setActive(id)}
            onCancel={() => setActive(undefined)}
            currentAddOns={repair.addOns || []}
            addOn={addOn}
            isSelected={active === addOn.id} />

        )}
    </Box>
    <Box flex='horizontal' alignChildrenHorizontal='between'>
      <Button onClick={() => {
        if (product?.requiresAtPosition || product?.requiresFromToPosition) {
          setStepId('ProductMetadata')
        } else {
          setStepId('ProductDetails')
        }
      }}>{`< Prev`}</Button>
      <Button

        disabled={active !== undefined}
        onClick={() => {
          onAdd(repair)
          setActive(undefined)
          onClose()
        }} color='blue' > +</Button>
    </Box>
  </Box>
  )
}
