import firebase from 'firebase';

const authorised = [
   "david@happydrains.co.uk",
   "sam.joseph.miele@gmail.com",
   "max@happydrains.co.uk",
   "william@happydrains.co.uk",
   "jason@happydrains.co.uk"
]

export function isAdmin() {
  const email = firebase.auth().currentUser?.email;
  if (email) {
    return authorised.includes(email);
  } else return false;
}

export function isSam() {
  const email = firebase.auth().currentUser?.email;
  if (email) {
    return authorised.includes("sam.joseph.miele@gmail.com");
  } else return false;
}
