import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardRow,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Image,
  LabelledList,
  Link,
  Modal,
  Page,
  PageBody,
  PageHeader,
  PageLoading,
  Text,
  TextInput,
} from '@hd/ui';
import useFirestoreCollection, {
  TypeFilter,
} from '../../utils/useFirestoreCollection';
import useFirestoreConfig from '../../utils/useFirestoreConfig';
import { WebInquiry, WebInquiryConversion } from '@hd/types';
import useFirestoreDocument from '../../utils/useFirestoreDocument';
import toCurrencyFormat from '../../utils/toCurrencyFormat';
import {
  FormError,
  validateWebInquiryConversion,
} from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';
import { HelpCircle } from '@styled-icons/boxicons-regular';

export default function WebInquiryAutomatic() {
  const { magicId } = useParams<{ magicId: string }>();
  const filters = React.useMemo(
    () => [['magicId', '==', magicId]] as TypeFilter[],
    [magicId]
  );
  const [error, setError] = React.useState<FormError | null>(null);

  const {
    document: webInquiryConversion,
    update,
    save,
  } = useFirestoreDocument<WebInquiryConversion>(
    'WebInquiryConversion',
    'webInquiryConversions',
    undefined,
    {
      siteIsCustomer: true,
      customerAddressLine3: '',
      siteAddressLine3: '',
    }
  );

  const { documents, ids, isFetching } = useFirestoreCollection<WebInquiry>(
    'WebInquiry',
    'webInquiries',
    {
      filters: filters,
      limit: 1,
    }
  );

  const d = documents[ids[0]];
  const { document: config } = useFirestoreConfig();
  const {
    document,
    hasDocument,
    update: queryUpdate,
    save: querySave,
  } = useFirestoreDocument<WebInquiry>(
    'WebInquiry',
    'webInquiries',
    d ? d._id : undefined,
    {}
  );

  React.useEffect(() => {
    if (hasDocument) {
      update({
        customerName: document.firstname + ' ' + document.surname,
        customerEmail: document.email,
        customerTelephone: document.phone,
        customerPostcode: document.postcode,
        problem: document.problem,

        webInquiry: document,
        instantQuoteJobType: document.instantQuoteJobType,
      });
    }
  }, [hasDocument]);

  const submit = async () => {
    // validate
    // carry over description? Or from customer?
    const error = validateWebInquiryConversion(webInquiryConversion);
    setError(error);
    if (!error) {
      queryUpdate({
        status: 'CONVERTED',
      });
      await save();
      await querySave();
    }
    // push
  };

  const [bookModalVisible, setBookModalVisible] = React.useState(false);

  const vatRate = config.vatRate;

  const priceBreakdown = () => [
    [
      'Total ex VAT',
      toCurrencyFormat(document.instantQuoteJobType?.priceCustomer?.toString()),
    ],
    ['VAT', vatRate + '%'],
    [
      'Total Inc VAT',
      toCurrencyFormat(
        (
          document.instantQuoteJobType.priceCustomer *
          (1 + vatRate / 100)
        ).toString()
      ),
    ],
  ];

  if (isFetching) {
    return <PageLoading />;
  }

  if (!hasDocument) {
    return (
      <Box padding="x10">
        <Card maxWidth="600px" maxWidthCentered>
          <Image src={require('@hd/ui/assets/header-1.png')} />
          <Text align="middle" margin="x20" maxWidthCentered strong>
            Query not found.
          </Text>
          <Image src={require('@hd/ui/assets/footer.jpg')} />
        </Card>
      </Box>
    );
  }

  if (document.status === 'CONVERTED') {
    return (
      <Box alignChildren="middle" flex="vertical" grow>
        <Text textColor="blue" strong>
          Good news! We are looking into your problem.
        </Text>
        <Text textColor="blue">You'll hear from us shortly</Text>
      </Box>
    );
  }

  if (document.status === 'DELETED') {
    return (
      <Box alignChildren="middle" flex="vertical" grow>
        <Text textColor="red" strong>
          Unfortunately this web inquiry has expired.
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Page>
        <Box flex="horizontal" alignChildrenHorizontal="middle">
          <Image src={require('@hd/ui/assets/header-1.png')} maxWidth="600px" />
        </Box>

        <PageBody>
          <Box flex="horizontal" gap="x1" alignChildrenVertical="middle">
            <Text strong>Details</Text>
            <HelpCircle size="1rem" />
          </Box>
          <Box flex="vertical" gap="x3">
            <CardRow>
              <Card padding="x2">
                <Box flex="vertical" gap="x3">
                  {document.instantQuoteJobType?.salesInfo
                    ?.split('\n')
                    .map((para, i) => (
                      <Text emphasis key={i} size="x2">
                        {para}
                      </Text>
                    ))}
                  <Text>
                    If you have any additional requirments, or need
                    clarification. Please do not hesistate to contact us
                  </Text>
                  <Text>
                    Any questions? Please{' '}
                    <Link href="mailto:service@happydrains.co.uk">
                      contact us
                    </Link>
                    .
                  </Text>
                </Box>
              </Card>
            </CardRow>
            <CardRow>
              <Card padding="x2">
                <LabelledList items={priceBreakdown()} margin="x4" />
                <Box alignChildrenHorizontal="middle" flex="horizontal">
                  <Button
                    color="blue"
                    level="primary"
                    onClick={() => setBookModalVisible(true)}
                  >
                    Click here to book now!
                  </Button>
                </Box>
              </Card>
            </CardRow>
            <Image src={require('@hd/ui/assets/footer.jpg')} />
          </Box>
        </PageBody>
      </Page>
      <Modal
        onClose={() => setBookModalVisible(false)}
        visible={bookModalVisible}
      >
        <Card
          backgroundColor="light"
          flex="vertical"
          gap="x3"
          margin="x4"
          padding="x6"
        >
          <Box>
            <Text strong>Your details</Text>

            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Name</FormInputLabel>
              </FormInputHeader>

              <TextInput
                onChange={(e) =>
                  update({
                    customerName: (e.target as HTMLInputElement).value,
                  })
                }
                value={webInquiryConversion.customerName || ''}
              />
            </FormInput>

            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Contact name</FormInputLabel>
              </FormInputHeader>

              <TextInput
                onChange={(e) =>
                  update({
                    customerContactName: (e.target as HTMLInputElement).value,
                  })
                }
                value={webInquiryConversion.customerContactName || ''}
              />
            </FormInput>

            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Email</FormInputLabel>
              </FormInputHeader>
              <TextInput
                onChange={(e) =>
                  update({
                    customerEmail: (e.target as HTMLInputElement).value,
                  })
                }
                value={webInquiryConversion.customerEmail || ''}
              />
            </FormInput>

            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Tel</FormInputLabel>
              </FormInputHeader>
              <TextInput
                onChange={(e) =>
                  update({
                    customerTelephone: (e.target as HTMLInputElement).value,
                  })
                }
                value={webInquiryConversion.customerTelephone || ''}
              />
            </FormInput>

            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Address line 1</FormInputLabel>
              </FormInputHeader>

              <TextInput
                onChange={(e) =>
                  update({
                    customerAddressLine1: (e.target as HTMLInputElement).value,
                  })
                }
                value={webInquiryConversion.customerAddressLine1 || ''}
              />
            </FormInput>

            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Address line 2</FormInputLabel>
              </FormInputHeader>
              <TextInput
                onChange={(e) =>
                  update({
                    customerAddressLine2: (e.target as HTMLInputElement).value,
                  })
                }
                value={webInquiryConversion.customerAddressLine2 || ''}
              />
            </FormInput>

            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Address line 3</FormInputLabel>
              </FormInputHeader>

              <TextInput
                onChange={(e) =>
                  update({
                    customerAddressLine3: (e.target as HTMLInputElement).value,
                  })
                }
                value={webInquiryConversion.customerAddressLine3 || ''}
              />
            </FormInput>

            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Postcode</FormInputLabel>
              </FormInputHeader>
              <TextInput
                onChange={(e) =>
                  update({
                    customerPostcode: (e.target as HTMLInputElement).value,
                  })
                }
                value={webInquiryConversion.customerPostcode || ''}
              />
            </FormInput>
          </Box>

          <CheckBox
            checked={webInquiryConversion.siteIsCustomer}
            onChange={() =>
              update({ siteIsCustomer: !webInquiryConversion.siteIsCustomer })
            }
          >
            Site is same address
          </CheckBox>
          {!webInquiryConversion.siteIsCustomer && (
            <Box>
              <Text strong>Site details</Text>
              <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Name</FormInputLabel>
                </FormInputHeader>

                <TextInput
                  onChange={(e) =>
                    update({
                      siteName: (e.target as HTMLInputElement).value,
                    })
                  }
                  value={webInquiryConversion.siteName || ''}
                />
              </FormInput>

              <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Contact name</FormInputLabel>
                </FormInputHeader>

                <TextInput
                  onChange={(e) =>
                    update({
                      siteContactName: (e.target as HTMLInputElement).value,
                    })
                  }
                  value={webInquiryConversion.siteContactName || ''}
                />
              </FormInput>

              <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Contact number</FormInputLabel>
                </FormInputHeader>
                <TextInput
                  onChange={(e) =>
                    update({
                      siteTelephone: (e.target as HTMLInputElement).value,
                    })
                  }
                  value={webInquiryConversion.siteTelephone || ''}
                />
              </FormInput>

              <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Address line 1</FormInputLabel>
                </FormInputHeader>

                <TextInput
                  onChange={(e) =>
                    update({
                      siteAddressLine1: (e.target as HTMLInputElement).value,
                    })
                  }
                  value={webInquiryConversion.siteAddressLine1 || ''}
                />
              </FormInput>
              <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Address line 2</FormInputLabel>
                </FormInputHeader>
                <TextInput
                  onChange={(e) =>
                    update({
                      siteAddressLine2: (e.target as HTMLInputElement).value,
                    })
                  }
                  value={webInquiryConversion.siteAddressLine2 || ''}
                />
              </FormInput>

              <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Address line 3</FormInputLabel>
                </FormInputHeader>

                <TextInput
                  onChange={(e) =>
                    update({
                      siteAddressLine3: (e.target as HTMLInputElement).value,
                    })
                  }
                  value={webInquiryConversion.siteAddressLine3 || ''}
                />
              </FormInput>
              <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Postcode</FormInputLabel>
                </FormInputHeader>
                <TextInput
                  onChange={(e) =>
                    update({
                      sitePostcode: (e.target as HTMLInputElement).value,
                    })
                  }
                  value={webInquiryConversion.sitePostcode || ''}
                />
              </FormInput>
            </Box>
          )}

          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Further information</FormInputLabel>
            </FormInputHeader>
            <TextInput
              multiline
              rows={20}
              onChange={(e) =>
                update({
                  problem: (e.target as HTMLInputElement).value,
                })
              }
              value={webInquiryConversion.problem || ''}
            />
          </FormInput>

          <Text size="x1">
            <LabelledList
              grow
              items={[
                ['Home or commericial', document.homeOrComm],
                ['How can we assist', document.howAssist],
                ['Site id', document.itw3SiteId],
                ['Preferred day', document.prefDay],
                ['Preferred time', document.prefTime],
                ['Postcode', document.postcode],
                ['Telephone number', document.phone],
              ]}
              margin="x4"
            />
          </Text>
          {error && <FormErrorMessage error={error} />}
          <Button color="blue" level="primary" onClick={() => submit()}>
            Submit
          </Button>
        </Card>
      </Modal>
    </>
  );
}
