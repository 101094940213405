import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormInput,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
  Text,
  TextInput,
} from '@hd/ui';
import { TypeJobStatus } from '@hd/types';
import { Plus } from '@styled-icons/boxicons-regular';
import JobsList from './JobsList';
import useKeywordFilter from '../../utils/useKeywordFilter';
import toStatusFormat from '../../utils/toStatusFormat';
import useQueryParams from '../../utils/useQueryParams';
import useMergedFilters from '../../utils/useMergedFilters';
import { TypeFilter } from '../../utils/useFirestoreCollection';

const statusParamFilterMap = (status: TypeJobStatus) =>
  ((
    {
      AWAITING_AUTHORISATION: [['status', '==', 'AWAITING_AUTHORISATION']],
      AWAITING_SURVEY: [['status', '==', 'AWAITING_SURVEY']],
      AWAITING_AUTHORISATION_CCTV: [
        ['status', '==', 'AWAITING_AUTHORISATION'],
        ['isCCTV', '==', true],
      ],
      AWAITING_AUTHORISATION_OTHER: [
        ['status', '==', 'AWAITING_AUTHORISATION'],
        ['isCCTV', '==', false],
      ],

      UNALLOCATED: [['status', '==', 'UNALLOCATED']],
      IN_PROGRESS: [['status', '==', 'IN_PROGRESS']],
      CANCELLED: [['status', '==', 'CANCELLED']],
      COMPLETED: [['status', '==', 'COMPLETED']],
      AUTHORISE_CANCELLATION: [['status', '==', 'AUTHORISE_CANCELLATION']],

      TO_CHASE_COMMERCIAL: [
        ['status', '==', 'COMPLETED'],
        ['invoiceCreated', '<', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)],
        ['isCommercial', '==', true],
      ],

      TO_CHASE_DOMESTIC: [
        ['status', '==', 'COMPLETED'],
        ['invoiceCreated', '<', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)],
        ['isDomestic', '==', true],
      ],

      PAID: [['status', '==', 'PAID']],
      NO_FIX: [['status', '==', 'NO_FIX']],
      UNAPPROVED_SUBJOB: [['status', '==', 'UNAPPROVED_SUBJOB']],

      OVERDUE_CCTV: [
        ['date', '<', new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)],
        ['status', '==', 'IN_PROGRESS'],
        ['isCCTV', '==', true],
      ],

      OVERDUE_REPAIR: [
        ['date', '<', new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)],
        ['status', '==', 'IN_PROGRESS'],
        ['isRepair', '==', true],
      ],

      OVERDUE_UNBLOCK: [
        ['date', '<', new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)],
        ['status', '==', 'IN_PROGRESS'],
        ['isUnblock', '==', true],
      ],
      UNRESOLVED_COMPLAINTS: [['complaints', '>', 0]],
      POTENTIAL_FURTHER_WORK: [['potentialFurtherWork', '==', true]],
      CONTRACTOR_RAISED: [['status', '==', 'CONTRACTOR_RAISED']],
      RECALL: [
        ['status', '==', 'IN_PROGRESS'],
        ['jobType', '==', 'Recall'],
      ],
    } as Record<TypeJobStatus, TypeFilter[]>
  )[status]);

export default function Customers() {
  const { push } = useHistory();
  const [value, setValue] = React.useState('');
  const queryParams = useQueryParams();
  const statusQueryParam = queryParams.get('status') as null | TypeJobStatus;
  const keywordFilter = useKeywordFilter(value);
  const statusFilter = React.useMemo(
    () =>
      (statusQueryParam && statusParamFilterMap(statusQueryParam)) ||
      ([] as TypeFilter[]),
    [statusQueryParam]
  );
  const filters = useMergedFilters(keywordFilter, statusFilter);

  return (
    <Page data-at-id="JobsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>
              {statusQueryParam ? toStatusFormat(statusQueryParam) : 'All Jobs'}
            </PageTitle>
          </Box>

          <Box>
            <Button
              color="blue"
              data-at-id="AddJobButton"
              level="tertiary"
              onClick={() => push('/job')}
            >
              <Plus size="1.5rem" />
              <Text>Add Job</Text>
            </Button>
          </Box>
        </Box>

        <FormInput margin="x3">
          <TextInput
            onChange={(e) => setValue((e.target as HTMLInputElement).value)}
            placeholder="Search jobs"
            value={value}
          />
        </FormInput>
      </PageHeader>

      <PageBody>
        <JobsList key={statusQueryParam} options={{ filters }} />
      </PageBody>
    </Page>
  );
}
