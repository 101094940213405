import { CCTVRecommendationCategory } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';
import { v4 } from 'uuid';

export default function useFirestoreProductCategory(id?: string) {
  return useFirestoreDocument<CCTVRecommendationCategory>('CCTVRecommendationCategory', 'product-categories', id, {
    id: v4(),
    name: '',
  });
}
