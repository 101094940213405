import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { isAdmin } from '../../utils/authLevel';
import Admin from './Admin';
import AdminJobType from './AdminJobType';
import AdminJobTypes from './AdminJobTypes';
import AdminNotesAndQuotes from './AdminNotesAndQuotes';
import AdminNoteOrQuote from './AdminNoteOrQuote';
import AdminProduct from './AdminProduct';

export default function AdminIndex() {
  if (!isAdmin()) {
    return null;
  }
  return (
    <Switch>
      <Route component={AdminJobTypes} exact path="/admin" />
      <Route component={AdminJobType} path="/admin/job-types/:jobTypeId" />
      <Route component={AdminJobTypes} path="/admin/job-types" />
      <Route component={Admin} exact path="/admin/products" />
      <Route component={AdminProduct} path="/admin/products/:productId" />
      <Route
        component={AdminNotesAndQuotes}
        exact
        path="/admin/notes-and-quotes"
      />
      <Route
        component={AdminNoteOrQuote}
        path="/admin/notes-and-quotes/:noteOrQuoteId"
      />
    </Switch>
  );
}
