import * as React from 'react';
import {
  Box,
  Button,
  Card,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Link,
  Modal,
  Text,
  TextInput,
} from '@hd/ui';
import {
  ErrorCircle,
  Pound,
  QuestionMark,
} from '@styled-icons/boxicons-regular';
import { UnitType } from './AdminProduct';

type Props = {
  unitType?: UnitType;
  unitTypeContractor?: UnitType;
  onChange: (unitType: UnitType) => void;
  updateContractor: (unitType: UnitType) => void;
  header: string;
};

export default function AdminProductUnitType({
  unitType,
  unitTypeContractor,
  onChange,
  updateContractor,
  header,
}: Props) {
  const [basePriceHelperVisible, setBasePriceHelperVisible] =
    React.useState(false);

  const isPriceError = (value?: number) => value === undefined || isNaN(value);

  return (
    <>
      <Text strong>{header}</Text>
      <Box flex="horizontal" gap="x3">
        <FormInput grow maxWidth="200px">
          <FormInputHeader>
            <FormInputLabel>Price</FormInputLabel>
          </FormInputHeader>

          <TextInput
            IconPre={Pound}
            data-at-id="Price"
            onChange={(e) => {
              onChange({
                ...unitType,
                price: parseInt((e.target as HTMLInputElement).value),
              });
            }}
            required
            value={unitType?.price || '0'}
          />
          {isPriceError(unitType?.price) && (
            <Box textColor="red" flex="horizontal">
              <ErrorCircle size="1.25rem" />
              <Text size="x1" weak>
                Required
              </Text>
            </Box>
          )}
        </FormInput>

        <FormInput grow maxWidth="200px">
          <FormInputHeader>
            <FormInputLabel>Price Contractor</FormInputLabel>
          </FormInputHeader>

          <TextInput
            IconPre={Pound}
            data-at-id="Price"
            onChange={(e) => {
              updateContractor({
                ...unitTypeContractor,
                price: parseInt((e.target as HTMLInputElement).value),
              });
            }}
            required
            value={unitTypeContractor?.price || '0'}
          />
          {isPriceError(unitTypeContractor?.price) && (
            <Box textColor="red" flex="horizontal">
              <ErrorCircle size="0.75rem" />
              <Text size="x1" weak>
                Required
              </Text>
            </Box>
          )}
        </FormInput>
        <FormInput grow maxWidth="200px">
          <FormInputHeader>
            <FormInputLabel>Minimum</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="Min" // something unique
            onChange={(e) => {
              onChange({
                ...unitType,
                min: parseInt((e.target as HTMLInputElement).value),
              });
            }}
            required
            value={unitType?.min || '0'}
          />
        </FormInput>
        {unitType?._type !== 'models.HalfDayRate' &&
          unitType?._type !== 'models.HourRate' && (
            <FormInput grow maxWidth="200px">
              <FormInputHeader>
                <FormInputLabel gap="x2" flex="horizontal">
                  <Text>Base price</Text>
                </FormInputLabel>
              </FormInputHeader>

              <TextInput
                IconPre={Pound}
                data-at-id="BasePrice" // something unique
                onChange={(e) => {
                  onChange({
                    ...unitType,
                    basePrice: parseInt((e.target as HTMLInputElement).value),
                  });
                }}
                required
                value={unitType?.basePrice || '0'}
              />
              <Link
                size="x1"
                weak
                onClick={() => setBasePriceHelperVisible(true)}
              >
                What is base price?
              </Link>
            </FormInput>
          )}
      </Box>
      <Modal
        maxWidth="600px"
        visible={basePriceHelperVisible}
        onClose={() => setBasePriceHelperVisible(false)}
      >
        <Card backgroundColor="light" padding="x10">
          <Box flex="vertical" gap="x2">
            <Text strong size="x4">
              Base price explained
            </Text>
            <Text>First things first...</Text>
            <Text weak>SORRRRRRRRRY</Text>
            <Text>
              Base price was a botched job of implementing "Subsequent pricing"
              - that's when
            </Text>
            <Text>
              "The first costs X, but any additional Y" (When Y is smaller)
            </Text>
            <Text>
              So the way it's been implemented was using "Base price". Let's
              learn by example
            </Text>
            <Text>
              Let's say we have a product which is "£5 for the first, then any
              extra are £2"
            </Text>
            <Text>Great. So we can say that's...</Text>
            <Text>1 = 5</Text>
            <Text>2 = 7</Text>
            <Text>3 = 9</Text>
            <Text>etc...</Text>
            <Text>So we COULD say that each additional one costs £2.</Text>
            <Text>And that the first one costs a fiver</Text>
            <Text>OR (And again...sorry)</Text>
            <Text>We could say..</Text>
            <Text>THEY ALL COST £2</Text>
            <Text>
              And the BASE PRICE (The initial price for getting this product) is
              £3
            </Text>
            <Text>1 = 2</Text>
            <Text>2 = 4</Text>
            <Text>3 = 6</Text>
            <Text>PLUS £3 as a base price...means...</Text>
            <Text>1 = 5</Text>
            <Text>2 = 7</Text>
            <Text>3 = 9</Text>
            <Text>
              The best thing to do is think about it as "Price is how much each
              one costs extra, the base price brings the first one up to desired
              first value"
            </Text>
            <Text>
              The second best thing to do call me on 07545979663 to double
              check. I'll likely be as confused.{' '}
            </Text>
          </Box>
          <Button
            level="primary"
            color="blue"
            onClick={() => setBasePriceHelperVisible(false)}
          >
            Close
          </Button>
        </Card>
      </Modal>
    </>
  );
}
