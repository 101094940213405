import * as React from 'react';
import { Box, Button, Card, Modal } from '@hd/ui';
import { FormError } from '../../../utils/formValidators';
import FormErrorMessage from '../../FormErrorMessage';
import useFirestoreProductCategory from '../../../utils/useFirestoreProductCategory';
import CategoryForm from './CategoriesForm';
import { CCTVRecommendationCategory } from '@hd/types';

interface Props {
  onClose: () => void;
  visible: boolean;
}

export default function CategoryAddModal(props: Props) {
  const { onClose, visible } = props;
  const { document, update, save, clear } = useFirestoreProductCategory();
  const [error, setError] = React.useState<FormError | null>(null);

  const handleSave = async () => {
      const saved = await save();
      clear();
      
      onClose();
  };

  return (
    <Modal maxWidth="900px" onClose={onClose} visible={visible}>
      <Card backgroundColor="light" padding="x10">

        <CategoryForm category={document} update={update} />
        {error && <FormErrorMessage error={error} />}

        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x4"
          margin="x10"
        >
          <Button data-at-id="ModalCancel" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="blue"
            data-at-id="ModalConfirm"
            level="primary"
            onClick={handleSave}
            type="submit"
          >
            Create Category
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
