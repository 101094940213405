import { CCTVReccomendation, CCTVRecommendationProduct } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';
import { v4 } from 'uuid';

export default function useFirestoreProduct(id?: string) {
  return useFirestoreDocument<CCTVRecommendationProduct>('CCTVReccomendation', 'products', id, {
    id: v4(),
    parentId: '',
    name: '',
    pricing: [],
    addOns: [],
    sectionApplications: [],
    requiresAtPosition: false,
    requiresFromToPosition: false
  });
}
