import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import WebInquiries from './WebInquiries';
import WebInquiry from './WebInquiry';

export default function WebInquiriesIndex() {
  return (
    <Switch>
      <Route component={WebInquiries} exact path="/web-inquiries" />
      <Route component={WebInquiry} path="/web-inquiries/:webInquiryId" />
    </Switch>
  );
}
