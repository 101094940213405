import { CCTVRecommendationProductUnit, ProgressivePrice } from '@hd/types';
import {
  Box,
  Button,
  Text,
} from '@hd/ui';
import * as React from 'react';
import { humanReadableUnitType } from '../../constants/CCTVReccommendationContants';
import { ArrowToBottom, DownArrow, DownArrowAlt, Trash } from '@styled-icons/boxicons-regular';


interface Props {
  progressivePrice: ProgressivePrice;
  onRemove: (id: string) => void;
  unit: CCTVRecommendationProductUnit;
  readOnly?: boolean;
}

export default function ProgressivePriceView(props: Props) {
  const { onRemove, progressivePrice, unit, readOnly} = props;

  return ( <>
  {progressivePrice.threshold !== 0 && <Box grow alignChildrenHorizontal='start' flex='horizontal' style={{ marginLeft: '30px'}}><DownArrowAlt size='1rem'/></Box>}
  <Box borderColor='gray' backgroundColor='light' borderSize='x1' grow flex='horizontal' > 
                <Box flex='vertical' alignChildrenHorizontal='middle' padding='x3' backgroundColor='light-gray'>
                  <Text size='x3' textColor='dark-gray'>{progressivePrice.threshold} </Text>
                  <Text size='x1'>and above</Text>
                </Box>
                <Box padding='x4'>
                  <Text strong size='x2'>HD £{progressivePrice.price} </Text>
                  <Text strong size='x2'> Contractor: £{progressivePrice.contractorPrice}</Text>
                </Box>
                  {
                    !!!readOnly  && 
                  <Box flex='vertical' alignChildrenVertical='middle'>
                    <Button size='small' color='red' level='tertiary' onClick={() => onRemove(progressivePrice.id)}> <Trash size='1rem'/> </Button>
                  </Box>
                  }
                </Box>
              </>
  );
}
