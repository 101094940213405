import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Link,
  Page,
  PageBack,
  PageBody,
  PageDescription,
  PageHeader,
  PageTitle,
  Text,
} from '@hd/ui';
import { Save, Trash } from '@styled-icons/boxicons-regular';
import getAddress from '../../utils/getAddress';
import useFirestoreContractor from '../../utils/useFirestoreContractor';
import { validateContractor, FormError } from '../../utils/formValidators';
import ContractorForm from './ContractorForm';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import ConfirmationModal from '../ConfirmationModal';
import EngineersList from '../Engineers/EngineersList';
import FormErrorMessage from '../FormErrorMessage';
import ContractorFilesList from './ContractorFilesList';

export default function Customer() {
  const { push } = useHistory();
  const { contractorId } = useParams<{ contractorId: string }>();
  const data = useFirestoreContractor(contractorId);
  const [isRemoveContractorModalVisible, setIsRemoveContractorModalVisible] =
    React.useState(false);
  const [error, setError] = React.useState<FormError | null>(null);

  const handleRemoveContractor = async () => {
    await data.remove();
    push('/contractors');
  };

  const handleSubmit = async () => {
    const error = validateContractor(data.document);
    setError(error);

    if (!error) {
      await data.save();
      push('/contractors');
    }
  };

  return (
    <FirebaseDocument {...data}>
      <Page>
        <PageHeader>
          <Box alignChildrenVertical="end" flex="horizontal">
            <Box grow>
              <PageBack to="/contractors">Contractors</PageBack>
              <PageTitle>{data.document.name}</PageTitle>
              <PageDescription>{getAddress(data.document)}</PageDescription>
            </Box>

            <Box flex="horizontal" gap="x2">
              {data.document.canRaiseJobs && (
                <Button
                  onClick={() =>
                    window.open(`/crj/co/${data.document._id}`, '_blank')
                  }
                >
                  Job raise link
                </Button>
              )}
              <Button
                color="blue"
                level="tertiary"
                onClick={handleSubmit}
                type="submit"
              >
                <Save size="1.25rem" />
                <Text>Save</Text>
              </Button>
            </Box>
          </Box>
        </PageHeader>

        <PageBody>
          {error && <FormErrorMessage error={error} />}

          <Card backgroundColor="light" margin="x6" padding="x10">
            <ContractorForm document={data.document} update={data.update} />
          </Card>

          <Box margin="x16">
            <Box alignChildrenVertical="start" flex="horizontal" margin="x3">
              <Box grow>
                <Text size="x4">Engineers</Text>
              </Box>
            </Box>

            <EngineersList contractor={data.document} />
          </Box>
          <ContractorFilesList contractorId={contractorId} />
        </PageBody>
      </Page>

      <ConfirmationModal
        message={`Are you sure you want to remove the contractor "${data.document.name}"?`}
        onCancel={() => setIsRemoveContractorModalVisible(false)}
        onConfirm={handleRemoveContractor}
        visible={isRemoveContractorModalVisible}
      />
    </FirebaseDocument>
  );
}
