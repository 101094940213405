import { Job } from '@hd/types';
import * as React from 'react';
import SiteView from '../Sites/SiteView';

export interface Props {
  job: Job;
}

export default function JobSelectionViewSite(props: Props) {
  const { job } = props;

  if (!job.customerSite) {
    return null;
  }

  return (
    <SiteView site={ job.customerSite } />
  );
}
