import 'regenerator-runtime/runtime';
import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import numbro from 'numbro';
import numbroEN from 'numbro/languages/en-GB';
import App from './components/App';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import './index.css';

numbro.registerLanguage(numbroEN);
numbro.setLanguage('en-GB');

firebase.initializeApp({
  apiKey: 'AIzaSyBCivI9vSZU6l52FO0j5uEopk0vMFPFH_8',
  authDomain: 'happy-drains-job-managment.firebaseapp.com',
  databaseURL: 'https://happy-drains-job-managment.firebaseio.com',
  projectId: 'happy-drains-job-managment',
  storageBucket: 'happy-drains-job-managment.appspot.com',
  messagingSenderId: '709796733406',
  appId: '1:709796733406:web:da1789d12772e65fee3d4f',
  measurementId: 'G-C1VFB958P',
});


if (location.hostname === 'localhost') {
  firebase.firestore().settings({ experimentalForceLongPolling: true });
  firebase.firestore().useEmulator('localhost', 9002);
  firebase.functions().useEmulator('localhost', 9001);
}

const rootElement = document.getElementById('Root');

if (rootElement) {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>, rootElement
  );
}
