import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Customer from './Customer';
import Customers from './Customers';

export default function CustomersIndex() {
  return (
    <Switch>
      <Route component={ Customer } path="/customers/:customerId" />
      <Route component={ Customers } path="/customers" />
    </Switch>
  );
}
