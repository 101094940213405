import { Box, Button, Text, Progress } from '@hd/ui';
import * as React from 'react';
import firebase from 'firebase/app';
import { Job } from '../../../../types/types';
import JobDrainReporterReport from './JobDrainReporterReport';
import { isAdmin } from '../../utils/authLevel';

interface Props {
  job: Job;
}

type DrainReporterStatus =
  | 'InProgress'
  | 'Editing'
  | 'Submitted'
  | 'HappyDrainsDaveEditing'
  | 'HappyDrainsDaveVerified';

export interface DrainReporterProductOrderSummary {
  name: string;
  quantity: number;
  totalPrice: number;
  unitPrice: number;
  unitType: string;
  basePrice?: number;
}

export interface DrainReporterPayload {
  status: DrainReporterStatus;
  privateNote: string;
  publicNote: string;
  price: number;
  contractorPrice?: number;
  products: DrainReporterProductOrderSummary[];
  contractorProducts: DrainReporterProductOrderSummary[];
}

export const getDrainReporterStatus = async (
  jobNumber: string
): Promise<DrainReporterPayload | null> => {
  try {
    const response = await fetch(
      `https://drainreporter.com/summary/${jobNumber.replace('/', '%2F')}`,
      //`http://localhost:9000/summary/${jobNumber.replace('/', '%2F')}`,
      {
        headers: {
          'x-happy-drains':
            '2dabfab0996332832f8adbf62d0eb3472358e96acf17e0d841ce951d66dd552a',
        },
      }
    );
    if (response.ok) {
      return response.json();
    }
  } catch (e) {
    return null;
  }

  return null;
};

export default function JobDrainReporter(props: Props) {
  const { job } = props;
  const [{ isGettingState, payload }, setState] = React.useState<{
    isGettingState: boolean;
    payload: null | DrainReporterPayload;
  }>({
    isGettingState: true,
    payload: null,
  });

  React.useEffect(() => {
    getDrainReporterStatus(job.jobNumber).then((payload) => {
      setState({
        isGettingState: false,
        payload: payload,
      });
    });
  }, [job.jobNumber]);

  const showSendToDrainReporterOption = payload === null;

  const showDownloadOption =
    payload &&
    (payload.status === 'Submitted' ||
      payload.status === 'HappyDrainsDaveEditing' ||
      payload.status === 'HappyDrainsDaveVerified');

  const showAlreadySubmittedMessage =
    payload &&
    (payload.status === 'InProgress' || payload.status === 'Editing');

  if (isGettingState) {
    return (
      <Box alignChildren="middle" flex="vertical" grow padding="x6">
        <Progress />
      </Box>
    );
  }

  if (showAlreadySubmittedMessage) {
    return (
      <Box alignChildren="middle" flex="vertical" grow padding="x6">
        <Text margin="x4" strong>
          The engineer is currently filling out the Drain Reporter report.
        </Text>
        <a
          href={`https://drainreporter.com/job-select-happydrains/${job.jobNumber.replace(
            '/',
            '%2F'
          )}/${job.customerSite?.addressPostcode}`}
          rel="noreferrer"
          target="_blank"
        >
          <Button color="blue" level="primary">
            Link to Drainreporter
          </Button>
        </a>
      </Box>
    );
  }

  if (showDownloadOption) {
    return (
      <Box flex="vertical" grow padding="x6">
        <Text margin="x4" strong>
          Engineer has completed their report. Click below to generate the cover
          letter.
        </Text>

        {payload && (
          <JobDrainReporterReport jobNumber={job.jobNumber} report={payload} />
        )}

        <Box alignChildrenHorizontal="between" flex="horizontal">
          {isAdmin() && (
            <Button
              color="blue"
              href={`https://drainreporter.com/admin`}
              level="primary"
              tag="a"
              target="_DrainReporter"
            >
              Got to DrainReporter
            </Button>
          )}
          <Button
            color="blue"
            href={`https://drainreporter.com/happydrains/generate-cover-letter/${job.jobNumber.replace(
              '/',
              '%2F'
            )}/A8iF2S4DtT1iIgv`}
            level="primary"
            tag="a"
            target="_DrainReporter"
          >
            Generate cover letter
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box alignChildren="middle" flex="vertical" grow padding="x6">
      <Text margin="x4">
        If you see this text. It means there is an unhandled case on the drain
        reporter. Please report it.
      </Text>
    </Box>
  );
}
