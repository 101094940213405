import { JobFile } from '@hd/types';
import useFirestoreCollection, { Options } from './useFirestoreCollection';

export default function useFirestoreContractorFiles(
  contractorId: string,
  options?: Options<JobFile>
) {
  return useFirestoreCollection<JobFile>(
    'File',
    `contractors/${contractorId}/files`,
    options
  );
}
