/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CCTVRecommendationProduct,
  CCTVRecommendationProductAddOn,
  Contractor,
  ContractorRaisedJob,
  Customer,
  Engineer,
  Job,
  JobReport,
  JobType,
  NoteOrQuote,
  Quote,
  Site,
  SiteDetails,
  UnblockForm,
  WebInquiryConversion,
} from '@hd/types';
import * as yup from 'yup';
import timeWindows from '../constants/timeWindows';
import { UseType, PipeSizeTypes, PipeMaterialTypes, LegalOwnershipTypes, SiteTypeTypes, DischangeIntoTankTypes, ConnectedToMainsTypes, SiteStateTypes, AccessTypes, SectionApplicationTypes } from '../constants/CCTVReccommendationContants';
export interface FormError {
  field: string;
  message: string;
}

// General validation for the common address properties (spread below)
const schemaAddress = {
  addressPostcode: yup.string().required(),
  addressLine3: yup.string(),
  addressLine2: yup.string().required(),
  addressLine1: yup.string().required(),
};

// General validation for the common contact properties (spread below)
const schemaContact = {
  contactPhoneNumbers: yup.array().of(yup.string().required()).min(1).required(),
  contactEmails: yup.array().of(yup.string().required()).min(1).required(),
  contactName: yup.string().required(),
};

// Contractor validation props.
const schemaContractor = yup.object().shape({
  coverage: yup.array().of(yup.string().required()).min(1).required(),
  name: yup.string().required(),
  sageReference: yup.string().required(),
  ...schemaContact,
  ...schemaAddress,
} as Record<keyof Contractor, any>);

// Customer validation props.
const schemaCustomer = yup.object().shape({
  name: yup.string().required(),
  ...schemaContact,
  ...schemaAddress,
} as Record<keyof Customer, any>);

// Engineer validation props.
const schemaEngineer = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  phoneNumber: yup.string().required(),
} as Record<keyof Engineer, any>);

// Job validation props.
const schemaJob = yup.object().shape({
  customer: yup.object().required(),
  customerSite: yup.object().required(),
  description: yup.string(),
  jobType: yup.string().required(),
  priceContractor: yup.string().required(),
  priceCustomer: yup.string().required(),
  timeWindow: yup.string().oneOf(timeWindows).required(),
  contractor: yup.object().nullable(),
  contractorEngineer: yup.object().nullable().when('contractor', {
    is: (contractor) => contractor != null,
    then: yup.object().nullable().required('You must select an engineer when contractor is allocated!'),
  }),
} as Record<keyof Job, any>);

// Job type props.
const schemaJobType = yup.object().shape({
  name: yup.string().required(),
  priceContractor1: yup.number().required(),
  priceContractor2: yup.number().required(),
  priceContractor3: yup.number().required(),
  priceCustomer: yup.number().required(),
  customerNominalCode: yup.number().required(),
  contractorNominalCode: yup.number().required(),
  salesInfo: yup.string().required(),
} as Record<keyof JobType, any>);

// Job type props.
const schemaQuote = yup.object().shape({
  quoteText: yup.string().required(),
  price: yup.number().required(),
  siteAddressPostcode: yup.string().required().min(3),
  customerEmail: yup.string().when('customer', {
    is: (customer) => customer == null,
    then: yup.string().email().required(),
  }),
  customerName: yup.string().when('customer', {
    is: (customer) => customer == null,
    then: yup.string().required().min(3),
  }),
  customerPostcode: yup.string().when('customer', {
    is: (customer) => customer == null,
    then: yup.string().min(3).required(),
  }),
  siteContactName: yup.string().required().min(3),
  siteContactNumber: yup.string().required().min(6),
} as Record<keyof Quote, any>);

const schemaQuoteForConversion = yup.object().shape({
  price: yup.number().required(),
  quoteText: yup.string(),
  recipient: yup.string(),
  siteContactName: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  siteContactNumber: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  siteName: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  siteAddressLine1: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  siteAddressLine2: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  siteAddressPostcode: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  customerName: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  customerEmail: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  customerNumber: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  customerAddress1: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  customerAddress2: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  customerAddress3: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  customerContactName: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
  customerPostcode: yup.string().when('parent', {
    is: (parent) => parent == null,
    then: yup.string().required(),
  }),
} as Record<keyof Quote, any>);
// Note or quote props
const schemaNoteOrQuote = yup.object().shape({
  name: yup.string().required(),
  value: yup.string().required(),
} as Record<keyof NoteOrQuote, any>);

// Job Report validation props.
const schemaJobReport = yup.object().shape({
  jobDoneToGuarantee: yup.boolean().required(),
  jobRequiresFurtherWork: yup.boolean().required(),
  publicNote: yup.string().required(),
  privateNote: yup.string().required(),
  timestampSubmitted: yup.object().required(),
} as Record<keyof JobReport, any>);

const schemaJobReportUnblockForm = yup.object().shape({
  cleanSuccess: yup.boolean().required(),
  cause: yup.string().required(),
  fromCleared: yup.string().required(),
  toCleared: yup.string().required(),
  equpmentUsed: yup.string().required(),
} as Record<keyof UnblockForm, any>);

const schemaContractorRaisedJobForm = yup.object().shape({
  price: yup.number().required(),
  description: yup.string(),
  isCommercial: yup.boolean().required(),
  isCCTV: yup.boolean().required(),
  isDomestic: yup.boolean().required(),
  isRepair: yup.boolean().required(),
  siteIsCustomer: yup.boolean().required(),
  isUnblock: yup.boolean().required(),
  jobType: yup.string().required(),
  siteContactName: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteContactNumber: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteName: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteAddressLine1: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteAddressLine2: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteAddressLine3: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteAddressPostcode: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  timeWindow: yup.string().oneOf(timeWindows).required(),
  customerName: yup.string().required(),
  customerEmail: yup.string().email().required(),
  customerNumber: yup.string().required(),
  customerAddress1: yup.string().required(),
  customerAddress2: yup.string().required(),
  customerAddress3: yup.string(),
  customerContactName: yup.string().required(),
  customerPostcode: yup.string().required(),
  purchaseOrderNumber: yup.string(),
  contractorEngineer: yup.object().required(),
} as Record<keyof ContractorRaisedJob, any>);

const schemaWebInquiryConversion = yup.object().shape({
  customerName: yup.string().required(),
  customerContactName: yup.string().required(),
  customerEmail: yup.string().email().required(),
  customerTelephone: yup.string().required(),
  customerAddressLine1: yup.string().required(),
  customerAddressLine2: yup.string().required(),
  customerAddressLine3: yup.string(),
  customerPostcode?: yup.string().required(),
  siteContactName: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteTelephone: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteName: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteAddressLine1: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteAddressLine2: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteAddressLine3: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  sitePostcode: yup.string().when('siteIsCustomer', {
    is: (siteIsCustomer) => siteIsCustomer !== true,
    then: yup.string().required(),
  }),
  siteIsCustomer: yup.boolean().required(),
} as Record<keyof WebInquiryConversion, any>);

const schemaSiteDetails = yup.object().shape({
  use: yup.string().oneOf(UseType).required(),
  access: yup.string().oneOf(AccessTypes).required(),
  pipeSize: yup.string().oneOf(PipeSizeTypes).required(),
  pipeMaterial: yup.string().oneOf(PipeMaterialTypes).required(),
  siteType: yup.string().oneOf(SiteTypeTypes).required(),
  legalOwnership: yup.string().oneOf(LegalOwnershipTypes).required(),
  foulSystemConnectedToMains: yup.string().oneOf(ConnectedToMainsTypes).required(),
  dischargeIntoTank: yup.string().oneOf(DischangeIntoTankTypes).required(),
  state: yup.string().oneOf(SiteStateTypes).required(),
} as Record<keyof SiteDetails, any>);

const schemaCCTVRecommendationProduct = yup.object().shape({
  id: yup.string().required(),
  parentId: yup.string().required(),
  name: yup.string().required(),
  pricing: yup.array().min(1),
  sectionApplications: yup.array().of(yup.string().oneOf(SectionApplicationTypes)).min(1),
  requiresAtPosition: yup.boolean().required(),
  requiresFromToPosition: yup.boolean().required(),
  addOns: yup.array(),
  confirmationText: yup.string(),
} as Record<keyof CCTVRecommendationProduct, any>);

const schemaCCTVRecommendationProductAddOn = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  pricing: yup.array().min(1),
} as Record<keyof CCTVRecommendationProductAddOn, any>);



// Site validation props.
const schemaSite = yup.object().shape({
  contactName: yup.string().required(),
  contactPhoneNumber: yup.string().required(),
  name: yup.string().required(),
} as Record<keyof Site, any>);

// Create the validation functions
export const createValidate = <T>(s: yup.ObjectSchema<any>) => (v: T): null | FormError => {
  try {
    s.validateSync(v);
  } catch (error) {
    return {
      field: error.path,
      message: error.message,
    };
  }

  return null;
};

// Exported validation functions
export const validateContractor = createValidate<Contractor>(schemaContractor);
export const validateCustomer = createValidate<Customer>(schemaCustomer);
export const validateEngineer = createValidate<Engineer>(schemaEngineer);
export const validateJob = createValidate<Job>(schemaJob);
export const validateJobReport = createValidate<JobReport>(schemaJobReport);
export const validateJobReportUnblockForm = createValidate<JobReport>(schemaJobReportUnblockForm);
export const validateSite = createValidate<Site>(schemaSite);
export const validateJobType = createValidate<JobType>(schemaJobType);
export const validateQuote = createValidate<Quote>(schemaQuote);
export const validateQuoteBeforeConvertionToJob = createValidate<Quote>(schemaQuoteForConversion);
export const validateNoteOrQuote = createValidate<NoteOrQuote>(schemaNoteOrQuote);
export const validateContractorRaisedJob = createValidate<ContractorRaisedJob>(schemaContractorRaisedJobForm);
export const validateWebInquiryConversion = createValidate<WebInquiryConversion>(schemaWebInquiryConversion);
export const validateSiteDetails = createValidate<SiteDetails>(schemaSiteDetails);
export const validateCCTVRecommendationProduct = createValidate<CCTVRecommendationProduct>(schemaCCTVRecommendationProduct);
export const validateCCTVRecommendationProductAddOn = createValidate<CCTVRecommendationProductAddOn>(schemaCCTVRecommendationProductAddOn);