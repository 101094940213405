import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Page, PageBody, PageHeader, PageTitle } from '@hd/ui';
import JobsList from './JobsList';
import { TypeFilter } from '../../utils/useFirestoreCollection';
import useFirestoreContractor from '../../utils/useFirestoreContractor';

export default function JobsContractorRaised() {
  const { contractorId } = useParams<{ contractorId: string }>();
  const contractor = useFirestoreContractor(contractorId);

  const filters = [
    ['status', '==', 'CONTRACTOR_RAISED'],
    ['contractorName', '==', contractor.document.name],
  ] as TypeFilter[];

  return (
    <Page data-at-id="ContractorJobsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>{contractor.document.name}</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <JobsList
          options={{ filters }}
          jobParam={`?redirect=/crj/${contractor.document._id}`}
        />
      </PageBody>
    </Page>
  );
}
