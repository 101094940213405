import { CustomerVatType, ContractorVatType } from '@hd/types';

export const customerVatTypes: CustomerVatType[] = [
  'VAT',
  'Reverse Charge',
  'Exempt',
];

export const contractorVatTypes: ContractorVatType[] = [
  'VAT Registered',
  'Not VAT Registered',
];
