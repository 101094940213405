import { Modal } from '@hd/ui';
import { Site } from '@hd/types';
import * as React from 'react';
import SitesList from '../Sites/SitesList';

interface Props {
  onClose: () => void;
  onSelect: (value: Site) => void;
}

export default function JobSelectionModalSite(props: Props) {
  const {
    onClose,
    onSelect,
  } = props;

  return (
    <Modal
        maxWidth="600px"
        onClose={ onClose }
        visible>
      <SitesList
          onSelect={ onSelect }
          options={ { limit: 25 } } />
    </Modal>
  );
}
