import * as React from 'react';
import { Box, Card, CardRow, Link, Text } from '@hd/ui';
import { Job } from '@hd/types';

interface Props<T> {
  SelectionModal: React.FunctionComponent<{
    job: Job;
    onClose: () => void;
    onSelect: (value: Exclude<T, undefined>) => void;
  }>;
  SelectionView: React.FunctionComponent<{
    job: Job;
  }>;
  disabled?: boolean;
  editUrl?: string | null;
  job: Job;
  onSelect: (item: Exclude<T, undefined>) => void;
  value: null | T;
}

export default function JobSelectionBox<T>(props: Props<T>) {
  const {
    SelectionModal,
    SelectionView,
    disabled,
    editUrl,
    job,
    onSelect,
    value,
    ...rest
  } = props;

  const [isSelectionModalVisible, setIsSelectionModalVisible] = React.useState(false);

  const handleSelect = (value: Exclude<T, undefined>) => {
    onSelect(value);
    setIsSelectionModalVisible(false);
  };

  if (disabled) {
    return (
      <Card { ...rest }
          alignChildren="middle"
          backgroundColor="light"
          flex="vertical"
          grow
          padding="x4">
        <Text
            align="middle"
            size="x2"
            textColor="gray">
          Requires previous selected item.
        </Text>
      </Card>
    );
  }

  if (value) {
    return (
      <>
        <Card { ...rest }
            backgroundColor="light"
            flex="vertical"
            gap="x4"
            grow
            padding="x4">
          <Box grow>
            <SelectionView job={ job } />
          </Box>

          <Box alignChildrenHorizontal="between" flex="horizontal">
            <Link onClick={ () => setIsSelectionModalVisible(true) } size="x2" strong>Change?</Link>
            { editUrl && <Link size="x2" strong to={ editUrl }>Edit?</Link> }
          </Box>
        </Card>

        { isSelectionModalVisible && (
          <SelectionModal
              job={ job }
              onClose={ () => setIsSelectionModalVisible(false) }
              onSelect={ handleSelect } />
        ) }
      </>
    );
  }

  return (
    <>
      <Card
          backgroundColor="light"
          borderColor="blue"
          borderSize="x2"
          borderStyle="dashed"
          flex="vertical"
          grow>
        <CardRow { ...rest }
            alignChildren="middle"
            flex="vertical"
            grow
            onClick={ () => setIsSelectionModalVisible(true) }
            paddingHorizontal="x6"
            paddingVertical="x12">
          <Text
              align="middle"
              size="x2"
              strong
              textColor="blue">
            Select
          </Text>
        </CardRow>
      </Card>

      { isSelectionModalVisible && (
        <SelectionModal
            job={ job }
            onClose={ () => setIsSelectionModalVisible(false) }
            onSelect={ handleSelect } />
      ) }
    </>
  );
}
