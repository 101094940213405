import { CCTVRecommendationProductAddOn } from '@hd/types';
import {
  Box,
  Card,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Text,
  TextInput,
} from '@hd/ui';
import * as React from 'react';
import { humanReadableUnitType, UnitTypes } from '../../constants/CCTVReccommendationContants';
import ProductPriceInput from './ProductPriceInput';

interface Props {
  product: CCTVRecommendationProductAddOn;
  update: (updates: Partial<CCTVRecommendationProductAddOn>) => void;
}

export default function ProductForm(props: Props) {
  const { product, update } = props;

  return (<>
    <Box flex="horizontal" margin="x8">
      <Box basis="none" flex="vertical" grow>
        <Text margin="x3" strong>
          Product
        </Text>
      </Box>
    </Box>

    <Card backgroundColor="light" margin="x4" padding="x6">
      <Box flex="horizontal" gap="x6" margin="x6">
        <FormInput grow>
          <FormInputHeader>
            <FormInputLabel>Name</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="ProductAddOnName"
            onChange={(e) => update({
              name: (e.target as HTMLInputElement).value
            })}
            value={product.name}
          />
        </FormInput>
      </Box>
      </Card>

    <Card backgroundColor="light" margin="x4" padding="x6">
      <FormInput margin='x8'>
        <FormInputHeader>
          <FormInputLabel>Structures</FormInputLabel>
        </FormInputHeader>
        <Box flex='horizontal' alignChildrenHorizontal='around'>
          {
            UnitTypes.map(type =>
              <Box flex='vertical' alignChildrenHorizontal='middle' key={`${type}-structure-option`}>
                {humanReadableUnitType[type]}
                <CheckBox checked={product.pricing.map(e => e.type).includes(type)} onChange={() => {
                  if (product.pricing.map(e => e.type).includes(type)) {
                    update({
                      pricing: product.pricing.filter(e => e.type !== type)
                    })
                  } else {
                    update({
                      pricing: [...product.pricing, {
                        type,
                        price: 0,
                        min: 0,
                        contractorPrice: 0
                      }]
                    })
                  }
                }} />
              </Box>
            )}
        </Box>
      </FormInput>

    </Card>

    {
      product.pricing.map(e => <ProductPriceInput key={`${e.type}-price-input`} product={product} unit={e} update={update} />)
    }

  </>
  );
}
