import * as React from 'react';
import { Pencil, Plus, Trash } from '@styled-icons/boxicons-regular';
import { Box, Button, Card, CardRow, Text, FormInput, TextInput } from '@hd/ui';
import { Site } from '@hd/types';
import ConfirmationModal from '../ConfirmationModal';
import FirebaseList from '../Firebase/FirebaseList';
import SiteModal from './SiteModal';
import useKeywordFilter from '../../utils/useKeywordFilter';
import useMergedFilters from '../../utils/useMergedFilters';
import { Options } from '../../utils/useFirestoreCollection';
import useFirestoreCustomerSites from '../../utils/useFirestoreCustomerSites';
import SiteView from './SiteView';

interface Props {
  isResultsVisibleBeforeSearch?: boolean;
  options?: Options<Site>;
  onSelect?: (site: Site) => void;
}

export default function SitesList(props: Props) {
  const { isResultsVisibleBeforeSearch, options, onSelect } = props;
  const [value, setValue] = React.useState('');
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);
  const data = useFirestoreCustomerSites({ ...options, filters });
  const [isAddSiteModalVisible, setIsAddSiteModalVisible] = React.useState(false);
  const [removeSiteId, setRemoveSiteId] = React.useState<undefined | string>();
  const [updateSiteId, setUpdateSiteId] = React.useState<undefined | string>();

  const handleAddCreateSiteModal = (site?: Site) => {
    setUpdateSiteId(undefined);

    if (site && onSelect) {
      onSelect(site);
    }

    setIsAddSiteModalVisible(false);
  };

  const handleRemoveSite = async() => {
    if (removeSiteId) {
      await data.remove(removeSiteId);
      setRemoveSiteId(undefined);
    }
  };

  return (
    <>
      <Card backgroundColor="light" flex="horizontal" gap="x4" margin="x3" padding="x3">
        <Box grow>
          <FormInput margin="x3">
            <TextInput
                onChange={ (e) => setValue((e.target as HTMLInputElement).value) }
                placeholder="Search sites"
                value={ value } />
          </FormInput>
        </Box>

        <Box>
          <Button
              color="blue"
              data-at-id="AddSiteButton"
              level="tertiary"
              onClick={ () => setIsAddSiteModalVisible(true) }>
            <Plus size="1.5rem" />
            <Text>Add New Site</Text>
          </Button>
        </Box>
      </Card>

      { (isResultsVisibleBeforeSearch || value) && (
        <FirebaseList { ...data } onMore={ data.more }>
          <Card backgroundColor="light">
            { data.ids.map((id) => (
              <CardRow
                  flex="horizontal"
                  key={ id }
                  onClick={ onSelect && (() => onSelect(data.documents[id])) }
                  paddingHorizontal="x10"
                  paddingVertical="x4">
                <Box grow>
                  <SiteView site={ data.documents[id] } />
                </Box>

                { !onSelect && (
                  <Box flex="horizontal" gap="x2">
                    <Button
                        color="light"
                        level="tertiary"
                        onClick={ () => setUpdateSiteId(id) }
                        size="small">
                      <Pencil size="1.25rem" />
                    </Button>

                    <Button
                        color="red"
                        level="tertiary"
                        onClick={ () => setRemoveSiteId(id) }
                        size="small">
                      <Trash size="1.25rem" />
                    </Button>
                  </Box>
                ) }
              </CardRow>
            )) }
          </Card>
        </FirebaseList>
      )
    }

      <SiteModal
          onClose={ handleAddCreateSiteModal }
          siteId={ updateSiteId }
          visible={ isAddSiteModalVisible || !!updateSiteId } />

      <ConfirmationModal
          message={ removeSiteId && data.documents[removeSiteId] ? `Are you sure you want to delete the site "${data.documents[removeSiteId].name}"?` : '' }
          onCancel={ () => setRemoveSiteId(undefined) }
          onConfirm={ handleRemoveSite }
          visible={ !!removeSiteId } />
    </>
  );
}
