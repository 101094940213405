import * as React from 'react';
import {
  Box,
  Button,
  Card, Modal, Text} from '@hd/ui';
import { CCTVRecommendationProduct, Repair, SectionApplication } from '@hd/types';
import { X } from '@styled-icons/boxicons-regular';
import SectionApplicability from './CCTVRepair/SectionApplicability';
import ProductSelection from './CCTVRepair/ProductSelection';
import ProductSelectionDetails from './CCTVRepair/ProductSelectionDetails';
import ProductSelectionMetadata from './CCTVRepair/ProductSelectionMetadata';
import { Step } from './CCTVRecommendationsRecommendationForm';
import ProductSelectionAddOns from './CCTVRepair/ProductSelectionAddOns';


type Props = {
  onAdd: (repair: Repair) => void;
  onClose: () => void;
  editing?: Repair;
}



export default function CCTVReccomendationModal(props: Props) {
  const { onAdd, onClose, editing } = props;

  const [stepId, setStepId] = React.useState<Step | undefined>('SectionApplicability')
  const [repair, setRepair] = React.useState<Repair>(editing || {})


  const update = (updates: Partial<Repair>) => {
    setRepair({
      ...repair,
      ...updates
    })
  };


  return (
      <Modal visible={true} onClose={onClose}>
        <Card backgroundColor="light" margin="x4" padding="x6">
          <Box flex='horizontal' alignChildrenHorizontal='end'>
            
            <Button level='tertiary' onClick={onClose}>
              <Box flex='horizontal' alignChildrenVertical='middle'>
                <Text size='x1'>Close</Text> <X size='1rem'/>
              </Box>
            </Button>
          </Box>
          <SectionApplicability update={update} repair={repair} activeStepId={stepId} setStepId={setStepId}/> 
          <ProductSelection update={update} repair={repair}  setStepId={setStepId} activeStepId={stepId}/>
          <ProductSelectionDetails onAdd={onAdd} update={update} activeStepId={stepId} repair={repair} setStepId={setStepId} onClose={onClose}/>
          <ProductSelectionMetadata onAdd={onAdd} update={update} activeStepId={stepId} repair={repair} setStepId={setStepId} onClose={onClose}/>
          <ProductSelectionAddOns onAdd={onAdd} update={update} activeStepId={stepId} repair={repair} setStepId={setStepId} onClose={onClose}/>
        </Card>
      </Modal>

  );

}

