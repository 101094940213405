import { ContractorRaisedJob } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';
import firebase from 'firebase';

export default function useFirestoreContractorRaisedJob() {
  return useFirestoreDocument<ContractorRaisedJob>('ContractorRaisedJob', 'contractorRaisedJobs', undefined, {
    price: 0,
    date: firebase.firestore.Timestamp.now(),
    siteIsCustomer: true,
    purchaseOrderNumber: '',
    description: '',
  });
}
