import { JobType, Quote, Site } from '@hd/types';
import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Modal,
  Text,
  TextInput,
} from '@hd/ui';
import * as React from 'react';
import useInputNumber from '../../utils/useInputNumber';
import CustomerView from '../Customers/CustomerView';
import SiteView from '../Sites/SiteView';
import { useHistory } from 'react-router';
import { LinkAlt } from '@styled-icons/boxicons-regular';
import useFirestoreCollection, {
  TypeOrderBy,
} from '../../utils/useFirestoreCollection';
import Dropdown from '../Dropdown/Dropdown';
import CustomersList from '../Customers/CustomersList';
import MapSearchInput from '../MapSearchInput';

interface Props {
  quote: Quote;
  update: (updates: Partial<Quote>) => void;
}

const orderBys: TypeOrderBy<JobType>[] = [['name', 'asc']];

export default function QuoteForm(props: Props) {
  const { quote, update } = props;
  const { push } = useHistory();
  const [siteCustomerLinked, setSiteCustomerLinked] = React.useState(false);

  const [customerModalVisible, setCustomerModalVisible] = React.useState(false);
  const [price, handleUpdatePrice] = useInputNumber(quote.price, (price) => {
    update({ price });
  });

  const fullJobTypes = useFirestoreCollection<JobType>('JobTypes', 'jobTypes', {
    orderBys,
  });

  const jobTypeMap: Record<string, JobType> = {};

  const handleSiteCustomerLinked = () => {
    setSiteCustomerLinked(!siteCustomerLinked);
    if (quote.siteAddressLine1) {
      update({
        customerAddress1: quote.siteAddressLine1 || '',
        customerAddress2: quote.siteAddressLine2 || '',
        customerAddress3: quote.siteAddressLine3 || '',
        customerContactName: quote.siteContactName || '',
        customerPostcode: quote.siteAddressPostcode || '',
        customerNumber: quote.siteContactNumber || '',
        customerName: quote.siteContactName || '',
      });
    }
  };

  fullJobTypes.ids.forEach((e) => {
    const jobType = fullJobTypes.documents[e];
    if (jobType.name) {
      jobTypeMap[jobType.name] = jobType;
    }
  });

  return (
    <>
      {quote.parent ? (
        <Card
          alignChildrenHorizontal="middle"
          alignChildrenVertical="middle"
          backgroundColor="light"
          flex="vertical"
          gap="x3"
          margin="x4"
          padding="x3"
        >
          <Button
            onClick={() => push(`/jobs/${quote.parent._id}`)}
            padding="x2"
          >
            <LinkAlt color="blue" size="1rem" />
            <Text strong textColor="blue">
              Parent job
            </Text>
            <Badge backgroundColor="dark-gray" textColor="light-gray">
              {quote.parent.jobNumber}
            </Badge>
          </Button>
          <Box flex="horizontal" gap="x3">
            <Card padding="x3">
              <SiteView site={quote.customerSite} />
            </Card>
            <Card padding="x3">
              <CustomerView customer={quote.customer} />
            </Card>
          </Box>
        </Card>
      ) : (
        <Box flex="horizontal" gap="x3">
          <Card grow padding="x3" minWidth="400px">
            <Box flex="vertical" gap="x3" grow>
              <Text strong>Site</Text>
              <Box backgroundColor='light'>
                <Text strong>Autofill</Text>
              <MapSearchInput
              onChange={s => {
                update({
                    siteAddressLine1: s.addressLine1,
                    siteAddressLine2: s.addressLine2 || '',
                    siteAddressLine3: s.addressLine3 || '',
                    siteAddressPostcode: s.addressPostcode

                })
              }} value={{
                    _id: "",
                    _ref: '',
                    addressLine1: quote.siteAddressLine1 || '',
                    addressLine2: quote.siteAddressLine2 || '',
                    addressLine3: quote.siteAddressLine3 || '',
                    addressPostcode: quote.siteAddressPostcode || '' ,
                    name: '',
                    contactName: '',
                    contactPhoneNumber: ''
                  
                  }}/>
</Box>
              <Box flex="horizontal" gap="x2">
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Contact name</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteContactName: (e.target as HTMLInputElement).value,
                      });
                      if (siteCustomerLinked) {
                        update({
                          customerContactName: (e.target as HTMLInputElement)
                            .value,
                          customerName: (e.target as HTMLInputElement).value,
                        });
                      }
                    }}
                    placeholder="Required"
                    value={quote.siteContactName}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Contact number</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteContactNumber: (e.target as HTMLInputElement).value,
                      });
                      if (siteCustomerLinked) {
                        update({
                          customerNumber: (e.target as HTMLInputElement).value,
                        });
                      }
                    }}
                    placeholder="Required"
                    value={quote.siteContactNumber}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Site postcode</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteAddressPostcode: (
                          e.target as HTMLInputElement
                        ).value.toUpperCase(),
                      });
                      if (siteCustomerLinked) {
                        update({
                          customerPostcode: (e.target as HTMLInputElement)
                            .value,
                        });
                      }
                    }}
                    placeholder={'Required'}
                    value={quote.siteAddressPostcode || ''}
                  />
                </FormInput>
              </Box>
              <Box flex="horizontal" gap="x2">
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Site Name</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) =>
                      update({
                        siteName: (e.target as HTMLInputElement).value,
                      })
                    }
                    value={quote.siteName}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Address line 1</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteAddressLine1: (e.target as HTMLInputElement).value,
                      });
                      if (siteCustomerLinked) {
                        update({
                          customerAddress1: (e.target as HTMLInputElement)
                            .value,
                        });
                      }
                    }}
                    value={quote.siteAddressLine1 || ''}
                  />
                </FormInput>
              </Box>
              <Box flex="horizontal" gap="x2">
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Address line 2</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteAddressLine2: (e.target as HTMLInputElement).value,
                      });
                      if (siteCustomerLinked) {
                        update({
                          customerAddress2: (e.target as HTMLInputElement)
                            .value,
                        });
                      }
                    }}
                    value={quote.siteAddressLine2 || ''}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Address line 3</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteAddressLine3: (e.target as HTMLInputElement).value,
                      });
                      if (siteCustomerLinked) {
                        update({
                          customerAddress3: (e.target as HTMLInputElement)
                            .value,
                        });
                      }
                    }}
                    value={quote.siteAddressLine3 || ''}
                  />
                </FormInput>
              </Box>
            </Box>
          </Card>
          <Card grow padding="x3">
            <Box flex="vertical" gap="x3" grow>
              <Box alignChildrenHorizontal="between" flex="horizontal">
                <Box
                  alignChildrenHorizontal="between"
                  flex="horizontal"
                  gap="x2"
                >
                  <Text strong> Customer </Text>
                </Box>

                {!quote.customer && (
                  <Box flex="horizontal" gap="x3">
                    <Text>Site is customer</Text>
                    <CheckBox
                      checked={siteCustomerLinked}
                      onChange={handleSiteCustomerLinked}
                    />
                  </Box>
                )}
              </Box>
              {!quote.customer && (
                <Box flex="horizontal">
                  <Button
                    color="blue"
                    level="primary"
                    onClick={() => setCustomerModalVisible(true)}
                  >
                    <Text size="x1">Use existing customer</Text>
                  </Button>
                </Box>
              )}
              {quote.customer && <CustomerView customer={quote.customer} />}
              {
                !siteCustomerLinked && <MapSearchInput
              onChange={s => {
                update({
                    customerAddress1: s.addressLine1,
                    customerAddress2: s.addressLine2 || '',
                    customerAddress3: s.addressLine3 || '',
                    customerPostcode: s.addressPostcode

                })
              }} value={{
                    _id: "",
                    _ref: '',
                    addressLine1: quote.customerAddress1 || '',
                    addressLine2: quote.customerAddress2 || '',
                    addressLine3: quote.customerAddress3 || '',
                    addressPostcode: quote.customerPostcode || '' ,
                    name: '',
                    contactName: '',
                    contactPhoneNumber: ''
                  
                  }}/>
              }
              {!quote.customer && (
                <>
                  <Box flex="horizontal" gap="x2">
                    <FormInput grow>
                      <FormInputHeader>
                        <FormInputLabel>Email</FormInputLabel>
                      </FormInputHeader>
                      <TextInput
                        onChange={(e) =>
                          update({
                            customerEmail: (e.target as HTMLInputElement).value,
                          })
                        }
                        placeholder={'Required'}
                        value={quote.customerEmail || ''}
                      />
                    </FormInput>
                    <FormInput grow>
                      <FormInputHeader>
                        <FormInputLabel>Customer name</FormInputLabel>
                      </FormInputHeader>
                      <TextInput
                        onChange={(e) =>
                          update({
                            customerName: (e.target as HTMLInputElement).value,
                          })
                        }
                        placeholder="Required"
                        value={quote.customerName || ''}
                      />
                    </FormInput>
                    <FormInput grow>
                      <FormInputHeader>
                        <FormInputLabel>Contact name</FormInputLabel>
                      </FormInputHeader>
                      <TextInput
                        disabled={siteCustomerLinked}
                        onChange={(e) =>
                          update({
                            customerContactName: (e.target as HTMLInputElement)
                              .value,
                          })
                        }
                        placeholder="Required"
                        value={quote.customerContactName || ''}
                      />
                    </FormInput>
                  </Box>
                  <Box flex="horizontal" gap="x2">
                    <FormInput grow>
                      <FormInputHeader>
                        <FormInputLabel>Customer postcode</FormInputLabel>
                      </FormInputHeader>
                      <TextInput
                        onChange={(e) =>
                          update({
                            customerPostcode: (
                              e.target as HTMLInputElement
                            ).value.toUpperCase(),
                          })
                        }
                        placeholder="Required"
                        value={quote.customerPostcode || ''}
                      />
                    </FormInput>
                    <FormInput grow>
                      <FormInputHeader>
                        <FormInputLabel>Phone number</FormInputLabel>
                      </FormInputHeader>
                      <TextInput
                        onChange={(e) =>
                          update({
                            customerNumber: (e.target as HTMLInputElement)
                              .value,
                          })
                        }
                        value={quote.customerNumber || ''}
                      />
                    </FormInput>
                    <FormInput grow>
                      <FormInputHeader>
                        <FormInputLabel>Address line 1</FormInputLabel>
                      </FormInputHeader>
                      <TextInput
                        disabled={siteCustomerLinked}
                        onChange={(e) =>
                          update({
                            customerAddress1: (e.target as HTMLInputElement)
                              .value,
                          })
                        }
                        value={quote.customerAddress1 || ''}
                      />
                    </FormInput>
                  </Box>
                  <Box flex="horizontal" gap="x2">
                    <FormInput grow>
                      <FormInputHeader>
                        <FormInputLabel>Address line 2</FormInputLabel>
                      </FormInputHeader>
                      <TextInput
                        disabled={siteCustomerLinked}
                        onChange={(e) =>
                          update({
                            customerAddress2: (e.target as HTMLInputElement)
                              .value,
                          })
                        }
                        value={quote.customerAddress2 || ''}
                      />
                    </FormInput>
                    <FormInput grow>
                      <FormInputHeader>
                        <FormInputLabel>Address line 3</FormInputLabel>
                      </FormInputHeader>
                      <TextInput
                        disabled={siteCustomerLinked}
                        onChange={(e) =>
                          update({
                            customerAddress3: (e.target as HTMLInputElement)
                              .value,
                          })
                        }
                        value={quote.customerAddress3 || ''}
                      />
                    </FormInput>
                  </Box>
                </>
              )}
            </Box>
          </Card>
        </Box>
      )}

      {quote.customer?.managamentFee && (
        <Alert
          textColor="blue-dark"
          backgroundColor="blue-light"
          borderColor="blue-dark"
        >
              This is a property management account with a{' '}
              {quote.customer.managamentFee}% fee. Please check it's been applied.
        </Alert>
      )}

      <Card margin="x4" padding="x6">
        <Box basis="none" flex="horizontal" gap="x6">
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Job types</FormInputLabel>
            </FormInputHeader>
            <Dropdown
              data-at-id="JobType"
              grow
              onChange={(e) => {
                const jobType = jobTypeMap[e];
                update({
                  price: quote.customer?.managamentFee
                    ? jobType?.priceCustomer +
                      (jobType?.priceCustomer * (quote.customer?.managamentFee /
                        100)
                    : jobType?.priceCustomer,
                  jobType: jobType.name,
                  isCommercial: jobType?.isCommerical,
                  isCCTV: jobType?.isCCTV,
                  isDomestic: jobType?.isCommerical,
                  isRepair: jobType?.isRepair,
                  isUnblock: jobType?.isUnblock,
                  contractorNominalCode: jobType?.contractorNominalCode,
                  customerNominalCode: jobType?.customerNominalCode,
                  quoteText: jobType.salesInfo,
                });
              }}
              options={fullJobTypes.ids.map(
                (id) => fullJobTypes.documents[id].name
              )}
              value={quote.jobType ? quote.jobType : 'Select price'}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Price</FormInputLabel>
            </FormInputHeader>
            <TextInput grow onChange={handleUpdatePrice} value={price} />
          </FormInput>
        </Box>
      <Box flex="horizontal" gap="x2">
        <Text strong>Hourly Rate</Text>
        <CheckBox
          checked={quote.hourlyRate}
          onChange={() => update({ hourlyRate: !quote.hourlyRate })}
        />
      </Box>
      </Card>

      <Card
        backgroundColor="light"
        flex="vertical"
        gap="x3"
        margin="x4"
        padding="x6"
      >
        <Text strong>Descriptor for job</Text>
        <TextInput
          multiline
          onChange={(e) =>
            update({
              description: (e.target as HTMLInputElement).value,
            })
          }
          placeholder="This will be used as 'description' in job"
          rows={5}
          size="large"
          value={quote.description}
        />
      </Card>

      <Card backgroundColor="light" flex="vertical" gap="x3" padding="x6">
        {quote.jobType === undefined ? (
          <Text strong textColor="gray">
            Quote text (Select job type to begin)
          </Text>
        ) : (
          <Text strong>Quote text</Text>
        )}
        <TextInput
          disabled={quote.jobType === undefined}
          multiline
          onChange={(e) =>
            update({
              quoteText: (e.target as HTMLInputElement).value,
            })
          }
          placeholder="Select job type to begin"
          rows={15}
          size="large"
          value={quote.quoteText}
        />
      </Card>
      <Modal
        maxWidth="600px"
        onClose={() => setCustomerModalVisible(false)}
        visible={customerModalVisible}
      >
        <CustomersList
          isResultsVisibleBeforeSearch={false}
          onSelect={(customer) => {
            update({
              customer,
            });
            if (quote.jobType && customer.managamentFee) {
              const jobType = jobTypeMap[quote.jobType];
              update({
                  price: jobType?.priceCustomer +
                      (jobType?.priceCustomer * (customer?.managamentFee /
                        100))
              });
            }
            setCustomerModalVisible(false);
          }}
        />
      </Modal>
    </>
  );
}
