import { Job } from '@hd/types';
import * as React from 'react';
import EngineerView from '../Engineers/EngineerView';

export interface Props {
  job: Job;
}

export default function JobSelectionViewEngineer(props: Props) {
  const { job } = props;

  if (!job.contractorEngineer) {
    return null;
  }

  return (
    <EngineerView engineer={ job.contractorEngineer } />
  );
}
