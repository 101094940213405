import * as React from 'react';
import {
  Box,
  Card,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  TextInput,
} from '@hd/ui';
import { Site } from '@hd/types';
import FormInputAddress from '../FormInputs/FormInputAddress';
import MapSearchInput from '../MapSearchInput';

interface Props {
  document: Site;
  update: (updates: Partial<Site>) => void;
}

export default function SiteForm(props: Props) {
  const { document, update } = props;

  return (
    <Box flex='vertical' gap='x5' margin='x3'>
      <Box 
        backgroundColor='light-gray'
        borderRadius='x2'
        borderSize='x1'
        borderColor='dark-gray'
        padding='x3' 
        margin='x2' 
        elevation='x1'
        overflow="hidden"
        >
        <FormInput>
            <FormInputHeader>
              <FormInputLabel>Lookup</FormInputLabel>
            </FormInputHeader>
            <Box backgroundColor='light'>
              <MapSearchInput onChange={site => update(site)} value={document} />
            </Box>
        </FormInput>
      </Box>

    <Card padding='x3'>
      <FormInput padding='x3'>
          <FormInputHeader>
            <FormInputLabel>Name/Site</FormInputLabel>
          </FormInputHeader>
        <TextInput
            data-at-id="SiteName"
            onChange={ (e) => update({ name: (e.target as HTMLInputElement).value }) }
            required
            value={ document.name } />
      </FormInput>

      <FormInput padding='x3'>
        <FormInputHeader>
          <FormInputLabel>Contact Name</FormInputLabel>
        </FormInputHeader>

        <TextInput
            data-at-id="ContactName"
            onChange={ (e) => update({ contactName: (e.target as HTMLInputElement).value }) }
            required
            value={ document.contactName } />
      </FormInput>

      <FormInput padding='x3'>
        <FormInputHeader>
          <FormInputLabel>Contact Phone Number</FormInputLabel>
        </FormInputHeader>

        <TextInput
            data-at-id="ContactPhoneNumber"
            onChange={ (e) => update({ contactPhoneNumber: (e.target as HTMLInputElement).value }) }
            required
            value={ document.contactPhoneNumber } />
      </FormInput>

      <FormInputAddress
          document={ document }
          padding='x3'
          update={ update } />
      </Card>
    </Box>
  );
}
