import { TypeJobEmail } from '@hd/types';

export default function toEmailTypeFormat(type: TypeJobEmail) {
  return ({
    'checkatrade_review': 'Review Email',
    'contractor_invoice': 'Contractor Invoice',
    'contractor_invoice_engineer': 'Contractor Invoice to Engineer',
    'contractor_report': 'Contractor Report',
    'contractor_report_engineer': 'Contractor Report to Engineer',
    'customer_cancellation': 'Customer Cancellation',
    'contractor_cancellation': 'Contractor Cancellation',
    'contractor_cancellation_engineer': 'Contractor Cancellation to Engineer',
    'customer_confirmation': 'Customer Confirmation',
    'customer_invoice': 'Customer Invoice',
    'contractor_survey' : 'Survey Submission Email',
    'contractor_survey_engineer': 'Survey Submission Engineer',
    'survey_uploaded': 'Survey Uploaded',
    'customer_follow_up_quote': 'Follow up quote',
    'instant_quote': 'Instant quote',
  })[type] ;
}
