import * as React from 'react';
import { Box, Button, Card, CardRow, FormInput, Text, TextInput } from '@hd/ui';
import { Customer, Site } from '@hd/types';
import { BuildingHouse, Plus } from '@styled-icons/boxicons-regular';
import useFirestoreCustomers from '../../utils/useFirestoreCustomers';
import FirebaseList from '../Firebase/FirebaseList';
import useKeywordFilter from '../../utils/useKeywordFilter';
import useMergedFilters from '../../utils/useMergedFilters';
import { Options } from '../../utils/useFirestoreCollection';
import CustomerModal from './CustomerModal';
import CustomerView from './CustomerView';

interface Props {
  options?: Options<Customer>;
  onSelect?: (customer: Customer) => void;
  site?: Site;
}

export default function CustomersList(props: Props) {
  const { onSelect, options, site } = props;
  const [value, setValue] = React.useState('');
  const [isAddCustomerModalVisible, setIsAddCustomerModalVisible] =
    React.useState(false);
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);
  const data = useFirestoreCustomers({ filters: filters, limit: 25 });

  const handleCloseAddModal = (customer?: Customer) => {
    setIsAddCustomerModalVisible(false);

    if (customer && onSelect) {
      onSelect(customer);
    }
  };

  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow>
          <FormInput margin="x3">
            <TextInput
              onChange={(e) => setValue((e.target as HTMLInputElement).value)}
              placeholder="Search customers"
              value={value}
            />
          </FormInput>
        </Box>

        <Box>
          <Button
            color="blue"
            data-at-id="AddCustomerButton"
            level="tertiary"
            onClick={() => setIsAddCustomerModalVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add Customer</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data} onMore={data.more}>
        <Card>
          {data.ids.map((id) => (
            <CardRow
              flex="horizontal"
              key={id}
              onClick={onSelect && (() => onSelect(data.documents[id]))}
              padding="x4"
            >
              <Box grow>
                <CustomerView customer={data.documents[id]} />
              </Box>
              {data.documents[id].managamentFee && (
                <Box
                  flex="vertical"
                  alignChildrenHorizontal="middle"
                  textColor="blue-dark"
                >
                  <BuildingHouse size="2rem" />
                  <Text uppercase strong size="x1">
                    Management
                  </Text>
                </Box>
              )}
            </CardRow>
          ))}
        </Card>
      </FirebaseList>

      <CustomerModal
        onClose={handleCloseAddModal}
        site={site}
        visible={isAddCustomerModalVisible}
      />
    </>
  );
}
