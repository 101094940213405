import * as React from 'react';
import { Box, Button, Card, CardRow, FormInput, Text, TextInput } from '@hd/ui';
import { Contractor } from '@hd/types';
import {
  Briefcase,
  CheckShield,
  Plus,
  XCircle,
} from '@styled-icons/boxicons-regular';
import useFirestoreContractors from '../../utils/useFirestoreContractors';
import ContractorModal from './ContractorModal';
import FirebaseList from '../Firebase/FirebaseList';
import getAddress from '../../utils/getAddress';
import { Options, TypeOrderBy } from '../../utils/useFirestoreCollection';
import useKeywordFilter from '../../utils/useKeywordFilter';
import useMergedFilters from '../../utils/useMergedFilters';

interface Props {
  isSearchable?: boolean;
  options?: Options<Contractor>;
  onSelect: (contractor: Contractor) => void;
}

const orderBys: TypeOrderBy<Contractor>[] = [
  ['disabled', 'asc'],
  ['name', 'asc'],
];

export default function ContractorsList(props: Props) {
  const { isSearchable, onSelect, options } = props;
  const [value, setValue] = React.useState('');
  const [isAddContractorModalVisible, setIsAddContractorModalVisible] =
    React.useState(false);
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);
  const data = useFirestoreContractors({
    filters: filters,
    orderBys: orderBys,
    limit: 25,
  });

  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow>
          {isSearchable && (
            <FormInput margin="x3">
              <TextInput
                onChange={(e) => setValue((e.target as HTMLInputElement).value)}
                placeholder="Search customers"
                value={value}
              />
            </FormInput>
          )}
        </Box>

        <Box>
          <Button
            color="blue"
            data-at-id="AddContractorButton"
            level="tertiary"
            onClick={() => setIsAddContractorModalVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add Contractor</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data} onMore={data.more}>
        <Card>
          {data.ids.map((id) => (
            <CardRow
              alignChildrenVertical="middle"
              data-at-id="ContractorLisItem"
              flex="horizontal"
              key={id}
              onClick={() => onSelect(data.documents[id])}
              padding="x4"
            >
              <Box grow>
                <Text strong>{data.documents[id].name}</Text>
                <Text size="x2" textColor="gray">
                  {getAddress(data.documents[id])}
                </Text>
              </Box>

              <Box flex="horizontal" gap="x4">
                {data.documents[id].canRaiseJobs && (
                  <Box alignChildren="middle" flex="vertical" textColor="green">
                    <Briefcase size="32px" />
                    <Text align="middle" margin="x1" size="x1" strong uppercase>
                      Can raise jobs
                    </Text>
                  </Box>
                )}
                {data.documents[id].preferred && (
                  <Box alignChildren="middle" flex="vertical" textColor="green">
                    <CheckShield size="32px" />
                    <Text align="middle" margin="x1" size="x1" strong uppercase>
                      Preferred
                    </Text>
                  </Box>
                )}
                {data.documents[id].disabled && (
                  <Box alignChildren="middle" flex="vertical" textColor="red">
                    <XCircle size="32px" />
                    <Text align="middle" margin="x1" size="x1" strong uppercase>
                      Disabled
                    </Text>
                  </Box>
                )}
              </Box>
            </CardRow>
          ))}
        </Card>
      </FirebaseList>

      <ContractorModal
        onClose={() => setIsAddContractorModalVisible(false)}
        visible={isAddContractorModalVisible}
      />
    </>
  );
}
