import React from "react";
import useFirestoreProduct from "../../utils/useFirestoreProduct";
import { Box, Button, Page, PageBack, PageBody, PageHeader, PageTitle, Text } from '@hd/ui'
import FirebaseDocument from "../Firebase/FirebaseDocument";
import FormErrorMessage from "../FormErrorMessage";
import ProductForm from "./ProductAddOnForm";
import { useHistory, useParams } from "react-router-dom";
import useFirestoreProductAddOn from "../../utils/useFirestoreProductAddOn";
import { FormError, validateCCTVRecommendationProductAddOn } from "../../utils/formValidators";
import { isPricingValid } from "./util";

type Props = {
    addOnId: string;
}


export default function ProductAddOnView(props: Props) {
    const { push } = useHistory();
    const { productAddOnId } = useParams<{ productAddOnId: string }>();
    const [error, setError] = React.useState<FormError | null>(null);
    const document = useFirestoreProductAddOn(productAddOnId);

    const handleUpdate = async () => {
        // TODO - validate that the prices are correct (I.e. contractor is less than HD)
        const error = validateCCTVRecommendationProductAddOn(document.document)
        if (error) {
            setError(error)
        } else if (!isPricingValid(document.document.pricing)) {
            setError({
                field: '',
                message: 'Contractor price exceed HD price'
            })
        }
        else {
            await document.save()
            push('/products')
        }
    }

    return (
        <FirebaseDocument {...document} allowNoDocument={!productAddOnId}>
            <Page>
                <PageHeader padding="x0" paddingHorizontal="x10">
                    <Box alignChildrenVertical="end" flex='vertical' gap="x4" paddingVertical="x10">
                        <Box basis="none" grow>
                            <PageBack to={'/products/add-ons'}>Add-ons</PageBack>
                        </Box>
                        <Box
                            alignChildrenVertical="middle"
                            flex="horizontal"
                            gap="x2">
                            <PageTitle>{document.document.name} </PageTitle>

                        </Box>

                    </Box>

                </PageHeader>


                <PageBody>
                    <ProductForm product={document.document} update={document.update} />

                    {error && (
                        <FormErrorMessage error={error} />
                    )}
                    <Button
                        color="blue"
                        level="primary"
                        onClick={handleUpdate}>
                        Update Product
                    </Button>
                </PageBody>
            </Page>
        </FirebaseDocument>
    )
}