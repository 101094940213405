import { CCTVRecommendationProductUnit, UnitTypeType, CCTVRecommendationProduct } from "@hd/types";
import React from "react";
import { humanReadableUnitType } from "../../constants/CCTVReccommendationContants";
import { CheckBox, Box } from "@hd/ui";

type Props = {
    update: (updates: Partial<CCTVRecommendationProduct>) => void;
    type: UnitTypeType;
    product: CCTVRecommendationProduct;
}

const timeBasedUnits: UnitTypeType[] = [
    'HourRate', 'HalfDayRate', 'DayRate'
]

function calculateDisabled(type: UnitTypeType, selectedUnit?: UnitTypeType) {
    if (!selectedUnit) {
        return false;
    } else if (selectedUnit === type) {
        return false;
    } else if (timeBasedUnits.includes(selectedUnit)) {
        return !timeBasedUnits.includes(type)
    } else return type !== selectedUnit
}

export default function ProductUnitTypeCheckBox(props: Props) {
    const { update, type, product } = props;
    const selectedUnit = product.pricing.length > 0 ? product.pricing[0].type : undefined
    const isDisabled = calculateDisabled(type, selectedUnit)

    return (
        <Box style={{ 'opacity': isDisabled ? '0.4' : '1' }} flex='vertical' alignChildrenHorizontal='middle'>
            {humanReadableUnitType[type]}
            <CheckBox checked={product.pricing.map(e => e.type).includes(type)} onChange={isDisabled ? undefined : () => {
                if (product.pricing.map(e => e.type).includes(type)) {
                    update({
                        pricing: product.pricing.filter(e => e.type !== type)
                    })
                } else {
                    update({
                        requiresAtPosition: type === 'Quantity',
                        requiresFromToPosition: type === 'Meterage',
                        pricing: [...product.pricing, {
                            type,
                            price: 0,
                            min: 0,
                            contractorPrice: 0
                        }]
                    })
                }
            }} />
        </Box>

    )
}
