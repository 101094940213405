import { Card, Modal } from '@hd/ui';
import { CCTVRecommendationProduct, CCTVRecommendationSubcategory, Site } from '@hd/types';
import * as React from 'react';
import SitesList from '../Sites/SitesList';
import SubcategoriesList from './subcategory/SubcategoriesList';

interface Props {
  onClose: () => void;
  onSelect: (value: CCTVRecommendationSubcategory) => void;
}

export default function JobSelectionModalSite(props: Props) {
  const {
    onClose,
    onSelect,
  } = props;

  return (
    <Modal
      maxWidth="600px"
      onClose={onClose}
      visible>
      <Card padding='x8'>
        <SubcategoriesList
          onSelect={onSelect}
          options={{ limit: 25 }} />
      </Card>
    </Modal>
  );
}
