import * as React from 'react';
import {
  Badge,
  Box,
  Button,
  Card, Text} from '@hd/ui';
import { CCTVReccomendation, Repair, SectionApplication } from '@hd/types';
import { Plus } from '@styled-icons/boxicons-regular';
import CCTVReccomendationModal from './CCTVRecommendationModal';
import CCTVReccoemdnationRepairSummary from './CCTVRecommendationsRepairSummary';
import { v4 } from 'uuid'


type Props = {
  document: CCTVReccomendation;
  update: (c: Partial<CCTVReccomendation>) => void;
  save: () => void;
  readOnly?: boolean;
}

export type Step = 'SectionApplicability' | 'Menu' | 'ProductDetails' | 'ProductMetadata' | 'ProductAddOns'

export default function CCTVRecommendationsRecommendationForm(props: Props) {
  const { document, update, save, readOnly = false } = props;

  const onAdd = (repair: Repair) => {
    update({
      ...document,
      repairs: [
        ...document.repairs,
        {
          ...repair,
          id: v4()
        }
      ]
    })
    save();
  }

  const onEdit = (updated: Repair) => {
    update({
      ...document,
      repairs: [
        ...document.repairs.map(e => e.id === updated.id ? updated : e)
      ]
    })
    save();
  }

  const handleRemoveRepair = (toRemove: Repair) => {
    update({
      ...document,
      repairs: document.repairs.filter(e => e.id !== toRemove.id)
    })
    save();
  }

  const [createModalVisible, setCreateModalVisible] = React.useState(false)

  const getSectionName = (section: string, sectionApplication: SectionApplication) => {
    if(sectionApplication === 'Single') {
      return `Section/Lateral ${section}`
    }
    if(sectionApplication === 'Multiple') {
      return `Sections/Laterals ${section}`
    }
    if(sectionApplication === 'Site wide') {
      return `Site wide`
    }
  }

  const groupBy = (array: Repair[]): Record<string, Repair[]> => {
    return array.reduce((result, currentValue) => {
      const groupKey = getSectionName(currentValue.section!, currentValue.sectionApplication!)!
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {} as Record<string, Repair[]>);
  };


  const groupedRepairs = groupBy(document.repairs);

  return (
    <>
      <Card backgroundColor="light" margin="x2" padding="x6">
        <Box flex='horizontal' alignChildrenHorizontal='between'>
          <Text size="x4" strong>Reccomendations</Text>
          {
            !readOnly && <Button color='blue' onClick={() => setCreateModalVisible(true)} size='small' level='tertiary'>
            <Plus size='1rem'/>
            <Text size='x1'> Add </Text>
          </Button>
          }
        </Box>
        <Box flex='vertical'>

        {
          Object.keys(groupedRepairs).map(repairGroup => (
            <Box key={repairGroup} margin='x4'>
              <Box flex='horizontal'>
                <Badge backgroundColor='dark-gray' textColor='light'>{repairGroup}</Badge>
              </Box>
              <Box flex='vertical' gap='x2' margin='x3'>
                { groupedRepairs[repairGroup].map((repair, i) => 
                  <CCTVReccoemdnationRepairSummary key={repair.id} repair={repair} onEdit={onEdit} onRemove={handleRemoveRepair} index={i + 1} readOnly={readOnly}/>
                )}
              </Box>
            </Box>
          ))
        }



        { document.repairs.length === 0 && <Box><Text>No reccommendations yet</Text></Box> }
        </Box>

      </Card>


      {
        createModalVisible && <CCTVReccomendationModal onAdd={onAdd} onClose={() => setCreateModalVisible(false)}/>
      }

    </>

  );

}
