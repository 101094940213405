import { Contractor } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreContractor(id?: string) {
  return useFirestoreDocument<Contractor>('Contractor', 'contractors', id, {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressPostcode: '',
    coverage: [],
    contactEmails: [],
    contactName: '',
    contactPhoneNumbers: [],
    name: '',
    preferred: false,
    disabled: false,
    sageReference: '',
    vatType: 'VAT Registered',
  });
}
