import { CCTVRecommendationProduct } from '@hd/types';
import { Box, Text } from '@hd/ui';
import React from 'react';

export interface Props {
  product: CCTVRecommendationProduct;
}

export default function JobSelectionViewSite(props: Props) {
  const { product } = props;

  return ( 
    <Box grow alignChildrenHorizontal='middle' alignChildrenVertical='middle'>
      <Box>
        <Text strong size='x3' textColor='dark-gray'> {`${product.cachedGrandparentName} > ${product.cachedParentName}`} </Text>
      </Box> 
    </Box>
  );
}
