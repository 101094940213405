import { Quote } from '@hd/types';
import firebase from 'firebase/app';
import { Box, Button, TextInput, Text } from '@hd/ui';
import * as React from 'react';
import FormErrorMessage from '../FormErrorMessage';
import { FormError } from '../../utils/formValidators';
import { isFollowUpQuoteInvalid } from '../../utils/quote';

interface Props {
  quote: Quote;
  save: () => Promise<Quote>;
}

export function JobReportFollowUpQuoteManualSendEmail({ quote, save }: Props) {
  const [error, setError] = React.useState<FormError>();
  const [sending, setSending] = React.useState(false);
  const [emailAddress, setEmailAddress] = React.useState<string>();
  const expression =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

  const sendEmail = async () => {
    setSending(true);
    const validEmail = emailAddress && expression.test(emailAddress);
    if (isFollowUpQuoteInvalid(quote) && quote.furtherWorkRequired) {
      setError({
        field: '',
        message: 'Cannot send email. Quote invalid.',
      });
      setSending(false);
      return;
    }
    if (validEmail) {
      await save();
      await firebase.functions().httpsCallable('manuallySendQuote')({
        quoteId: quote._id,
        emailAddress,
      });
      setEmailAddress('');
      setError(undefined);
    } else {
      setError({ field: '', message: 'Invalid email' });
    }
    setSending(false);
  };

  return (
      <>
        <TextInput
          grow
          data-at-id="EmailAddress"
          minWidth='200px'
          onChange={(e) =>
            setEmailAddress((e.target as HTMLInputElement).value)
          }
          type="email"
          value={emailAddress || ''}
        />
        <Button
          disabled={sending || isFollowUpQuoteInvalid(quote)}
          onClick={sendEmail}
        >
          {sending ? 'Sending' : 'Override send email'}
        </Button>
      {error && <FormErrorMessage error={error} />}
    </>
  );
}

export default JobReportFollowUpQuoteManualSendEmail;
