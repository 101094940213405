import { Box } from '@hd/ui';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Authenticated from './Authenticated';
import Complete from './Complete';
import Survey from './Survey';
import InvoiceContractor from './Invoice/InvoiceContractor';
import InvoiceCustomer from './Invoice/InvoiceCustomer';
import Login from './Login';
import Notifications from './Notifications/Notifications';
import QuoteCustomer from './QuoteCustomer/QuoteCustomer';
import ContractorRaisedJob from './ContractorRaisedJob/ContractorRaisedJob';
import WebInquiryAutomatic from './WebInquiries/WebInquiryAutomatic';
import RiskAssessmentTemplates from './RiskAssessmentTemplates/RiskAssessmentTemplates';
import CCTVRecommendations from './CCTVRecommendations';
import CCTVRecommendationsPDF from './CCTVRecommendations/CCTVRecommendationsPDF';

export default function App() {
  return (
    <Box data-at-id="AppRoot" flex="vertical" grow>
      <Notifications>
        <Switch>
          <Route component={Login} path="/login" />
          <Route component={Complete} path="/complete/:magicId" />
          <Route component={CCTVRecommendations} path="/cctv-reccomendations/:magicId" />
          <Route component={CCTVRecommendations} path="/survey/:magicId" />
          <Route component={InvoiceContractor} path="/invoice/co/:magicId" />
          <Route component={InvoiceCustomer} path="/invoice/cu/:magicId" />
          <Route component={QuoteCustomer} path="/quote/cu/:magicId" />
          <Route component={CCTVRecommendationsPDF} path="/cover-letter/cu/:magicId" />
          <Route
            component={WebInquiryAutomatic}
            path="/cu/web-inquiry/:magicId"
          />
          <Route component={ContractorRaisedJob} path="/crj/co/:contractorId" />
          <Route component={RiskAssessmentTemplates} path="/risk-assessment-templates" />
          {
            //<Route component={EngineerPage} path="/engineer/:contractorId"/>
          }
          <Route component={Authenticated} />
        </Switch>
      </Notifications>
    </Box>
  );
}
