import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { isAdmin } from '../../utils/authLevel';
import Products from './Products';
import Subcategories from './subcategory/Subcategories';
import ProductView from './ProductView';
import ProductsAddOns from './ProductsAddOns';
import ProductAddOnView from './ProductAddOnView';
import SubcategoryView from './subcategory/SubcategoryView';
import Categories from './categories/Categories';
import CategoriesView from './categories/CategoriesView';

export default function ProductsIndex() {
  if (!isAdmin()) {
    return null;
  }

  return (
    <Switch>
      <Route component={Products} exact path="/products" />
      <Route component={ProductsAddOns} exact path="/products/add-ons" />
      <Route component={Subcategories} exact path="/products/subcategories" />
      <Route component={SubcategoryView} exact path="/products/subcategories/:subcategoryId" />
      <Route component={ProductAddOnView} exact path="/products/add-ons/:productAddOnId" />
      <Route component={Categories} exact path="/products/categories" />
      <Route component={CategoriesView} exact path="/products/categories/:categoryId" />
      <Route component={ProductView} exact path="/products/:productId" />
    </Switch>
  );
}
