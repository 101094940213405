import {
  BlockageCause,
  JobReport,
  NodeType,
  UnblockEquipement,
} from '@hd/types';
import React from 'react';
import { Box, Text } from '@hd/ui';
import Dropdown from '../Dropdown/Dropdown';

interface Props {
  jobReport: JobReport;
  update: (updates: Partial<JobReport>) => void;
}

export default function CompleteFormUnblockForm(props: Props) {
  const { jobReport, update } = props;
  return (
    <Box flex="vertical" gap="x3">
      <Box
        flex="vertical"
        alignChildrenHorizontal="between"
        alignChildrenVertical="middle"
      >
        <Text strong>Successful</Text>
        <Box grow>
          <Dropdown
            grow
            value={
              jobReport.unblockForm?.cleanSuccess === undefined
                ? ''
                : jobReport.unblockForm?.cleanSuccess
                ? 'Yes'
                : 'No'
            }
            options={['Yes', 'No']}
            onChange={(e) =>
              update({
                unblockForm: {
                  ...jobReport.unblockForm,
                  cleanSuccess: e === 'Yes' ? true : false,
                },
              })
            }
            onTextInput={() => {}}
          />
        </Box>
      </Box>

      <Box
        flex="vertical"
        alignChildrenHorizontal="between"
        alignChildrenVertical="middle"
      >
        <Text strong>Equipment used</Text>
        <Box grow>
          <Dropdown<UnblockEquipement>
            grow
            value={jobReport.unblockForm?.equpmentUsed}
            options={[
              'Handheld Electromechanical Machine',
              'High-Pressure Jet',
              'Large Electromechanical Machine',
              'Other',
              'Picote',
              'Rods',
              'Vac',
            ]}
            onChange={(e) =>
              update({
                unblockForm: {
                  ...jobReport.unblockForm,
                  equpmentUsed: e,
                },
              })
            }
            onTextInput={() => {}}
          />
        </Box>
      </Box>

      <Box
        flex="vertical"
        alignChildrenHorizontal="between"
        alignChildrenVertical="middle"
      >
        <Text strong>Cause</Text>
        <Box grow>
          <Dropdown<BlockageCause>
            value={jobReport.unblockForm?.cause}
            options={[
              'General blockage',
              'Greese fat',
              'Misuse',
              'Wipes',
              'Structural issue',
              'Suspected structural issue',
            ]}
            onChange={(e) =>
              update({
                unblockForm: {
                  ...jobReport.unblockForm,
                  cause: e,
                },
              })
            }
            onTextInput={() => {}}
          />
        </Box>
      </Box>

      <Box
        flex="vertical"
        alignChildrenHorizontal="between"
        alignChildrenVertical="middle"
      >
        <Text strong>From</Text>
        <Box grow>
          <Dropdown<NodeType>
            value={jobReport.unblockForm?.fromCleared}
            options={['Gully', 'IC', 'MH', 'Other', 'Sink', 'Stack', 'WC']}
            onChange={(e) =>
              update({
                unblockForm: {
                  ...jobReport.unblockForm,
                  fromCleared: e,
                },
              })
            }
            onTextInput={() => {}}
          />
        </Box>
      </Box>

      <Box
        flex="vertical"
        alignChildrenHorizontal="between"
        alignChildrenVertical="middle"
      >
        <Text strong>To</Text>
        <Box grow>
          <Dropdown<NodeType>
            value={jobReport.unblockForm?.toCleared}
            options={[
              'Gully',
              'IC',
              'MH',
              'Other',
              'Sink',
              'Stack',
              'WC',
              'Undetermined outlet',
            ]}
            onChange={(e) =>
              update({
                unblockForm: {
                  ...jobReport.unblockForm,
                  toCleared: e,
                },
              })
            }
            onTextInput={() => {}}
          />
        </Box>
      </Box>
    </Box>
  );
}
