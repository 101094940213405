import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import RecurringJobs from './RecurringJobs';

export default function RecurringJobsIndex() {
  return (
    <Switch>
      <Route component={RecurringJobs} path="/recurring-jobs" />
    </Switch>
  );
}
