import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Dictionary } from 'lodash';
import firebase from 'firebase/app';
import keyBy from 'lodash.keyby';
import { firestoreSnapshotToDocument } from './useFirestoreDocument';
import { TypeTableSort } from '@hd/ui';
import { NotificationsContext } from '../components/Notifications/Notifications';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypeFilter = [string | firebase.firestore.FieldPath, firebase.firestore.WhereFilterOp, any];
export type TypeOrderBy<T> = [keyof T, TypeTableSort];

export interface Data<T> {
  ids: string[];
  documents: Dictionary<T>;
  hasFailed: boolean;
  hasFetched: boolean;
  isEmpty: boolean;
  isFetching: boolean;
  add: (item: T) => Promise<void>;
  remove: (id: string) => Promise<void>;
  more?: () => Promise<void>;
}

export interface Options<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: TypeFilter[];
  limit?: number;
  orderBys?: TypeOrderBy<T>[];
  realtime?: boolean;
  resolve?: boolean;
}

export default function useFirestoreCollection<T>(entity: string, collection: string, options: Options<T> = {}): Data<T> {
  const { addNotification } = useContext(NotificationsContext);
  const refIsFetching = useRef(false);
  const refUnSubscribe = useRef<() => void>();
  const refLastSnapshot = useRef<firebase.firestore.DocumentSnapshot>();
  const [data, setData] = useState<Omit<Data<T>, 'add' | 'more' | 'remove'>>({
    ids: [],
    documents: {},
    hasFailed: false,
    hasFetched: false,
    isFetching: false,
    isEmpty: false,
  });

  const createRef = useCallback(() => {
    let ref: firebase.firestore.Query = firebase.firestore().collection(collection);

    if (Array.isArray(options.filters)) {
      for (const [field, operation, value] of options.filters) {
        ref = ref.where(field, operation, value);
      }
    }

    if (options.orderBys) {
      options.orderBys.forEach(([key, direction]) => {
        ref = ref.orderBy(key as string, direction);
      });
    }

    if (refLastSnapshot.current) {
      ref = ref.startAfter(refLastSnapshot.current);
    }

    if (options.limit !== undefined) {
      ref = ref.limit(options.limit);
    }

    return ref;
  }, [
    collection,
    options.filters,
    options.limit,
    options.orderBys,
  ]);

  const handleSnapshot = useCallback(async(snapshot: firebase.firestore.QuerySnapshot) => {
    const isPaginating = options.limit !== undefined;

    const list = await Promise.all(snapshot.docs.map((doc) => firestoreSnapshotToDocument<T>(doc, options.resolve)));
    const documents = keyBy(list, '_id');
    const ids = Object.keys(documents);

    refIsFetching.current = false;
    refLastSnapshot.current = isPaginating ? snapshot.docs[snapshot.docs.length - 1] : undefined;

    setData((state) => ({
      ids: isPaginating ? [...state.ids, ...ids] : ids,
      documents: isPaginating ? { ...state.documents, ...documents } : documents,
      hasFailed: false,
      hasFetched: true,
      isFetching: false,
      isEmpty: ids.length === 0 && (!isPaginating || state.ids.length === 0),
    }));
  }, [
    options.limit,
    options.resolve,
  ]);

  const more = useCallback(async() => {
    if (!refIsFetching.current) {
      refIsFetching.current = true;
      const ref = createRef();

      if (options.realtime) {
        if (options.limit) {
          throw Error('It\'s not a good idea to paginate with realtime listeners.');
        }

        refUnSubscribe.current = ref.onSnapshot(handleSnapshot);
        return;
      }

      handleSnapshot(await ref.get());
    }
  }, [
    createRef,
    options.realtime,
    options.limit,
    handleSnapshot,
  ]);

  const reset = useCallback(() => {
    if (refUnSubscribe.current) {
      refUnSubscribe.current();
    }

    refIsFetching.current = false;
    refLastSnapshot.current = undefined;

    setData({
      ids: [],
      documents: {},
      hasFailed: false,
      hasFetched: false,
      isFetching: true,
      isEmpty: false,
    });
  }, []);

  const add = async(item: T) => {
    await firebase
      .firestore()
      .collection(collection)
      .doc()
      .set(item);
    addNotification(`${entity} added successfully!`);
  };

  const remove = async(id: string) => {
    await firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .delete();
    addNotification(`${entity} removed successfully!`);
  };

  useEffect(() => {
    reset();
    more();

    return () => {
      if (refUnSubscribe.current) {
        refUnSubscribe.current();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    more,
    reset,
  ]);

  return {
    ...data,
    add: add,
    remove: remove,
    more: refLastSnapshot.current ? more : undefined,
  };
}
