import firebase from 'firebase/app';
import { Complaint } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreComplaint(jobId: string, contractorId: string, complaintId?: string) {
  return useFirestoreDocument<Complaint>('Complaint', '/complaints', complaintId, {
    contractorId: contractorId,
    description: '',
    jobId: jobId,
    timestampLogged: firebase.firestore.Timestamp.now(),
    timestampResolved: null,
  });
}
