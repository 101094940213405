import { Modal } from '@hd/ui';
import { Engineer, Job } from '@hd/types';
import * as React from 'react';
import EngineersList from '../Engineers/EngineersList';

interface Props {
  job: Job;
  onClose: () => void;
  onSelect: (value: Engineer) => void;
}

export default function JobSelectionModalEngineer(props: Props) {
  const {
    job,
    onClose,
    onSelect,
  } = props;

  if (!job.contractor) {
    return null;
  }

  return (
    <Modal
        maxWidth="600px"
        onClose={ onClose }
        visible>
      <EngineersList
          contractor={ job.contractor }
          onSelect={ onSelect } />
    </Modal>
  );
}
