import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Jobs from './Jobs';
import JobCreateChild from '../Job/JobCreateChild';
import JobView from '../Job/JobView';
import JobsContractorRaised from './JobsContractorRaised';
import ContractorRaisedDashboard from './ContractorRaisedDashboard';

export default function JobsIndex() {
  return (
    <Switch>
      <Route component={JobCreateChild} path="/jobs/:jobId/job" />
      <Route component={JobView} path="/jobs/:jobId" />
      <Route component={Jobs} path="/jobs" />
      <Route component={Jobs} path="/requires-action" />
      <Route exact component={ContractorRaisedDashboard} path="/crj" />
      <Route component={JobsContractorRaised} path="/crj/:contractorId" />
    </Switch>
  );
}
