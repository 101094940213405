import * as React from 'react';
import { Box, Button, Card, Modal, Text } from '@hd/ui';
//import useFirestoreContractor from "../../utils/useFirestoreContractor";
//import { validateContractor, FormError } from "../../utils/formValidators";
import FormErrorMessage from '../FormErrorMessage';
import useFirestoreJobTypes from '../../utils/useFirestoreJobTypes';
import JobTypeForm from './JobTypeForm';
import { FormError, validateJobType } from '../../utils/formValidators';

interface Props {
  onClose: () => void;
  visible: boolean;
}

export default function JobTypeModal(props: Props) {
  const { onClose, visible } = props;
  const { document, update, save, clear } = useFirestoreJobTypes();
  const [error, setError] = React.useState<FormError | null>(null);

  const handleSave = async () => {
    const error = validateJobType(document);
    setError(error);

    if (!error) {
      await save();
      clear();
      onClose();
    }
  };

  return (
    <Modal maxWidth="600px" onClose={onClose} visible={visible}>
      <Card backgroundColor="light" padding="x10">
        <Text size="x4">Add Job Type</Text>

        <JobTypeForm document={document} update={update} />
        {error && <FormErrorMessage error={error} />}

        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x4"
          margin="x10"
        >
          <Button data-at-id="ModalCancel" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="blue"
            data-at-id="ModalConfirm"
            level="primary"
            onClick={handleSave}
            type="submit"
          >
            Create Job Type
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
