import * as React from 'react';
import { Box, Button, Card, CardRow, FormInput, Text, TextInput } from '@hd/ui';
import { JobType } from '@hd/types';
import { Plus } from '@styled-icons/boxicons-regular';
import FirebaseList from '../Firebase/FirebaseList';
import useFirestoreCollection, {
  Options,
  TypeOrderBy,
} from '../../utils/useFirestoreCollection';
import useKeywordFilter from '../../utils/useKeywordFilter';
import useMergedFilters from '../../utils/useMergedFilters';
import JobTypeModal from './JobTypeModal';

interface Props {
  isSearchable?: boolean;
  options?: Options<JobType>;
  onSelect: (jobType: JobType) => void;
}

const orderBys: TypeOrderBy<JobType>[] = [['name', 'asc']];

export default function JobTypesList(props: Props) {
  const { isSearchable, onSelect, options } = props;
  const [value, setValue] = React.useState('');
  const [isAddJobTypeVisible, setIsAddJobTypeModalVisible] =
    React.useState(false);
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);
  const data = useFirestoreCollection<JobType>('JobType', 'jobTypes', {
    filters: filters,
    limit: 50,
    orderBys,
  });

  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow>
          {isSearchable && (
            <FormInput margin="x3">
              <TextInput
                onChange={(e) => setValue((e.target as HTMLInputElement).value)}
                placeholder="Search job types"
                value={value}
              />
            </FormInput>
          )}
        </Box>

        <Box>
          <Button
            color="blue"
            data-at-id="AddContractorButton"
            level="tertiary"
            onClick={() => setIsAddJobTypeModalVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add Job Type</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data} onMore={data.more}>
        <Card>
          {data.ids.map((id) => (
            <CardRow
              alignChildrenVertical="middle"
              data-at-id="ContractorLisItem"
              flex="horizontal"
              key={id}
              onClick={() => onSelect(data.documents[id])}
              padding="x4"
            >
              <Box grow>
                <Text strong>{data.documents[id].name}</Text>
                <Text weak size="x1">
                  {data.documents[id].isFixedPrice
                    ? `${data.documents[id].priceContractor1} / ${data.documents[id].priceContractor2} / ${data.documents[id].priceContractor3}`
                    : 'No fixed price'}
                </Text>
              </Box>
            </CardRow>
          ))}
        </Card>
      </FirebaseList>

      <JobTypeModal
        onClose={() => setIsAddJobTypeModalVisible(false)}
        visible={isAddJobTypeVisible}
      />
    </>
  );
}
