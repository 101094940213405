import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Contractor from './Contractor';
import Contractors from './Contractors';

export default function ContractorsIndex() {
  return (
    <Switch>
      <Route component={ Contractor } path="/contractors/:contractorId" />
      <Route component={ Contractors } path="/contractors" />
    </Switch>
  );
}
