import * as React from 'react';
import { Box, Button, Card, CardRow, CheckBox, Modal, Text } from '@hd/ui';
import firebase from 'firebase/app';
import { Job } from '@hd/types';
import { Data } from '../../utils/useFirestoreDocument';
import FormErrorMessage from '../FormErrorMessage';

interface Props {
  visible: boolean;
  onClose: () => void;
  job: Data<Job>;
  
}

export default function JobCancelModal(props: Props) {
  const { visible, job, onClose  } = props;
  const [sendCancellationEmail, setSendCancellationEmail] = React.useState(true)
  const [working, setWorking] = React.useState(false)

  const handleCancelJob = async() => {
    if(working) {
      return;
    }
    await firebase.functions().httpsCallable('onSendCancelEmailsContractor')({
      jobId: job.document._id,
    });
    if (job.document.status == 'UNAPPROVED_SUBJOB') {
      job.update({
          status: 'CANCELLED',
          purchaseOrderNumber: 'YOU NEED TO CHANGE ALL INFORMATION',
          jobType: 'Other',
          contractorEngineer: null,
          contractor: null,
          lastBookedUpdatedBy: firebase.auth().currentUser?.email
        });

    } else {
    job.update({ 
      status: 'CANCELLED',
      contractorEngineer: null,
      contractor: null,
      lastBookedUpdatedBy: firebase.auth().currentUser?.email
     });
    }

    if (sendCancellationEmail) {
      await firebase.functions().httpsCallable('onSendCancelEmails')({
        jobId: job.document._id,
      });
    };
    job.save();
    setWorking(false)
    onClose()
  }

  return (
    <Modal maxWidth="300px" onClose={onClose} visible={visible}>
      <Card backgroundColor="light" padding="x6" flex='vertical' gap='x3'>
        <Box>
          <Text size='x4'>Are you soure you want to cancel job?</Text>
        </Box>
        <Box flex='vertical' elevation='x2' gap='x2' alignChildrenHorizontal='middle' padding='x4' borderColor='light-gray' borderSize='x1'>
          <Text size='x1' strong> Send cancellation email to customer </Text>
          <CheckBox checked={sendCancellationEmail} onChange={() => setSendCancellationEmail(!sendCancellationEmail)} />
        </Box>


        <Box
          alignChildrenHorizontal='middle'
          flex="horizontal"
          gap="x4"
        >
          <Button onClick={onClose}>Cancel</Button>

          <Button color="red" level="primary" disabled={working} onClick={async() => {
            setWorking(true)
            handleCancelJob()
            }} type="submit">
            Confirm
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
