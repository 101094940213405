import { useEffect, useState, ChangeEvent, FormEventHandler } from 'react';

export default function useInputNumber(value: number, update: (value: number) => void): [string, FormEventHandler] {
  const [inputValueString, setInputValueString] = useState(value.toString());

  useEffect(() => {
    setInputValueString(value.toString());
  }, [value]);

  const updateValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(target.value);

    setInputValueString(target.value);

    if (!isNaN(value)) {
      update(value);
    }
  };

  return [inputValueString, updateValue];
}
