import * as React from 'react';
import {
  Box,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
} from '@hd/ui';
import { useHistory } from 'react-router-dom';
import SubcategoriesList from './SubcategoriesList';

export default function Subcategories() {
  const { push } = useHistory();


  return (
    <Page data-at-id="ProdutcsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Subcategories</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <SubcategoriesList
          isSearchable
          onSelect={(subcategory) => push(`/products/subcategories/${subcategory._id}`)}
        />
      </PageBody>
    </Page>
  );
}
