import { JobEmail } from '@hd/types';
import useFirestoreCollection, { Options, TypeOrderBy } from './useFirestoreCollection';

const orderBys: TypeOrderBy<JobEmail>[] = [['timestamp', 'asc']];

export default function useFirestoreJobEmails(jobId: string, options?: Options<JobEmail>) {
  return useFirestoreCollection<JobEmail>('Email', `jobs/${jobId}/emails`, {
    ...options,
    orderBys,
  });
}
