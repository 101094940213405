import * as React from 'react';
import { Box, Button, Card, Modal, Text } from '@hd/ui';
import { FormError, validateNoteOrQuote } from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';
import AdminNoteAndQuoteForm from './AdminNoteOrQuoteForm';
import useFirestoreNotesAndQuotes from '../../utils/useFirestoreNotesAndQuotes';

interface Props {
  onClose: () => void;
  visible: boolean;
}

export default function AdminNoteOrQuoteModal(props: Props) {
  const { onClose, visible } = props;
  const { document, update, save, clear } = useFirestoreNotesAndQuotes();
  const [error, setError] = React.useState<FormError | null>(null);

  const handleSave = async () => {
    const error = validateNoteOrQuote(document);
    setError(error);

    if (!error) {
      await save();
      clear();
      onClose();
    }
  };

  return (
    <Modal maxWidth="600px" onClose={onClose} visible={visible}>
      <Card backgroundColor="light" padding="x10">
        <Text size="x4">Add Note/quote</Text>

        <AdminNoteAndQuoteForm document={document} update={update} />
        {error && <FormErrorMessage error={error} />}

        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x4"
          margin="x10"
        >
          <Button data-at-id="ModalCancel" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="blue"
            data-at-id="ModalConfirm"
            level="primary"
            onClick={handleSave}
            type="submit"
          >
            Create note or quote
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
