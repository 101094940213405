import * as React from 'react';
import { Box, Card, CardRow, Link, Text } from '@hd/ui';
import { CCTVRecommendationProduct, CCTVRecommendationSubcategory, Job } from '@hd/types';

interface Props<T> {
  SelectionModal: React.FunctionComponent<{
    subcategory: CCTVRecommendationSubcategory;
    onClose: () => void;
    onSelect: (value: Exclude<T, undefined>) => void;
  }>;
  SelectionView: React.FunctionComponent<{
    subcategory: CCTVRecommendationSubcategory;
  }>;
  subcategory: CCTVRecommendationSubcategory;
  editUrl?: string | null;
  onSelect: (item: Exclude<T, undefined>) => void;
}

export default function SubcategorySelectionBox<T>(props: Props<T>) {
  const {
    SelectionModal,
    SelectionView,
    editUrl,
    subcategory,
    onSelect,
    ...rest
  } = props;

  const [isSelectionModalVisible, setIsSelectionModalVisible] = React.useState(false);

  const handleSelect = (value: Exclude<T, undefined>) => {
    onSelect(value);
    setIsSelectionModalVisible(false);
  };

  if (subcategory.parentId) {
    return (
      <>
        <Card { ...rest }
            backgroundColor="light"
            flex="vertical"
            gap="x4"
            grow
            padding="x4">
            <SelectionView subcategory={ subcategory } />

          <Box alignChildrenHorizontal="between" flex="horizontal">
            <Link onClick={ () => setIsSelectionModalVisible(true) } size="x2" strong>Change?</Link>
            { editUrl && <Link size="x2" strong to={ editUrl }>Edit?</Link> }
          </Box>
        </Card>

        { isSelectionModalVisible && (
          <SelectionModal
              subcategory={ subcategory }
              onClose={ () => setIsSelectionModalVisible(false) }
              onSelect={ handleSelect } />
        ) }
      </>
    );
  }

  return (
    <>
      <Card
          backgroundColor="light"
          borderColor="blue"
          borderSize="x2"
          borderStyle="dashed"
          flex="vertical"
          grow>
        <CardRow { ...rest }
            alignChildren="middle"
            flex="vertical"
            grow
            onClick={ () => setIsSelectionModalVisible(true) }
            paddingHorizontal="x6"
            paddingVertical="x12">
          <Text
              align="middle"
              size="x2"
              strong
              textColor="blue">
            Select
          </Text>
        </CardRow>
      </Card>

      { isSelectionModalVisible && (
        <SelectionModal
          onClose={() => setIsSelectionModalVisible(false)}
          onSelect={handleSelect} subcategory={subcategory} />
      ) }
    </>
  );
}
