import { Engineer } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreContractorEngineer(customerId: string, engineerId?: string) {
  return useFirestoreDocument<Engineer>('Engineer', `contractors/${customerId}/engineers`, engineerId, {
    email: '',
    name: '',
    phoneNumber: '',
  });
}
