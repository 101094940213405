import { FirestoreDocument, OmitFirestoreProps } from '@hd/types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import firebase from 'firebase/app';
import omit from 'lodash.omit';
import isObject from 'lodash.isobject';
import { NotificationsContext } from '../components/Notifications/Notifications';

export interface Data<T> {
  clear: () => void;
  document: T;
  hasChanges: boolean;
  hasDocument: boolean;
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  update: (updates: Partial<T>) => void;
  remove: () => Promise<void>;
  save: () => Promise<T>;
}

export const firestoreSnapshotToDocument = async<T extends OmitFirestoreProps<T>>(snapshot: firebase.firestore.DocumentSnapshot, resolve = true): Promise<T> => {
  const data = {
    ...snapshot.data() as T,
    _id: snapshot.id,
    _ref: snapshot.ref.path,
  };

  if (resolve) {
    for (const [key, value] of Object.entries(data)) {
      if (value instanceof firebase.firestore.DocumentReference) {
        data[key] = await firestoreSnapshotToDocument(await value.get(), false);
      }
    }
  }

  return data;
};

export const documentToFirestoreSnapshot = <T extends FirestoreDocument>(document: T): OmitFirestoreProps<T> => {
  const data = omit(document, ['_id', '_ref']);

  for (const [key, value] of Object.entries<T>(document)) {
    if (isObject(value) && value._ref) {
      data[key] = firebase.firestore().doc(value._ref);
    }
  }

  return data;
};

export default function useFirestoreDocument<T extends FirestoreDocument>(entity: string, collection: string, docId: undefined | string, initial: OmitFirestoreProps<T>): Data<T> {
  const { addNotification } = useContext(NotificationsContext);
  const [data, setData] = useState<Omit<Data<T>, 'clear' | 'update' | 'remove' | 'save'>>({
    document: {
      ...initial,
      _ref: '',
      _id: '',
    },
    hasChanges: false,
    hasDocument: false,
    hasFailed: false,
    hasFetched: docId === undefined,
    isFetching: docId !== undefined,
  });

  const refDocument = useRef(data.document);
  const ref = useMemo(() => docId &&
    firebase
      .firestore()
      .collection(collection)
      .doc(docId), [collection, docId]);

  const clear = useCallback(() => {
    setData({
      document: refDocument.current = {
        ...initial,
        _ref: '',
        _id: '',
      },
      hasChanges: false,
      hasDocument: false,
      hasFailed: false,
      hasFetched: false,
      isFetching: false,
    });
  }, []);

  const update = (updates: Partial<T>) => {
    const document = refDocument.current = {
      ...initial,
      ...refDocument.current,
      ...updates,
    };

    setData((data) => ({
      ...data,
      hasChanges: true,
      document: document,
    }));
  };

  const save = async() => {
    if (ref) {
      await ref.set(documentToFirestoreSnapshot(refDocument.current), { merge: true });
      addNotification(`${entity} saved successfully!`);
      return refDocument.current;
    } else {
      const ref = await firebase
        .firestore()
        .collection(collection)
        .add(documentToFirestoreSnapshot(refDocument.current));
      const snapshot = await ref.get();
      refDocument.current = await firestoreSnapshotToDocument(snapshot),
      setData((data) => ({
        ...data,
        document: refDocument.current,
      }));
      addNotification(`${entity} saved successfully!`);
      return refDocument.current;
    }
  };

  const remove = async() => {
    if (ref) {
      await ref.delete();
      addNotification(`${entity} removed successfully!`);
    }
  };

  useEffect(() => {
    if (ref) {
      return ref.onSnapshot(async(snapshot) => {
        setData({
          document: refDocument.current = {
            ...initial,
            ...(await firestoreSnapshotToDocument(snapshot)),
          },
          hasChanges: false,
          hasFailed: false,
          hasFetched: true,
          hasDocument: snapshot.exists,
          isFetching: false,
        });
      }, () => {
        setData((data) => ({
          ...data,
          hasFailed: true,
          hasFetched: true,
          isFetching: false,
        }));
      });
    }
  }, [clear, ref]);

  return {
    ...data,
    clear: clear,
    document: data.document,
    update: update,
    remove: remove,
    save: save,
  };
}
