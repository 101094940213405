import * as React from 'react';
import { Box, BoxProps, Card, CardRow, FileInput, Text } from '@hd/ui';
import { FileBlank, Upload } from '@styled-icons/boxicons-regular';
import firebase from 'firebase/app';
import FirebaseList from '../Firebase/FirebaseList';
import ConfirmationModal from '../ConfirmationModal';
import useFirestoreJobFiles from '../../utils/useFirestoreJobFiles';
import FileListItem from '../FileList/FileListItem';

interface Props extends BoxProps {
  jobId: string;
}

export default function JobFilesList(props: Props) {
  const { jobId, ...rest } = props;
  const [removeFileId, setRemoveFileId] = React.useState<undefined | string>();
  const data = useFirestoreJobFiles(jobId, { realtime: true });

  const handleRemoveFile = async () => {
    if (removeFileId) {
      await data.remove(removeFileId);
      setRemoveFileId(undefined);
    }
  };

  const handleToggleFileVisibility = async (toggleVisibilityFileId: string, isPublic: boolean) => {
    if (toggleVisibilityFileId) {
      firebase.firestore().doc(`jobs/${jobId}/files/${toggleVisibilityFileId}`).set({
        isPublic,
      }, {
        merge: true
      })
    }
  };

  const handleSelectFiles = async (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const files = (event.target as HTMLInputElement).files;

    try {
      if (files && files.length) {
        const ref = firebase.storage().ref();

        for (const file of files) {
          const childRef = ref.child(`/jobs/${jobId}/${file.name}`);
          await childRef.put(file);
          await data.add({
            _id: '',
            _ref: '',
            name: file.name,
            downloadUrl: await childRef.getDownloadURL(),
            size: file.size,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box {...rest}>
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x2"
        margin="x3"
      >
        <Box>
          <FileBlank size="1.5rem" />
        </Box>

        <Box grow>
          <Text strong>Files</Text>
        </Box>

        <Box>
          <FileInput
            color="blue"
            level="tertiary"
            margin="x6"
            multiple
            onChange={handleSelectFiles}
          >
            <Upload size="1.25rem" />
            <Text>Upload File</Text>
          </FileInput>
        </Box>
      </Box>

      <FirebaseList
        {...rest}
        {...data}
        backgroundColor="light"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Card>
          {data.ids.map((id) => (
            <CardRow key={id} padding="x3">
              <FileListItem
                file={data.documents[id]}
                onRemoveFile={() => setRemoveFileId(id)}
                onToggleVisibility={(b: boolean) => handleToggleFileVisibility(id, b)}
              />
            </CardRow>
          ))}
        </Card>
      </FirebaseList>

      <ConfirmationModal
        message="Are you sure you want to remove this file?"
        onCancel={() => setRemoveFileId(undefined)}
        onConfirm={handleRemoveFile}
        visible={!!removeFileId}
      />
    </Box>
  );
}
