import { JobFile } from '@hd/types';
import { Box, BoxProps, Line, Text } from '@hd/ui';
import * as React from 'react';
import FileListItem from './FileListItem';

interface Props extends BoxProps {
  files: (File | JobFile)[];
  isValid?: (file: File) => boolean;
  onRemoveFile?: (index: number) => void;
}

export default function FileList(props: Props) {
  const { files, isValid, onRemoveFile, ...rest } = props;

  return (
    <Box { ...rest }>
      { !files.length && (
        <Text margin="x2" strong>There are no files to display</Text>
      ) }

      { files.map((file, index) => (
        <FileListItem
            borderColor="gray"
            borderSides="T"
            borderSize="x1"
            file={ file }
            isValid={ isValid && file instanceof File ? isValid(file) : true }
            key={ index }
            onRemoveFile={ onRemoveFile && (() => onRemoveFile(index)) }
            padding="x2" />
      )) }

      <Line borderColor="gray" borderSize="x2" />
    </Box>
  );
}
