import * as React from 'react';
import {
  Box,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Text,
  TextInput,
} from '@hd/ui';
import { JobType } from '@hd/types';
import useInputNumber from '../../utils/useInputNumber';

interface Props {
  document: JobType;
  update: (updates: Partial<JobType>) => void;
}

export default function JobTypeForm(props: Props) {
  const { document, update } = props;

  const [priceContractor1, handleUpdatePriceContractor1] = useInputNumber(
    document.priceContractor1 || 0,
    (priceContractor1) => update({ priceContractor1 })
  );

  const [priceContractor2, handleUpdatePriceContractor2] = useInputNumber(
    document.priceContractor2 || 0,
    (priceContractor2) => update({ priceContractor2 })
  );

  const [priceContractor3, handleUpdatePriceContractor3] = useInputNumber(
    document.priceContractor3 || 0,
    (priceContractor3) => update({ priceContractor3 })
  );

  const [priceCustomer, handleUpdatePriceCustomer] = useInputNumber(
    document.priceCustomer || 0,
    (priceCustomer) => update({ priceCustomer })
  );

  return (
    <>
      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Job type name</FormInputLabel>
        </FormInputHeader>

        <TextInput
          data-at-id="JobTypeName"
          onChange={(e) =>
            update({ name: (e.target as HTMLInputElement).value })
          }
          required
          value={document.name || ''}
        />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Fixed price</FormInputLabel>
        </FormInputHeader>

        <CheckBox
          checked={!!document.isFixedPrice}
          data-at-id="IsFixedPrice"
          margin="x4"
          onChange={() => {
            const nextVal = !document.isFixedPrice
            if(nextVal === true) {
              update({ 
                isFixedPrice: true,
                priceContractor1: undefined,
                priceContractor2: undefined,
                priceContractor3: undefined,
                priceCustomer: undefined,
              }) 
            } else {
              update({ 
                isFixedPrice: false,
                priceContractor1: 0,
                priceContractor2: 0,
                priceContractor3: 0,
                priceCustomer: 0,
              }) 

            }
          }
        />
      </FormInput>

      <Box hidden={!document.isFixedPrice}>
        <FormInput margin="x3">
          <FormInputHeader>
            <FormInputLabel>Contractor price (Tier 1)</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="JobTypePriceContractor1"
            onChange={handleUpdatePriceContractor1}
            required
            value={priceContractor1}
          />
        </FormInput>
        <FormInput margin="x3">
          <FormInputHeader>
            <FormInputLabel>Contractor price (Tier 2)</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="JobTypePriceContractor2"
            onChange={handleUpdatePriceContractor2}
            required
            value={priceContractor2}
          />
        </FormInput>
        <FormInput margin="x3">
          <FormInputHeader>
            <FormInputLabel>Contractor price (Tier 3)</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="JobTypePriceContractor3"
            onChange={handleUpdatePriceContractor3 }
            required
            value={priceContractor3}
          />
        </FormInput>
        <FormInput margin="x3">
          <FormInputHeader>
            <FormInputLabel>Customer price</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="PriceCustomer"
            onChange={handleUpdatePriceCustomer}
            required
            value={priceCustomer}
          />
        </FormInput>
      </Box>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Customer nominal node</FormInputLabel>
        </FormInputHeader>

        <TextInput
          data-at-id="CustomerNominalCode"
          onChange={(e) =>
            update({
              customerNominalCode: (e.target as HTMLInputElement).value,
            })
          }
          required
          value={document.customerNominalCode || ''}
        />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Contractor nominal node</FormInputLabel>
        </FormInputHeader>

        <TextInput
          data-at-id="ContractorNominalCode"
          onChange={(e) =>
            update({
              contractorNominalCode: (e.target as HTMLInputElement).value,
            })
          }
          required
          value={document.contractorNominalCode || ''}
        />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Sales information</FormInputLabel>
        </FormInputHeader>

        <TextInput
          data-at-id="SalesInformation"
          rows={20}
          multiline
          onChange={(e) =>
            update({
              salesInfo: (e.target as HTMLInputElement).value,
            })
          }
          required
          value={document.salesInfo || ''}
        />
      </FormInput>

      <Box flex="vertical">
        <Text strong>Filters</Text>
        <Box
          flex="horizontal"
          grow
          alignChildrenHorizontal="around"
          alignChildrenVertical="middle"
        >
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>CCTV</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              checked={!!document.isCCTV}
              data-at-id="IsCCTV"
              margin="x4"
              onChange={() => update({ isCCTV: !document.isCCTV })}
            />
          </FormInput>
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>Domestic</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              checked={!!document.isDomestic}
              data-at-id="IsDomestic"
              margin="x4"
              onChange={() => update({ isDomestic: !document.isDomestic })}
            />
          </FormInput>
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>Commercial</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              checked={!!document.isCommerical}
              data-at-id="IsCommercial"
              margin="x4"
              onChange={() => update({ isCommerical: !document.isCommerical })}
            />
          </FormInput>
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>Unblock</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              checked={!!document.isUnblock}
              data-at-id="IsUnblock"
              margin="x4"
              onChange={() => update({ isUnblock: !document.isUnblock })}
            />
          </FormInput>
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>Repair</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              checked={!!document.isRepair}
              data-at-id="IsRepair"
              margin="x4"
              onChange={() => update({ isRepair: !document.isRepair })}
            />
          </FormInput>
        </Box>
      </Box>
    </>
  );
}
