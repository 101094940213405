import * as React from 'react';
import { Box, Button, Card, Modal, Text } from '@hd/ui';
import { Site } from '@hd/types';
import useFirestoreCustomerSite from '../../utils/useFirestoreCustomerSite';
import { validateSite, FormError } from '../../utils/formValidators';
import SiteForm from './SiteForm';
import FormErrorMessage from '../FormErrorMessage';
import MapSearchInput from '../MapSearchInput';

interface Props {
  onClose: (site?: Site) => void;
  siteId?: string;
  visible: boolean;
}

export default function SiteModal(props: Props) {
  const { onClose, siteId, visible } = props;
  const { document, clear, update, save } = useFirestoreCustomerSite(siteId);
  const [error, setError] = React.useState<FormError | null>(null);

  const handleSubmit = async() => {
    const error = validateSite(document);
    setError(error);

    if (!error) {
      const newSite = await save();
      clear();
      onClose(newSite);
    }
  };

  return (
    <Modal
        maxWidth="600px"
        onClose={ () => onClose() }
        visible={ visible }>
      <Card
          backgroundColor="light"
          padding="x10">
        <Text size="x4">
          { siteId ? 'Update Site' : 'Add site' }
        </Text>

        <SiteForm
            document={ document }
            update={ update } />

        { error && (
          <FormErrorMessage error={ error } />
        ) }

        <Box alignChildrenHorizontal="end" flex="horizontal" gap="x4" margin="x10" >
          <Button onClick={ () => onClose() }>
            Cancel
          </Button>

          <Button
              color="blue"
              data-at-id="ModalConfirm"
              level="primary"
              onClick={ handleSubmit }>
            Done
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
