import * as React from 'react';
import {
  Box,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
} from '@hd/ui';
import { useHistory } from 'react-router-dom';
import ProductsList from './ProductsList';

export default function Products() {
  const { push } = useHistory();

  return (
    <Page data-at-id="ProdutcsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Products</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <ProductsList
          isSearchable
          onSelect={(product) => push(`/products/${product._id}`)}
        />
      </PageBody>
    </Page>
  );
}
