import firebase from 'firebase/app';
import { JobNote } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreJobNote(jobId: string, noteId?: string) {
  return useFirestoreDocument<JobNote>('Note', `jobs/${jobId}/notes`, noteId, {
    note: '',
    timestamp: firebase.firestore.Timestamp.now(),
  });
}
