import { Box, Button, Page, PageBack, PageBody, PageHeader, PageTitle } from '@hd/ui';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useFirestoreCCTVProductSubcategories from '../../../utils/useFirestoreCCTVProductSubcategories';
import useFirestoreProduct from '../../../utils/useFirestoreProduct';
import useFirestoreProductSubcategory from '../../../utils/useFirestoreProductSubcategory';
import { FormError } from '../../../utils/formValidators';
import FirebaseDocument from '../../Firebase/FirebaseDocument';
import FormErrorMessage from '../../FormErrorMessage';
import SubcategoryForm from './SubcategoryForm';
import { CCTVRecommendationSubcategory } from '@hd/types';
import ConfirmationModal from '../../ConfirmationModal';

export default function ProducView() {
  const { push } = useHistory();
  const { subcategoryId } = useParams<{ subcategoryId: string }>();
  const [error, setError] = React.useState<FormError | null>(null);
  const [visible, setVisible] = React.useState(false)


  // make single for this one
  const data = useFirestoreProductSubcategory(subcategoryId);
  useFirestoreProduct()

  const handleUpdate = async () => {
    if (data.document.name === undefined || data.document.name === '') {
      setError({
        field: '',
        message: 'Subcategory needs name'
      })
    }
    else if (data.document.parentId === undefined || data.document.parentId === '') {
      setError({
        field: '',
        message: 'Subcategory needs name'
      })
    } else {
      await data.save()
      push('/products/subcategories')
    }
  }

  const handleDelete = async () => {
    await data.remove()
    push('/products/subcategories')

  }

  return (
    <>
      <FirebaseDocument {...data} allowNoDocument={!subcategoryId}>
        <Page>
          <PageHeader padding="x0" paddingHorizontal="x10">
            <Box alignChildrenVertical="end" flex='vertical' gap="x4" paddingVertical="x10">
              <Box basis="none" grow>
                <PageBack to={'/products/subcategories'}>Subcategories</PageBack>
              </Box>
              <Box
                alignChildrenVertical="middle"
                flex="horizontal"
                gap="x2">
                <PageTitle>{data.document.name} </PageTitle>
              </Box>
            </Box>
          </PageHeader>


          <PageBody>
            <SubcategoryForm subcategory={data.document} update={data.update} />

            {error && (
              <FormErrorMessage error={error} />
            )}
            <Box flex='horizontal' alignChildrenHorizontal='between'>
              <Button
                color="blue"
                level="primary"
                onClick={handleUpdate}>
                Update Subcategory
              </Button>
              <Button
                color='red'
                level="primary"
                onClick={() => setVisible(true)}>
                Delete Subcategory
              </Button>
              <ConfirmationModal message={'Are you sure you want to delete sub category?'} onCancel={() => setVisible(false)} onConfirm={handleDelete} visible={visible}/>
            </Box>
          </PageBody>
        </Page>
      </FirebaseDocument>
    </>
  )
}