import * as React from 'react';
import { FormInput, FormInputHeader, FormInputLabel, TextInput } from '@hd/ui';
import { NoteOrQuote } from '@hd/types';

interface Props {
  document: NoteOrQuote;
  update: (updates: Partial<NoteOrQuote>) => void;
}

export default function AdminNoteAndQuoteForm(props: Props) {
  const { document, update } = props;

  return (
    <>
      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Name</FormInputLabel>
        </FormInputHeader>

        <TextInput
          data-at-id="Name"
          onChange={(e) =>
            update({ name: (e.target as HTMLInputElement).value })
          }
          required
          value={document.name || ''}
        />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Value</FormInputLabel>
        </FormInputHeader>

        <TextInput
          multiline
          rows={30}
          data-at-id="NoteOrQuote"
          onChange={(e) =>
            update({
              value: (e.target as HTMLInputElement).value,
            })
          }
          required
          value={document.value || ''}
        />
      </FormInput>
    </>
  );
}
