import * as React from 'react';
import { Box, BoxProps, Button, Card, CardRow, Link, Text } from '@hd/ui';
import { Notepad, Plus } from '@styled-icons/boxicons-regular';
import FirebaseList from '../Firebase/FirebaseList';
import ConfirmationModal from '../ConfirmationModal';
import useFirestoreJobNotes from '../../utils/useFirestoreJobNotes';
import toDateFormat from '../../utils/toDateFormat';
import JobNoteModal from './JobNoteModal';
import { JobNote } from '@hd/types';
import { TypeOrderBy } from '../../utils/useFirestoreCollection';

interface Props extends BoxProps {
  jobId: string;
}
const orderBys: TypeOrderBy<JobNote>[] = [['timestamp', 'asc']];

export default function JobNotesList(props: Props) {
  const { jobId, ...rest } = props;
  const [removeNoteId, setRemoveNoteId] = React.useState<undefined | string>();
  const data = useFirestoreJobNotes(jobId, { orderBys : orderBys, realtime: true });
  const [isAddNoteModalVisible, setIsAddNoteModalVisible] = React.useState(false);

  const handleRemoveNote = async() => {
    if (removeNoteId) {
      await data.remove(removeNoteId);
      setRemoveNoteId(undefined);
    }
  };

  return (
    <Box { ...rest }>
      <Box
          alignChildrenVertical="middle"
          flex="horizontal"
          gap="x2"
          margin="x3">
        <Box>
          <Notepad size="1.5rem" />
        </Box>

        <Box grow>
          <Text strong>Notes</Text>
        </Box>

        <Box>
          <Button
              color="blue"
              level="tertiary"
              onClick={ () => setIsAddNoteModalVisible(true) }>
            <Plus size="1.5rem" />
            <Text>Add Note</Text>
          </Button>
        </Box>
      </Box>

      <FirebaseList { ...rest } { ...data }
          backgroundColor="light"
          gap="x4"
          margin="x3"
          padding="x3">
        <Card>
          { data.ids.map((id) => (
            <CardRow key={ id } padding="x3">
              <Text flex="horizontal" size="x2">
                <Text grow strong>{ toDateFormat(data.documents[id].timestamp, true) }</Text>
                <Text><Link onClick={ () => setRemoveNoteId(id) } textColor="red">Delete</Link></Text>
              </Text>

              <Text margin="x2" size="x2">
                { data.documents[id].note }
              </Text>
            </CardRow>
          )) }
        </Card>
      </FirebaseList>

      <JobNoteModal
          jobId={ jobId }
          onClose={ () => setIsAddNoteModalVisible(false) }
          visible={ isAddNoteModalVisible } />

      <ConfirmationModal
          message="Are you sure you want to remove this note?"
          onCancel={ () => setRemoveNoteId(undefined) }
          onConfirm={ handleRemoveNote }
          visible={ !!removeNoteId } />
    </Box>
  );
}
