import { CCTVReccomendation, CCTVRecommendationProduct, CCTVRecommendationProductAddOn } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';
import { v4 } from 'uuid';

export default function useFirestoreProductAddOn(id?: string) {
  return useFirestoreDocument<CCTVRecommendationProductAddOn>('CCTVRecommendationProductAddOn', 'product-add-ons', id, {
    id: v4(),
    name: '',
    pricing: [],
  });
}
