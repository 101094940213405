import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Card,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Image,
  LabelledList,
  Link,
  Modal,
  PageLoading,
  Text,
  TextInput,
} from '@hd/ui';
import { TypeFilter } from '../../utils/useFirestoreCollection';
import useFirestoreQuotes from '../../utils/useFirestoreQuotes';
import useFirestoreQuote from '../../utils/useFirestoreQuote';
import toCurrencyFormat from '../../utils/toCurrencyFormat';
import useFirestoreConfig from '../../utils/useFirestoreConfig';
import useFirestoreJob from '../../utils/useFirestoreJob';

export default function QuoteCustomer() {
  const { magicId } = useParams<{ magicId: string }>();
  const filters = React.useMemo(
    () => [['magicId', '==', magicId]] as TypeFilter[],
    [magicId]
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const { documents, ids, isFetching } = useFirestoreQuotes({
    filters: filters,
    limit: 1,
  });

  const d = documents[ids[0]];
  const { document: config } = useFirestoreConfig();
  const { document, update, save, hasDocument } = useFirestoreQuote(
    d ? d._id : undefined
  );
  const [bookModalVisible, setBookModalVisible] = React.useState(false);

  const job = useFirestoreJob(
    document.parent ? document.parent._id : undefined
  );

  const vatType = document?.customer ? document.customer.vatType : 'VAT';
  const vatRate = vatType === 'VAT' && document ? config.vatRate : 0;
  const [noCustomerBookedNameError, setNoCustomerBookedNameError] =
    React.useState(false);

  const priceBreakdown = [
    [
      document.hourlyRate ? 'Hourly rate ex VAT' : 'Total ex VAT',
      toCurrencyFormat(document.price.toString()),
    ],
    ['VAT', vatRate + '%'],
    [
      document.hourlyRate ? 'Hourly rate inc VAT' : 'Total Inc VAT',
      toCurrencyFormat((document.price * (1 + vatRate / 100)).toString()),
    ],
  ];

  const accept = () => {
    if (!document.customerBookedName && document.status === 'AWAITING') {
      setNoCustomerBookedNameError(true);
      return;
    }
    if (document.status === 'FOLLOW_UP_UNACCEPTED') {
      if (job.document.status === 'PAID') {
        update({
          status: 'ACCEPTED',
        });
      } else {
        update({
          status: 'FOLLOW_UP_ACCEPTED_UNPAID',
        });
      }
    } else {
      update({
        status: 'ACCEPTED',
      });
    }
    save();
  };

  if (isFetching) {
    return <PageLoading />;
  }

  if (!document || !hasDocument) {
    return (
      <Box padding="x10">
        <Card maxWidth="600px" maxWidthCentered>
          <Image src={require('@hd/ui/assets/header-1.png')} />
          <Text align="middle" margin="x20" maxWidthCentered strong>
            Quote not found.
          </Text>
          <Image src={require('@hd/ui/assets/footer.jpg')} />
        </Card>
      </Box>
    );
  }

  if (
    document.status !== 'AWAITING' &&
    document.status !== 'PAID' &&
    document.status != 'FOLLOW_UP_UNACCEPTED'
  ) {
    return (
      <Box padding="x10">
        <Card maxWidthCentered ref={ref} width="600px">
          <Image src={require('@hd/ui/assets/header-1.png')} />
          <Box padding="x6">
            <Text margin="x4" size="x4" strong>
              Good news!
            </Text>
            <Text margin="x4" size="x4" strong>
              We're on it! You will recieve further information once we've
              allocated one of our engineers who will get you back up and
              running as soon as possible!
            </Text>

            <Text>Your quote reference is {document.quoteId}</Text>

            <Box margin="x2" padding="x2">
              <Text strong>Details</Text>
              <LabelledList items={priceBreakdown} margin="x4" />
              <LabelledList
                items={[
                  [
                    'Site address line 1',
                    document.parent
                      ? job.document.customerSite?.addressLine1
                      : document.siteAddressLine1,
                  ],
                  [
                    'Site address line 2',
                    document.parent
                      ? job.document.customerSite?.addressLine2
                      : document.siteAddressLine2,
                  ],
                  [
                    'Site address line 3',
                    document.parent
                      ? job.document.customerSite?.addressLine3
                      : document.siteAddressLine3,
                  ],
                  [
                    'Site postcode',
                    document.parent
                      ? job.document.customerSite?.addressPostcode
                      : document.siteAddressPostcode,
                  ],
                ]}
                margin="x4"
              />
              <Box>
                {document.quoteText?.split('\n').map((para, i) => (
                  <Text emphasis key={i} size="x2">
                    {para}
                  </Text>
                ))}
              </Box>
              <Text size="x1">
                {`Booked by ${document.customerBookedName} ${document.createdAt
                  .toDate()
                  .toLocaleDateString()} @ ${document.createdAt
                  .toDate()
                  .toTimeString()}`}
              </Text>
            </Box>

          {job.document.status === 'PAID' && (
            <>
              <Text strong>Previous works documentation</Text>
              <Box
                borderColor="gray"
                borderRadius="x1"
                borderSize="x1"
                padding="x5"
              >
                <Box alignChildrenHorizontal="around" flex="horizontal">
                  <Button
                    color="blue"
                    onClick={() =>
                      window.open(
                        document.report?.downloadUrl,
                        document.report?.name
                      )
                    }
                  >
                    Technical Report
                  </Button>
                  <Button
                    color="blue"
                    onClick={() => {
                      const url = document.usingNewDrainReporter ?  `/cover-letter/cu/${magicId}` : document.coverLetter?.downloadUrl
                      window.open(
                        url,
                        document.coverLetter?.name
                      )
                    }}
                  >
                    Cover letter/Quote
                  </Button>
                </Box>
              </Box>
            </>
          )}

            <Text>
              Any questions? Please{' '}
              <Link href="mailto:service@happydrains.co.uk">contact us</Link>.
            </Text>
          </Box>
          <Image src={require('@hd/ui/assets/footer.jpg')} />
        </Card>
      </Box>
    );
  }

  if (document.status === 'AWAITING') {
    return (
      <>
        <Box>
          <Card maxWidthCentered padding="x8" ref={ref} width="600px">
            <Image src={require('@hd/ui/assets/header-1.png')} />
            <Text margin="x4" size="x4" strong>
              Quote - Reference {document.quoteId}
            </Text>

            <Box flex="vertical" gap="x3">
              <Text>
                Hello! We've added the quote below. Once you're happy, click the
                'Book now' button below and we'll start the process to fixing
                your drains.
              </Text>
              <Text>
                Any clarification or questions feel free to contact us by
                calling, or emailing with the link below the quote!
              </Text>

              <Box>
                {document.quoteText?.split('\n').map((para, i) => (
                  <Text emphasis key={i} size="x2">
                    {para}
                  </Text>
                ))}
              </Box>

              <Box alignChildrenHorizontal="middle" flex="horizontal">
                <Button
                  color="blue"
                  elevation="x1"
                  level="primary"
                  onClick={() => setBookModalVisible(true)}
                  size="large"
                >
                  Book now
                </Button>
              </Box>

              <LabelledList items={priceBreakdown} margin="x4" />

              <Text>
                Any questions? Please{' '}
                <Link href="mailto:service@happydrains.co.uk">contact us</Link>.
              </Text>
            </Box>
            <Image src={require('@hd/ui/assets/footer.jpg')} />
          </Card>
        </Box>
        <Modal
          maxWidth="600px"
          onClose={() => setBookModalVisible(false)}
          visible={bookModalVisible}
        >
          <Card flex="vertical" gap="x2" padding="x10">
            <Text size="x5" strong>
              Confirm booking
            </Text>
            <Box>
              <LabelledList items={priceBreakdown} margin="x4" />
            </Box>
            <Box>
              <FormInput margin="x6">
                <FormInputHeader>
                  <FormInputLabel>Additional information</FormInputLabel>
                </FormInputHeader>

                <TextInput
                  multiline
                  onChange={(e) => {
                    update({
                      customerNotes: (e.target as HTMLInputElement).value,
                    });
                  }}
                  placeholder="Add any additional information here."
                  rows={10}
                />
              </FormInput>
            </Box>
            <Box>
              <FormInput>
                <FormInputHeader>
                  <FormInputLabel>Your name </FormInputLabel>
                </FormInputHeader>
                <TextInput
                  onChange={(e) => {
                    update({
                      customerBookedName: (e.target as HTMLInputElement).value,
                    });
                  }}
                  placeholder="Add any additional information here."
                />
                {noCustomerBookedNameError && (
                  <Alert
                    backgroundColor="red-light"
                    borderColor="red-dark"
                    textColor="red-dark"
                  >
                    You must add a name
                  </Alert>
                )}
              </FormInput>
            </Box>
            <Box alignChildrenHorizontal="middle" flex="horizontal">
              <Button
                color="blue"
                elevation="x2"
                level="primary"
                onClick={accept}
                size="large"
              >
                Book now
              </Button>
            </Box>
          </Card>
        </Modal>
      </>
    );
  }

  return (
    <Box padding="x10">
      <Card maxWidthCentered ref={ref} padding='x8'>
        <Image src={require('@hd/ui/assets/header-1.png')} />
        <Text margin="x4" size="x4" strong>
          Quote - Reference {document.quoteId}
        </Text>
        <Box flex="vertical" gap="x3">
          {document.furtherWorkRequired ? (
            <Text>
              Hello! We've added the quote below. Once you're happy, click the
              'Book now' button below and we'll start the process to fixing your
              drains.
            </Text>
          ) : (
            <Text>
              Thank you. We found no reason for further work. Documents attached
              below
            </Text>
          )}
          <Text>
            Any clarification or questions feel free to contact us by calling,
            or emailing with the link below the quote!
          </Text>

          {job.document.isCCTV && (
            <>
              <Text strong>Previous works documentation</Text>
              <Box
                borderColor="gray"
                borderRadius="x1"
                borderSize="x1"
                padding="x5"
              >
                <Box alignChildrenHorizontal="around" flex="horizontal">
                  <Button
                    color="blue"
                    onClick={() =>
                      window.open(
                        document.report?.downloadUrl,
                        document.report?.name
                      )
                    }
                  >
                    Technical Report
                  </Button>
                  {
                    //here
                  }
                  <Button
                    color="blue"
                    onClick={() => {
                      const url = document.usingNewDrainReporter ?  `/cover-letter/cu/${job.document.magicId}` : document.coverLetter?.downloadUrl
                      window.open(
                        url,
                        document.coverLetter?.name
                      )
                    }}
                  >
                    Cover letter/Quote
                  </Button>
                </Box>
              </Box>
            </>
          )}

          {document.furtherWorkRequired && (
            <>
              <Box>
                {document.quoteText?.split('\n').map((para, i) => (
                  <Text key={i}>{para}</Text>
                ))}
              </Box>

              <LabelledList items={priceBreakdown} margin="x4" />
              <LabelledList
                items={[
                  [
                    'Site address line 1',
                    document.parent
                      ? job.document.customerSite?.addressLine1
                      : document.siteAddressLine1,
                  ],
                  [
                    'Site address line 2',
                    document.parent
                      ? job.document.customerSite?.addressLine2
                      : document.siteAddressLine2,
                  ],
                  [
                    'Site address line 3',
                    document.parent
                      ? job.document.customerSite?.addressLine3
                      : document.siteAddressLine3,
                  ],
                  [
                    'Site postcode',
                    document.parent
                      ? job.document.customerSite?.addressPostcode
                      : document.siteAddressPostcode,
                  ],
                ]}
                margin="x4"
              />
              <Button color="blue" level="primary" onClick={accept}>
                Book now
              </Button>
            </>
          )}

          <Text>
            Any questions? Please{' '}
            <Link href="mailto:service@happydrains.co.uk">contact us</Link>.
          </Text>
        </Box>
        <Image src={require('@hd/ui/assets/footer.jpg')} />
        {document.customerBookedName && (
          <Text size="x1">
            {`Originally booked by ${
              document.customerBookedName
            } ${document.createdAt
              .toDate()
              .toLocaleDateString()} @ ${document.createdAt
              .toDate()
              .toTimeString()}`}
          </Text>
        )}
      </Card>
    </Box>
  );
}
