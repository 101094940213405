import { TypeTimeWindow } from '@hd/types';

export default [
  'AM',
  'PM',
  '8AM - 10AM',
  '9AM - 1PM',
  '10AM - 2PM',
  '11AM - 3PM',
  '12PM - 4PM',
  '1PM - 5PM',
  '4PM - 7PM',
] as TypeTimeWindow[];
