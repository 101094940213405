import { Appear, BoxProps, Text } from '@hd/ui';
import * as React from 'react';
import { FormError } from '../utils/formValidators';

interface Props extends BoxProps {
  error: FormError;
}

export default function FormErrorMessage(props: Props) {
  const { error, ...rest } = props;

  return (
    <Appear { ...rest }
        backgroundColor="red-light"
        borderColor="red"
        borderRadius="x1"
        borderSize="x1"
        paddingHorizontal="x3"
        paddingVertical="x1"
        textColor="red">
      <Text size="x2" strong>{ error.message }</Text>
    </Appear>
  );
}
