import * as React from 'react';
import {
  Box,
  Button,
  Text,
  TextInput,
  Appear,
  Badge
} from '@hd/ui';
import { AddOnRepair, CCTVRecommendationProductAddOn, Repair, UnitTypeType } from '@hd/types';
import { Check, Trash, X } from '@styled-icons/boxicons-regular';
import { humanReadableUnitType } from '../../../constants/CCTVReccommendationContants';


type Props = {
  onAdd: (repair: Partial<AddOnRepair>) => void;
  onCancel: () => void;
  onRemove: (id: string) => void;
  onSelect: (id: string) => void;
  addOn: CCTVRecommendationProductAddOn;
  isSelected: boolean;
  currentAddOns: AddOnRepair[];
}

const unitTypeLabel: Record<UnitTypeType, string> = {
  Quantity: '#',
  Meterage: 'M',
  HourRate: 'Hrs',
  HalfDayRate: 'Half days',
  DayRate: 'Days'
}


export default function ProductSelectionAddOns(props: Props) {
  const { onAdd, onCancel, addOn, isSelected, onSelect, onRemove, currentAddOns } = props;

  const [unitType, setUnitType] = React.useState<UnitTypeType | undefined>(addOn.pricing.length === 1 ? addOn.pricing[0].type : undefined)
  const [quantity, setQuantity] = React.useState<number>(1)

  const inTheBag = currentAddOns.filter(e => e.product?.id === addOn.id)

  return (<Box elevation='x1' flex='vertical' backgroundColor={isSelected ? 'gray' : 'light-gray'} padding='x2' onClick={() => !isSelected && onSelect(addOn.id)}>
    <Text strong size='x1'> {addOn.name} </Text>
    {
      isSelected && inTheBag.length === 0 &&
      <Appear animation='FadeSlideDown'>
        <Box flex='vertical' gap='x3'>
          <Box flex='horizontal' alignChildrenHorizontal='around'>
            {addOn.pricing.map(e =>
              <Box key={`${addOn.name}-${e.type}`} flex='horizontal'>
                <Badge backgroundColor={unitType === e.type ? 'gray' : 'light-gray'} borderColor='gray' borderSize='x1' onClick={() => setUnitType(e.type)}>{humanReadableUnitType[e.type]}</Badge> </Box>)}
          </Box>
          {unitType &&

            <Box backgroundColor='light'>
              <TextInput size='small' type='number' value={quantity} onChange={(e) => setQuantity(parseFloat((e.target as HTMLInputElement).value))} />
            </Box>

          }
          <Box flex='horizontal' alignChildrenHorizontal='between'>
            <Button size='small' color='blue' onClick={() => {
              onAdd({
                product: addOn,
                quantity: quantity,
                unitType: unitType,
              })
              onCancel()

            }}><Check size='1rem' /></Button>
            <Button onClick={onCancel} level='secondary'> <X size='1rem' /></Button>
          </Box>
        </Box>
      </Appear>
    }
    {inTheBag.length > 0 && inTheBag.map(e =>
      <Box key={`${e.product}-bagged`} flex='horizontal' alignChildrenVertical='middle' alignChildrenHorizontal='around' padding='x1' borderRadius='x2'>
        <Text size='x1' strong>{`${e.quantity} ${unitTypeLabel[e.unitType!]}`}</Text>
        {isSelected && <Button color='red' size='small' onClick={() => {
          onRemove(addOn.id)
          onCancel()
        }}><Trash size='1rem' /></Button>}
        {isSelected && <Button size='small' onClick={onCancel}><X size='1rem' /></Button>}
        {!isSelected && <Badge size='x1' backgroundColor='green' textColor='green-dark'><Check size='1rem' color='green' /> Added!</Badge>}
      </Box>)}
  </Box>

  );
}

