import { Config } from '@hd/types';
import firebase from 'firebase/app';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreConfig() {
  return useFirestoreDocument<Config>('Config', 'config', 'config', {
    exportRecipients: [],
    lastExportTime: firebase.firestore.Timestamp.now(),
    nextCCTVId: 0,
    nextHDAuditNumber: 0,
    nextInvoiceNumber: 0,
    nextUnblockId: 0,
    vatRate: 20,
  });
}
