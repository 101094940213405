import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Page,
  PageBack,
  PageBody,
  PageHeader,
  PageTitle,
  Text,
} from '@hd/ui';
import { Save } from '@styled-icons/boxicons-regular';
import { FormError, validateJobType } from '../../utils/formValidators';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FormErrorMessage from '../FormErrorMessage';
import useFirestoreJobTypes from '../../utils/useFirestoreJobTypes';
import JobTypeForm from './JobTypeForm';
import { isAdmin } from '../../utils/authLevel';

export default function AdminJobType() {
  const { push } = useHistory();
  const { jobTypeId } = useParams<{ jobTypeId: string }>();
  const data = useFirestoreJobTypes(jobTypeId);
  const [error, setError] = React.useState<FormError | null>(null);
  if (!isAdmin()) {
    return null;
  }

  const handleSubmit = async () => {
    const error = validateJobType(data.document);
    setError(error);

    if (!error) {
      await data.save();
      push('/admin/job-types');
    }
  };

  return (
    <FirebaseDocument {...data}>
      <Page>
        <PageHeader>
          <Box alignChildrenVertical="end" flex="horizontal">
            <Box grow>
              <PageBack to="/admin/job-types">Job types</PageBack>
              <PageTitle>{data.document.name}</PageTitle>
            </Box>

            <Box flex="horizontal" gap="x2">
              <Button
                color="blue"
                level="tertiary"
                onClick={handleSubmit}
                type="submit"
              >
                <Save size="1.25rem" />
                <Text>Save</Text>
              </Button>
            </Box>
          </Box>
        </PageHeader>

        <PageBody>
          {error && <FormErrorMessage error={error} />}

          <Card backgroundColor="light" margin="x6" padding="x10">
            <JobTypeForm document={data.document} update={data.update} />
          </Card>
        </PageBody>
      </Page>
    </FirebaseDocument>
  );
}
