import { DateTime } from 'luxon';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Appear,
  Box,
  Button,
  Card,
  CheckBox,
  DatePicker,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Image,
  Modal,
  PageLoading,
  Text,
  TextInput,
} from '@hd/ui';
import useFirestoreContractor from '../../utils/useFirestoreContractor';
import { Calendar, Pound } from '@styled-icons/boxicons-regular';
import Dropdown from '../Dropdown/Dropdown';
import useFirestoreContractorRaisedJob from '../../utils/useFirestoreContractorRaisedJob';
import timeWindows from '../../constants/timeWindows';
import useFirestoreCollection from '../../utils/useFirestoreCollection';
import { JobType } from '@hd/types';
import useFirestoreContractorEngineers from '../../utils/useFirestoreContractorEngineers';
import firebase from 'firebase';
import useInputNumber from '../../utils/useInputNumber';
import {
  FormError,
  validateContractorRaisedJob,
} from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';

export default function OutOfHours() {
  const { contractorId } = useParams<{ contractorId: string }>();
  const { documents: engineers, ids: engineerIds } =
    useFirestoreContractorEngineers(contractorId);

  const {
    document: contractorRaisedJob,
    update,
    save,
    clear,
  } = useFirestoreContractorRaisedJob();
  const dateToday = DateTime.local().startOf('day').toJSDate();

  const [price, handleUpdatePrice] = useInputNumber(
    contractorRaisedJob.price,
    (price) => update({ price })
  );
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState<FormError | null>(null);

  const {
    document: contractor,
    isFetching,
    hasDocument,
  } = useFirestoreContractor(contractorId);

  const jobTypes = useFirestoreCollection<JobType>('JobTypes', 'jobTypes', {});
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const toggleSiteIsCustomer = () => {
    update({
      siteIsCustomer: !contractorRaisedJob.siteIsCustomer,
    });
    if (contractorRaisedJob.siteIsCustomer) {
      update({
        siteContactName: undefined,
        siteContactNumber: undefined,
        siteName: undefined,
        siteAddressLine1: undefined,
        siteAddressLine2: undefined,
        siteAddressLine3: undefined,
        siteAddressPostcode: undefined,
      });
    } else {
      update({
        siteContactName: contractorRaisedJob.customerContactName,
        siteContactNumber: contractorRaisedJob.customerNumber,
        siteName: contractorRaisedJob.customerName,
        siteAddressLine1: contractorRaisedJob.customerAddress1,
        siteAddressLine2: contractorRaisedJob.customerAddress2,
        siteAddressLine3: contractorRaisedJob.customerAddress3,
        siteAddressPostcode: contractorRaisedJob.customerPostcode,
      });
    }
  };

  if (submitted) {
    return (
      <Box alignChildren="middle" flex="vertical" gap="x3" grow>
        <Text>Job created!</Text>
        <Button
          color="blue"
          level="primary"
          onClick={() => setSubmitted(false)}
        >
          Create new job
        </Button>
      </Box>
    );
  }

  if (isFetching) {
    return <PageLoading />;
  }

  if (!hasDocument || !contractor.canRaiseJobs) {
    return (
      <Box padding="x10">
        <Card maxWidth="600px" maxWidthCentered>
          <Image src={require('@hd/ui/assets/header-1.png')} />
          <Text align="middle" margin="x20" maxWidthCentered strong>
            Invalid link
          </Text>
          <Image src={require('@hd/ui/assets/footer.jpg')} />
        </Card>
      </Box>
    );
  }

  const hydrateSiteInfo = () => {
    if (contractorRaisedJob.siteIsCustomer) {
      update({
        siteContactName: contractorRaisedJob.customerContactName,
        siteContactNumber: contractorRaisedJob.customerNumber,
        siteName: contractorRaisedJob.customerName,
        siteAddressLine1: contractorRaisedJob.customerAddress1,
        siteAddressLine2: contractorRaisedJob.customerAddress2,
        siteAddressLine3: contractorRaisedJob.customerAddress3,
        siteAddressPostcode: contractorRaisedJob.customerPostcode,
      });
    }
  };

  const handleSave = () => {
    const error = validateContractorRaisedJob(contractorRaisedJob);
    setError(error);

    if (!error) {
      setShowConfirmation(true);
    }
  };

  const confirmSave = async () => {
    setShowConfirmation(false);
    await save();
    clear();
    setSubmitted(true);
  };

  return (
    <>
      <Box
        flex="vertical"
        gap="x10"
        padding="x10"
        alignChildrenHorizontal="middle"
      >
        <Card
          backgroundColor="light"
          maxWidth="600px"
          flex="vertical"
          gap="x3"
          margin="x4"
          padding="x6"
        >
          <Text margin="x4" size="x4" strong>
            {contractor.name}
          </Text>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel> Customer Email</FormInputLabel>
            </FormInputHeader>
            <TextInput
              onChange={(e) =>
                update({
                  customerEmail: (e.target as HTMLInputElement).value,
                })
              }
              value={contractorRaisedJob.customerEmail || ''}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Customer name</FormInputLabel>
            </FormInputHeader>
            <TextInput
              onChange={(e) =>
                update({
                  customerName: (e.target as HTMLInputElement).value,
                })
              }
              value={contractorRaisedJob.customerName || ''}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Contact name</FormInputLabel>
            </FormInputHeader>
            <TextInput
              onChange={(e) =>
                update({
                  customerContactName: (e.target as HTMLInputElement).value,
                })
              }
              value={contractorRaisedJob.customerContactName || ''}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Customer postcode</FormInputLabel>
            </FormInputHeader>
            <TextInput
              onChange={(e) =>
                update({
                  customerPostcode: (
                    e.target as HTMLInputElement
                  ).value.toUpperCase(),
                })
              }
              value={contractorRaisedJob.customerPostcode || ''}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Phone number</FormInputLabel>
            </FormInputHeader>
            <TextInput
              onChange={(e) =>
                update({
                  customerNumber: (e.target as HTMLInputElement).value,
                })
              }
              value={contractorRaisedJob.customerNumber || ''}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Address line 1</FormInputLabel>
            </FormInputHeader>
            <TextInput
              onChange={(e) =>
                update({
                  customerAddress1: (e.target as HTMLInputElement).value,
                })
              }
              value={contractorRaisedJob.customerAddress1 || ''}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Address line 2</FormInputLabel>
            </FormInputHeader>
            <TextInput
              onChange={(e) =>
                update({
                  customerAddress2: (e.target as HTMLInputElement).value,
                })
              }
              value={contractorRaisedJob.customerAddress2 || ''}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Address line 3</FormInputLabel>
            </FormInputHeader>
            <TextInput
              onChange={(e) =>
                update({
                  customerAddress3: (e.target as HTMLInputElement).value,
                })
              }
              value={contractorRaisedJob.customerAddress3 || ''}
            />
          </FormInput>
          <Box flex="horizontal" gap="x3">
            <CheckBox
              checked={contractorRaisedJob.siteIsCustomer}
              grow
              onChange={toggleSiteIsCustomer}
            >
              <Text strong>Site is customer</Text>
            </CheckBox>
          </Box>
        </Card>
        {!contractorRaisedJob.siteIsCustomer && (
          <Appear animation="Pop" maxWidth="600px">
            <Card
              backgroundColor="light"
              flex="vertical"
              gap="x3"
              margin="x4"
              padding="x6"
            >
              <Box flex="vertical" gap="x3" grow>
                <Text strong>Site</Text>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Contact name</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteContactName: (e.target as HTMLInputElement).value,
                      });
                    }}
                    value={contractorRaisedJob.siteContactName || ''}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Contact number</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteContactNumber: (e.target as HTMLInputElement).value,
                      });
                    }}
                    value={contractorRaisedJob.siteContactNumber || ''}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Site postcode</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteAddressPostcode: (
                          e.target as HTMLInputElement
                        ).value.toUpperCase(),
                      });
                    }}
                    value={contractorRaisedJob.siteAddressPostcode || ''}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Site Name</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) =>
                      update({
                        siteName: (e.target as HTMLInputElement).value,
                      })
                    }
                    value={contractorRaisedJob.siteName || ''}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Address line 1</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteAddressLine1: (e.target as HTMLInputElement).value,
                      });
                    }}
                    value={contractorRaisedJob.siteAddressLine1 || ''}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Address line 2</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteAddressLine2: (e.target as HTMLInputElement).value,
                      });
                    }}
                    value={contractorRaisedJob.siteAddressLine2 || ''}
                  />
                </FormInput>
                <FormInput grow>
                  <FormInputHeader>
                    <FormInputLabel>Address line 3</FormInputLabel>
                  </FormInputHeader>
                  <TextInput
                    onChange={(e) => {
                      update({
                        siteAddressLine3: (e.target as HTMLInputElement).value,
                      });
                    }}
                    value={contractorRaisedJob.siteAddressLine3 || ''}
                  />
                </FormInput>
              </Box>
            </Card>
          </Appear>
        )}

        <Card backgroundColor="light" margin="x4" padding="x6" maxWidth="600px">
          <Box flex="horizontal" gap="x6" margin="x6">
            <Box basis="none" grow>
              <FormInput>
                <FormInputHeader>
                  <FormInputLabel>Purchase order number</FormInputLabel>
                </FormInputHeader>

                <TextInput
                  onChange={(e) =>
                    update({
                      purchaseOrderNumber: (e.target as HTMLInputElement).value,
                    })
                  }
                  value={contractorRaisedJob.purchaseOrderNumber || ''}
                />
              </FormInput>
            </Box>
          </Box>
          <Box basis="none" grow>
            <FormInput>
              <FormInputHeader>
                <FormInputLabel>Date</FormInputLabel>
              </FormInputHeader>

              <DatePicker
                dateSelectableFrom={dateToday}
                onApply={(date) => {
                  if (date) {
                    update({
                      date: firebase.firestore.Timestamp.fromDate(date),
                    });
                  }
                }}
              >
                {(props) => (
                  <TextInput
                    {...props}
                    IconPost={Calendar}
                    onChange={() => {}}
                    readOnly
                    value={
                      contractorRaisedJob.date
                        ? contractorRaisedJob.date.toDate().toDateString()
                        : ''
                    }
                  />
                )}
              </DatePicker>
            </FormInput>
          </Box>

          <Box basis="none" grow>
            <FormInput>
              <FormInputHeader>
                <FormInputLabel>Time Window</FormInputLabel>
              </FormInputHeader>

              <Dropdown
                data-at-id="TimeWindow"
                onChange={(timeWindow) => update({ timeWindow })}
                options={timeWindows}
                value={contractorRaisedJob.timeWindow}
              />
            </FormInput>
          </Box>
        </Card>

        <Card backgroundColor="light" margin="x4" padding="x6" maxWidth="600px">
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>Job Type</FormInputLabel>
            </FormInputHeader>
            <Dropdown
              data-at-id="JobType"
              onChange={(jobTypeName) => {
                const jobType = jobTypes.ids
                  .map((id) => jobTypes.documents[id])
                  .find((e) => e.name === jobTypeName);
                if (jobType) {
                  update({
                    isCommercial: jobType.isCommerical,
                    isCCTV: jobType.isCCTV,
                    isDomestic: jobType.isDomestic,
                    isRepair: jobType.isRepair,
                    isUnblock: jobType.isUnblock,
                    jobType: jobType.name,
                    customerNominalCode: jobType.customerNominalCode,
                    contractorNominalCode: jobType.contractorNominalCode,
                    price: jobType.isFixedPrice
                      ? jobType.priceCustomer
                      : contractorRaisedJob.price,
                  });
                }
              }}
              options={jobTypes.ids.map((id) => jobTypes.documents[id].name)}
              value={contractorRaisedJob.jobType || ''}
            />
          </FormInput>
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>Customer Price (Excluding VAT)</FormInputLabel>
            </FormInputHeader>

            <TextInput
              IconPre={Pound}
              onChange={handleUpdatePrice}
              value={contractorRaisedJob.price}
            />
          </FormInput>
        </Card>

        <Card backgroundColor="light" margin="x4" padding="x6" maxWidth="600px">
          <Text>Engineer</Text>
          <Dropdown
            onChange={(engineerName) => {
              const engineer = engineerIds
                .map((id) => engineers[id])
                .find((e) => e.name === engineerName);
              if (engineer) {
                update({
                  contractorEngineer: engineer,
                  contractor: contractor,
                });
              }
            }}
            options={engineerIds.map((id) => engineers[id].name)}
            value={contractorRaisedJob.contractorEngineer?.name || ''}
          />
        </Card>

        <Card backgroundColor="light" margin="x4" padding="x6" maxWidth="600px">
          <Text>Additional information</Text>
          <TextInput
            multiline
            onChange={(e) =>
              update({ description: (e.target as HTMLInputElement).value })
            }
            rows={20}
            value={contractorRaisedJob.description || ''}
          />
        </Card>

        {error && <FormErrorMessage error={error} />}
        <Button
          color="blue"
          level="primary"
          onClick={() => {
            hydrateSiteInfo();
            handleSave();
          }}
        >
          Submit
        </Button>
      </Box>
      <Modal
        flex="horizontal"
        grow
        maxWidth="300px"
        onClose={() => setShowConfirmation(false)}
        visible={showConfirmation}
      >
        <Card
          backgroundColor="light"
          flex="vertical"
          gap="x3"
          padding="x6"
          maxWidth="600px"
        >
          <Box>
            <Text>Booked by</Text>
            <TextInput
              onChange={(e) =>
                update({ bookedBy: (e.target as HTMLInputElement).value })
              }
              value={contractorRaisedJob.bookedBy || ''}
            />
          </Box>
          <Button
            color="blue"
            disabled={contractorRaisedJob.bookedBy === undefined}
            level="primary"
            onClick={confirmSave}
          >
            Submit
          </Button>
        </Card>
      </Modal>
    </>
  );
}
