import { AddOnRepair, Repair } from "@hd/types";

export const calculateRepairsPrice = (repairs: Repair[] | AddOnRepair[], ignoreAddOn?: boolean) => {
  let price = 0;

  for (const repair of repairs) {
    const unit = repair.product?.pricing.find(e => e.type === repair.unitType)
    if (unit?.progressivePrice && unit?.progressivePrice?.length > 0) {
      for (let i = 0; i < (repair.quantity || 0); i++) {
        let applicablePrice = [{
          id: '',
          threshold: 0,
          price: unit.price,
          contractorPrice: unit.contractorPrice
        }, ...unit.progressivePrice].filter(e => e.threshold <= (i + 1))
          .reduce((prev, current) => {
            return (prev.threshold > current.threshold) ? prev : current;
          });
        price += applicablePrice.price;
      }
    } else {
      price += (unit?.price || 0) * (repair.quantity || 0)
    }
    if('addOns' in repair && !ignoreAddOn) {
      price += calculateRepairsPrice(repair.addOns || [])
    }
  }
  return price
}

export const calculateContractorTotal = (repairs: Repair[] | AddOnRepair[], ignoreAddOn?: boolean) => {
  let price = 0;

  for (const repair of repairs) {
    const unit = repair.product?.pricing.find(e => e.type === repair.unitType)
    if (unit?.progressivePrice && unit?.progressivePrice?.length > 0) {
      for (let i = 0; i < (repair.quantity || 0); i++) {
        let applicablePrice = [{
          id: '',
          threshold: 0,
          price: unit.price,
          contractorPrice: unit.contractorPrice
        }, ...unit.progressivePrice].filter(e => e.threshold <= (i + 1))
          .reduce((prev, current) => {
            return (prev.threshold > current.threshold) ? prev : current;
          });
        price += applicablePrice.contractorPrice;

      }

    } else {
      price += (unit?.contractorPrice || 0) * (repair.quantity || 0)
    }
    if('addOns' in repair && !ignoreAddOn) {
      price += calculateContractorTotal(repair.addOns || [])
    }
  }
  return price
}
