import * as React from 'react';
import { Box, Button, Card, CardRow, FormInput, Text, TextInput } from '@hd/ui';
import { JobType, NoteOrQuote } from '@hd/types';
import { Plus } from '@styled-icons/boxicons-regular';
import FirebaseList from '../Firebase/FirebaseList';
import useFirestoreCollection, {
  Options,
  TypeOrderBy,
} from '../../utils/useFirestoreCollection';
import useKeywordFilter from '../../utils/useKeywordFilter';
import useMergedFilters from '../../utils/useMergedFilters';
import AdminNoteOrQuoteModal from './AdminNoteOrQuoteModal';

interface Props {
  isSearchable?: boolean;
  options?: Options<JobType>;
  onSelect: (jobType: JobType) => void;
}

const orderBys: TypeOrderBy<NoteOrQuote>[] = [['name', 'asc']];

export default function AdminNotesAndQuotesList(props: Props) {
  const { isSearchable, onSelect, options } = props;
  const [value, setValue] = React.useState('');
  const [isAddNoteOrQuoteVisible, setIsAddNoteOrQuoteVisible] =
    React.useState(false);
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);
  const data = useFirestoreCollection<NoteOrQuote>(
    'NoteOrQuote',
    'notesAndQuotes',
    {
      filters: filters,
      limit: 50,
      orderBys,
    }
  );

  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow>
          {isSearchable && (
            <FormInput margin="x3">
              <TextInput
                onChange={(e) => setValue((e.target as HTMLInputElement).value)}
                placeholder="Search notes and quotes"
                value={value}
              />
            </FormInput>
          )}
        </Box>

        <Box>
          <Button
            color="blue"
            data-at-id="AddNoteOrQuoteButton"
            level="tertiary"
            onClick={() => setIsAddNoteOrQuoteVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add note/quote</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data} onMore={data.more}>
        <Card>
          {data.ids.map((id) => (
            <CardRow
              alignChildrenVertical="middle"
              data-at-id="ContractorLisItem"
              flex="horizontal"
              key={id}
              onClick={() => onSelect(data.documents[id])}
              padding="x4"
            >
              <Box grow>
                <Text strong>{data.documents[id].name}</Text>
                <Text size="x1" weak>
                  {data.documents[id].value?.substring(0, 100)}...
                </Text>
              </Box>
            </CardRow>
          ))}
        </Card>
      </FirebaseList>

      <AdminNoteOrQuoteModal
        onClose={() => setIsAddNoteOrQuoteVisible(false)}
        visible={isAddNoteOrQuoteVisible}
      />
    </>
  );
}
