import * as React from 'react';
import { Box, Button, Card, CardRow, FormInput, Text, TextInput } from '@hd/ui';
import { CCTVRecommendationCategory } from '@hd/types';
import { Plus } from '@styled-icons/boxicons-regular';
import FirebaseList from '../../Firebase/FirebaseList';
import useFirestoreCollection, {
  Options,
} from '../../../utils/useFirestoreCollection';
import useKeywordFilter from '../../../utils/useKeywordFilter';
import useMergedFilters from '../../../utils/useMergedFilters';
import CategoryAddModal from './CategoryAddModal';

interface Props {
  isSearchable?: boolean;
  options?: Options<CCTVRecommendationCategory>;
  onSelect: (jobType: CCTVRecommendationCategory) => void;
}

export default function CategoriesList(props: Props) {
  const { isSearchable, onSelect, options } = props;
  const [value, setValue] = React.useState('');
  const [isAddCategoryModalVisible, setAddCategoryModalVisible] =
    React.useState(false);
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);
  const data = useFirestoreCollection<CCTVRecommendationCategory>(
    'CCTVRecommendationCategory',
    'product-categories',
    {
      limit: 50,
      filters
    }
  );

  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow>
          {isSearchable && (
            <FormInput margin="x3">
              <TextInput
                onChange={(e) => setValue((e.target as HTMLInputElement).value)}
                placeholder="Search categories"
                value={value}
              />
            </FormInput>
          )}
        </Box>

        <Box>
          <Button
            color="blue"
            data-at-id="AddNoteOrQuoteButton"
            level="tertiary"
            onClick={() => setAddCategoryModalVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add category</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data} onMore={data.more}>
        <Card>
          {data.ids.map((id) => (
            <CardRow
              alignChildrenVertical="middle"
              data-at-id="ContractorLisItem"
              flex="horizontal"
              key={id}
              onClick={() => onSelect(data.documents[id])}
              padding="x4"
            >
              <Box grow>
                <Text strong>{data.documents[id].name}</Text>
                <Text size="x1" weak>
                </Text>
              </Box>
            </CardRow>
          ))}
        </Card>
      </FirebaseList>

      <CategoryAddModal
        onClose={() => setAddCategoryModalVisible(false)}
        visible={isAddCategoryModalVisible}
      />
    </>
  );
}
