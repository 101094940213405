import { Job, TypeJobType } from '@hd/types';
import firebase from 'firebase/app';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreJob(id?: string) {
  return useFirestoreDocument<Job>('Job', 'jobs', id, {
    contractor: null,
    contractorEngineer: null,
    contractorInvoiceId: '',
    contractorName: '',
    customer: undefined,
    customerInvoiceNumber: 0,
    customerInvoiceId: '',
    customerName: '',
    customerSite: undefined,
    date: firebase.firestore.Timestamp.now(),
    description: '',
    isCommercial: false,
    isCCTV: false,
    isDomestic: false,
    isRepair: false,
    isUnblock: false,
    jobNumber: '',
    jobType: '' as TypeJobType,
    magicId: '',
    priceContractor: 0,
    priceCustomer: 0,
    purchaseOrderNumber: '',
    status: 'UNALLOCATED',
    timeWindow: undefined,
    timestampCreated: firebase.firestore.Timestamp.now(),
    timestampUpdated: firebase.firestore.Timestamp.now(),
    vatRate: 0,
    contractorCollectsPayment: true,
    bookingCustomerName: '',
  });
}
