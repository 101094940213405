import firebase from 'firebase/app';
import {
  Badge,
  Box,
  Button,
  Card,
  CheckBox,
  FileInput,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Link,
  Text,
  TextInput,
} from '@hd/ui';
import * as React from 'react';
import useFirestoreCollection, {
  TypeOrderBy,
} from '../../utils/useFirestoreCollection';
import { Job, JobType } from '@hd/types';
import JobDrainReporter, {
  DrainReporterPayload,
  getDrainReporterStatus,
} from './JobDrainReporter';
import Dropdown from '../Dropdown/Dropdown';
import useInputNumber from '../../utils/useInputNumber';
import FileItem from '../FileList/FileItem';
import { ErrorCircle } from '@styled-icons/boxicons-regular';
import JobReportFollowUpQuoteEmails from './JobReportFollowUpQuoteEmails';
import { FormError } from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';
import useFirestoreQuote from '../../utils/useFirestoreQuote';
import { isFollowUpQuoteInvalid } from '../../utils/quote';
import JobReportFollowUpQuoteCCTVForm from './JobReportFollowUpQuoteCCTVForm';

type Props = {
  job: Job;
  onClose: () => void;
};

const orderBys: TypeOrderBy<JobType>[] = [['name', 'asc']];

export default function JobReportFollowUpQuote({ job, onClose }: Props) {
  const fullJobTypes = useFirestoreCollection<JobType>('JobTypes', 'jobTypes', {
    orderBys,
  });

  const { document, update, save, isFetching } = useFirestoreQuote(
    job.followUpQuote?._id
  );

  const { isCCTV } =  job;

  const [error, setError] = React.useState<FormError>();

  const handleSave = async () => {
    if (isFollowUpQuoteInvalid(document) && document.furtherWorkRequired) {
      setError({
        field: '',
        message: 'Please fill out all quote information.',
      });
    } else if (
      job.isCCTV &&
      (document.report === undefined || document.coverLetter === undefined)
    ) {
      setError({
        field: '',
        message: 'Please add all quote documentation',
      });
    } else {
      setError(undefined);
      await save();
      onClose();
    }
  };

  const jobTypeMap: Record<string, JobType> = {};

  const addDrainReporterReport = async (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const files = (event.target as HTMLInputElement).files;

    try {
      if (files && files.length) {
        const ref = firebase.storage().ref();

        for (const file of files) {
          const childRef = ref.child(`/jobs/${document._id}/${file.name}`); // change to quotes
          await childRef.put(file);
          update({
            coverLetter: {
              _id: '',
              _ref: '',
              name: file.type,
              downloadUrl: await childRef.getDownloadURL(),
              size: file.size,
            },
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const addDrainifyWincamReport = async (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const files = (event.target as HTMLInputElement).files;

    try {
      if (files && files.length) {
        const ref = firebase.storage().ref();

        for (const file of files) {
          const childRef = ref.child(`/jobs/${document._id}/${file.name}`); // Change to the goddamn quotes
          await childRef.put(file);
          update({
            report: {
              _id: '',
              _ref: '',
              name: file.name,
              downloadUrl: await childRef.getDownloadURL(),
              size: file.size,
            },
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  fullJobTypes.ids.forEach((e) => {
    const jobType = fullJobTypes.documents[e];
    jobTypeMap[jobType._id] = jobType;
  });

  const [drainReporterReport, setDrainReporterReport] =
    React.useState<DrainReporterPayload | null>();

  const [quotePrice, handleUpdateQuotePrice] = useInputNumber(
    document.price,
    (price) => update({ price })
  );

  const [quoteContractorPrice, handleUpdateQuoteContractorPrice] = useInputNumber(
    document.contractorPrice,
    (contractorPrice) => update({ contractorPrice })
  );

  const handleSetDrainReporterReport = async () => {
    const report = await getDrainReporterStatus(job.jobNumber);
    setDrainReporterReport(report);
  };

  React.useEffect(() => {
    if (job.isCCTV) {
      handleSetDrainReporterReport();
    }
  }, []);



  if(job.isCCTV && job.usingNewDrainReporter) {
    return ( <JobReportFollowUpQuoteCCTVForm job={job} onClose={onClose} quote={document}/>)
  }

  if (!document.furtherWorkRequired) {
    return (
      <Card padding="x5">
        <Box flex="horizontal">
          <Box width="50%">
            <Text size="x5" strong>
              {job.jobNumber}
            </Text>
            <Link to={`/quote/cu/${document.magicId}`}>Link to quote</Link>
            {error && <FormErrorMessage error={error} />}
            <Text strong size="x5">
              No further work
            </Text>
            <Box
              borderColor="gray"
              padding="x3"
              flex="horizontal"
              borderSize="x1"
              alignChildrenHorizontal="around"
              grow
            >
              {!document.report ? (
                <Box>
                  <FileInput
                    onChange={addDrainifyWincamReport}
                    color="red"
                    flex="horizontal"
                    gap="x2"
                  >
                    <ErrorCircle size="1rem" />
                    Add missing Drainify/Wincam report
                  </FileInput>
                </Box>
              ) : (
                <Box>
                  <FileItem
                    file={document.report}
                    name="Report"
                    onRemoveFile={() => update({ report: undefined })}
                  />
                </Box>
              )}
              {!document.coverLetter ? (
                <Box borderSize="x1">
                  <FileInput
                    onChange={addDrainReporterReport}
                    color="red"
                    flex="horizontal"
                    gap="x2"
                  >
                    <ErrorCircle size="1rem" />
                    <Box>Add missing Drain reporter report</Box>
                  </FileInput>
                </Box>
              ) : (
                <Box>
                  <FileItem
                    grow
                    file={document.coverLetter}
                    name="Drain reporter"
                    onRemoveFile={() => update({ coverLetter: undefined })}
                  />
                </Box>
              )}
            </Box>
            {drainReporterReport &&
              drainReporterReport.price !== document.price && (
                <Box flex="vertical" grow alignChildrenVertical="middle">
                  <Text size="x5">🤔</Text>
                  <Box flex="horizontal" gap="x2">
                    <Text size="x4">You have selected </Text>
                    <Text size="x4" strong>
                      no further work
                    </Text>
                    <Text size="x4">
                      But drain reporter price detected. Is this correct?
                    </Text>
                  </Box>
                </Box>
              )}
          </Box>
          {isCCTV && <JobDrainReporter job={job} />}
        </Box>

        {document._id && (
          <JobReportFollowUpQuoteEmails quote={document} save={handleSave} />
        )}
        <Button
          onClick={async () => {
            update({ quoteText: '' }); // temp fix
            await handleSave();
          }}
        >
          Save
        </Button>
      </Card>
    );
  }

  if (isCCTV) {
    return (
      <Card
        backgroundColor="light"
        padding="x6"
        margin="x4"
        flex="vertical"
        gap="x3"
      >
        {error && <FormErrorMessage error={error} />}
        <Box flex="horizontal">
          <Box width="50%">
            <Box flex="horizontal" gap="x2">
              <Badge backgroundColor="yellow-dark" textColor="gray">
                CCTV
              </Badge>
              <Text size="x5" strong>
                {job.jobNumber}
              </Text>
              <Text strong>Follow up quote</Text>
              <Link to={`/quote/cu/${document.magicId}`}>Link to quote</Link>
            </Box>
            <Box>
              {drainReporterReport ? (
                <Box></Box>
              ) : (
                <Box>Missing Drain reporter report</Box>
              )}
            </Box>
            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>
                  Job type (Default should be Drain Repair)
                </FormInputLabel>
              </FormInputHeader>
              <Box>
                <Dropdown
                  grow
                  data-at-id="QuoteJobType"
                  onChange={(e) => {
                    const id = fullJobTypes.ids
                      .map((id) => fullJobTypes.documents[id])
                      .find((jt) => jt.name === e)?._id;
                    update({
                      jobType: jobTypeMap[id].name,
                      contractorNominalCode:
                        jobTypeMap[id].contractorNominalCode,
                      customerNominalCode: jobTypeMap[id].customerNominalCode,
                      price: jobTypeMap[id].priceCustomer || 0,
                      quoteText: jobTypeMap[id].salesInfo,
                      isCCTV: jobTypeMap[id].isCCTV || false,
                      isCommercial: jobTypeMap[id].isCommercial || false,
                      isUnblock: jobTypeMap[id].isUnblock || false,
                      isRepair: jobTypeMap[id].isRepair || false,
                      isDomestic: jobTypeMap[id].isDomestic || false,
                    });
                  }}
                  options={fullJobTypes.ids.map(
                    (id) => fullJobTypes.documents[id].name
                  )}
                  value={document.jobType || 'Select job type'}
                />
              </Box>
            </FormInput>
            <Box flex='horizontal'>
              <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Price (Excluding VAT)</FormInputLabel>
                </FormInputHeader>
                <Box>
                  <TextInput
                    grow
                    onChange={handleUpdateQuotePrice}
                    value={quotePrice}
                  />
                </Box>
              </FormInput>
                <FormInput grow>
                <FormInputHeader>
                  <FormInputLabel>Contractor Price</FormInputLabel>
                </FormInputHeader>
                <Box>
                  <TextInput
                    grow
                    onChange={handleUpdateQuoteContractorPrice}
                    value={quoteContractorPrice}
                  />
                </Box>
              </FormInput>
            </Box>
            <Text strong>Quote text</Text>
            <TextInput
              rows={15}
              multiline
              size="large"
              value={document.quoteText}
              onChange={(e) =>
                update({ quoteText: (e.target as HTMLInputElement).value })
              }
            />
            <Box flex="vertical" margin="x5" gap="x3">
              <Text strong>Required Documentation</Text>
              <Box
                flex="horizontal"
                alignChildrenHorizontal="around"
                borderColor="light-gray"
                borderSize="x1"
                padding="x8"
              >
                {!document.report ? (
                  <Box>
                    <FileInput
                      onChange={addDrainifyWincamReport}
                      color="red"
                      flex="horizontal"
                      gap="x2"
                    >
                      <ErrorCircle size="1rem" />
                      Add missing Drainify/Wincam report
                    </FileInput>
                  </Box>
                ) : (
                  <Box>
                    <FileItem
                      file={document.report}
                      name="Report"
                      onRemoveFile={() => update({ report: undefined })}
                    />
                  </Box>
                )}
                {!document.coverLetter ? (
                  <Box>
                    <FileInput
                      onChange={addDrainReporterReport}
                      color="red"
                      flex="horizontal"
                      gap="x2"
                    >
                      <ErrorCircle size="1rem" />
                      <Box>Add missing Drain reporter report</Box>
                    </FileInput>
                  </Box>
                ) : (
                  <Box>
                    <FileItem
                      grow
                      file={document.coverLetter}
                      name="Drain reporter"
                      onRemoveFile={() => update({ coverLetter: undefined })}
                    />
                  </Box>
                )}
              </Box>
              <Box flex="horizontal" gap="x2">
                <Text strong>Hourly Rate</Text>
                <CheckBox
                  checked={document.hourlyRate}
                  onChange={() => update({ hourlyRate: !document.hourlyRate })}
                />
              </Box>
            </Box>
          </Box>
          {document.furtherWorkRequired && <JobDrainReporter job={job} />}
        </Box>
        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
        {document._id && (
          <JobReportFollowUpQuoteEmails quote={document} save={handleSave} />
        )}
      </Card>
    );
  }

  return (
    <Card
      backgroundColor="light"
      padding="x6"
      margin="x4"
      flex="vertical"
      gap="x3"
    >
      {error && <FormErrorMessage error={error} />}
      <Box flex="horizontal" gap="x2">
        <Badge backgroundColor="green-dark" textColor="gray">
          Non-CCTV
        </Badge>
        <Text size="x5" strong>
          {job.jobNumber}
        </Text>
        <Text strong>Follow up quote</Text>
        <Link to={`/quote/cu/${document.magicId}`}>Link to quote</Link>
      </Box>
      <FormInput grow>
        <FormInputHeader>
          <FormInputLabel>
            Job type (Default should be Drain Repair)
          </FormInputLabel>
        </FormInputHeader>
        <Box
          borderColor={document.jobType ? 'dark' : 'red'}
          borderSize={document.jobType ? 'x0' : 'x2'}
        >
          <Dropdown
            grow
            data-at-id="QuoteJobType"
            onChange={(jobType) => {
              const id = fullJobTypes.ids
                .map((id) => fullJobTypes.documents[id])
                .find((jt) => jt.name === jobType)?._id;
              update({
                jobType: jobType,
                price: jobTypeMap[id].priceCustomer || 0,
                quoteText: jobTypeMap[id].salesInfo,
                isCCTV: jobTypeMap[id].isCCTV || false,
                isCommercial: jobTypeMap[id].isCommercial || false,
                isUnblock: jobTypeMap[id].isUnblock || false,
                isRepair: jobTypeMap[id].isRepair || false,
                isDomestic: jobTypeMap[id].isDomestic || false,
                contractorNominalCode: jobTypeMap[id].contractorNominalCode,
                customerNominalCode: jobTypeMap[id].customerNominalCode,
              });
            }}
            options={fullJobTypes.ids.map(
              (id) => fullJobTypes.documents[id].name
            )}
            value={document.jobType || 'Select job type'}
          />
        </Box>
      </FormInput>
      <Box flex='horizontal'>
      <FormInput grow>
        <FormInputHeader>
          <FormInputLabel>Customer Price (Excluding VAT)</FormInputLabel>
        </FormInputHeader>
        <Box>
          <TextInput
            grow
            onChange={handleUpdateQuotePrice}
            value={quotePrice}
          />
        </Box>
      </FormInput>
       <FormInput grow>
        <FormInputHeader>
          <FormInputLabel>Contractor Price</FormInputLabel>
        </FormInputHeader>
        <Box>
          <TextInput
            grow
            onChange={handleUpdateQuoteContractorPrice}
            value={quoteContractorPrice}
          />
        </Box>
      </FormInput>
      </Box>
      <Text strong>Quote text</Text>
      <TextInput
        rows={10}
        multiline
        size="large"
        value={document.quoteText}
        onChange={(e) =>
          update({ quoteText: (e.target as HTMLInputElement).value })
        }
      />
      <Box flex="horizontal" gap="x2">
        <Text strong>Hourly Rate</Text>
        <CheckBox
          checked={document.hourlyRate}
          onChange={() => update({ hourlyRate: !document.hourlyRate })}
        />
      </Box>
      {document._id && (
        <JobReportFollowUpQuoteEmails quote={document} save={handleSave} />
      )}
      <Button
        onClick={() => {
          handleSave();
        }}
      >
        Save
      </Button>
    </Card>
  );
}
