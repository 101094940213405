import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Page, PageBody, PageHeader, PageTitle, Text } from '@hd/ui';
import ContractorsList from './ContractorsList';

export default function Contractors() {
  const { push } = useHistory();

  return (
    <Page data-at-id="ContractorsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Contractors</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <ContractorsList
          isSearchable
          onSelect={(contractor) => push(`/contractors/${contractor._id}`)}
          options={{ resolve: false }}
        />
      </PageBody>
    </Page>
  );
}
