/* eslint-disable camelcase */
//import { Location } from '@drainify/types';
//import { fromPlaceResultToLocation, getAddressString } from '@drainify/utils';
import { Site } from '@hd/types';
import {
  Box,
  Image,
  Link,
  Placement,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  Text,
  TextInput,
} from '@hd/ui';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { number } from 'yup';
import getAddress from '../utils/getAddress';

type Props = {
  onChange: (site: Partial<Site>) => void;
  value?: Site;
}

const MapSearchInput = ({ onChange, value }: Props) => {
  const refAutocompleteService = useRef<google.maps.places.AutocompleteService>(
    new google.maps.places.AutocompleteService()
  );
  const refAutocompleteSessionToken =
    useRef<google.maps.places.AutocompleteSessionToken>(
      new google.maps.places.AutocompleteSessionToken()
    );

  const [valueString, setValueString] = React.useState<string>(value ? getAddress(value) : '');
  const [results, setResults] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [visible, setVisible] = useState(false);

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setValueString(event.target.value);
    setVisible(true);

    if (event.target.value && refAutocompleteService.current) {
      try {
        const brightonHoveLocation = new google.maps.LatLng(50.8225, -0.1372); // Latitude first, then longitude


        const { predictions } =
          await refAutocompleteService.current.getPlacePredictions({
            input: event.target.value,
            location: brightonHoveLocation,
            radius: 5000,

            sessionToken: refAutocompleteSessionToken.current,
          });

        setResults(predictions);
      } catch (error) {
        console.error(error);
        setResults([]);
      }
    }
  };

  const handleSelect = async (
    result: google.maps.places.AutocompletePrediction
  ) => {
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div')
    );

    placesService.getDetails(
      {
        placeId: result.place_id,
        fields: ['address_components', 'geometry'],
        sessionToken: refAutocompleteSessionToken.current,
      },
      (response) => {
        if (response) {
          console.log(response)
          onChange(fromPlaceResultToLocation(response));
        } else {
          console.error('No place found');
        }
      }
    );

    setVisible(false);
  };

  useEffect(() => {
    setValueString(value ? getAddress(value) : '');
    refAutocompleteSessionToken.current =
      new google.maps.places.AutocompleteSessionToken();
  }, [value]);

  useEffect(() => {
    if (google.maps.places.AutocompleteService) {
      refAutocompleteService.current =
        new google.maps.places.AutocompleteService();
    }
  }, []);
  return (
  <PlacementManager>
    <PlacementReference>
      {(props) => (
        <TextInput
          {...props}
          borderSize='x3'
          autoComplete='off'
          name={`${name}.address.street`}
          onChange={handleInputChange}
          placeholder="Type to autofill..."
          width="100%"
          backgroundColor='light'
          value={valueString}
        />
      )}
    </PlacementReference>

    <Placement
      animation="FadeSlideDown"
      width="reference"
      visible={visible}
      zIndex={1}
    >
      <PlacementContent
        borderRadius="x2"
        backgroundColor='light'
        borderSize="x2"
      >
        {results.length === 0 && (
          <Text padding="x2" size="x2" strong>
            No results found
          </Text>
        )}

        {results.map((result) => (
          <Link
            display="block"
            key={result.place_id}
            onClick={() => handleSelect(result)}
            padding="x2"
            size="x2"
            strong
          >
            {result.description}
          </Link>
        ))}

        <Box alignChildrenHorizontal="end" flex="horizontal" padding="x2">
          <Image
            height={15}
            src="https://storage.googleapis.com/geo-devrel-public-buckets/powered_by_google_on_white.png"
          />
        </Box>
      </PlacementContent>
    </Placement>
  </PlacementManager>
)

};

export default MapSearchInput;

export const fromPlaceResultToLocation = (
  place: google.maps.places.PlaceResult
): Partial<Site> => {
  const { address_components, geometry } = place;

  if (!address_components || !geometry || !geometry.location) {
    throw new Error('Invalid place format');
  }

  const address: Partial<Site> = {
    name: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressPostcode: ''
  };
  console.log(address_components)

  for (const component of address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number': {
        address.addressLine1 = component.long_name;
        break;
      }

      case 'route': {
        address.addressLine2 = component.long_name;
        break;
      }

      case 'postal_code': {
        address.addressPostcode = `${component.long_name}${address.addressPostcode}`;
        break;
      }

      case 'postal_code_suffix': {
        address.addressPostcode = `${address.addressPostcode}-${component.long_name}`;
        break;
      }

      case 'administrative_area_level_2': {
        address.addressLine3 = component.long_name;
        break;
      }

      case 'postal_town': {
        address.addressLine3 = component.long_name;
        break;
      }
    }
  }

  return {
    ...address,
    coordinates: [geometry.location.lng(), geometry.location.lat()],
  };
};