import { Box, Progress, Text } from "@hd/ui";
import * as React from "react";
import { Data } from "../../utils/useFirestoreDocument";

interface Props<T> extends Data<T> {
  allowNoDocument?: boolean;
}

export default function FirebaseDocument<T>(
  props: React.PropsWithChildren<Props<T>>
) {
  const {
    allowNoDocument,
    children,
    hasDocument,
    hasFailed,
    hasFetched,
    isFetching,
  } = props;

  if (hasFailed) {
    return (
      <Box alignChildren="middle" flex="vertical" grow padding="x6">
        <Text strong textColor="red">
          There was a problem
        </Text>
      </Box>
    );
  }

  if (isFetching) {
    return (
      <Box alignChildren="middle" flex="vertical" grow padding="x6">
        <Progress />
      </Box>
    );
  }

  if (!allowNoDocument && hasFetched && !hasDocument) {
    return (
      <Box alignChildren="middle" flex="vertical" grow padding="x6">
        <Text strong>No record was found.</Text>
      </Box>
    );
  }

  return <>{children}</>;
}
