import * as React from 'react';
import { Box, Button, Card, Modal, Text } from '@hd/ui';
import { FormError, validateCCTVRecommendationProduct } from '../../../utils/formValidators';
import useFirestoreProduct from '../../../utils/useFirestoreProduct';
import FormErrorMessage from '../../FormErrorMessage';
import ProductForm from '../ProductAddOnForm';
import { isPricingValid } from '../util';
import SubcategoryForm from './SubcategoryForm';
import useFirestoreProductSubcategory from '../../../utils/useFirestoreProductSubcategory';

interface Props {
  onClose: () => void;
  visible: boolean;
}

export default function SubcategoryAddModal(props: Props) {
  const { onClose, visible } = props;
  const { document, update, save, clear } = useFirestoreProductSubcategory();
  const [error, setError] = React.useState<FormError | null>(null);

  const handleSave = async () => {
      await save();
      clear();
      onClose();
  };

  return (
    <Modal maxWidth="900px" onClose={onClose} visible={visible}>
      <Card backgroundColor="light" padding="x10">
        <Text size="x4">Add subcategory</Text>

        <SubcategoryForm subcategory={document} update={update} />
        {error && <FormErrorMessage error={error} />}

        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x4"
          margin="x10"
        >
          <Button data-at-id="ModalCancel" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="blue"
            data-at-id="ModalConfirm"
            level="primary"
            onClick={handleSave}
            type="submit"
          >
            Create subcategory
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
