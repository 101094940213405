import { Contractor, Job } from '@hd/types';
import { Modal } from '@hd/ui';
import * as React from 'react';
import ContractorsList from '../Contractors/ContractorsList';
import { TypeFilter } from '../../utils/useFirestoreCollection';

const getPostcodeTokens = (postcode: string) => {
  return postcode.split('').reduce<string[]>((tokens, letter, i) => {
    tokens.push(`${tokens[i - 1] || ''}${letter}`);
    return tokens;
  }, []);
};

interface Props {
  job: Job;
  onClose: () => void;
  onSelect: (value: Contractor) => void;
}

export default function JobSelectionModalContractor(props: Props) {
  const { job, onClose, onSelect } = props;

  const filters = React.useMemo(
    () =>
      job.customerSite
        ? [
            [
              'coverage',
              'array-contains-any',
              getPostcodeTokens(job.customerSite.addressPostcode),
            ],
            ['disabled', '==', false] as TypeFilter,
          ]
        : undefined,
    [job.customerSite]
  );

  return (
    <Modal maxWidth="600px" onClose={onClose} visible>
      <ContractorsList onSelect={onSelect} options={{ filters }} />
    </Modal>
  );
}
