import firebase from 'firebase/app';
import { JobReport } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreJobReport(jobId?: string) {
  return useFirestoreDocument<JobReport>('Report', 'jobsReports', jobId, {
    jobDoneToGuarantee: true,
    jobRequiresFurtherWork: false,
    files: [],
    privateNote: '',
    publicNote: '',
    timestampSubmitted: firebase.firestore.Timestamp.now(),
  });
}
