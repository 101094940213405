import * as React from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import {
  Button,
  Card,
  Form,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Image,
  Page,
  PageBody,
  PageFooter,
  PageLoading,
  Text,
  TextInput,
} from '@hd/ui';
import useIsUserSignedIn from '../utils/useIsUserSignedIn';

const errors: Record<string, string> = {
  'auth/email-already-in-use':
    'Looks like you already have an account, head on over to the sign in page.',
  'auth/invalid-email': 'Your email address looks invalid, have another go.',
  'auth/passwords-do-not-match': "Your passwords don't match, have another go.",
  'auth/user-not-found':
    "Either your email or password aren't is incorrect, have another go.",
  'auth/weak-password':
    'Your password is too weak, it needs at least 6 characters, have another go.',
  'auth/wrong-password':
    "Either your email or password aren't is incorrect, have another go.",
};

export default function Login() {
  const { replace } = useHistory();
  const { isSignedIn, isSigningIn } = useIsUserSignedIn();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      replace('/');
    } catch (error) {
      console.log(error);
      setError(
        errors[error.code] ||
          'There was an issue logging in, please try again. ' +
            'If it keeps happening please get in contact.'
      );
    }
  };

  React.useEffect(() => {
    if (isSignedIn && !isSigningIn) {
      replace('/');
    }
  }, [isSignedIn, isSigningIn, replace]);

  if (isSigningIn) {
    return <PageLoading />;
  }

  return (
    <Page backgroundColor="light-gray" padding="x6">
      <PageBody
        alignChildrenHorizontal="middle"
        flex="vertical"
        maxWidth="448px"
        paddingVertical="x20"
      >
        <Image
          margin="x10"
          src={require('@hd/ui/assets/logo.png')}
          width="200px"
        />

        <Card backgroundColor="light" borderRadius="x2" padding="x10">
          <Form onSubmit={handleSubmit}>
            <Text margin="x8" size="x4">
              Sign in to your account
            </Text>

            <FormInput margin="x6">
              <FormInputHeader>
                <FormInputLabel>Email</FormInputLabel>
              </FormInputHeader>

              <TextInput
                data-at-id="EmailInput"
                onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
                value={email}
              />
            </FormInput>

            <FormInput margin="x6">
              <FormInputHeader>
                <FormInputLabel>Password</FormInputLabel>
              </FormInputHeader>

              <TextInput
                data-at-id="PasswordInput"
                onChange={(e) =>
                  setPassword((e.target as HTMLInputElement).value)
                }
                type="password"
                value={password}
              />
            </FormInput>

            {error && (
              <Text margin="x4" textColor="red">
                {error}
              </Text>
            )}

            <Button
              color="blue"
              data-at-id="LoginButon"
              disabled={!email || !password}
              fullWidth
              level="primary"
              type="submit"
            >
              Sign in
            </Button>
          </Form>
        </Card>
      </PageBody>

      <PageFooter />
    </Page>
  );
}
