import * as React from 'react';
import { Pencil, Plus, Trash } from '@styled-icons/boxicons-regular';
import { Box, Button, Card, CardRow, Text } from '@hd/ui';
import { Contractor, Engineer } from '@hd/types';
import ConfirmationModal from '../ConfirmationModal';
import FirebaseList from '../Firebase/FirebaseList';
import EngineerModal from './EngineerModal';
import EngineerView from './EngineerView';
import { Options } from '../../utils/useFirestoreCollection';
import useFirestoreContractorEngineers from '../../utils/useFirestoreContractorEngineers';

interface Props {
  contractor: Contractor;
  options?: Options<Engineer>;
  onSelect?: (site: Engineer) => void;
}

export default function EngineersList(props: Props) {
  const { contractor, options, onSelect } = props;
  const data = useFirestoreContractorEngineers(contractor._id, {
    ...options,
    realtime: true,
  });
  const [isAddEngineerModalVisible, setIsAddEngineerModalVisible] =
    React.useState(false);

  //const [removeEngineerId, setRemoveEngineerId] = React.useState< undefined | string >();

  const [updateEngineerId, setUpdateEngineerId] = React.useState<
    undefined | string
  >();

  const handleCloseUpdateEngineerModal = () => {
    setIsAddEngineerModalVisible(false);
    setUpdateEngineerId(undefined);
  };

  //const handleRemoveEngineer = async () => {
  //if (removeEngineerId) {
  //await data.remove(removeEngineerId);
  //setRemoveEngineerId(undefined);
  //}
  //};

  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow />

        <Box>
          <Button
            color="blue"
            data-at-id="AddEngineerButton"
            level="tertiary"
            onClick={() => setIsAddEngineerModalVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add Engineer</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data}>
        <Card>
          {data.ids.map((id) => (
            <CardRow
              data-at-id="EngineerListItem"
              flex="horizontal"
              key={id}
              onClick={onSelect && (() => onSelect(data.documents[id]))}
              paddingHorizontal="x10"
              paddingVertical="x4"
            >
              <Box grow>
                <EngineerView engineer={data.documents[id]} />
              </Box>

              {!onSelect && (
                <Box flex="horizontal" gap="x2">
                  <Button
                    color="light"
                    level="tertiary"
                    onClick={() => setUpdateEngineerId(id)}
                    size="small"
                  >
                    <Pencil size="1.25rem" />
                  </Button>

                  {
                    //<Button
                    //color="red"
                    //level="tertiary"
                    //onClick={ () => setRemoveEngineerId(id) }
                    //size="small">
                    //</Box><Trash size="1.25rem" />
                    //</Button>
                  }
                </Box>
              )}
            </CardRow>
          ))}
        </Card>
      </FirebaseList>

      <EngineerModal
        contractorId={contractor._id}
        engineerId={updateEngineerId}
        onClose={handleCloseUpdateEngineerModal}
        visible={isAddEngineerModalVisible || !!updateEngineerId}
      />

      {
        //<ConfirmationModal message={ removeEngineerId && data.documents[removeEngineerId] ? `Are you sure you want to delete the engineer "${data.documents[removeEngineerId].name}"?` : '' } onCancel={() => setRemoveEngineerId(undefined)} onConfirm={handleRemoveEngineer} visible={!!removeEngineerId} />
      }
    </>
  );
}
