import { Box, Button, Page, PageBack, PageBody, PageHeader, PageTitle } from '@hd/ui';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormError } from '../../../utils/formValidators';
import FirebaseDocument from '../../Firebase/FirebaseDocument';
import FormErrorMessage from '../../FormErrorMessage';
import useFirestoreProductCategory from '../../../utils/useFirestoreProductCategory';
import CategoryForm from './CategoriesForm';
import ConfirmationModal from '../../ConfirmationModal';

export default function CategoriesView() {
  const { push } = useHistory();
  const { categoryId } = useParams<{ categoryId: string }>();
  const [error, setError] = React.useState<FormError | null>(null);
  const [visible, setVisible] = React.useState<boolean>(false)

  const data = useFirestoreProductCategory(categoryId);

  const handleUpdate = async () => {
    if (data.document.name === undefined || data.document.name === '') {
      setError({
        field: '',
        message: 'Category needs name'
      })
    }
    else {
      await data.save()
      push('/products/categories')
    }
  }

  const handleDelete = async () => {
    await data.remove()
    push('/products/categories')
  }

  return (
    <>
      <FirebaseDocument {...data} allowNoDocument={!categoryId}>
        <Page>
          <PageHeader padding="x0" paddingHorizontal="x10">
            <Box alignChildrenVertical="end" flex='vertical' gap="x4" paddingVertical="x10">
              <Box basis="none" grow>
                <PageBack to={'/products/categories'}>Categories</PageBack>
              </Box>
              <Box
                alignChildrenVertical="middle"
                flex="horizontal"
                gap="x2">
                <PageTitle>{data.document.name} </PageTitle>
              </Box>
            </Box>
          </PageHeader>


          <PageBody>
            <CategoryForm category={data.document} update={data.update}/>

            {error && (
              <FormErrorMessage error={error} />
            )}
            <Box flex='horizontal' alignChildrenHorizontal='between'>
              <Button
                color="blue"
                level="primary"
                onClick={handleUpdate}>
                Update Category
              </Button>
              <Button
                color='red'
                level="primary"
                onClick={() => setVisible(true)}>
                Delete category
              </Button>
              <ConfirmationModal message={'Are you sure you want to delete category?'} onCancel={() => setVisible(false)} onConfirm={handleDelete} visible={visible}/>
            </Box>
          </PageBody>
        </Page>
      </FirebaseDocument>
    </>
  )
}