import { Use, PipeSize, PipeMaterial, LegalOwnership, SiteType, DischargeIntoTank, ConnectedToMains, SiteState, Access, SectionApplication, UnitTypeType } from '@hd/types';

export const UseType = [ 'Foul', 'Surface System', 'Foul to Mains and Surface Water to Soakway', 'Combined', 'Foul and Surface', 'Effluent', 'Other' ] as Use[];
export const PipeSizeTypes = [ 'Dia 100mm', 'Dia 150mm', 'Dia 225mm', 'Large Bore', 'Various'] as PipeSize[]
export const PipeMaterialTypes = [ 'VC', 'UPVC', 'Concrete', 'Pitch Fiber', 'Other' ] as PipeMaterial[]
export const LegalOwnershipTypes = ['Private' , 'Public' , 'Private and Shared', 'Unknown' ] as LegalOwnership[]
export const SiteTypeTypes = ['Residential', 'Commercial', 'Industrial', 'Mixed'] as SiteType[]
export const DischangeIntoTankTypes = ['No', 'Cesspit', 'Septic Tank', 'Treatment', 'Unknown Tank Type', 'Pumping Chamber' ] as DischargeIntoTank[]
export const ConnectedToMainsTypes = ['Yes', 'No', 'Unknown'] as ConnectedToMains[]
export const SiteStateTypes = ['No Defects', 'No Defects in Private', 'Defects', 'Defect No Quote'] as SiteState[]
export const AccessTypes = ['Inspection Chamber', 'Access Point', 'Other'] as Access[]

export const SectionApplicationTypes = ['Single', 'Multiple', 'Site wide'] as SectionApplication[]
export const UnitTypes = ['HourRate', 'HalfDayRate', 'DayRate', 'Quantity', 'Meterage'] as UnitTypeType[]

export const humanReadableUnitType: Record<UnitTypeType, string> = {
  Quantity: 'Quantity',
  Meterage: 'Meterage',
  HourRate: 'Hours',
  HalfDayRate: 'Half days',
  DayRate: 'Days'
}

export const humanReadableUnitTypeSingular: Record<UnitTypeType, string> = {
  Quantity: '',
  Meterage: 'Meter',
  HourRate: 'Hour',
  HalfDayRate: 'Half day',
  DayRate: 'Day'
}