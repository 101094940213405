import { Box, Button, Text } from "@hd/ui"
import React from "react"
import { useHistory } from "react-router-dom"
import { Bookmark } from "@hd/types"

type Props = {
    bookmark: Bookmark;
}

export default function BookmarkButton(props: Props) {
    const { push } = useHistory();
    const { bookmark } = props;

    return (
        <Box flex='horizontal'>
            <Button onClick={() => push(`/jobs/${bookmark.id}`)} size='small'>
                <Text textColor="light" size='x1'>{bookmark.jobNumber}</Text>
            </Button>
        </Box>
    )
}