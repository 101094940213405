import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, PageLoading, Text } from '@hd/ui';
import { TypeFilter } from '../../utils/useFirestoreCollection';
import useFirestoreJobs from '../../utils/useFirestoreJobs';
import CCTVRecommendationsForm from './CCTVRecommendationsForm';

export default function CCTVRecommendations() {
  const { magicId } = useParams<{ magicId: string }>();
  const filters = React.useMemo(
    () => [['magicId', '==', magicId]] as TypeFilter[],
    [magicId]
  );

  const { documents, ids, isFetching } = useFirestoreJobs({ filters }); // Change to useFirestoreCCTVRecommendations

  const document = documents[ids[0]];

  if (isFetching) {
    return <PageLoading />;
  }

  if (document) {
    return <CCTVRecommendationsForm job={document} />;
  }

  return (
    <Box>
      <Text maxWidth="300px" maxWidthCentered strong>
        We couldn't find a job matching this ID. It's possible this ID has
        expired. Please check if you have been sent a more recent link,
        otherwise contact Happy Drains.
      </Text>
    </Box>
  );
}
