import { Complaint } from '@hd/types';
import { useMemo } from 'react';
import useFirestoreCollection, { Options } from './useFirestoreCollection';
import useMergedFilters from './useMergedFilters';

export default function useFirestoreComplaints(jobId: string, options?: Options<Complaint>) {
  const filters = useMergedFilters(
    options?.filters,
    useMemo(() => [['jobId', '==', jobId]], [jobId]));

  return useFirestoreCollection<Complaint>('Complaint', 'complaints', { ...options, filters });
}
