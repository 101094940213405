import * as React from 'react';
import { Box, BoxProps, Button, Card, CardRow, Link, Text } from '@hd/ui';
import { ErrorAlt, Plus } from '@styled-icons/boxicons-regular';
import FirebaseList from '../Firebase/FirebaseList';
import useFirestoreComplaints from '../../utils/useFirestoreComplaints';
import toDateFormat from '../../utils/toDateFormat';
import JobComplaintModal from './JobComplaintModal';

interface Props extends BoxProps {
  contractorId: string;
  jobId: string;
}

export default function JobComplaintsList(props: Props) {
  const { contractorId, jobId, ...rest } = props;
  const data = useFirestoreComplaints(jobId, { realtime: true });
  const [isAddComplaintModalVisible, setIsAddComplaintModalVisible] = React.useState(false);
  const [updateComplaintId, setUpdateComplaintId] = React.useState<undefined | string>();

  const handleCloseUpdateComplaintModal = async() => {
    setIsAddComplaintModalVisible(false);
    setUpdateComplaintId(undefined);
  };

  return (
    <Box { ...rest }>
      <Box
          alignChildrenVertical="middle"
          flex="horizontal"
          gap="x2"
          margin="x3">
        <Box textColor="red">
          <ErrorAlt size="1.5rem" />
        </Box>

        <Box grow textColor="red">
          <Text strong>Complaints</Text>
        </Box>

        <Box>
          <Button
              color="blue"
              level="tertiary"
              onClick={ () => setIsAddComplaintModalVisible(true) }>
            <Plus size="1.5rem" />
            <Text>Add Complaint</Text>
          </Button>
        </Box>
      </Box>

      <FirebaseList { ...rest } { ...data }
          backgroundColor="light"
          gap="x4"
          margin="x3"
          padding="x3">
        <Card>
          { data.ids.map((id) => (
            <CardRow key={ id } padding="x3">
              <Text flex="horizontal" gap="x1" size="x2">
                <Text grow strong textColor={ data.documents[id].timestampResolved ? 'green' : 'red' }>
                  { data.documents[id].timestampResolved
                    ? `Resolved: ${toDateFormat(data.documents[id].timestampResolved, true)}`
                    : 'Unresolved!' }
                </Text>
                <Text><Link onClick={ () => setUpdateComplaintId(id) } textColor="blue">View/Update</Link></Text>
              </Text>

              <Text margin="x2" size="x2">
                { data.documents[id].description }
              </Text>
            </CardRow>
          )) }
        </Card>
      </FirebaseList>

      <JobComplaintModal
          complaintId={ updateComplaintId }
          contractorId={ contractorId }
          jobId={ jobId }
          onClose={ handleCloseUpdateComplaintModal }
          visible={ isAddComplaintModalVisible || !!updateComplaintId } />
    </Box>
  );
}
