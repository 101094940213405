import {
  Box,
  Button,
  FormInput,
  FormInputLabel,
  FormInputHeader,
  Text,
  TextInput,
  Badge,
} from '@hd/ui';
import * as React from 'react';
import { DrainReporterPayload } from './JobDrainReporter';
import { Pound } from '@styled-icons/boxicons-regular';

interface Props {
  report: DrainReporterPayload;
  jobNumber: string;
}

const updatePrice = async (
  jobNumber: string,
  price: number,
  contractorPrice: number
): Promise<DrainReporterPayload | null> => {
  try {
    const response = await fetch(
      //`http://localhost:9000/updatePrices/${jobNumber.replace('/', '%2F')}`,
      `https://drainreporter.com/updatePrices/${jobNumber.replace('/', '%2F')}`,
      {
        body: JSON.stringify({ price, contractorPrice }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-happy-drains':
            '2dabfab0996332832f8adbf62d0eb3472358e96acf17e0d841ce951d66dd552a',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    window.location.reload(false);
  } catch (e) {
    return null;
  }

  return null;
};

export default function JobDrainReporterReport(props: Props) {
  const {
    report: {
      privateNote,
      publicNote,
      contractorPrice,
      price,
      products,
      contractorProducts,
    },
    jobNumber,
  } = props;
  const [contractorPriceState, setContractorPrice] = React.useState(
    contractorPrice || 0
  );
  const [priceState, setPrice] = React.useState(price || 0);
  const initialPrice = price;
  const initialContractorPrice = contractorPrice;

  return (
    <Box
      borderColor="gray"
      borderRadius="x3"
      borderSize="x3"
      margin="x6"
      maxWidth="800px"
    >
      <Box>
        <Text backgroundColor="gray" padding="x2" size="x2" strong>
          Public Note:
        </Text>
        <Text padding="x2">{publicNote}</Text>
      </Box>

      <Box>
        <Text backgroundColor="gray" padding="x2" size="x2" strong>
          Private Note:
        </Text>
        <Text padding="x2">{privateNote}</Text>
      </Box>
      <Box>
        <Text backgroundColor="gray" padding="x2" size="x2" strong>
          HD Price breakdown (Note - does not include addons)
        </Text>
        <Text padding="x2">
          {products.map((e, i) => (
            <>
              <Badge backgroundColor={'blue-light'} textColor={'blue-dark'}>
                {e.name}
              </Badge>
              <div>
                {' '}
                {e.quantity} {e.unitType} @ {e.unitPrice}{' '}
                {e.basePrice && `+ ${e.basePrice}`} = £
                {(e.basePrice && e.totalPrice + e.basePrice) || e.totalPrice}{' '}
              </div>
              <br />
            </>
          ))}
        </Text>
        <FormInput>
          <FormInputHeader>
            <FormInputLabel>
              {' '}
              Total {priceState == initialPrice || '*** UNSAVED CHANGES'}
            </FormInputLabel>
          </FormInputHeader>

          <TextInput
            size="large"
            IconPre={Pound}
            data-at-id="Price"
            onChange={(e) => {
              const potentialUpdate = parseFloat(
                (e.target as HTMLInputElement).value
              );
              if (!isNaN(potentialUpdate)) {
                setPrice(potentialUpdate);
              } else if ((e.target as HTMLInputElement).value == '') {
                setPrice(0);
              }
            }}
            value={priceState}
          />
        </FormInput>
      </Box>

      <Box>
        <Text backgroundColor="gray" padding="x2" size="x2" strong>
          Contractor Price breakdown (Note - does not include addons)
        </Text>
        <Text padding="x2">
          {contractorProducts.map((e, i) => (
            <>
              <Badge backgroundColor={'yellow-light'} textColor={'blue-dark'}>
                {e.name}
              </Badge>
              <div>
                {' '}
                {e.quantity} {e.unitType} @ {e.unitPrice}{' '}
                {e.basePrice && `+ ${e.basePrice}`} = £
                {(e.basePrice && e.totalPrice + e.basePrice) || e.totalPrice}{' '}
              </div>
              <br />
            </>
          ))}
        </Text>
        <FormInput>
          <FormInputHeader>
            <FormInputLabel>
              {' '}
              Total{' '}
              {contractorPriceState == initialContractorPrice ||
                '*** UNSAVED CHANGES'}
            </FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="ContractorPrice"
            IconPre={Pound}
            onChange={(e) => {
              const potentialUpdate = parseFloat(
                (e.target as HTMLInputElement).value
              );
              if (!isNaN(potentialUpdate)) {
                setContractorPrice(potentialUpdate);
              } else if ((e.target as HTMLInputElement).value == '') {
                setContractorPrice(0);
              }
            }}
            value={contractorPriceState}
          />
        </FormInput>
      </Box>

      <Box></Box>
      <Button
        color="blue"
        level="primary"
        onClick={() => updatePrice(jobNumber, priceState, contractorPriceState)}
      >
        {' '}
        Update price{' '}
      </Button>
    </Box>
  );
}
