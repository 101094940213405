import { Activity } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';
import useFirestoreCollection, { TypeOrderBy } from './useFirestoreCollection';

const orderBys: TypeOrderBy<Activity>[] = [['time', 'asc']];

export default function useFirestoreActivityLog(jobId: string) {
  return useFirestoreCollection<Activity>('ActivityLog', `jobs/${jobId}/activity-log`, {
    orderBys
  });
}
