import * as React from 'react';
import { Alert, Badge, Box, Text } from '@hd/ui';
import { Customer } from '@hd/types';
import getAddress from '../../utils/getAddress';

export interface Props {
  customer: Customer;
}

export default function CustomerView(props: Props) {
  const { customer } = props;

  return (
    <Box>
      <Box flex='horizontal' gap='x2'>
      <Text strong>{ customer.name }</Text>
      {
        customer.accountOnHold && <Badge backgroundColor='red-light' textColor='red-dark'>ON HOLD</Badge>
      }
      </Box>
      <Text size="x2" textColor="dark-gray">
        { getAddress(customer) }
      </Text>
    </Box>
  );
}
