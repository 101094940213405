import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MaybeFilter = any[] | undefined;

const mergeArrays = (...arrays: MaybeFilter[]): MaybeFilter => {
  const array = [];

  for (const arr of arrays) {
    if (arr && arr.some((v) => v)) {
      array.push(...arr);
    }
  }

  return array.length ? array : undefined;
};


export default function useMergedFilters(filters1: MaybeFilter, filters2: MaybeFilter) {
  return useMemo(() => {
    return mergeArrays(filters1, filters2);
  }, [filters1, filters2]);
}
