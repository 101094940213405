import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Text,
  TextInput,
} from '@hd/ui';
import { JobType } from '@hd/types';
import { Product } from './ProductCreateModal';
import Dropdown from '../Dropdown/Dropdown';
import { parentIds } from './Admin';
import AdminProductUnitType from './AdminProductUnitType';
import { UnitType, UnitTypeType } from './AdminProduct';

interface Props {
  unitType?: Partial<UnitType>;
  update: (unitType?: UnitType) => void;
  unitTypeContractor?: Partial<UnitType>;
  updateContractor: (unitType?: UnitType) => void;
  header: string;
  unitTypeType: UnitTypeType;
}

export default function UnitTypeForm({
  unitType,
  unitTypeContractor,
  update,
  updateContractor,
  header,
  unitTypeType,
}: Props) {
  const toggleUnitType = () => {
    if (unitType) {
      update(undefined);
    } else {
      update({
        price: 0,
        min: 1,
        basePrice: 0,
        _type: unitTypeType,
      });
    }
  };

  return (
    <FormInput margin="x3">
      <Card margin="x4" padding="x4">
        <FormInputHeader>
          <FormInputLabel>{header} price</FormInputLabel>
          <CheckBox onChange={() => toggleUnitType()} checked={unitType} />
        </FormInputHeader>
        {unitType && (
          <AdminProductUnitType
            unitType={unitType}
            onChange={update}
            header
            unitTypeContractor={unitTypeContractor}
            updateContractor={updateContractor}
          />
        )}
      </Card>
    </FormInput>
  );
}
