import * as React from 'react';
import {
  FormInput,
  FormInputHeader,
  FormInputLabel,
  TextInput,
} from '@hd/ui';
import { Engineer } from '@hd/types';

interface Props {
  document: Engineer;
  update: (updates: Partial<Engineer>) => void;
}

export default function EngineerForm(props: Props) {
  const { document, update } = props;

  return (
    <>
      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Engineer Name</FormInputLabel>
        </FormInputHeader>

        <TextInput
            data-at-id="EngineerName"
            onChange={ (e) => update({ name: (e.target as HTMLInputElement).value }) }
            required
            value={ document.name } />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Engineer Email</FormInputLabel>
        </FormInputHeader>

        <TextInput
            data-at-id="EngineerEmail"
            onChange={ (e) => update({ email: (e.target as HTMLInputElement).value }) }
            required
            value={ document.email } />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Engineer Phone Number</FormInputLabel>
        </FormInputHeader>

        <TextInput
            data-at-id="EngineerPhoneNumber"
            onChange={ (e) => update({ phoneNumber: (e.target as HTMLInputElement).value }) }
            required
            value={ document.phoneNumber } />
      </FormInput>
    </>
  );
}
