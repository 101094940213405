import { CCTVRecommendationSubcategory, } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';
import { v4 } from 'uuid';

export default function useFirestoreProductSubcategory(id?: string) {
  return useFirestoreDocument<CCTVRecommendationSubcategory>('CCTVRecommendationSubcategory', 'product-subcategories', id, {
    id: v4(),
    parentId: '',
    name: '',
  });
}
