import * as React from 'react';
import {
  Box,
  FormInput,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
  TextInput,
} from '@hd/ui';
import RecurringJobList from './RecurringJobsList';
import useKeywordFilter from '../../utils/useKeywordFilter';

export default function Customers() {
  const [value, setValue] = React.useState('');
  const filters = useKeywordFilter(value);

  return (
    <Page data-at-id="JobsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>
              Recurring jobs
            </PageTitle>
          </Box>

        </Box>

        <FormInput margin="x3">
          <TextInput
            onChange={(e) => setValue((e.target as HTMLInputElement).value)}
            placeholder="Search jobs"
            value={value}
          />
        </FormInput>
      </PageHeader>

      <PageBody>
        <RecurringJobList options={{ filters }} />
      </PageBody>
    </Page>
  );
}
