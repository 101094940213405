import * as React from 'react';
import {
  Box,
  Button,
  Page,
  PageBack,
  PageBody,
  PageHeader,
  PageTitle,
  Text,
} from '@hd/ui';
import { useHistory, useParams } from 'react-router-dom';
import { NotificationsContext } from '../Notifications/Notifications';
import ProductForm from './ProductForm';
import {
  ProductHDApp,
} from './ProductCreateModal';

export type UnitTypeType =
  | 'models.HourRate'
  | 'models.DayRate'
  | 'models.HalfDayRate'
  | 'models.Quantity'
  | 'models.Meterage';

export interface UnitType {
  _type?: UnitTypeType;
  min?: number;
  price?: number;
  basePrice?: number;
}

export default function AdminProduct() {
  const { push } = useHistory();
  const { addNotification } = React.useContext(NotificationsContext);
  const { productId } = useParams<{ productId: string }>();
  const [loading, setLoading] = React.useState(true);

  const [product, setProduct] = React.useState<ProductHDApp>({
    name: undefined,
    parentId: undefined,
    maxDepth: undefined,
    unitQuantity: undefined,
    unitMeterage: undefined,
    unitDay: undefined,
    unitHour: undefined,
    unitHalfDay: undefined,
    unitQuantityContractor: undefined,
    unitMeterageContractor: undefined,
    unitDayContractor: undefined,
    unitHourContractor: undefined,
    unitHalfDayContractor: undefined,
    requiresAtPosition: false,
    requiresFromPosition: false,
    requiresToPosition: false,
    singleSectionApplication: false,
    multipleSectionApplication: false,
    allSectionApplication: false,
  });

  type DrainReporterProduct = {
    name: string;
    parentId: string;
    id: number;
    unit: UnitType[];
    contractorUnit: UnitType[];
    maxDepth?: number;
    sectionApplications: any[];
    requiresAtPosition: boolean;
    requiresFromPosition: boolean;
    requiresToPosition: boolean;
  };

  const fetchProduct = async () => {
    const response: DrainReporterProduct = await fetch(
      `https://drainreporter.com/product/${productId}`,
      {
        method: 'GET',
        headers: {
          'x-happy-drains':
            '2dabfab0996332832f8adbf62d0eb3472358e96acf17e0d841ce951d66dd552a',
          'Access-Control-Allow-Origin': '*',
        },
      }
    ).then((e) => e.json());

    const dayRate = response.unit.find((e) => e._type === 'models.DayRate');
    const hourRate = response.unit.find((e) => e._type === 'models.HourRate');
    const quantity = response.unit.find((e) => e._type === 'models.Quantity');
    const meterage = response.unit.find((e) => e._type === 'models.Meterage');
    const halfDayRate = response.unit.find(
      (e) => e._type === 'models.HalfDayRate'
    );
    const contractorDayRate = response.contractorUnit.find(
      (e) => e._type === 'models.DayRate'
    );
    const contractorHourRate = response.contractorUnit.find(
      (e) => e._type === 'models.HourRate'
    );
    const contractorQuantity = response.contractorUnit.find(
      (e) => e._type === 'models.Quantity'
    );
    const contractorMeterage = response.contractorUnit.find(
      (e) => e._type === 'models.Meterage'
    );
    const contractorHalfDayRate = response.contractorUnit.find(
      (e) => e._type === 'models.HalfDayRate'
    );

    let allSectionApplication = false;
    let singleSectionApplication = false;
    let multipleSectionApplication = false;

    response.sectionApplications.map((e) => {
      if (Object.keys(e).includes('Single')) {
        singleSectionApplication = true;
      } else if (Object.keys(e).includes('Multiple')) {
        multipleSectionApplication = true;
      } else if (Object.keys(e).includes('All')) {
        allSectionApplication = true;
      }
    });

    setProduct({
      name: response.name,
      unitDay: dayRate,
      unitHour: hourRate,
      unitHalfDay: halfDayRate,
      unitMeterage: meterage,
      unitQuantity: quantity,

      unitDayContractor: contractorDayRate,
      unitHourContractor: contractorHourRate,
      unitHalfDayContractor: contractorHalfDayRate,
      unitMeterageContractor: contractorMeterage,
      unitQuantityContractor: contractorQuantity,

      parentId: response.parentId,
      singleSectionApplication: singleSectionApplication,
      multipleSectionApplication: multipleSectionApplication,
      allSectionApplication: allSectionApplication,
      maxDepth: response.maxDepth,
      requiresAtPosition: response.requiresAtPosition,
      requiresFromPosition: response.requiresFromPosition,
      requiresToPosition: response.requiresToPosition,

      // Requires position...
    });
    setLoading(false);
  };

  const updateDrainReporter = async () => {
    const { maxDepth, parentId } = product;

    const body = {
      name: product.name,
      dayRate: product.unitDay,
      parentId: parseInt(parentId),
      halfDayRate: product.unitHalfDay,
      halfDayRateContractor:
        product.unitHalfDay?.price && product.unitHalfDayContractor?.price
          ? {
              ...product.unitHalfDay,
              price: product.unitHalfDayContractor?.price,
            }
          : undefined,
      hourRate: product.unitHour,
      hourRateContractor:
        product.unitHourContractor?.price && product.unitHour
          ? {
              ...product.unitHour,
              price: product.unitHourContractor?.price,
            }
          : undefined,
      quantity: product.unitQuantity,

      quantityContractor:
        product.unitQuantityContractor?.price && product.unitQuantity
          ? {
              ...product.unitQuantity,
              price: product.unitQuantityContractor?.price,
            }
          : undefined,

      meterage: product.unitMeterage,
      meterageContractor:
        product.unitMeterageContractor?.price && product.unitMeterage
          ? {
              ...product.unitMeterage,
              price: product.unitMeterageContractor?.price,
            }
          : undefined,

      maxDepth: maxDepth ? parseInt(maxDepth) : undefined,
      singleSectionApplication: product.singleSectionApplication,
      multipleSectionApplication: product.multipleSectionApplication,
      allSectionApplication: product.allSectionApplication,
      requiresAtPosition: product.requiresAtPosition,
      requiresFromPosition: product.requiresFromPosition,
      requiresToPosition: product.requiresToPosition,
    };
    await fetch(`https://drainreporter.com/updateProduct/${productId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-happy-drains':
          '2dabfab0996332832f8adbf62d0eb3472358e96acf17e0d841ce951d66dd552a',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    }).then((e) => console.log(e));
    await fetchProduct();
    addNotification('Product updated!');
    push('../');
  };

  React.useEffect(() => {
    fetchProduct();
  }, []);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Page data-at-id="AdminProductPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageBack to="/admin/products">Products</PageBack>
            <Box flex="horizontal" alignChildrenHorizontal="between">
              <PageTitle>{product.name}</PageTitle>
              <Button
                level="primary"
                color="blue"
                onClick={updateDrainReporter}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <ProductForm product={product} update={setProduct} />
      </PageBody>
    </Page>
  );
}
