import * as React from 'react';
import { Badge, Box, Text } from '@hd/ui';
import { Site } from '@hd/types';
import getAddress from '../../utils/getAddress';

export interface Props {
  site: Site;
}

export default function SiteView(props: Props) {
  const { site } = props;

  return (
    <Box>
      <Box flex='horizontal' gap='x2'>
      <Text strong>{ site.name }</Text>
        {
          site.customer?.accountOnHold && <Badge backgroundColor='red-light' textColor='red-dark'>ON HOLD</Badge>
        }
      </Box>
      <Text size="x2" textColor="dark-gray">
        { getAddress(site) }
      </Text>
    </Box>
  );
}
