import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Image, Page, PageBody } from '@hd/ui';
import CCTVRecommendations from './CCTVRecommendations';

export default function CCTVRecommendationsIndex() {
  return (
    <Page
        backgroundColor="light-gray"
        >
      <PageBody
          alignChildrenHorizontal="middle"
          flex="vertical"
          maxWidth="800px"
          paddingVertical="x20">

          <Image src={require('@hd/ui/assets/header-1.png')}/>

        <Switch>
          <Route component={ CCTVRecommendations } />
        </Switch>
      </PageBody>

    </Page>
  );
}
