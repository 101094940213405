import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Upload } from '@styled-icons/boxicons-regular';
import firebase from 'firebase/app';
import {
  Alert,
  Box,
  Button,
  Card,
  CheckBox,
  FileInput,
  Form,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  LabelledList,
  Link,
  Text,
  TextInput,
} from '@hd/ui';
import { Job } from '@hd/types';
import getAddress from '../../utils/getAddress';
import toDateFormat from '../../utils/toDateFormat';
import CompleteInfo from './CompleteInfo';
import FileList from '../FileList/FileList';
import FormErrorMessage from '../FormErrorMessage';
import {
  validateJobReport,
  FormError,
  validateJobReportUnblockForm,
} from '../../utils/formValidators';
import toCurrencyFormat from '../../utils/toCurrencyFormat';
import useFirestoreJobReport from '../../utils/useFirestoreJobReport';
import useFirestoreJob from '../../utils/useFirestoreJob';
import ConfirmationModal from '../ConfirmationModal';
import useQueryParams from '../../utils/useQueryParams';
import resizeImage from '../../utils/ImageCompressor';
import CompleteFormUnblockForm from './CompleteFormUnblockForm';
import JobFilesListContractor from '../Job/JobFilesListContractor';

const fileSizeLimitMb = 6;

const isFileValid = (file: File) =>
  file.type.startsWith('image/') && file.size <= fileSizeLimitMb * 1024 * 1024;

interface Props {
  job: Job;
}

export default function Complete(props: Props) {
  const {
    document: job,
    update: updateJob,
    save: saveJob,
  } = useFirestoreJob(props.job._id);
  const {
    _id,
    customer,
    customerSite,
    description,
    date,
    jobType,
    magicId,
    priceContractor,
    timeWindow,
  } = job;

  const { replace } = useHistory();
  const {
    document: jobReport,
    update: updateJobReport,
    save: saveJobReport,
  } = useFirestoreJobReport(_id);
  const [files, setFiles] = React.useState<File[]>([]);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<FormError | null>(null);
  const [cancellationError, setCancellationError] =
    React.useState<FormError | null>(null);
  const [isConfirmationVisible, setIsConfirmationVisible] =
    React.useState(false);

  const handleSelectFiles = async (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const newFiles = (event.target as HTMLInputElement).files;

    if (newFiles) {
      const filesArray = [];
      for (const file of newFiles) {
        const compressedBlob = (await resizeImage({
          file: file!,
          maxSize: 500,
        })) as Blob;

        const compressedImage = new File([compressedBlob], file.name, {
          type: compressedBlob.type,
        });

        filesArray.push(compressedImage);
      }
      setFiles(files.concat(filesArray));
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((files) => files.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const error = validateJobReport(jobReport);
    const areFilesValid = files.every(isFileValid);
    if (
      job.isUnblock &&
      (jobReport.unblockForm === undefined ||
        validateJobReportUnblockForm(jobReport.unblockForm))
    ) {
      return setError({
        field: '',
        message: 'Complete unblock form',
      });
    }

    if (!files.length) {
      return setError({
        field: '',
        message: 'Please upload some evidence.',
      });
    } else if (!areFilesValid) {
      return setError({
        field: '',
        message: `One or more files is not a picture or exceeds the file size limit of ${fileSizeLimitMb}Mb.`,
      });
    } else if (error) {
      return setError(error);
    }

    setSubmitting(true);

    try {
      if (files.length) {
        const ref = firebase.storage().ref();

        for (const file of files) {
          const childRef = ref.child(`/jobs/${_id}/${file.name}`);
          await childRef.put(file);
          jobReport.files.push({
            _id: '',
            _ref: '',
            name: file.name,
            downloadUrl: await childRef.getDownloadURL(),
            size: file.size,
          });
        }
      }

      await updateJobReport({ jobIsCancelled: false });

      if(jobReport.isNoFix) {
        updateJob({
          status: 'NO_FIX',
          priceContractor: 0,
          priceCustomer: 0,
        })
      }
      else if (jobReport.jobIsCancelled) {
        await updateJob({ status: 'AUTHORISE_CANCELLATION' });
      } else if (job.isCCTV) {
        await updateJob({ status: 'AWAITING_SURVEY' });
      } else {
        await updateJob({
          status:
            job.priceCustomer == 0 && job.jobType !== 'Recall'
              ? 'PAID'
              : jobReport.jobDoneToGuarantee &&
                !jobReport.jobRequiresFurtherWork
              ? 'COMPLETED'
              : 'AWAITING_AUTHORISATION',
        });
      }
      await saveJobReport();
      await saveJob();

      replace(`/complete/${magicId}/success`);
    } catch (e) {
      setError({
        field: '',
        message: `There was a problem uploading the file. Error: ${e.code}`,
      });

      setSubmitting(false);
    }
  };

  const attemptSubmit = () => {
    if (!jobReport.jobRequiresFurtherWork) {
      setError(null);
      setIsConfirmationVisible(true);
    } else {
      handleSubmit();
    }
  };

  const handleCancel = async () => {
    const error = validateJobReport(jobReport);
    if (error) {
      return setCancellationError({
        field: '',
        message: 'Please give reason for cancellation.',
      });
    }
    setSubmitting(true);
    await updateJobReport({ jobIsCancelled: true });
    await saveJobReport();
    await updateJob({ status: 'AUTHORISE_CANCELLATION' });
    await saveJob();
    setSubmitting(false);
  };

  const handleUncancel = async () => {
    setSubmitting(true);
    await updateJob({ status: 'IN_PROGRESS' });
    await updateJobReport({ jobIsCancelled: false });
    await saveJob();
    await saveJobReport();
    setSubmitting(false);
  };

  const queryParams = useQueryParams();

  return (
    <Box>
      {(job.status === 'COMPLETED' ||
        job.status === 'PAID' ||
        job.status === 'AWAITING_AUTHORISATION') && (
        <Card backgroundColor="light" margin="x6" padding="x6">
          <Text margin="x3" size="x5" strong>
            Thank you - This job has been submitted
          </Text>
        </Card>
      )}

      {jobReport.jobIsCancelled &&
        job.status !== 'COMPLETED' &&
        job.status !== 'PAID' &&
        job.status !== 'AWAITING_AUTHORISATION' && (
          <Card backgroundColor="light" margin="x6" padding="x6">
            <Text margin="x3" size="x5" strong>
              This job is now cancelled
            </Text>
            {cancellationError && (
              <FormErrorMessage error={cancellationError} />
            )}
            <Form>
              <Text margin="x3">
                Cancelled by mistake? Click the button below to uncancel the
                job.
              </Text>
              <Button
                color="red"
                disabled={isSubmitting}
                level="primary"
                margin="x3"
                onClick={() => {
                  handleUncancel();
                }}
                size="large"
              >
                Un-cancel
              </Button>
            </Form>
          </Card>
        )}

      {!jobReport.jobIsCancelled &&
        job.status !== 'COMPLETED' &&
        job.status !== 'PAID' &&
        job.status !== 'AWAITING_AUTHORISATION' && (
          <>

          {
          <Card padding='x6'>
            <Text strong size='x4'>Risk assessment</Text>
            <Text strong size='x1'>
              <Link to='/risk-assessment-templates' target='_blank'>
                Remember to download and fill out your risk assement if needs be and send to the client seperatly.
              
              </Link>
            </Text>
           </Card>
          }
            <Card backgroundColor="light" margin="x6" padding="x6">
              <Text size="x4" strong>
                {job.jobNumber}
              </Text>

              <LabelledList
                items={[
                  ['Site Name', customerSite?.name],
                  ['Site Address', getAddress(customerSite)],
                  ['Site Contact', customerSite?.contactName],
                  ['Site Phone Number', customerSite?.contactPhoneNumber],
                ]}
                margin="x6"
              />

              <LabelledList
                items={[
                  ['Customer Name', customer?.name],
                  [
                    'Customer Phone Numbers',
                    customer?.contactPhoneNumbers.join(', '),
                  ],
                ]}
                margin="x6"
              />
            </Card>
            <Card backgroundColor="light" margin="x6" padding="x6">

                <Text margin="x3" size="x4" strong>
                  {jobType}
                </Text>
                <Text margin="x3" size="x4" strong>
                  {toDateFormat(date)} between {timeWindow}
                </Text>
              <Box margin="x6">
                <Text margin="x3" size="x4" strong>
                  Details
                </Text>
                <Box>
                  {description}
                  
                  </Box>
              </Box>

              {queryParams.get('contractor') === 'true' && (
                <Box margin="x6">
                  <Text margin="x3" size="x4" strong>
                    Price
                  </Text>
                  <CompleteInfo>
                    {toCurrencyFormat(priceContractor.toString())}
                  </CompleteInfo>
                </Box>
              )}
            </Card>

            {job.isUnblock && (
              <Card backgroundColor="light" margin="x6" padding="x6">
                <Text margin="x3" size="x5" strong>
                  Details
                </Text>
                <Alert margin="x3">
                  Reminder: Please add relevent evidence to evidence section
                  above
                </Alert>
                <CompleteFormUnblockForm
                  jobReport={jobReport}
                  update={updateJobReport}
                />
              </Card>
            )}

            <JobFilesListContractor jobId={job._id}/>

            <Card backgroundColor="light" margin="x6" padding="x6">
              <Form>
                <Text margin="x3">
                  Please use this section to add any relevant informat about the
                  job, which will get added to the job report and can be read by
                  our Happy Drains staff.
                </Text>

                <FormInput margin="x3">
                  <FormInputHeader>
                    <FormInputLabel>Note for customer</FormInputLabel>
                  </FormInputHeader>

                  <TextInput
                    multiline
                    onChange={(e) =>
                      updateJobReport({
                        publicNote: (e.target as HTMLInputElement).value,
                      })
                    }
                    required
                    rows={10}
                    value={jobReport.publicNote}
                  />
                </FormInput>

                <FormInput margin="x3">
                  <FormInputHeader>
                    <FormInputLabel>Private note (Office only)</FormInputLabel>
                  </FormInputHeader>

                  <TextInput
                    multiline
                    onChange={(e) =>
                      updateJobReport({
                        privateNote: (e.target as HTMLInputElement).value,
                      })
                    }
                    required
                    rows={10}
                    value={jobReport.privateNote}
                  />
                </FormInput>

                <Box margin="x6">
                  <Text margin="x3" size="x5" strong>
                    Upload evidence
                  </Text>
                  <Text margin="x3">
                    {' '}
                    Please upload evidence that the job been completed, for
                    example a photo of the work or as proof of visit.{' '}
                  </Text>

                  <FileInput
                    accept="image/*"
                    color="blue"
                    level="secondary"
                    margin="x6"
                    multiple
                    onChange={handleSelectFiles}
                  >
                    <Upload size="1.25rem" />
                    <Text>Upload Evidence</Text>
                  </FileInput>

                  <FileList
                    files={files}
                    isValid={isFileValid}
                    margin="x3"
                    onRemoveFile={handleRemoveFile}
                  />
                </Box>

                <CheckBox
                  checked={!!jobReport.jobDoneToGuarantee}
                  margin="x2"
                  onChange={() =>
                    updateJobReport({
                      jobDoneToGuarantee: !jobReport.jobDoneToGuarantee,
                    })
                  }
                >
                  <Text display="inline" strong>
                    These works are guaranteed
                  </Text>{' '}
                  If not, un-tick section and give full detail above.
                </CheckBox>

                <CheckBox
                  checked={!!jobReport.jobRequiresFurtherWork}
                  margin="x2"
                  onChange={() =>
                    updateJobReport({
                      jobRequiresFurtherWork: !jobReport.jobRequiresFurtherWork,
                    })
                  }
                >
                  <Text display="inline" strong>
                    Reccomended further work
                  </Text>{' '}
                  - If so, tick section and please give full detail above
                </CheckBox>

                <Box borderSize="x1">
                  <Alert backgroundColor="red-light" textColor="red-dark">
                    <CheckBox
                      checked={!!jobReport.isNoFix}
                      margin="x2"
                      onChange={() =>
                        updateJobReport({
                          isNoFix: !jobReport.isNoFix,
                        })
                      }
                    >
                      <Text display="inline" strong>
                        No Fix - Unsuccessful Works, Beyond Our Control
                      </Text>
                    </CheckBox>
                  </Alert>
                </Box>

                <Box margin="x6">
                  <Text margin="x3">
                    Once all the above information has been input, please submit
                    the job report below.
                  </Text>

                  {error && <FormErrorMessage error={error} />}

                  <Button
                    color="blue"
                    disabled={isSubmitting}
                    level="primary"
                    margin="x3"
                    onClick={attemptSubmit}
                    size="large"
                  >
                    Submit Report
                  </Button>
                </Box>
              </Form>
            </Card>
            <Card backgroundColor="light" margin="x6" padding="x6">
              <Text margin="x3" size="x5" strong>
                Cancel job
              </Text>
              {cancellationError && (
                <FormErrorMessage error={cancellationError} />
              )}
              <Form>
                <Text margin="x3">
                  Please use this section to give reasons to why this job has
                  been cancelled. This will be read by our Happy Drains staff.
                </Text>
                <TextInput
                  multiline
                  onChange={(e) =>
                    updateJobReport({
                      privateNote: (e.target as HTMLInputElement).value,
                      publicNote: (e.target as HTMLInputElement).value,
                    })
                  }
                  required
                  rows={10}
                  value={jobReport.privateNote}
                />

                <Button
                  color="red"
                  disabled={isSubmitting}
                  level="primary"
                  margin="x3"
                  onClick={() => {
                    handleCancel();
                  }}
                  size="large"
                >
                  Cancel
                </Button>
              </Form>
            </Card>
            <ConfirmationModal
              message={"Are you sure there's no further work"}
              onCancel={() => setIsConfirmationVisible(false)}
              onConfirm={handleSubmit}
              visible={isConfirmationVisible}
              error={error}
            />
          </>
        )}
    </Box>
  );
}
