import * as React from 'react';
import { Box, BoxProps, Card, CardRow, Text } from '@hd/ui';
import FirebaseList from '../Firebase/FirebaseList';
import toDateFormat from '../../utils/toDateFormat';
import toEmailTypeFormat from '../../utils/toEmailTypeFormat';
import { Check, Envelope, X } from '@styled-icons/boxicons-regular';
import useFirestoreWebInquiryEmails from '../../utils/useFirestoreWebInquiryEmails';

interface Props extends BoxProps {
  webInquiryId: string;
}

export default function WebInqueryEmailHistory(props: Props) {
  const { webInquiryId, ...rest } = props;
  const data = useFirestoreWebInquiryEmails(webInquiryId, { realtime: true });

  return (
    <Box {...rest}>
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x2"
        margin="x3"
      >
        <Box>
          <Envelope size="1.5rem" />
        </Box>

        <Box grow>
          <Text strong>Emails</Text>
        </Box>
      </Box>

      <FirebaseList
        {...rest}
        {...data}
        backgroundColor="light"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Card>
          {data.ids.map((id, index) => (
            <CardRow
              alignChildrenVertical="middle"
              flex="horizontal"
              gap="x4"
              key={id}
              padding="x3"
            >
              {data.documents[id].sent ? (
                <Box textColor="green">
                  <Check size="2rem" />
                </Box>
              ) : (
                <Box textColor="red">
                  <X size="2rem" />
                </Box>
              )}

              <Box basis="none" grow>
                <Text>
                  <Text display="inline" strong>
                    {toEmailTypeFormat(data.documents[id].type)}
                  </Text>{' '}
                  ({toDateFormat(data.documents[id].timestamp, true)})
                </Text>
                <Text>{data.documents[id].recipient}</Text>

                {data.documents[id].error && (
                  <Text size="x2" textColor="red">
                    {data.documents[id].error}
                  </Text>
                )}
              </Box>
            </CardRow>
          ))}
        </Card>
      </FirebaseList>
    </Box>
  );
}
