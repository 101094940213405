import * as React from 'react';
import {
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Text,
  TextInput,
} from '@hd/ui';
import { Contractor } from '@hd/types';
import FormInputArray from '../FormInputs/FormInputArray';
import FormInputAddress from '../FormInputs/FormInputAddress';
import Dropdown from '../Dropdown/Dropdown';
import { contractorVatTypes } from '../../constants/vatTypes';

interface Props {
  document: Contractor;
  update: (updates: Partial<Contractor>) => void;
}

export default function ContractorForm(props: Props) {
  const { document, update } = props;

  return (
    <>
      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Company Name</FormInputLabel>
        </FormInputHeader>

        <TextInput
          data-at-id="CompanyName"
          onChange={(e) =>
            update({ name: (e.target as HTMLInputElement).value })
          }
          required
          value={document.name}
        />
      </FormInput>

      <FormInput>
        <FormInputHeader>
          <FormInputLabel>Sage Reference </FormInputLabel>
        </FormInputHeader>
        <TextInput
          data-at-id="SageReference"
          onChange={(e) =>
            update({
              sageReference: (e.target as HTMLInputElement).value.toUpperCase(),
            })
          }
          required
          value={document.sageReference}
        />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Contact Name</FormInputLabel>
        </FormInputHeader>

        <TextInput
          data-at-id="ContactName"
          onChange={(e) =>
            update({ contactName: (e.target as HTMLInputElement).value })
          }
          required
          value={document.contactName}
        />
      </FormInput>

      <FormInputAddress document={document} margin="x3" update={update} />

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Phone Number(s)</FormInputLabel>
        </FormInputHeader>

        <FormInputArray
          data-at-id="ContactPhoneNumbers"
          onChange={(contactPhoneNumbers) => update({ contactPhoneNumbers })}
          type="phone number"
          values={document.contactPhoneNumbers}
        />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Email Address(es)</FormInputLabel>
        </FormInputHeader>

        <FormInputArray
          data-at-id="ContactEmails"
          onChange={(contactEmails) => update({ contactEmails })}
          type="email address"
          values={document.contactEmails}
        />
      </FormInput>

      <FormInput basis="none" grow>
        <FormInputHeader>
          <FormInputLabel>VAT Reg</FormInputLabel>
        </FormInputHeader>

        <Dropdown
          onChange={(vatType) => update({ vatType })}
          options={contractorVatTypes}
          value={document.vatType}
        />
      </FormInput>

      <FormInput margin="x3">
        <FormInputHeader>
          <FormInputLabel>Coverage</FormInputLabel>
        </FormInputHeader>

        <FormInputArray
          data-at-id="Coverage"
          onChange={(coverage) =>
            update({ coverage: coverage.map((e) => e.toUpperCase()) })
          }
          type="coverage"
          values={document.coverage}
        />
      </FormInput>

      <CheckBox
        checked={!!document.preferred}
        data-at-id="PreferredContactor"
        margin="x4"
        onChange={() => update({ preferred: !document.preferred })}
      >
        <Text strong>This is a preferred contractor?</Text>
      </CheckBox>

      <CheckBox
        checked={!!document.canRaiseJobs}
        data-at-id="CanRaiseJobs"
        margin="x4"
        onChange={() => update({ canRaiseJobs: !document.canRaiseJobs })}
      >
        <Text strong>Can raise jobs</Text>
      </CheckBox>

      <FormInput basis="none" grow>
        <FormInputHeader>
          <FormInputLabel>Price tier</FormInputLabel>
        </FormInputHeader>

        <Dropdown
          onChange={(priceTier) => update({ priceTier })}
          options={['1', '2', '3']}
          value={document.priceTier}
        />
      </FormInput>
      <CheckBox
        checked={!!document.disabled}
        data-at-id="PreferredContactor"
        margin="x4"
        onChange={() => update({ disabled: !document.disabled })}
      >
        <Text strong>Disable contractor</Text>
      </CheckBox>
    </>
  );
}
