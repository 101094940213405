import * as React from 'react';
import {
  Badge,
  Box,
  Button,
  Card,
  CardRow,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
  Place,
  Text,
} from '@hd/ui';
import { WebInquiry } from '@hd/types';
import useFirestoreDocument from '../../utils/useFirestoreDocument';
import { Link, useHistory, useParams } from 'react-router-dom';
import WebInquiryForm from './WebInquiryForm';
import WebInqueryEmailHistory from './WebInquiryEmailHistory';
import { File, LinkAlt, LinkExternal } from '@styled-icons/boxicons-regular';
import useFirestoreWebInqiriesFiles from '../../utils/useFirestoreWebInqueryFiles';
import JobFilesList from '../Job/JobFilesList';
import FileListItem from '../FileList/FileListItem';

export default function WebInquiryPage() {
  const { webInquiryId } = useParams<{ webInquiryId: string }>();
  const { push } = useHistory();
  const [refMenuLink, setRefMenuLink] = React.useState<HTMLElement | null>(null);
  const [isLinkMenuVisible, setShowLinkMenu] = React.useState(false);

  const { document, hasFetched, update, save } =
    useFirestoreDocument<WebInquiry>(
      'WebInquiry',
      'webInquiries',
      webInquiryId,
      {}
    );

  const files = useFirestoreWebInqiriesFiles(webInquiryId)

  const handleRemove = async () => {
    update({
      status: 'DELETED',
    });
    await save();
    push('/web-inquiries');
  };

  const onComplete = async () => {

    update({
      status: 'CONVERTED',
    });
    await save();
  };
  console.log(document.toJob)

  return (
    <Page data-at-id="WebInquiryPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle flex="horizontal" gap="x3">
              <Text>Web inquiry</Text>
              {
                <Badge backgroundColor="blue-light">{document.status}</Badge>
              }
            </PageTitle>
          </Box>


              <Button
                  color="blue"
                  level="tertiary"
                  onClick={ () => setShowLinkMenu(true) }
                  ref={ setRefMenuLink }>
                <LinkAlt size="20px" />
                <Text>Links</Text>
              </Button>

              <Place
                  minWidth="reference"
                  onClose={ () => setShowLinkMenu(false) }
                  referenceElement={ refMenuLink }
                  visible={ isLinkMenuVisible }>
                <Card backgroundColor="light">
                  <Box maxHeight="200px" scrollable>
                    { document.toJob && (
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/jobs/${document.toJob}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">Created job</Text>
                      </CardRow>
                    ) }

                    { document.toQuote && (
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={ `/quotes/${document.toQuote}` }
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">Created quote</Text>
                      </CardRow>
                    ) }





          {
            document.status === 'AUTOMATIC' && 
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/cu/web-inquiry/${document.magicId}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">Customer's link</Text>
                      </CardRow>
          }




                  </Box>
                </Card>
              </Place>
















        </Box>
      </PageHeader>
      <PageBody>
        {document.status === 'AUTOMATIC' && (
          <Box margin="x3">
            <WebInqueryEmailHistory webInquiryId={document._id} />
          </Box>
        )}
        <CardRow margin="x3">
          <Text strong flex="horizontal" gap="x2" margin="x2">
            <File size="1.5rem" />
            Web form
          </Text>
          <Card>
            <Box flex="horizontal" padding="x4">
              <Box grow>
                <Box flex="horizontal" gap="x2">
                  <Text strong>Home or commericial </Text>
                  <Text> {document.homeOrComm} </Text>
                </Box>
                <Box flex="horizontal" gap="x2">
                  <Text strong>How can we assist </Text>
                  <Text>{document.howAssist}</Text>
                </Box>
                <Box flex="horizontal" gap="x2">
                  <Text strong>Site id </Text>
                  <Text>{document.itw3SiteId}</Text>
                </Box>
              </Box>
              <Box grow>
                <Box flex="horizontal" gap="x2">
                  <Text strong>Preferred day</Text>
                  <Text>{document.prefDay}</Text>
                </Box>
                <Box flex="horizontal" gap="x2">
                  <Text strong>Preferred time </Text>
                  <Text>{document.prefTime}</Text>
                </Box>
              </Box>
            </Box>
        {
          files.ids.length > 0 && 

          
              files.ids.map((id) => ( <CardRow key={id} padding="x3"> <FileListItem file={files.documents[id]} /> </CardRow>))
          
          }
          </Card>
        </CardRow>
        {hasFetched && (
          <WebInquiryForm
            webInquiry={document}
            onRemove={handleRemove}
            onComplete={onComplete}
          />
        )}

      </PageBody>
    </Page>
  );
}
