import * as React from 'react';
import { Box, BoxProps, Card, CardRow, FileInput, Link, Text } from '@hd/ui';
import { FileBlank } from '@styled-icons/boxicons-regular';
import firebase from 'firebase/app';
import { File as FileIcon } from '@styled-icons/boxicons-regular';
import FirebaseList from '../Firebase/FirebaseList';
import ConfirmationModal from '../ConfirmationModal';
import useFirestoreJobFiles from '../../utils/useFirestoreJobFiles';
import FileListItem from '../FileList/FileListItem';
import toFileSizeFormat from '../../utils/toFileSizeFormat';

interface Props extends BoxProps {
  jobId: string;
}

const filters = [[ 'isPublic', '==', true]] 

export default function JobFilesList(props: Props) {
  const { jobId, ...rest } = props;
  const data = useFirestoreJobFiles(jobId, { realtime: true, filters });

  if(jobId === undefined || jobId === '') {
    return null;
  }

  return (
    <Box {...rest}>
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x2"
        margin="x3"
      >
        <Box>
          <FileBlank size="1.5rem" />
        </Box>

        <Box grow>
          <Text strong>Files</Text>
        </Box>

      </Box>

      <FirebaseList
        {...rest}
        {...data}
        backgroundColor="light"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Card>
          {data.ids.map((id) => 
            <CardRow key={id} padding="x3">
              <Box alignChildrenVertical="middle" flex="horizontal" gap="x3">
                <Box textColor="gray"> <FileIcon size="1.25rem" /> </Box>

                <Text basis="none" flex="horizontal" gap="x2" grow size="x2">
                      <Link onClick={() => window.open(data.documents[id].downloadUrl, data.documents[id].name)}>
                  <Text basis="none" grow strong>
                    <Text display="inline-block" strong>
                      {data.documents[id].name}
                    </Text>{' '}
                    ({toFileSizeFormat(data.documents[id].size)} )
                  </Text>
                  <Text>
                  </Text>
                      </Link>
                </Text>
              </Box>
            </CardRow>
          )}
        </Card>
      </FirebaseList>

    </Box>
  );
}
