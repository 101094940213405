import { useEffect, useState } from 'react';
import firebase from 'firebase/app';

export default function useIsUserSignedIn() {
  const [data, setIsSignedIn] = useState({
    isSignedIn: !!firebase.auth().currentUser,
    isSigningIn: true,
  });

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      setIsSignedIn({
        isSignedIn: !!user,
        isSigningIn: false,
      });
    });
  }, []);

  return data;
}
