import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Page, PageBody, PageHeader, PageTitle } from '@hd/ui';
import JobTypeModal from './JobTypeModal';
import JobTypesList from './JobTypesList';

export default function AdminJobTypes() {
  const { push } = useHistory();
  const [createModalVisible, setCreateModalVisible] = React.useState(false);

  return (
    <Page data-at-id="JobsPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Admin job page</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <JobTypesList
          isSearchable={true}
          onSelect={(jobType) => push(`/admin/job-types/${jobType._id}`)}
          options={{ resolve: false }}
        />

        <JobTypeModal
          visible={createModalVisible}
          onClose={() => setCreateModalVisible(false)}
        />
      </PageBody>
    </Page>
  );
}
