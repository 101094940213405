import * as React from 'react';
import {
  Box,
  Button,
  Page,
  PageBack,
  PageBody,
  PageHeader,
  PageTitle,
} from '@hd/ui';
import { useHistory, useParams } from 'react-router-dom';
import { NotificationsContext } from '../Notifications/Notifications';
import useFirestoreNotesAndQuotes from '../../utils/useFirestoreNotesAndQuotes';
import AdminNoteOrQuoteForm from './AdminNoteOrQuoteForm';

export default function AdminProduct() {
  const { push } = useHistory();
  const { addNotification } = React.useContext(NotificationsContext);
  const { noteOrQuoteId } = useParams<{ noteOrQuoteId: string }>();

  const {
    save,
    document,
    update: updateDocument,
  } = useFirestoreNotesAndQuotes(noteOrQuoteId);

  const update = async () => {
    await save();
    addNotification('updated');
    push('/admin/notes-and-quotes');
  };

  return (
    <Page data-at-id="AdminProductPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageBack to="/admin/products">Products</PageBack>
            <Box flex="horizontal" alignChildrenHorizontal="between">
              <PageTitle>{document.name}</PageTitle>
              <Button level="primary" color="blue" onClick={update}>
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <AdminNoteOrQuoteForm document={document} update={updateDocument} />
      </PageBody>
    </Page>
  );
}
