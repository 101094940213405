import { TypeJobStatus } from '@hd/types';

export default function toStatusFormat(status: TypeJobStatus) {
  return ({
    AUTHORISE_CANCELLATION: 'Contractor Cancelled',
    AWAITING_AUTHORISATION: 'Awaiting authorisation',
    AWAITING_AUTHORISATION_CCTV: 'Awaiting Authorisation',
    AWAITING_AUTHORISATION_OTHER: 'Awaiting Authorisation',
    AWAITING_SURVEY: 'Awaiting Survey',
    UNALLOCATED: 'Unallocated',
    IN_PROGRESS: 'In Progress',
    CANCELLED: 'Cancelled',
    COMPLETED: 'Completed',
    TO_CHASE_COMMERCIAL: '🚨 Requires Chasing 🚨',
    TO_CHASE_DOMESTIC: '🚨 Requires Chasing 🚨',
    PAID: '🎉 Paid 🎉',
    OVERDUE_CCTV: 'Overdue CCTV',
    OVERDUE_REPAIR: 'Overdue Repair',
    WRITTEN_OFF: 'Written off',
    PARTIALLY_WRITTEN_OFF: 'Partially written off',
    NO_FIX: 'No fix',
    OVERDUE_UNBLOCK: 'Overdue Unblock',
    UNRESOLVED_COMPLAINTS: 'Unresolved Complaints',
    POTENTIAL_FURTHER_WORK: 'Potential Further Work 🤔',
    RECALL: 'Recalled',
    CONTRACTOR_RAISED: 'Contractor raised',
  } as Record<TypeJobStatus, string>)[status] ;
}
