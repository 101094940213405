import * as React from 'react';
import {
  Badge,
  Box,
  Card,
  CardRow,
  FormInput,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
  Text,
  TextInput,
} from '@hd/ui';
import useFirestoreCollection, {
  TypeFilter,
  TypeOrderBy,
} from '../../utils/useFirestoreCollection';
import { TypeInquiryStatus, TypeJobStatus, WebInquiry } from '@hd/types';
import { Car, MapPin, User } from '@styled-icons/boxicons-regular';
import { useHistory } from 'react-router-dom';
import useKeywordFilter from '../../utils/useKeywordFilter';
import useMergedFilters from '../../utils/useMergedFilters';
import useQueryParams from '../../utils/useQueryParams';
import FirebaseList from '../Firebase/FirebaseList';
import { DateTime } from 'luxon'

const statusTitleMap: Record<TypeInquiryStatus, string> = {
  MANUAL: 'Web inquiries to work',
  AUTOMATIC: 'Unaccepted instant quotes',
  CONVERTED: 'Converted',
};

const orderBys: TypeOrderBy<WebInquiry>[] = [['createdAt', 'desc']]

const groupByDate = (ids: string[], jobs: Record<string, WebInquiry>) => {
  const groupedIds: Record<string, string[]> = {};

  for (const id of ids) {
    const job = jobs[id];

    if (job) {
      const { createdAt } = job;
      const key = DateTime.fromJSDate(createdAt!.toDate()).toFormat('DDD');

      groupedIds[key] = groupedIds[key] || [];
      groupedIds[key].push(id);
    }
  }

  return groupedIds;
};

export default function WebInquiries() {
  const { push } = useHistory();
  const [value, setValue] = React.useState('');
  const keywordFilter = useKeywordFilter(value);
  const queryParams = useQueryParams();
  const statusQueryParam = queryParams.get(
    'status'
  ) as null | TypeInquiryStatus;
  const statusFilter = React.useMemo(
    () =>
      (statusQueryParam && [['status', '==', statusQueryParam]]) ||
      ([] as TypeFilter[]),
    [statusQueryParam]
  );
  const filters = useMergedFilters(keywordFilter, statusFilter);
  const data = useFirestoreCollection<WebInquiry>(
    'WebInquiry',
    'webInquiries',
    {
      limit: 50,
      filters: filters,
      orderBys: orderBys
    }
  );
  const groupedIds = groupByDate(data.ids, data.documents);

  return (
    <Page data-at-id="WebInquiriesPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle flex="horizontal" gap="x3">
              <Text>{statusTitleMap[statusQueryParam!]}</Text>
            </PageTitle>
          </Box>
        </Box>
        <FormInput margin="x3">
          <TextInput
            onChange={(e) => setValue((e.target as HTMLInputElement).value)}
            placeholder="Search web inquiry"
            value={value}
          />
        </FormInput>
      </PageHeader>

      <PageBody>
        <FirebaseList {...data} onMore={data.more}>
          {Object.entries(groupedIds).map(([date, ids]) => (
            <Box key={date} margin="x10">
              <Box alignChildren="middle" flex="horizontal" gap="x4" margin="x2">
                <Text size="x3" strong>
                  {date}
                </Text>

                <Box grow>
                  <Text borderColor="gray" borderSides="T" borderSize="x1" />
                </Box>
              </Box>

              <Card>
                {ids.map((id) => (
                  <CardRow
                    key={id}
                    onClick={() => push(`/web-inquiries/${id}`)}
                    padding="x4"
                  >
                    <Box alignChildrenVertical="middle" flex="horizontal" gap="x10">
                      <Box basis="none" grow>
                        <Box
                          alignChildrenVertical="middle"
                          flex="horizontal"
                          gap="x2"
                        >
                          <Badge backgroundColor="dark-gray" textColor="light-gray">
                            {data.documents[id].email}
                          </Badge>

                          <Badge backgroundColor="light-gray" flex="horizontal">
                            <MapPin size="1rem" />
                            <Text>{data.documents[id].postcode}</Text>
                          </Badge>
                          <Badge backgroundColor="light-gray" flex="horizontal">
                            <User size="1rem" />
                            <Text>
                              {data.documents[id].firstname}{' '}
                              {data.documents[id].surname}
                            </Text>
                          </Badge>
                        </Box>

                        <Box margin="x3">
                          <Text ellipsis size="x2">
                            {data.documents[id].postcode}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </CardRow>
                ))}
                </Card>
                </Box>
          ))}
        </FirebaseList>
      </PageBody>
    </Page>
  )
}
