import * as React from 'react';
import { Address } from '@hd/types';
import { Box, FormInput, FormInputHeader, FormInputLabel, TextInput, BoxProps } from '@hd/ui';

interface Props<T extends Address> extends BoxProps {
  document: T;
  update: (document: Partial<Address>) => void;
}

export default function FormInputAddress<T extends Address>(props: Props<T>) {
  const { document, update, ...rest } = props;

  const updateValue = (key: keyof Address, value: undefined | string) => {
    if (value !== undefined) {
      update({ [key]: value });
    }
  };

  return (
    <Box { ...rest }>
      <FormInput margin="x2">
        <FormInputHeader>
          <FormInputLabel>Address</FormInputLabel>
        </FormInputHeader>

        <TextInput
            data-at-id="AddressLine1"
            onChange={ (e) => updateValue('addressLine1', (e.target as HTMLInputElement).value) }
            placeholder="Address line 1 "
            required
            value={ document.addressLine1 } />
      </FormInput>

      <FormInput margin="x2">
        <TextInput
            data-at-id="AddressLine2"
            onChange={ (e) => updateValue('addressLine2', (e.target as HTMLInputElement).value) }
            placeholder="Address line 2"
            required
            value={ document.addressLine2 } />
      </FormInput>

      <FormInput margin="x2">
        <TextInput
            onChange={ (e) => updateValue('addressLine3', (e.target as HTMLInputElement).value) }
            placeholder="Address line 3 (Optional)"
            value={ document.addressLine3 } />
      </FormInput>

      <FormInput margin="x2">
        <TextInput
            data-at-id="Postcode"
            onChange={ (e) => updateValue('addressPostcode', (e.target as HTMLInputElement).value.toUpperCase()) }
            placeholder="Postcode"
            required
            value={ document.addressPostcode } />
      </FormInput>
    </Box>
  );
}
