import * as React from 'react';
import { Appear, Box, Text } from '@hd/ui';
import { CheckCircle } from '@styled-icons/boxicons-regular';

export const NotificationsContext = React.createContext<{
  addNotification: (text: string) => void;
}>({
  addNotification: () => {},
});

export default function Notifications({ children }: React.PropsWithChildren<{}>) {
  const [notification, setNotification] = React.useState('');
  const refTimeout = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    if (notification) {
      refTimeout.current = setTimeout(() => {
        setNotification('');
      }, 3000);
    }

    return () => {
      if (refTimeout.current) {
        window.clearTimeout(refTimeout.current);
      }
    };
  }, [notification]);

  return (
    <NotificationsContext.Provider value={ { addNotification: setNotification } }>
      {
        !!notification && 
      <Appear
          absolute="bottom-right"
          animation="FadeSlideLeft"
          elevation="x2"
          style={ { right: 24, bottom: 24, position: 'fixed' } }
          visible={ !!notification }>
        <Box
            alignChildrenVertical="middle"
            backgroundColor="dark"
            borderRadius="x2"
            flex="horizontal"
            gap="x2"
            paddingHorizontal="x6"
            paddingVertical="x3"
            textColor="light"
            width="20rem">
          <Box textColor="green">
            <CheckCircle size="1.5rem" />
          </Box>

          <Box basis="none" grow>
            <Text size="x2" strong>{ notification }</Text>
          </Box>
        </Box>
      </Appear>

      }

      { children }
    </NotificationsContext.Provider>
  );
}
