import * as React from 'react';
import {
  Badge,
  Box,
  Button,
  Card, CardRow, CheckBox, Modal, Text,
} from '@hd/ui';
import { CCTVReccomendation, Job, Repair, SiteDetails } from '@hd/types';
import { UseType, PipeSizeTypes, PipeMaterialTypes, LegalOwnershipTypes, SiteTypeTypes, DischangeIntoTankTypes, ConnectedToMainsTypes, SiteStateTypes, AccessTypes } from '../../constants/CCTVReccommendationContants';
import { Edit, Plus } from '@styled-icons/boxicons-regular';
import { validateSiteDetails } from '../../utils/formValidators';

type Props = {
  document: CCTVReccomendation;
  update: (c: Partial<CCTVReccomendation>) => void;
  save: () => void;
  readOnly?: boolean;
}

export default function CCTVRecommendationsSiteStateForm(props: Props) {

  const { update, document, save, readOnly = false } = props;

  const [siteDetailsModalVisible, setSiteDetailsModalVisible] = React.useState(false)

  const handleSetAttribute = <T extends keyof SiteDetails>(attribute: T) =>
  (value?: SiteDetails[T]) => {
    update({
      details: {
        ...document.details,
        [attribute]: value
      }
    })
  };

  return (
    <>
    {
      validateSiteDetails(document.details) === null ?
        <Card backgroundColor="light" margin="x2" padding="x6">
          <Box flex='horizontal' alignChildrenHorizontal='between' alignChildrenVertical='start'>
            <Text size="x4" strong>Site state</Text>
            {
              !readOnly && <Button level='tertiary' color='blue' onClick={() => setSiteDetailsModalVisible(true)} size='small'>
              <Edit size='1rem'/>
              <Text size='x1'> Edit </Text>
            </Button>
            }
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>Access</Text>
            <Text size='x2'> {document.details.access} </Text>
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>Use</Text>
            <Text size='x2'> {document.details.use} </Text>
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>Discharge into tank</Text>
            <Text size='x2'> {document.details.dischargeIntoTank} </Text>
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>Pipe size</Text>
            <Text size='x2'> {document.details.pipeSize} </Text>
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>Pipe material</Text>
            <Text size='x2'> {document.details.pipeMaterial} </Text>
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>State</Text>
            <Text size='x2'> {document.details.state} </Text>
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>Legal ownership</Text>
            <Text size='x2'> {document.details.legalOwnership} </Text>
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>Foul system connected to mains</Text>
            <Text size='x2'> {document.details.foulSystemConnectedToMains} </Text>
          </Box>
          <Box flex='horizontal' gap='x1'>
            <Text strong size='x2'>Site type</Text>
            <Text size='x2'> {document.details.siteType} </Text>
          </Box>
        </Card> : <Card backgroundColor="light" margin="x2" padding="x6">
          <Box flex='horizontal' alignChildrenHorizontal='between' alignChildrenVertical='start'>
            <Text size="x4" strong>Site state</Text>
            <Button level='tertiary' color='blue' onClick={() => setSiteDetailsModalVisible(true)} size='small'>
              <Plus size='1rem'/>
              <Text size='x1'> Add </Text>
            </Button>
          </Box>
          <Badge backgroundColor='red-light'>Incomplete</Badge>
        </Card>
    }

      <Modal visible={siteDetailsModalVisible}>
        <Card backgroundColor="light" margin="x4" padding="x6" gap='x3' flex='vertical'>
          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>Access</Text>
            <Box flex='vertical' gap='x3'>
              { AccessTypes.map(e => <CheckBox key={e} checked={document.details.access === e} onChange={() => handleSetAttribute('access')(e)}>{e}</CheckBox>) }
            </Box>
          </CardRow>

          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>Use</Text>
            <Box flex='vertical' gap='x3'>
              {UseType.map(e => <CheckBox key={e} checked={document.details.use === e} onChange={() => handleSetAttribute('use')(e)}>{e}</CheckBox>)}
            </Box>
          </CardRow>

          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>Discharge into tank</Text>
            <Box flex='vertical' gap='x2'>
              {DischangeIntoTankTypes.map(e => <CheckBox key={e} checked={document.details.dischargeIntoTank === e} onChange={() => handleSetAttribute('dischargeIntoTank')(e)}>{e}</CheckBox>)}
            </Box>
          </CardRow>


          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>Pipe size</Text>
            <Box flex='vertical' gap='x2'>
              {PipeSizeTypes.map(e => <CheckBox key={e} checked={document.details.pipeSize === e} onChange={() => handleSetAttribute('pipeSize')(e)}>{e}</CheckBox>)}
            </Box>
          </CardRow>

          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>Pipe material</Text>
            <Box flex='vertical' gap='x2'>
              {PipeMaterialTypes.map(e => <CheckBox key={e} checked={document.details.pipeMaterial === e} onChange={() => handleSetAttribute('pipeMaterial')(e)}>{e}</CheckBox>)}
            </Box>
          </CardRow>

          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>State</Text>
            <Box flex='vertical' gap='x2'>
              {SiteStateTypes.map(e => <CheckBox key={e} checked={document.details.state === e} onChange={() => handleSetAttribute('state')(e)}>{e}</CheckBox>)}
            </Box>
          </CardRow>

          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>Legal ownership</Text>
            <Box flex='vertical' gap='x2'>
              {LegalOwnershipTypes.map(e => <CheckBox key={e} checked={document.details.legalOwnership === e} onChange={() => handleSetAttribute('legalOwnership')(e)}>{e}</CheckBox>)}
            </Box>
          </CardRow>

          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>Foul system connected to mains</Text>
            <Box flex='vertical' gap='x2'>
              {ConnectedToMainsTypes.map(e => <CheckBox key={e} checked={document.details.foulSystemConnectedToMains === e} onChange={() => handleSetAttribute('foulSystemConnectedToMains')(e)}>{e}</CheckBox>)}
            </Box>
          </CardRow>

          <CardRow padding='x3'>
            <Text strong size='x4' padding='x3'>Site type</Text>
            <Box flex='vertical' gap='x2'>
              {SiteTypeTypes.map(e => <CheckBox key={e} checked={document.details.siteType === e} onChange={() => handleSetAttribute('siteType')(e)}>{e}</CheckBox>)}
            </Box>
          </CardRow>

          <Box flex='horizontal' alignChildrenHorizontal='middle' padding='x4'>
            <Button color='blue' level='primary' onClick={() => {
              save()
              setSiteDetailsModalVisible(false)
            }}>Save & close</Button>
          </Box>
        </Card>

      </Modal>

    </>

  );

}