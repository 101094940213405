import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormInput,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
  Text,
  TextInput,
} from '@hd/ui';
import { Plus } from '@styled-icons/boxicons-regular';
import QuotesList from './QuotesList';
import useKeywordFilter from '../../utils/useKeywordFilter';
import { TypeFilter } from '../../utils/useFirestoreCollection';
import { TypeQuoteStatus } from '@hd/types';
import useQueryParams from '../../utils/useQueryParams';
import useMergedFilters from '../../utils/useMergedFilters';

const statusParamFilterMap = (status: TypeQuoteStatus) =>
  ((
    {
      AWAITING: [['status', 'in', ['AWAITING', 'FOLLOW_UP_UNACCEPTED']]],
      FOLLOW_UP_ACCEPTED_UNPAID: [
        ['status', '==', 'FOLLOW_UP_ACCEPTED_UNPAID'],
      ],
    } as Record<TypeQuoteStatus, TypeFilter[]>
  )[status]);

const statusTitleMap: Record<TypeQuoteStatus, string> = {
  AWAITING: 'Awaiting',
  FOLLOW_UP_ACCEPTED_UNPAID: 'Accepted, parent unpaid',
  ACCEPTED: '',
  CONVERTED: '',
  PAID: '',
  FOLLOW_UP_UNACCEPTED: '',
  CANCELLED: '',
};

export default function Quotes() {
  const { push } = useHistory();
  const [value, setValue] = React.useState('');

  const queryParams = useQueryParams();
  const statusQueryParam = queryParams.get('status') as null | TypeQuoteStatus;
  const keywordFilter = useKeywordFilter(value);
  const statusFilter = React.useMemo(
    () =>
      (statusQueryParam && statusParamFilterMap(statusQueryParam)) ||
      ([] as TypeFilter[]),
    [statusQueryParam]
  );

  const filters = useMergedFilters(keywordFilter, statusFilter);

  return (
    <Page data-at-id="QuotesPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>
              {statusQueryParam
                ? statusTitleMap[statusQueryParam]
                : 'All quotes'}
            </PageTitle>
          </Box>

          <Box>
            <Button
              color="blue"
              data-at-id="AddJobButton"
              level="tertiary"
              onClick={() => push('/quote')}
            >
              <Plus size="1.5rem" />
              <Text>Add Quote</Text>
            </Button>
          </Box>
        </Box>

        <FormInput margin="x3">
          <TextInput
            onChange={(e) => setValue((e.target as HTMLInputElement).value)}
            placeholder="Search jobs"
            value={value}
          />
        </FormInput>
      </PageHeader>

      <PageBody>
        <QuotesList options={{ filters }} />
      </PageBody>
    </Page>
  );
}
