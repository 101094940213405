import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Appear, Box, Button, Card, Line, Link, Page, PageBack, PageBody, PageDescription, PageHeader, PageTitle, Text } from '@hd/ui';
import { ArrowFromLeft, Save, Trash, Unlink } from '@styled-icons/boxicons-regular';
import getAddress from '../../utils/getAddress';
import useFirestoreCustomerSite from '../../utils/useFirestoreCustomerSite';
import { validateSite, FormError } from '../../utils/formValidators';
import SiteForm from './SiteForm';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FormErrorMessage from '../FormErrorMessage';
import ConfirmationModal from '../ConfirmationModal';
import CustomerView from '../Customers/CustomerView';
import SiteSelectionModalCustomer from './SiteCustomerSelectionModal';
import { Customer } from '@hd/types';
import useQueryParams from '../../utils/useQueryParams';

export default function Site() {
  const { push } = useHistory();
  const { siteId } = useParams<{ siteId: string }>();
  const data = useFirestoreCustomerSite(siteId);
  const [isSelectCustomerModalVisible, setIsSelectCustomerModalVisible] = React.useState(false);
  const [isRemoveSiteModalVisible, setIsRemoveSiteModalVisible] = React.useState(false);
  const [error, setError] = React.useState<FormError | null>(null);

  const handleUnlinkCustomer = async() => {
    data.update({ customer: null });
    await data.save();
  };

  const handleSetCustomer = async(customer: Customer) => {
    data.update({ customer });
    await data.save();
    setIsSelectCustomerModalVisible(false);
  };

  const handleRemoveSite = async() => {
    await data.remove();
    push('/sites');
  };
  const queryParams = useQueryParams();
  const redirectUrl = queryParams.get('redirect') as null | string;

  const handleSubmit = async() => {
    const error = validateSite(data.document);
    setError(error);

    if (!error) {
      await data.save();
    }
    if (redirectUrl) {
      push('/jobs/' + redirectUrl);
    } else {
      push('/sites');
    }
  };

  return (
    <FirebaseDocument { ...data }>
      <Page>
        <PageHeader>
          <Box alignChildrenVertical="end" flex="horizontal">
            <Box grow>
              <PageBack to="/sites">Sites</PageBack>
              <PageTitle>{ data.document.name }</PageTitle>
              <PageDescription>{ getAddress(data.document) }</PageDescription>
            </Box>

            <Box flex="horizontal" gap="x2">
              <Button
                  color="blue"
                  level="tertiary"
                  onClick={ handleSubmit }
                  type="submit">
                <Save size="1.25rem" />
                <Text>Save</Text>
              </Button>

              <Button
                  color="red"
                  disabled
                  level="tertiary"
                  onClick={ () => setIsRemoveSiteModalVisible(true) }>
                <Trash size="1.25rem" />
                <Text>Remove (Disabled)</Text>
              </Button>
            </Box>
          </Box>
        </PageHeader>

        <PageBody>
          { error && (
            <FormErrorMessage error={ error } />
          ) }

          { !data.document.customer && (
            <Appear animation="FadeSlideDown" delay={ 250 } margin="x8">
              <Alert>
                This site is not linked to a customer. <Link onClick={ () => setIsSelectCustomerModalVisible(true) } underline>Click here</Link> to link to a customer.
              </Alert>
            </Appear>
          ) }

          <Box flex="horizontal" gap="x8">
            <Box basis="none" grow>
              <Card backgroundColor="light" margin="x6" padding="x10">
                { data.document.customer && (
                  <>
                    <Text size="x4">Site</Text>
                    <Line borderColor="light-gray" margin="x4" />
                  </>
                ) }

                <SiteForm
                    document={ data.document }
                    update={ data.update } />
              </Card>
            </Box>

            { data.document.customer && (
              <Box basis="none" grow>
                <Card backgroundColor="light" margin="x6" padding="x10">
                  <Box flex="horizontal">
                    <Box basis="none" grow>
                      <Text size="x4">Customer</Text>
                    </Box>

                    <Box flex="horizontal" gap="x4">
                      <Box>
                        <Link
                            alignChildrenVertical="middle"
                            flex="horizontal"
                            gap="x2"
                            onClick={ handleUnlinkCustomer }
                            textColor="red">
                          <Text>Unlink</Text>
                          <Unlink size="1.25rem" />
                        </Link>
                      </Box>

                      <Box>
                        <Link
                            alignChildrenVertical="middle"
                            flex="horizontal"
                            gap="x2"
                            onClick={ () => push(`/customers/${data.document.customer?._id}`) }>
                          <Text>View</Text>
                          <ArrowFromLeft size="1.25rem" />
                        </Link>
                      </Box>
                    </Box>
                  </Box>

                  <Line borderColor="light-gray" margin="x4" />

                  <CustomerView customer={ data.document.customer } />
                </Card>
              </Box>
            ) }
          </Box>

        </PageBody>
      </Page>

      <ConfirmationModal
          message={ `Are you sure you want to remove the site "${data.document.name}"?` }
          onCancel={ () => setIsRemoveSiteModalVisible(false) }
          onConfirm={ handleRemoveSite }
          visible={ isRemoveSiteModalVisible } />

      <SiteSelectionModalCustomer
          onClose={ () => setIsSelectCustomerModalVisible(false) }
          onSelect={ handleSetCustomer }
          site={ data.document }
          visible={ isSelectCustomerModalVisible } />
    </FirebaseDocument>
  );
}
