import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Box, Card, CardRow, Text } from '@hd/ui';
import { Job, TypeJobStatus } from '@hd/types';
import { DateTime } from 'luxon';
import FirebaseList from '../Firebase/FirebaseList';
import { Options, TypeOrderBy } from '../../utils/useFirestoreCollection';
import useFirestoreJobs from '../../utils/useFirestoreJobs';
import toDateFormat from '../../utils/toDateFormat';
import useQueryParams from '../../utils/useQueryParams';
import StatusBadge from '../StatusBadge/StatusBadge';
import { IdCard, MapPin, User } from '@styled-icons/boxicons-regular';


interface Props {
  options?: Options<Job>;
  jobParam?: string;
}

const defaultOrderBys: TypeOrderBy<Job>[] = [['date', 'desc']];

const defaultOrderBysByStatus: Partial<
  Record<TypeJobStatus, TypeOrderBy<Job>[]>
> = {
  AWAITING_AUTHORISATION_OTHER: [['jobType', 'desc']],
  TO_CHASE_DOMESTIC: [['invoiceCreated', 'desc']],
  TO_CHASE_COMMERCIAL: [['invoiceCreated', 'desc']],
  UNRESOLVED_COMPLAINTS: [['complaints', 'desc']],
};

const groupByDate = (ids: string[], jobs: Record<string, Job>) => {
  const groupedIds: Record<string, string[]> = {};

  for (const id of ids) {
    const job = jobs[id];

    if (job) {
      const { date } = job;
      const key = DateTime.fromJSDate(date.toDate()).toFormat('DDD');

      groupedIds[key] = groupedIds[key] || [];
      groupedIds[key].push(id);
    }
  }

  return groupedIds;
};

const groupByInvoiceDate = (ids: string[], jobs: Record<string, Job>) => {
  const groupedIds: Record<string, string[]> = {};

  for (const id of ids) {
    const job = jobs[id];

    if (job) {
      const { invoiceCreated } = job;
      if (invoiceCreated) {
        const key = DateTime.fromJSDate(invoiceCreated.toDate()).toFormat(
          'DDD'
        );

        groupedIds[key] = groupedIds[key] || [];
        groupedIds[key].push(id);
      }
    }
  }

  return groupedIds;
};

export default function JobsList(props: Props) {
  const { options, jobParam } = props;
  const queryParams = useQueryParams();
  const statusQueryParam = queryParams.get('status') as TypeJobStatus;
  const [orderBys, setOrderBys] = React.useState<TypeOrderBy<Job>[]>(
    defaultOrderBysByStatus[statusQueryParam] || defaultOrderBys
  );
  const isToChaseSort =
    statusQueryParam === 'TO_CHASE_COMMERCIAL' ||
    statusQueryParam === 'TO_CHASE_DOMESTIC';
  const { push } = useHistory();
  const data = useFirestoreJobs({
    ...options,
    limit: 25,
    orderBys: orderBys,
    resolve: false,
  });
  console.log(data)
  console.log(options)

  React.useEffect(() => {
    setOrderBys(defaultOrderBysByStatus[statusQueryParam] || defaultOrderBys);
  }, [statusQueryParam]);

  const groupedIds = isToChaseSort
    ? groupByInvoiceDate(data.ids, data.documents)
    : groupByDate(data.ids, data.documents);


  return (
    <FirebaseList {...data} onMore={data.more}>
      {Object.entries(groupedIds).map(([date, ids]) => (
        <Box key={date} margin="x10">
          <Box alignChildren="middle" flex="horizontal" gap="x4" margin="x2">
            <Text size="x3" strong>
              {date} {isToChaseSort && ' - invoice raised'}
            </Text>

            <Box grow>
              <Text borderColor="gray" borderSides="T" borderSize="x1" />
            </Box>
          </Box>

          <Card>
            {ids.map((id) => (
              <CardRow
                key={id}
                onClick={() => push(`/jobs/${id}${jobParam || ''}`)}
                padding="x4"
              >
                <Box alignChildrenVertical="middle" flex="horizontal" gap="x10">
                  <Box basis="none" grow>
                    <Box
                      alignChildrenVertical="middle"
                      flex="horizontal"
                      gap="x2"
                    >
                      <Badge backgroundColor="dark-gray" textColor="light-gray">
                        {data.documents[id].jobNumber}
                      </Badge>

                      <Badge backgroundColor="light-gray" flex="horizontal">
                        <MapPin size="1rem" />
                        <Text>{data.documents[id].sitePostcode}</Text>
                      </Badge>

                      <Badge backgroundColor="light-gray" flex="horizontal">
                        <User size="1rem" />
                        <Text>{data.documents[id].customerName}</Text>
                      </Badge>

                      <Badge
                        backgroundColor={
                          data.documents[id].contractorName
                            ? 'light-gray'
                            : 'yellow-light'
                        }
                        flex="horizontal"
                        textColor={
                          data.documents[id].contractorName
                            ? undefined
                            : 'yellow-dark'
                        }
                      >
                        <IdCard size="1rem" />
                        <Text>
                          {data.documents[id].contractorName ||
                            'Requires Contractor'}
                        </Text>
                      </Badge>
                    </Box>

                    <Box margin="x3">
                      <Text ellipsis size="x2">
                        {data.documents[id].description}
                      </Text>
                    </Box>
                  </Box>

                  <Box></Box>

                  <Box alignChildrenHorizontal="end" flex="vertical" gap="x2">
                    <Text align="end" size="x2" strong>
                      {toDateFormat(data.documents[id].date)}
                    </Text>
                    <StatusBadge job={data.documents[id]} />
                  </Box>
                </Box>
              </CardRow>
            ))}
          </Card>
        </Box>
      ))}
    </FirebaseList>
  );
}
