import { CCTVRecommendationProduct } from '@hd/types';
import {
  Box,
  Button,
  Card,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Modal,
  Text,
  TextInput,
} from '@hd/ui';
import * as React from 'react';
import ProductSelectionBox from './ProductSelectionBox';
import ProductSelectionViewCategory from './ProductSelectionViewCategory';
import ProductSelectionModalCategories from './ProductSelectionModalCategories';
import { UnitTypes } from '../../constants/CCTVReccommendationContants';
import ProductPriceInput from './ProductPriceInput';
import ProductAddOnList from './ProductAddOnList';
import { Plus, X } from '@styled-icons/boxicons-regular';
import ProductUnitTypeCheckBox from './ProductUnitTypeCheckBox';

interface Props {
  product: CCTVRecommendationProduct;
  update: (updates: Partial<CCTVRecommendationProduct>) => void;
}

export default function ProductForm(props: Props) {
  const { product, update } = props;
  const [addOnModalVisible, setAddOnModalVisible] = React.useState(false)

  return (<>
    <Box flex="horizontal" margin="x8">
      <Box basis="none" flex="vertical" grow>
        <Text margin="x3" strong>
          Product
        </Text>
      </Box>
    </Box>
      <ProductSelectionBox
        SelectionModal={ProductSelectionModalCategories}
        SelectionView={ProductSelectionViewCategory}
        data-at-id="AddCategory"
        onSelect={e => update({
          parentId: e.id,
          cachedGrandParentId: e.parentId,
          cachedGrandparentName: e.cachedParentName,
          cachedParentName: e.name
        })}
        product={product}
      />

    <Card backgroundColor="light" margin="x4" padding="x6">
      <Box flex="horizontal" gap="x6" margin="x6">
        <FormInput grow>
          <FormInputHeader>
            <FormInputLabel>Name</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="ProductName"
            onChange={(e) => update({
              name: (e.target as HTMLInputElement).value
            })}
            value={product.name}
          />
        </FormInput>
      </Box>

      <Box flex='horizontal' grow gap='x4'>
          <Box flex="horizontal" grow alignChildrenHorizontal='middle' borderSize='x1' alignChildrenVertical='middle' padding='x4' borderColor='gray' borderRadius='x1'>
            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Single</FormInputLabel>
              </FormInputHeader>
              <CheckBox
                data-at-id="SingleSectionApplication"
                onChange={() => update({
                  sectionApplications: product.sectionApplications.includes('Single') ? [...product.sectionApplications].filter(e => e !== 'Single') : [...product.sectionApplications, 'Single']
                })}
                checked={product.sectionApplications.includes('Single')}
              />
            </FormInput>
            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Mutliple</FormInputLabel>
              </FormInputHeader>
              <CheckBox
                data-at-id="MultipleSectionApplication"
                onChange={() => update({
                  sectionApplications: product.sectionApplications.includes('Multiple') ? [...product.sectionApplications].filter(e => e !== 'Multiple') : [...product.sectionApplications, 'Multiple']
                })}
                checked={product.sectionApplications.includes('Multiple')}
              />
            </FormInput>
            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Side wide</FormInputLabel>
              </FormInputHeader>
              <CheckBox
                data-at-id="SideWideSectionApplication"
                onChange={() => update({
                  sectionApplications: product.sectionApplications.includes('Site wide') ? [...product.sectionApplications].filter(e => e !== 'Site wide') : [...product.sectionApplications, 'Site wide']
                })}
                checked={product.sectionApplications.includes('Site wide')}
              />
            </FormInput>
          </Box>

        </Box>
    </Card>

     <Card backgroundColor="light" margin="x4" padding="x6">
      <Box>
        <Box flex='horizontal' alignChildrenHorizontal='between'>
          <Text> Add ons </Text>
          <Button onClick={() => setAddOnModalVisible(true)}>Add</Button>
        </Box>

      </Box>
      <ProductAddOnList
        action={(id) => update({ addOns: product.addOns.filter(p => p != id) }) }
        actionIcon={<X size='1rem'/>}
        onClose={() => setAddOnModalVisible(false)}
        filterIds={product.addOns}
        filterNotIds={[]}
      />
    </Card>

    <Card backgroundColor="light" margin="x4" padding="x6">
      <FormInput margin='x8'>
        <FormInputHeader>
          <FormInputLabel>Pricing Structures</FormInputLabel>
        </FormInputHeader>
        <Box flex='horizontal' alignChildrenHorizontal='around'>
          {
            UnitTypes.map(type =>
            <ProductUnitTypeCheckBox  key={`${type}-structure-option`} type={type} product={product} update={update}/>
            )}
        </Box>
      </FormInput>



    </Card>

    {product.pricing.map(e => 
        <ProductPriceInput key={`${e.type}-price-input`} product={product} unit={e} update={update} />
    )}

    {
      addOnModalVisible &&

      <Modal visible={true} onClose={() => setAddOnModalVisible(false)}>
        <Card backgroundColor='light' padding='x3'>
          <ProductAddOnList
            action={(id) => update({ addOns: [...product.addOns, id] })}
            filterNotIds={product.addOns}
            actionIcon={<Plus size='1rem'/>}
            onClose={() => setAddOnModalVisible(false)}
          />
          <Box flex='horizontal' padding='x2' alignChildrenHorizontal='middle'>
            <Button level='primary' color='blue' onClick={() => setAddOnModalVisible(false)}>Close</Button>
          </Box>
        </Card>
      </Modal>
    }

  </>
  );
}
