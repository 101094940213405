import * as React from 'react';
import {
  Box,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  TextInput,
  Text,
  Card,
} from '@hd/ui';
import Dropdown from '../Dropdown/Dropdown';
import { parentIds } from './Admin';
import { UnitType } from './AdminProduct';
import UnitTypeForm from './UnitTypeForm';
import { ProductHDApp } from './ProductCreateModal';

interface Props {
  product: ProductHDApp;
  update: (product: ProductHDApp) => void;
}

export default function ProductForm({ product, update }: Props) {
  const getKeyByValue = (value: string) =>
    Object.keys(parentIds).find((key) => parentIds[key] === value);

  const handleUpdateQuantity = (updated?: UnitType) => {
    update({
      ...product,
      unitQuantity: updated,
    });
  };

  const handleUpdateDayRate = (updated?: UnitType) => {
    update({
      ...product,
      unitDay: updated,
    });
  };

  const handleUpdateHourRate = (updated?: UnitType) => {
    update({
      ...product,
      unitHour: updated,
    });
  };

  const handleUpdateHalfDay = (updated?: UnitType) => {
    update({
      ...product,
      unitHalfDay: updated,
    });
  };
  const handleUpdateMeterage = (updated?: UnitType) => {
    update({
      ...product,
      unitMeterage: updated,
    });
  };

  const handleUpdateDayRateContractor = (updated?: UnitType) => {
    update({
      ...product,
      unitDayContractor: updated,
    });
  };

  const handleUpdateHourRateContractor = (updated?: UnitType) => {
    update({
      ...product,
      unitHourContractor: updated,
    });
  };

  const handleUpdateHalfDayRateContractor = (updated?: UnitType) => {
    update({
      ...product,
      unitHalfDayContractor: updated,
    });
  };

  const handleUpdateQuantityRateContractor = (updated?: UnitType) => {
    update({
      ...product,
      unitQuantityContractor: updated,
    });
  };
  const handleUpdateMeterageRateContractor = (updated?: UnitType) => {
    update({
      ...product,
      unitMeterageContractor: updated,
    });
  };

  return (
    <>
      <Card margin="x4" padding="x4">
        <FormInput margin="x3">
          <FormInputHeader>
            <FormInputLabel>Product name</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="JobTypeName"
            onChange={(e) =>
              update({
                ...product,
                name: (e.target as HTMLInputElement).value,
              })
            }
            required
            value={product.name}
          />
        </FormInput>
      </Card>

      <Card margin="x4" padding="x4">
        <FormInput margin="x3">
          <FormInputHeader>
            <FormInputLabel>Product parent</FormInputLabel>
          </FormInputHeader>

          <Dropdown
            data-at-id="JobTypeName"
            options={Object.values(parentIds)}
            onChange={(e) =>
              update({
                ...product,
                parentId: parseInt(getKeyByValue(e)),
              })
            }
            required
            value={parentIds[product.parentId?.toString()]}
          />
        </FormInput>
      </Card>

      <Card margin="x4" padding="x4">
        <FormInput margin="x3">
          <FormInputHeader>
            <FormInputLabel>Max depth</FormInputLabel>
          </FormInputHeader>
          <TextInput
            data-at-id="ProductMaxDepth"
            placeholder="Leave empty if not needed"
            onChange={(e) =>
              update({
                ...product,
                maxDepth: parseInt((e.target as HTMLInputElement).value),
              })
            }
            required
            value={product.maxDepth || '0'}
          />
        </FormInput>
      </Card>

      <UnitTypeForm
        unitTypeContractor={product.unitDayContractor}
        updateContractor={handleUpdateDayRateContractor}
        unitType={product.unitDay}
        update={handleUpdateDayRate}
        header={'Day rate'}
        unitTypeType="models.DayRate"
      />
      <UnitTypeForm
        unitTypeContractor={product.unitHourContractor}
        updateContractor={handleUpdateHourRateContractor}
        update={handleUpdateHourRate}
        unitType={product.unitHour}
        header={'Hour rate'}
        unitTypeType="models.HourRate"
      />
      <UnitTypeForm
        unitTypeContractor={product.unitHalfDayContractor}
        updateContractor={handleUpdateHalfDayRateContractor}
        unitType={product.unitHalfDay}
        update={handleUpdateHalfDay}
        header={'Half day'}
        unitTypeType="models.HalfDayRate"
      />
      <UnitTypeForm
        unitTypeContractor={product.unitQuantityContractor}
        updateContractor={handleUpdateQuantityRateContractor}
        unitType={product.unitQuantity}
        update={handleUpdateQuantity}
        header={'Item quantity'}
        unitTypeType="models.Quantity"
      />

      <UnitTypeForm
        unitTypeContractor={product.unitMeterageContractor}
        updateContractor={handleUpdateMeterageRateContractor}
        unitType={product.unitMeterage}
        update={handleUpdateMeterage}
        header={'Meterage'}
        unitTypeType="models.Meterage"
      />

      <Card margin="x4" padding="x4" alignChildrenHorizontal="around">
        <Text strong>Requires position data</Text>
        <Box flex="horizontal" alignChildrenHorizontal="around">
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>At</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              data-at-id="RequiresAtPosition"
              onChange={(e) =>
                update({
                  ...product,
                  requiresAtPosition: !product.requiresAtPosition,
                })
              }
              required
              checked={product.requiresAtPosition}
            />
          </FormInput>

          <FormInput>
            <FormInputHeader>
              <FormInputLabel>From</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              data-at-id="RequiresFromPosition"
              onChange={(e) =>
                update({
                  ...product,
                  requiresFromPosition: !product.requiresFromPosition,
                })
              }
              required
              checked={product.requiresFromPosition}
            />
          </FormInput>

          <FormInput>
            <FormInputHeader>
              <FormInputLabel>To</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              data-at-id="RequiresToPosition"
              onChange={(e) =>
                update({
                  ...product,
                  requiresToPosition: !product.requiresToPosition,
                })
              }
              required
              checked={product.requiresToPosition}
            />
          </FormInput>
        </Box>
      </Card>

      <Card margin="x4" padding="x4">
        <Text strong>Section application</Text>
        <Box flex="horizontal" grow alignChildrenHorizontal="around">
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>Single</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              data-at-id="SectionApplicationSingle"
              onChange={(e) =>
                update({
                  ...product,
                  singleSectionApplication: !product.singleSectionApplication,
                })
              }
              required
              checked={product.singleSectionApplication}
            />
          </FormInput>
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>Multiple</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              data-at-id="SectionApplicationMultiple"
              onChange={(e) =>
                update({
                  ...product,
                  multipleSectionApplication:
                    !product.multipleSectionApplication,
                })
              }
              required
              checked={product.multipleSectionApplication}
            />
          </FormInput>
          <FormInput>
            <FormInputHeader>
              <FormInputLabel>All</FormInputLabel>
            </FormInputHeader>

            <CheckBox
              data-at-id="SectionApplicationAll"
              onChange={(e) =>
                update({
                  ...product,
                  allSectionApplication: !product.allSectionApplication,
                })
              }
              required
              checked={product.allSectionApplication}
            />
          </FormInput>
        </Box>
      </Card>
    </>
  );
}
