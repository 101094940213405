import * as React from 'react';
import { jsPDF } from 'jspdf';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Grid,
  Image,
  LabelledList,
  PageLoading,
  Text,
} from '@hd/ui';
import useFirestoreJobs from '../../utils/useFirestoreJobs';
import useFirestoreJobReport from '../../utils/useFirestoreJobReport';
import getAddress from '../../utils/getAddress';
import { Download, Printer } from '@styled-icons/boxicons-regular';
import toCurrencyFormat from '../../utils/toCurrencyFormat';
import { jobTypeNominalCodeContractor } from '../../constants/jobTypes';
import { TypeFilter } from '../../utils/useFirestoreCollection';

export default function InvoiceContractor() {
  const { magicId } = useParams<{ magicId: string }>();
  const filters = React.useMemo(
    () => [['contractorInvoiceId', '==', magicId]] as TypeFilter[],
    [magicId]
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const { documents, ids, isFetching } = useFirestoreJobs({ filters });

  const document = documents[ids[0]];

  const vatType = document?.contractor ? document.contractor.vatType : 'VAT';
  const vatRate = vatType === 'VAT Registered' ? document.vatRate : 0;
  const jobReport = useFirestoreJobReport(document ? document._id : undefined);

  const handleDownload = () => {
    if (ref.current) {
      setIsSaving(true);
      window.requestAnimationFrame(async () => {
        if (ref.current) {
          const doc = new jsPDF('p', 'pt', 'a4');
          await doc.html(
            `
              <div style="width: 100%; display: flex; justify-content: center;">
    <div style="width: 700px; padding: 20px; box-sizing: border-box;">
      ${ref.current.outerHTML}
    </div>
  </div>

            `
          );
          doc.save(`${document.jobNumber}-invoice`);
        }
        setIsSaving(false);
      });
    }
  };

  const handlePrint = () => {
    window.print();
  };

  if (isFetching) {
    return <PageLoading />;
  }

  if (!document) {
    return (
      <Box padding="x10">
        <Card maxWidth="600px" maxWidthCentered>
          <Image src={require('@hd/ui/assets/header-1.png')} />
          <Text align="middle" margin="x20" maxWidthCentered strong>
            Invoice not found.
          </Text>
          <Image src={require('@hd/ui/assets/footer.jpg')} />
        </Card>
      </Box>
    );
  }

  return (
    <Box padding="x10" ref={ref}>
      <Card maxWidthCentered width="600px">
        <Box padding="x6">
          <Text margin="x4" size="x4" strong>
            Produced on behalf of {document.contractor?.name} - an approved
            Happy Drains contractor.
          </Text>
          <Text emphasis margin="x4" size="x1">
            This job sheet will be treated as your invoice to Happy Drains Ltd.
            You can add your invoice number to this for your filing purposes.
          </Text>

          <LabelledList
            items={[
              ['HAPPY DRAINS LTD', ''],
              ['12B Chalex Estate', ''],
              ['Manor Hall Road', ''],
              ['Southwick', ''],
              ['BN42 4NH', ''],
            ]}
            margin="x4"
          />

          <LabelledList
            items={[
              [
                'Tax Date',
                jobReport?.document.timestampSubmitted
                  ?.toDate()
                  .toLocaleString(),
              ],
              ['Invoice Number', ''],
            ]}
            margin="x4"
          />

          <LabelledList
            items={[
              ['Job Number', document.jobNumber],
              ['Job Date', document.date.toDate().toLocaleString()],
              [
                'Contractor Nominal Code',
                document.contractorNominalCode ||
                  jobTypeNominalCodeContractor[document.jobType].toString(),
              ],
            ]}
            margin="x4"
          />

          <LabelledList
            items={[['Site Address', getAddress(document.customerSite)]]}
            margin="x4"
          />

          <LabelledList
            items={[
              [
                'Total Ex VAT',
                toCurrencyFormat(document.priceContractor.toString()),
              ],
              ['VAT type', vatType],
              ['VAT', vatRate + '%'],
              [
                'Total Inc VAT',
                toCurrencyFormat(
                  (document.priceContractor * (1 + vatRate / 100)).toString()
                ),
              ],
              ['Invoice queries?', 'Please contact accounts@happydrains.co.uk'],
              ['Job Type', document.jobType],
              ['What was the cause of the blockage', document.description],
            ]}
            margin="x4"
          />

          <Box margin="x3">
            <Text strong>Description</Text>
            <Text margin="x1">{document.description}</Text>
          </Box>

          <Box margin="x3">
            <Text strong>Public Notes</Text>
            <Text
              backgroundColor="light-gray"
              borderColor="gray"
              borderRadius="x1"
              borderSize="x1"
              borderStyle="solid"
              margin="x1"
              padding="x3"
            >
              {jobReport.document?.publicNote || 'No note added'}
            </Text>
          </Box>

          <Box margin="x3">
            <Text strong>Private notes</Text>
            <Text
              backgroundColor="light-gray"
              borderColor="gray"
              borderRadius="x1"
              borderSize="x1"
              borderStyle="solid"
              margin="x1"
              padding="x3"
            >
              {jobReport.document?.privateNote || 'No note added'}
            </Text>
          </Box>

          <Box margin="x6" style={{ breakBefore: 'page' }}>
            <Text margin="x1" strong>
              Images
            </Text>
            <Grid gap="x2" repeat="3" repeatWidth="1fr">
              {jobReport.document.files?.map(({ downloadUrl }) => (
                <Image key={downloadUrl} src={downloadUrl} />
              ))}
            </Grid>
          </Box>

          {!isSaving && (
            <Box flex="horizontal" gap="x3" margin="x6" printHide>
              <Button
                color="blue"
                level="primary"
                onClick={handleDownload}
                size="large"
              >
                <Download size="20px" />
                <Text>Download</Text>
              </Button>

              <Button
                color="blue"
                level="primary"
                onClick={handlePrint}
                size="large"
              >
                <Printer size="20px" />
                <Text>Print</Text>
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}
