// TODO, this file should be deprecated

export const jobTypePricesCustomer: Record<string, number> = {
  'Blockage - Domestic': 95,
  'Blockage - Commercial': 125,
  'Blockage - General': 135,
  'CCTV - 30': 185,
  'CCTV - 60': 295,
  'CCTV - other': 185,
  'Tanker': 0,
  'Tanker and CCTV': 0,
  'Other': 0,
  'Drain Repair': 0,
  'Saturday or OHH Domestic Unblock': 135,
  'Saturday or OHH Commercial Unblock': 150,
  'Abandonment Fee': 60,
  'CCTV Half day': 545,
  'CCTV Full day': 845,
  'Main Line Rig Half day': 595,
  'Main Line Rig Full day': 945,
  'Vacuum Tanker & Main Line Rig Full day': 1790,
  'Vacuum Tanker & Main Line Rig half day': 1090,
  'Picote Full Day': 895,
  'Picote Half Day': 595,
  'Recall': 0,
};

export const jobTypePricesContractor: Record<string, number> = {
  'Blockage - Domestic': 62.50,
  'Blockage - Commercial': 80,
  'Blockage - General': 85.50,
  'CCTV - 30': 105,
  'CCTV - 60': 150,
  'CCTV - other': 105,
  'Tanker': 0,
  'Tanker and CCTV': 0,
  'Other': 0,
  'Drain Repair': 0,
  'Saturday or OHH Domestic Unblock': 82.50,
  'Saturday or OHH Commercial Unblock': 90,
  'Abandonment Fee': 40,
  'CCTV Half day': 320,
  'CCTV Full day': 520,
  'Main Line Rig Half day': 350,
  'Main Line Rig Full day': 600,
  'Vacuum Tanker & Main Line Rig Full day': 990,
  'Vacuum Tanker & Main Line Rig half day': 600,
  'Picote Full Day': 495,
  'Picote Half Day': 345,
  'Recall': 0,
};

export default [
  'Blockage - Domestic',
  'Blockage - Commercial',
  'Blockage - General',
  'CCTV - 30',
  'CCTV - 60',
  'CCTV - other',
  'Tanker',
  'Tanker and CCTV',
  'Other',
  'Drain Repair',
  'Saturday or OHH Domestic Unblock',
  'Saturday or OHH Commercial Unblock',
  'Abandonment Fee',
  'CCTV Half day',
  'CCTV Full day',
  'Main Line Rig Half day',
  'Main Line Rig Full day',
  'Vacuum Tanker & Main Line Rig Full day',
  'Vacuum Tanker & Main Line Rig half day',
  'Picote Full Day',
  'Picote Half Day',
  'Recall',
] as string[];

export const domesticJobTypes = [
  'Abandonment Fee',
  'Blockage - Domestic',
  'Blockage - General',
  'CCTV - 30',
  'CCTV - other',
  'Recall',
  'Saturday or OHH Domestic Unblock',
] as string[];

export const unblockJobTypes = [
  'Abandonment fee',
  'Blockage - Commercial',
  'Blockage - Domestic',
  'Blockage - General',
  'Saturday or OHH Commercial Unblock',
  'Saturday or OHH Domestic Unblock',
  'Tanker',
] as string[];

export const repairsJobTypes = [
  'Drain Repair',
  'Other',
] as string[];


export const commercialJobTypes = [
  'Blockage - Commercial',
  'CCTV - 60',
  'CCTV Full day',
  'CCTV Half day',
  'Drain Repair',
  'Main Line Rig Full day',
  'Main Line Rig Half day',
  'Other',
  'Picote Full Day',
  'Picote Half Day',
  'Saturday or OHH Commercial Unblock',
  'Tanker and CCTV',
  'Tanker',
  'Vacuum Tanker & Main Line Rig Full day',
  'Vacuum Tanker & Main Line Rig half day',
];

export const cctvJobTypes = [
  'CCTV - 30',
  'CCTV - 60',
  'CCTV - other',
  'CCTV Half day',
  'CCTV Full day',
  'Tanker and CCTV',
  'Main Line Rig Half day',
  'Main Line Rig Full day',
] as string[];

export const jobTypeNominalCodeCustomer: Record<string, number> = {
  'Blockage - Domestic': 4000,
  'Blockage - Commercial': 4004,
  'Blockage - General': 4001,
  'CCTV - 30': 4001,
  'CCTV - 60': 4001,
  'CCTV - other': 4002,
  'Tanker': 4006,
  'Tanker and CCTV': 4005,
  'Other': 4003,
  'Drain Repair': 4003,
  'Saturday or OHH Domestic Unblock': 4000,
  'Saturday or OHH Commercial Unblock': 4000,
  'Abandonment Fee': 4000,
  'CCTV Half day': 4001,
  'CCTV Full day': 4001,
  'Main Line Rig Half day': 4001,
  'Main Line Rig Full day': 4001,
  'Vacuum Tanker & Main Line Rig Full day': 4000,
  'Vacuum Tanker & Main Line Rig half day': 4000,
  'Picote Full Day': 4001,
  'Picote Half Day': 4001,
  'Recall': 4000,
};

export const jobTypeNominalCodeContractor: Record<string, number> = {
  'Blockage - Commercial': 5000,
  'Blockage - Domestic': 5000,
  'Blockage - General': 5000,
  'CCTV - 30': 5001,
  'CCTV - 60': 5001,
  'CCTV - other': 5001,
  'Tanker': 5007,
  'Tanker and CCTV': 5007,
  'Other': 5003,
  'Drain Repair': 5003,
  'Saturday or OHH Domestic Unblock': 5000,
  'Saturday or OHH Commercial Unblock': 5000,
  'Abandonment Fee': 5000,
  'CCTV Half day': 5001,
  'CCTV Full day': 5001,
  'Main Line Rig Half day': 5001,
  'Main Line Rig Full day': 5001,
  'Vacuum Tanker & Main Line Rig Full day': 5000,
  'Vacuum Tanker & Main Line Rig half day': 5000,
  'Picote Full Day': 5001,
  'Picote Half Day': 5001,
  'Recall': 5000,
};
