import { Modal } from '@hd/ui';
import { CCTVRecommendationCategory } from '@hd/types';
import * as React from 'react';
import CategoriesList from '../categories/CategoriesList';

interface Props {
  onClose: () => void;
  onSelect: (value: CCTVRecommendationCategory) => void;
}

export default function SubcategorySelectionModalCategories(props: Props) {
  const {
    onClose,
    onSelect,
  } = props;

  return (
    <Modal
        maxWidth="600px"
        onClose={ onClose }
        visible>
          {
      <CategoriesList
          onSelect={onSelect}
          options={ { limit: 25 } } />

          }
    </Modal>
  );
}
