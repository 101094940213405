import {
  Box,
  Button,
  Page,
  PageBack,
  PageBody,
  PageHeader,
  PageTitle,
} from '@hd/ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import useFirestoreJob from '../../utils/useFirestoreJob';
import { validateJob, FormError } from '../../utils/formValidators';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FormErrorMessage from '../FormErrorMessage';
import JobForm from './JobForm';
import firebase from 'firebase';
import ConfirmationModal from '../ConfirmationModal';

export default function JobCreate() {
  const { push } = useHistory();
  const job = useFirestoreJob();
  const [error, setError] = React.useState<FormError | null>(null);
  const [confirm, setConfirm] = React.useState(false);

  const handleCreate = async () => {
    const error = validateJob(job.document);
    const priceCustomerN = parseFloat(job.document.priceCustomer.toString());
    const priceContractorN = parseFloat(
      job.document.priceContractor.toString()
    );

    if (isNaN(priceCustomerN) || isNaN(priceContractorN)) {
      setError({
        field: '',
        message:
          'Please ensure both contractor and customer prices are a number.',
      });
    } else {
      setError(error);

      if (!error) {
        if (
          !confirm &&
          (job.document.priceContractor === 0 ||
            job.document.priceCustomer === 0)
        ) {
          setConfirm(true);
          return;
        }
        setConfirm(false);
        job.update({
          bookingAgent: firebase.auth().currentUser?.email,
          lastBookedUpdatedBy: firebase.auth().currentUser?.email,
          priceCustomer: priceCustomerN,
          priceContractor: priceContractorN,
        });

        const a = await job.save();
        push('/jobs/' + a._id);
      }
    }
  };

  return (
    <FirebaseDocument {...job} allowNoDocument>
      <Page>
        <PageHeader>
          <PageBack to="/jobs">Jobs</PageBack>
          <PageTitle>New Job</PageTitle>
        </PageHeader>

        <PageBody>
          <JobForm job={job.document} update={job.update} />

          {error && <FormErrorMessage error={error} />}

          <Box
            alignChildrenHorizontal="end"
            flex="horizontal"
            gap="x2"
            margin="x4"
          >
            <Button
              color="blue"
              data-at-id="CreateJob"
              level="primary"
              onClick={handleCreate}
              disabled={job.document.customer?.accountOnHold }
            >
              Create Job
            </Button>
          </Box>
          <ConfirmationModal
            message="Are you sure you want to create a job with either customer price or contractor price as 0?"
            visible={confirm}
            onCancel={() => setConfirm(false)}
            onConfirm={handleCreate}
          />
        </PageBody>
      </Page>
    </FirebaseDocument>
  );
}
