import { ArrowToLeft, ArrowToRight, BookAdd, Bookmark, Check, Error, LinkAlt, PhoneCall, Pin, Plus, X} from '@styled-icons/boxicons-regular';
import { Alert, Appear, Badge, Box, Button, Card, CardRow, FormInput, FormInputHeader, FormInputLabel, Image, Link, Modal, Page, PageBack, PageBody, PageHeader, PageTitle, Place, Progress, Tab, Tabs, Text } from '@hd/ui';
import * as React from 'react';
import firebase from 'firebase/app';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import useFirestoreJob from '../../utils/useFirestoreJob';
import { validateJob, FormError } from '../../utils/formValidators';
import useFirestoreJobReport from '../../utils/useFirestoreJobReport';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FormErrorMessage from '../FormErrorMessage';
import JobForm from '../Job/JobForm';
import JobNotesList from '../Job/JobNotesList';
import JobFilesList from '../Job/JobFilesList';
import JobPaymentCard from './JobPaymentCard';
import JobEmailHistoryCard from './JobEmailHistory';
import useFirestoreConfig from '../../utils/useFirestoreConfig';
import JobComplaintsList from '../Job/JobComplaintsList';
import StatusBadge from '../StatusBadge/StatusBadge';
import JobReport from '../Job/JobReport';
import { isFollowUpQuoteInvalid } from '../../utils/quote';
import useFirestoreQuote from '../../utils/useFirestoreQuote';
import JobReportFollowUpQuoteEmails from './JobReportFollowUpQuoteEmails';
import useQueryParams from '../../utils/useQueryParams';
import { useBookmarkContext } from '../Authenticated';
import JobRecurringButton from './JobRecurringButton';
import { WebInquiryConversion } from '@hd/types';
import { NotificationsContext } from '../Notifications/Notifications';
import { isAdmin } from '../../utils/authLevel';
import JobReportFollowUpQuote from './JobReportFollowUpQuote';
import JobCancelModal from './JobCancelModal';
import JobActivityLog from './JobActivityLog';

export default function JobView() {
  const { push } = useHistory();
  const { jobId } = useParams<{ jobId: string }>();
  const [error, setError] = React.useState<FormError | null>(null);
  const job = useFirestoreJob(jobId);
  const report = useFirestoreJobReport(jobId);
  const [isRemovingJob, setIsCancellingJob] = React.useState(false);
  const { document: config } = useFirestoreConfig();
  const [refMenuLink, setRefMenuLink] = React.useState<HTMLElement | null>(null);
  const [isLinkMenuVisible, setShowLinkMenu] = React.useState(false);
  const [quoteInvalidAlertVisible, setQuoteInvalidAlertVisible] = React.useState(false);
  const [quoteConfirmationVisible, setQuoteConfirmationVisible] = React.useState(false)
  const {  document: quote, save: quoteSave } = useFirestoreQuote(job.document.followUpQuote ? job.document.followUpQuote._id : undefined)
  const {  document: fromQuote } = useFirestoreQuote(job.document.fromQuote ? job.document.fromQuote._id : undefined)
  const [webInquiryId, setWebInquiryId] = React.useState()
  const { addNotification } = React.useContext(NotificationsContext);
  const a = useBookmarkContext()
  React.useEffect(() => {
    if(job.document.fromWebInquiry) {
      console.log(job.document.fromWebInquiry)
      const gg: WebInquiryConversion = job.document.fromWebInquiry;
      const webInquiry: firebase.firestore.DocumentReference = gg.webInquiry;
      webInquiry.get().then(e => {
        setWebInquiryId(e.id)
      })
    }

  }, [job])

  const queryParams = useQueryParams();
  const redirectUrl = queryParams.get('redirect') as null | string;


  async function handleSendCheckatradeEmail(jobId: string) {
    await firebase.functions().httpsCallable('manuallySendCheckatradeEmail')({ jobId });
  }

  const handleUpdate = async() => {
    const error = validateJob(job.document);
    const priceCustomerN = parseFloat(job.document.priceCustomer.toString());
    const priceContractorN = parseFloat(job.document.priceContractor.toString());

    if (isNaN(priceCustomerN) || isNaN(priceContractorN)) {
      setError({
        field: '',
        message: 'Please ensure both contractor and customer prices are a number.',
      });
    } else {
      setError(error);

      if (!error) {
        job.update({
          priceCustomer: priceCustomerN,
          priceContractor: priceContractorN,
          lastBookedUpdatedBy: firebase.auth().currentUser?.email
        });
        await job.save();
      }
    }
  };


  const handleReopenJob = async() => {
    job.update({
      status: job.document.contractor
        ? 'IN_PROGRESS'
        : 'UNALLOCATED',
    });
    await job.save();
  };

  const handleApproveContractorRaisedJob = async() => {
    job.update({
      status: 'IN_PROGRESS'
    });
    await job.save();
  };

  const handleMarkAsComplete = async() => {
    if(job.document.followUpQuote || job.document.isCCTV) {
      if(job.document.isCCTV && (!quote.coverLetter && !job.document.usingNewDrainReporter)) {
        setQuoteInvalidAlertVisible(true)
        return;
      }
      if(quote.furtherWorkRequired && isFollowUpQuoteInvalid(quote)){
        setQuoteInvalidAlertVisible(true)
        return;
      }
      setQuoteConfirmationVisible(true)
    } else {
      job.update({ status: 'COMPLETED' });
      await job.save();
    }
  };


  const confirmMarkAsComplete = async() => {
      job.update({ status: 'COMPLETED' });
      await job.save();
  }

  if(job.document.jobNumber === undefined || job.document._id === undefined || job.document._id === '' || job.document.jobNumber === '') {
    return (
      <Box alignChildren="middle" flex="vertical" grow padding="x6">
        <Progress />
      </Box>
    )
  }

  return (
    <>
    <FirebaseDocument { ...job } allowNoDocument={ !jobId }>
      <Page>
        <PageHeader padding="x0" paddingHorizontal="x10">
          <Box alignChildrenVertical="end" flex="horizontal" gap="x4" paddingVertical="x10">
            <Box basis="none" grow>
              {
                redirectUrl ? <PageBack to={redirectUrl}>Back</PageBack> : <PageBack to="/jobs">Jobs</PageBack>
              }

              <Box alignChildrenVertical="middle" flex="vertical" gap="x4">
                <Box flex='horizontal' gap='x2'>
                  <PageTitle>Job #{ job.document.jobNumber } </PageTitle>
                  {
                    a.bookmarks.map(e => e.id).includes(job.document._id) ? 
                    
                    
                  <Button size='small' onClick={() => a.removeBookmark(
                    job.document._id,
                  ) } > <Bookmark size='1rem'/>- </Button>
                    : 
                  <Button size='small' onClick={() => a.addBookmark({
                    id: job.document._id,
                    jobNumber: job.document.jobNumber
                  }) } > <Bookmark size='1rem'/> +</Button>
                  }
                </Box>

                <Box>
                <Box alignChildrenVertical="middle" flex="horizontal" gap="x2">
                  <StatusBadge job={ job.document } />

                  <Badge backgroundColor="light-gray">
                    Sage Ref: { job.document.customer?.sageReference }
                  </Badge>

                  <Badge backgroundColor="light-gray">
                    <PhoneCall size="1rem" />
                    <Text>{ job.document.customer?.contactPhoneNumbers.concat('\n') }</Text>
                  </Badge>
                </Box>
              </Box>
              </Box>
            </Box>

              { job.document.status === 'CONTRACTOR_RAISED' && (
                    <Button  color="blue" level='secondary' onClick={ handleApproveContractorRaisedJob }>
                      <Text>Accept</Text>
                    </Button>
              ) }
            <Box
                alignChildrenVertical="middle"
                flex="horizontal"
                gap="x2">
                  <Box flex='horizontal' gap='x1'>
              { (job.document.status === 'AWAITING_AUTHORISATION' || job.document.status === 'NO_FIX' ) && (
                    <Button  color="blue" level='secondary' onClick={ handleMarkAsComplete }>
                      <Text>Authorize</Text>
                    </Button>
              ) }
              {
              // TODO - work heree
}
              { (job.document.status === 'AUTHORISE_CANCELLATION') && (
                    <Button color="blue" level='secondary' onClick={ () => setIsCancellingJob(true) }>
                      Authorise Cancellation
                    </Button>
                ) }

              { job.document.parent && (
                <Button
                    color="blue"
                    level="tertiary"
                    onClick={ () => push(`/jobs/${job.document.parent?._id}`) }>
                  <ArrowToLeft size="20px" />
                  <Text>View Parent Job</Text>
                </Button>
              ) }


              { job.document.child ? (
                <Button
                    color="blue"
                    level="tertiary"
                    onClick={ () => push(`/jobs/${job.document.child?._id}`) }>
                  <Text>View Sub Job</Text>
                  <ArrowToRight size="20px" />
                </Button>
              ) : (
                <Button
                    color="blue"
                    level="tertiary"
                    onClick={ () => push(`/jobs/${jobId}/job`) }>
                  <Plus size="20px" />
                  <Text>Add Sub Job</Text>
                </Button>
              ) }

              </Box>
              <Button
                  color="blue"
                  level="tertiary"
                  onClick={ () => setShowLinkMenu(true) }
                  ref={ setRefMenuLink }>
                <LinkAlt size="20px" />
                <Text>Links</Text>
              </Button>

              <Place
                  minWidth="reference"
                  onClose={ () => setShowLinkMenu(false) }
                  referenceElement={ refMenuLink }
                  visible={ isLinkMenuVisible }>
                <Card backgroundColor="light">
                  <Box maxHeight="200px" scrollable>
                    { job.document.customerInvoiceId && (
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={ `/invoice/cu/${job.document.customerInvoiceId}` }
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          target="_CustomerInvoice">
                        <Text size="x2">Customer Invoice</Text>
                      </CardRow>
                    ) }

                    { job.document.contractorInvoiceId && (
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={ `/invoice/co/${job.document.contractorInvoiceId}` }
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          target="_ContractorInvoice">
                        <Text size="x2">Contractor Invoice</Text>
                      </CardRow>
                    ) }

                    { job.document.magicId && (
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/complete/${job.document.magicId}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          target="_ContractorInvoice">
                        <Text size="x2">Contractor report submission</Text>
                      </CardRow>
                    ) }
                  {
                    isAdmin() && <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/cctv-reccomendations/${job.document.magicId}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">CCTV submission</Text>
                      </CardRow>
                  }

                { job.document.isFromQuote && (
                    <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/quotes/${job.document.fromQuote?._id}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">From quote</Text>
                      </CardRow>
                    )
                  }
                { webInquiryId && (
                    <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/web-inquiries/${webInquiryId}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">From web inquiry</Text>
                      </CardRow>
                    )
                  }
                  </Box>
                </Card>
              </Place>
            </Box>
          </Box>

          <Box alignChildren="middle" flex="horizontal">
            <Box grow>
              <Tabs backgroundColor="light">
                <Route exact path="/jobs/:jobId">
                  { ({ match }) => (
                    <Tab
                        active={ !!match }
                        onClick={ () => push(`/jobs/${jobId}`) }>Details</Tab>
                  ) }
                </Route>

                <Route path="/jobs/:jobId/activity-log">
                  { ({ match }) => (
                    <Tab
                        active={ !!match }
                        onClick={ () => push(`/jobs/${jobId}/activity-log`) }>Activity</Tab>
                  ) }
                </Route>


                <Route path="/jobs/:jobId/files">
                  { ({ match }) => (
                    <Tab
                        active={ !!match }
                        onClick={ () => push(`/jobs/${jobId}/files`) }>Files</Tab>
                  ) }
                </Route>


                <Route path="/jobs/:jobId/report">
                  { ({ match }) => (
                    <Tab
                        active={ !!match }
                        onClick={ () => push(`/jobs/${jobId}/report`) }>Report</Tab>
                  ) }
                </Route>

                {
                  job.document.usingNewDrainReporter && 
                <Route path="/jobs/:jobId/follow-up-quote">
                  { ({ match }) => (
                    <Tab
                        active={ !!match }
                        onClick={ () => push(`/jobs/${jobId}/follow-up-quote`) }>Follow up quote</Tab>
                  ) }
                </Route>
                }

              </Tabs>
            </Box>
          </Box>
        </PageHeader>

        <PageBody>
          <Switch>
            <Route exact path="/jobs/:jobId">

              { report.hasDocument && (
                <Appear animation="FadeSlideDown" delay={ 250 } margin="x2">
                  <Alert>
                    The report has been submitted for this
                    job. <Link onClick={ () => push(`/jobs/${jobId}/report`) } underline>Click here</Link> to
                    view it.
                  </Alert>
                </Appear>
              ) }

              { job.document.status === 'CANCELLED' && (
                <Appear animation="FadeSlideDown" delay={ 250 } margin="x6">
                  <Alert backgroundColor="red-light" borderColor="red-dark" textColor="red">
                    This job has been cancelled. <Link onClick={ handleReopenJob } textColor="red" underline>Click here</Link> to
                    reopen it.
                  </Alert>
                </Appear>
              ) }

              { (job.document.status === 'COMPLETED' || job.document.status === 'PAID' ) && (
                <JobPaymentCard
                    jobId={ jobId }
                    margin="x6"
                    total={ job.document.priceCustomer * (1 + (job.document.customer?.vatType === 'VAT' ? (config.vatRate / 100) : 0)) } />
                )
              }

              <JobForm
                  job={ job.document }
                  update={ job.update } />

              { fromQuote && fromQuote.customerNotes && (
                  <Card backgroundColor="light" margin="x4" padding="x6">
                    <Box flex="horizontal" gap="x6" margin="x6">
                      <Box basis="none" grow>
                        <FormInput>
                          <FormInputHeader>
                            <FormInputLabel>Notes from customer quote</FormInputLabel>
                          </FormInputHeader>
                          <Text> { fromQuote.customerNotes } </Text>
                        </FormInput>
                      </Box>
                    </Box>
                  </Card>
              )}

              { error && (
                <FormErrorMessage error={ error } />
              ) }

              {
                <Card backgroundColor="light" margin="x4" padding="x6">
                  <JobRecurringButton recurringJobId={job.document.recurringFrom?._id} jobDocument={job.document} jobSave={job.save} jobUpdate={job.update} />
                </Card>
              }

              <Box alignChildrenHorizontal="end" flex="horizontal" gap="x2" margin="x4">
                { (job.document.status === 'UNALLOCATED' || job.document.status === 'IN_PROGRESS' || job.document.status === 'CONTRACTOR_RAISED') && (
                  <Button
                      color="red"
                      level="primary"
                      onClick={ () => setIsCancellingJob(true) }>
                    Cancel Job
                  </Button>
                ) }

                { job.document.status === 'CANCELLED' && (
                  <Button
                      color="red"
                      level="primary"
                      onClick={ handleReopenJob }>
                    Reopen Job
                  </Button>
                ) }

                { (job.document.status === 'COMPLETED' || job.document.status === 'PAID') && (
                  <Button
                      color="blue"
                      level="secondary"
                      onClick={ () => handleSendCheckatradeEmail( job.document._id ) } >
                    Send Review Email
                    <Image src={ require('@hd/ui/assets/checkatrade-no-strapline.png') } width="100px" />
                  </Button>
                ) }

                <Button
                    color="blue"
                    level="primary"
                    onClick={ handleUpdate }>
                  Update Job
                </Button>
              </Box>
              <Text size='x1'>
              {
                job.document.bookingAgent && <Box> Originally booked by {job.document.bookingAgent}</Box>
              }
            </Text>
            </Route>


            <Route path="/jobs/:jobId/activity-log">
              <Box
                  alignChildrenVertical="start"
                  flex="horizontal"
                  gap="x12"
                  margin="x6">
                <Box basis="none" grow>
                  <JobNotesList jobId={ jobId } />
                </Box>

                { job.document.contractor && (
                  <Box basis="none" grow>
                    <JobComplaintsList contractorId={ job.document.contractor._id } jobId={ jobId } />
                  </Box>
                ) }
              </Box>
              <Box
                  alignChildrenVertical="start"
                  flex="horizontal"
                  gap="x12"
                  margin="x6">
                <Box basis="none" grow>
                  <JobActivityLog jobId={ jobId } />
                </Box>
              </Box>
              <JobEmailHistoryCard jobId={ jobId } />
            </Route>

            <Route path="/jobs/:jobId/files">
              <JobFilesList jobId={ jobId } />
            </Route>

            <Route path="/jobs/:jobId/report">
              <JobReport jobId={ jobId } />
            </Route>

{
  job.document.usingNewDrainReporter && job.document.followUpQuote && 
            <Route path="/jobs/:jobId/follow-up-quote">
                  <JobReportFollowUpQuote
                    job={job.document}
                    onClose={() => {}}
                  />
            </Route>
}
          </Switch>
        </PageBody>
      </Page>

      <JobCancelModal visible={isRemovingJob}  onClose={() =>setIsCancellingJob(false)} job={job}/>

      <Modal visible={quoteInvalidAlertVisible} flex='horizontal' alignChildrenHorizontal='middle' onClose={() => setQuoteInvalidAlertVisible(false)}>
        <Card maxHeight='600px' alignChildrenHorizontal='middle'  maxWidth='500px' padding='x10'>
        <Box padding='x5' flex='horizontal' alignChildrenHorizontal='middle' alignChildrenVertical='middle'>
          <Text textColor='red'>
            <Error size='2rem' />
          </Text>
          <Text strong>You need to complete quote before authorisation</Text>
        </Box>
        <Box flex='horizontal' alignChildrenHorizontal='middle'>
        <Button level='primary' color='blue' onClick={ () => 
          {
            setQuoteInvalidAlertVisible(false)
            push(`/jobs/${jobId}/report`) 
        }}>Go to report</Button>
        </Box>
        </Card>
      </Modal>
    </FirebaseDocument>
      <Modal visible={quoteConfirmationVisible} maxWidth="600px" >
        <Card
          backgroundColor="light"
          padding="x10">
        <Text size='x5' strong>Check before you go!</Text>
        <Box flex='vertical' gap='x5'>
          <Box>

          {
            quote.furtherWorkRequired ? 
            
            <Box flex='horizontal' gap='x2' alignChildrenVertical='middle'>
              <Text textColor='green'>
                <Check size="3rem"/>
              </Text>
              <Text>Further work is required.</Text> 
            </Box>
            
            : 
            <Box flex='horizontal' gap='x2' alignChildrenVertical='middle'>
              <Text textColor='red'>
                <X size="3rem"/>
              </Text>
              <Text>Further work is not required.</Text> 
            </Box>
          }
          {
            quote.furtherWorkRequired && 
            <Box flex='horizontal' gap='x2' alignChildrenVertical='middle'>
              <Text>Price is</Text>
              <Text strong size='x4'>£{quote.price}</Text>
            </Box>
          }
          </Box>
          {
            job.document.purchaseOrderNumber !== '' && job.document.isCCTV && (
            <Box padding='x4' borderSize='x1' borderColor='gray'>
              <Text strong> Purchase order number detected. Send now?</Text>
              <JobReportFollowUpQuoteEmails quote={quote} save={quoteSave}/>
            </Box>)
          }
          <Box flex='horizontal' gap='x3'>
        <Button level='primary' color='blue' onClick={ () => 
          {
            setQuoteConfirmationVisible(false)
            push(`/jobs/${jobId}/report`) 
        }}>Nope. Edit quote.</Button>
        <Button level='primary' color='blue' onClick={ () =>  {
            setQuoteConfirmationVisible(false)
            confirmMarkAsComplete()
        }}>Yes. Quote is correct.</Button>
        </Box>
        </Box>
      </Card>

    </Modal>
    </>
  );

