import { CCTVReccomendation, Repair, SectionApplication } from '@hd/types';
import * as React from 'react';
import { calculateContractorTotal, calculateRepairsPrice } from '../CCTVRecommendations/utils';
import { Badge, Box, Text, Button, Modal, Card, Table, TableHeaderRow, TableHeaderCell, TableBodyRow, TableBodyCell, Appear } from '@hd/ui';
import { AlarmExclamation, ChevronDown } from '@styled-icons/boxicons-regular';
import useInputNumber from '../../utils/useInputNumber';
import { humanReadableUnitType } from '../../constants/CCTVReccommendationContants';

export interface Props {
    reccomendations: CCTVReccomendation;
    onApply: (price: number, contractorPrice: number) => void;
    quoteP: number;
    quoteCp: number;
}

export default function QuotePriceSuggestion(props: Props) {
    const { reccomendations, onApply, quoteCp, quoteP } = props;
    const [contractorPrice, setContractorPrice] = React.useState<number>(quoteCp || calculateContractorTotal(reccomendations.repairs))
    const [price, setPrice] = React.useState<number>(quoteP || calculateRepairsPrice(reccomendations.repairs))

    const [p, setP] = useInputNumber(price, setPrice)
    const [cp, setCp] = useInputNumber(contractorPrice, setContractorPrice)

    const [breakdownVisible, setBreakdownVisible] = React.useState(false)


    React.useEffect(() => {
        reset();
    }, [reccomendations])

    const reset = () => {
        setContractorPrice(calculateContractorTotal(reccomendations.repairs))
        setPrice(calculateRepairsPrice(reccomendations.repairs))
    }

    const getSectionName = (section: string, sectionApplication: SectionApplication) => {
        if (sectionApplication === 'Single') {
            return `Section/Lateral ${section}`
        }
        if (sectionApplication === 'Multiple') {
            return `Sections/Laterals ${section}`
        }
        if (sectionApplication === 'Site wide') {
            return `Site wide`
        }
    }

    const groupBy = (array: Repair[]): Record<string, Repair[]> => {
        return array.reduce((result, currentValue) => {
            const groupKey = getSectionName(currentValue.section!, currentValue.sectionApplication!)!
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(currentValue);
            return result;
        }, {} as Record<string, Repair[]>);
    };





    const groupedRepairs = groupBy(reccomendations.repairs);

    return (
        <Appear animation='FadeSlideUp'>
            <Box borderSize='x3' borderColor='light-gray' padding='x2'>
                <Text size='x2' strong>Price suggestion tool</Text>
                <Box flex='horizontal' alignChildrenVertical='end'>
                    <Box flex='vertical' grow gap='x2'>
                        <Box flex='horizontal' grow gap='x2'>
                            <Box grow flex='vertical' maxWidth='150px' >
                                <Text size='x1'>HD</Text>
                                <Text>£{p}</Text> 
                            </Box>
                            <Box grow flex='vertical' maxWidth='150px' >
                                <Text size='x1'>Contractor</Text>
                                <Text>£{cp}</Text> 
                            </Box>
                            <Button size='small' level='primary' color='blue' disabled={quoteCp === contractorPrice && quoteP === price} onClick={() => onApply(price, contractorPrice)}>Use</Button>
                            <Button size='small' color='light' onClick={() => setBreakdownVisible(true)}>View calculations</Button>
                            {
                                    quoteCp !== contractorPrice || quoteP !== price && <Box backgroundColor='red-light' alignChildrenVertical='middle' textColor='red-dark' style={{ opacity: '0.7'}} padding='x2' borderRadius='x2' flex='horizontal' gap='x2'><AlarmExclamation size='1rem'/>
                                    <Text size='x1' strong> Price not applied </Text>
                                     </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            {
                breakdownVisible && <Modal maxWidth='800px' visible onClose={() => setBreakdownVisible(false)}>
                    <Card backgroundColor="light" padding="x10">
                        <Box flex='horizontal' alignChildrenHorizontal='between'>
                            <Text size='x4'>Breakdown</Text>
                            <Button onClick={() => setBreakdownVisible(false)}>Close</Button>
                        </Box>
                        <Box flex='vertical' gap='x4'>
                            {
                                Object.keys(groupedRepairs).map(repairGroup => (
                                    <>
                                        <Text>{repairGroup}</Text>
                                        <Table borderSize='x1'>
                                            <TableHeaderRow>
                                                <TableHeaderCell>Product</TableHeaderCell>
                                                <TableHeaderCell>Quantity</TableHeaderCell>
                                                <TableHeaderCell>Unit</TableHeaderCell>
                                                <TableHeaderCell>Price</TableHeaderCell>
                                                <TableHeaderCell>Contractor price</TableHeaderCell>
                                            </TableHeaderRow>
                                            {groupedRepairs[repairGroup].map((repair, i) =>
                                                <>
                                                    <TableBodyRow>
                                                        <TableBodyCell>{repair.product?.name}</TableBodyCell>
                                                        <TableBodyCell>{repair.quantity}</TableBodyCell>
                                                        <TableBodyCell>{humanReadableUnitType[repair.unitType!]}</TableBodyCell>
                                                        <TableBodyCell>{calculateRepairsPrice([repair], true)}</TableBodyCell>
                                                        <TableBodyCell>{calculateContractorTotal([repair], true)}</TableBodyCell>
                                                    </TableBodyRow>
                                                    {repair.addOns && repair.addOns.map(e =>
                                                        <TableBodyRow>
                                                            <TableBodyCell>{e.product?.name}</TableBodyCell>
                                                            <TableBodyCell>{e.quantity}</TableBodyCell>
                                                            <TableBodyCell>{humanReadableUnitType[e.unitType!]}</TableBodyCell>
                                                            <TableBodyCell>{calculateRepairsPrice([e], true)}</TableBodyCell>
                                                            <TableBodyCell>{calculateContractorTotal([e], true)}</TableBodyCell>
                                                        </TableBodyRow>
                                                    )}
                                                </>
                                            )}
                                            <TableHeaderRow>
                                                <TableHeaderCell>TOTAL</TableHeaderCell>
                                                <TableHeaderCell></TableHeaderCell>
                                                <TableHeaderCell></TableHeaderCell>
                                                <TableHeaderCell>{calculateRepairsPrice(groupedRepairs[repairGroup])}</TableHeaderCell>
                                                <TableHeaderCell>{calculateContractorTotal(groupedRepairs[repairGroup])}</TableHeaderCell>
                                            </TableHeaderRow>
                                        </Table>
                                    </>
                                ))
                            }
                        </Box>
                    </Card>
                </Modal>
            }
        </Appear>
    );
}

