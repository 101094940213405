import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Box, Card, CardRow, Text } from '@hd/ui';
import { Job, RecurringJob } from '@hd/types';
import { DateTime } from 'luxon';
import FirebaseList from '../Firebase/FirebaseList';
import { Options, TypeOrderBy } from '../../utils/useFirestoreCollection';
import useFirestoreRecurringJobs from '../../utils/useFirestoreRecurringJobs';
import toDateFormat from '../../utils/toDateFormat';
import { MapPin, User } from '@styled-icons/boxicons-regular';

interface Props {
  options?: Options<Job>;
  jobParam?: string;
}

const defaultOrderBys: TypeOrderBy<RecurringJob>[] = [['nextScheduleDate', 'desc']];

const groupByDate = (ids: string[], jobs: Record<string, RecurringJob>) => {
  const groupedIds: Record<string, string[]> = {};

  for (const id of ids) {
    const job = jobs[id];

    if (job) {
      const { nextScheduleDate } = job;
      const key = DateTime.fromJSDate(nextScheduleDate.toDate()).toFormat('DDD');

      groupedIds[key] = groupedIds[key] || [];
      groupedIds[key].push(id);
    }
  }

  return groupedIds;
};

export default function RecurringJobsList(props: Props) {
  const { options, jobParam } = props;
  const data = useFirestoreRecurringJobs({
    ...options,
    limit: 25,
    orderBys: defaultOrderBys,
    resolve: false,
  });

  const { push } = useHistory();


  const groupedIds = groupByDate(data.ids, data.documents);
  console.log(data.documents)
  console.log(data.documents)

  return (
    <FirebaseList {...data} onMore={data.more}>
      {Object.entries(groupedIds).map(([date, ids]) => (
        <Box key={date} margin="x10">
          <Box alignChildren="middle" flex="horizontal" gap="x4" margin="x2">

            <Box grow>
              <Text borderColor="gray" borderSides="T" borderSize="x1" />
            </Box>
          </Box>

          <Card>
            {ids.map((id) => (
              <CardRow
                key={id}
                onClick={() => push(`/${data.documents[id].lastScheduledJob._ref}${jobParam || ''}`)}
                padding="x4"
              >
                <Box alignChildrenVertical="middle" flex="horizontal" gap="x10">
                  <Box basis="none" grow>
                    <Box
                      alignChildrenVertical="middle"
                      flex="horizontal"
                      gap="x2"
                    >
                      <Badge backgroundColor="light-gray" flex="horizontal">
                        <MapPin size="1rem" />
                      </Badge>

                      <Badge backgroundColor="light-gray" flex="horizontal">
                        <User size="1rem" />
                      </Badge>

                    </Box>

                    <Box margin="x3">
                      <Text ellipsis size="x2">
                        {data.documents[id].description}
                      </Text>
                    </Box>
                  </Box>

                  <Box alignChildrenHorizontal="end" flex="vertical" gap="x2">
                    <Text align="end" size="x2" strong>
                      {toDateFormat(data.documents[id].date)}
                    </Text>
                    <Text>{data.documents[id].interval}</Text>
                  </Box>
                </Box>
              </CardRow>
            ))}
          </Card>
        </Box>
      ))}
    </FirebaseList>
  );
}
