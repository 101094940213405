import * as React from 'react';
import {
  Box,
  BoxProps,
  Button,
  FormInput,
  Link,
  TextInput,
} from '@hd/ui';
import { Minus } from '@styled-icons/boxicons-regular';

interface Props extends BoxProps {
  onChange: (values: string[]) => void;
  values: string[];
  type: string;
}

export default function FormInputArray(props: Props) {
  const { onChange, values, type, ...rest } = props;
  const [value, setValue] = React.useState('');

  const handleAdd = () => {
    onChange([...values, value]);
    setValue('');
  };

  const handleChange = (index: number, value: string) => {
    onChange(values.map((v, i) => index === i ? value : v));
  };

  const handleRemove = (index: number) => {
    onChange(values.filter((_, i) => index !== i));
  };

  return (
    <Box { ...rest }>
      { values.map((value, index) => (
        <Box flex="horizontal" gap="x2" key={ index } margin="x3">
          <FormInput grow>
            <TextInput
                data-at-id="FormArrayInput"
                onChange={ (e) => handleChange(index, (e.target as HTMLInputElement).value) }
                required
                value={ value } />
          </FormInput>

          <Button
              color="red"
              level="tertiary"
              onClick={ () => handleRemove(index) }>
            <Minus size="1.25rem" />
          </Button>
        </Box>
      )) }

      <Link data-at-id="FormArrayAdd" onClick={ () => handleAdd() }>
        Add { type }?
      </Link>
    </Box>
  );
}
