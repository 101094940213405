import * as React from 'react';
import firebase from 'firebase/app';
import { Box, Button, Card, FormInput, FormInputLabel, FormInputHeader, Modal, TextInput, CheckBox } from '@hd/ui';
import useFirestoreComplaint from '../../utils/useFirestoreComplaint';
import toDateFormat from '../../utils/toDateFormat';

interface Props {
  complaintId?: string;
  contractorId: string;
  jobId: string;
  onClose: () => void;
  visible: boolean;
}

export default function JobComplaintModal(props: Props) {
  const { complaintId, contractorId, jobId, onClose, visible } = props;
  const { document, update, save } = useFirestoreComplaint(jobId, contractorId, complaintId);

  const handleSave = async() => {
    update({ contractorId });
    await save();
    onClose();
  };

  const handleUpdateResolve = () => {
    update({
      timestampResolved: document.timestampResolved
        ? null : firebase.firestore.Timestamp.now(),
    });
  };

  return (
    <Modal
        maxWidth="600px"
        onClose={ onClose }
        visible={ visible }>
      <Card
          backgroundColor="light"
          padding="x10">
        <FormInput margin="x6">
          <FormInputHeader>
            <FormInputLabel>Complaint</FormInputLabel>
          </FormInputHeader>

          <TextInput
              multiline
              onChange={ (e) => update({ description: (e.target as HTMLInputElement).value }) }
              placeholder="Description..."
              rows={ 10 }
              value={ document.description } />
        </FormInput>

        { complaintId && (
          <FormInput margin="x6">
            <FormInputHeader>
              <FormInputLabel>Date/Time Logged</FormInputLabel>
            </FormInputHeader>

            <TextInput
                disabled
                onChange={ () => {} }
                value={ toDateFormat(document.timestampLogged, true) || '' } />
          </FormInput>
        ) }

        { document.timestampResolved && (
          <FormInput margin="x6">
            <FormInputHeader>
              <FormInputLabel>Date/Time Resolved</FormInputLabel>
            </FormInputHeader>

            <TextInput
                disabled
                onChange={ () => {} }
                value={ toDateFormat(document.timestampResolved, true) || '' } />
          </FormInput>
        ) }

        <CheckBox
            checked={ !!document.timestampResolved }
            margin="x4"
            onChange={ handleUpdateResolve }>
          Has this complaint been resolved?
        </CheckBox>

        <Box alignChildrenHorizontal="end" flex="horizontal" gap="x4" margin="x10" >
          <Button onClick={ onClose }>
            Cancel
          </Button>

          <Button
              color="blue"
              level="primary"
              onClick={ handleSave }
              type="submit">
            { complaintId ? 'Update' : 'Create' } Complaint
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
