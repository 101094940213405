import * as React from 'react';
import { Step } from '../CCTVRecommendationsRecommendationForm';
import { Box, Button, Appear, TextInput, Text, Alert } from '@hd/ui';
import { Repair } from '@hd/types';


type Props = {
    activeStepId?: Step;
    setStepId: (s: Step) => void;
    repair: Repair;
    update: (update: Partial<Repair>) => void;
}

const MULTI_SECTION_PATTERN =  /^\d+,\d+(,\d+)*$/;

export default function SectionApplicability(props: Props) {
    const { repair, update, activeStepId, setStepId } = props;

    if(activeStepId !== 'SectionApplicability') {
        return null;
    }

    const isDisabled = () => {
      if(repair.sectionApplication === undefined) {
        return true;
      } 
      if(repair.sectionApplication === 'Single') {
        return repair.section === undefined || repair.section === '';
      } 
      if(repair.sectionApplication === 'Multiple') {
        return repair.section === undefined || !MULTI_SECTION_PATTERN.test(repair.section.replace(new RegExp(' ', 'g'), ''))
      } else return false

    }

    return (
      <Box flex='vertical' alignChildrenHorizontal='middle' gap='x3'>
        <Text size='x3' strong>What sections/laterals does this apply to?</Text>

        <Button level='primary' width='100%' active={repair.sectionApplication === 'Single'} color={repair.sectionApplication === 'Single' ? 'blue' : 'light'} onClick={() => { 
          update({ 
                sectionApplication: 'Single',


          }) }}>Single section/lateral</Button> 
        { repair.sectionApplication === 'Single' && 
          <Appear animation='FadeSlideDown'>
            <TextInput type='number' width='100%' autoFocus onChange={(e) => 
              update({
                section: ((e.target as HTMLInputElement).value),
              })
          } value={repair.section || ''} /> 
          </Appear>
        }

          <Button level='primary' width='100%' color={repair.sectionApplication === 'Multiple' ? 'blue' : 'light'} onClick={() => { 
            
            update({
              sectionApplication: 'Multiple',

            })}}>Multi section/lateral</Button> 
          { repair.sectionApplication === 'Multiple' && 
            <Appear animation='FadeSlideDown'>
              {isDisabled() && <Appear animation='FadeSlideDown'>
                <Alert>Please input in format e.g: 3,4,6</Alert>
              </Appear>

              }
              <TextInput width='100%' autoFocus onChange={(e) => update({
                section: (e.target as HTMLInputElement).value,
                })} value={repair.section || ''} /> 
            </Appear>
          }

        <Button level='primary' width='100%' color={repair.sectionApplication === 'Site wide' ? 'blue' : 'light'} onClick={() => { 
          update({
            sectionApplication: 'Site wide',
          }) 
          
          }}>Site wide</Button> 

      <Box flex='horizontal' grow width='100%' alignChildrenHorizontal='end'>
        <Button color='blue' onClick={() => setStepId('Menu')} disabled={isDisabled()}>{'>'}</Button>
      </Box>

    </Box>
  )

}