import numbro from 'numbro';

export default function toCurrencyFormat(n: string) {
  const number = parseFloat(n);

  if (isNaN(number)) {
    return '-';
  }


  return numbro(number).formatCurrency({
    thousandSeparated: true,
    mantissa: 2,
  });
}
