import {
  Box,
  Button,
  Page,
  PageBack,
  PageBody,
  PageHeader,
  PageTitle,
} from '@hd/ui';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useFirestoreJob from '../../utils/useFirestoreJob';
import { validateJob, FormError } from '../../utils/formValidators';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FormErrorMessage from '../FormErrorMessage';
import JobForm from './JobForm';

export default function JobCreateChild() {
  const { push } = useHistory();
  const { jobId } = useParams<{ jobId: string }>();
  const jobParent = useFirestoreJob(jobId);
  const job = useFirestoreJob();
  const [error, setError] = React.useState<FormError | null>(null);

  const handleCreate = async () => {
    const error = validateJob(job.document);
    setError(error);

    if (!error) {
      jobParent.update({ child: await job.save() });
      await jobParent.save();
      push(`/jobs/${jobParent.document._id}`);
    }
  };

  React.useEffect(() => {
    if (
      job.document.customer !== jobParent.document.customer &&
      job.document.customerSite !== jobParent.document.customerSite &&
      job.document.parent !== jobParent.document &&
      jobParent.document.child !== job.document
    ) {
      job.update({
        customer: jobParent.document.customer,
        customerSite: jobParent.document.customerSite,
        customerName: jobParent.document.customerName,
        parent: jobParent.document,
      });
    }
  }, [job, jobParent]);
  console.log(job.document.customer)

  return (
    <FirebaseDocument {...jobParent} allowNoDocument>
      <Page>
        <PageHeader>
          <PageBack to="/jobs">Jobs</PageBack>
          <PageTitle>
            Create follow up Job from #{jobParent.document.jobNumber}
          </PageTitle>
        </PageHeader>

        <PageBody>
          <JobForm job={job.document} update={job.update} />

          {error && <FormErrorMessage error={error} />}

          <Box
            alignChildrenHorizontal="end"
            flex="horizontal"
            gap="x2"
            margin="x4"
            disabled={job.document.customer?.accountOnHold }
          >
            <Button color="blue" level="primary" onClick={handleCreate}>
              Create Job
            </Button>
          </Box>
        </PageBody>
      </Page>
    </FirebaseDocument>
  );
}
