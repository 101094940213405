import * as React from 'react';
import { Box, Text } from '@hd/ui';
import { Contractor } from '@hd/types';
import getAddress from '../../utils/getAddress';

export interface Props {
  contractor: Contractor;
}

export default function ContractorView(props: Props) {
  const { contractor } = props;

  return (
    <Box>
      <Text strong>{contractor.name}</Text>
      <Text>Tier: {contractor.priceTier}</Text>
      <Text size="x2" textColor="dark-gray">
        {getAddress(contractor)}
      </Text>
    </Box>
  );
}
