import { Badge, TypeColor } from '@hd/ui';
import { Job, TypeJobStatus } from '@hd/types';
import * as React from 'react';
import toStatusFormat from '../../utils/toStatusFormat';
import useFirestoreJobPayments from '../../utils/useFirestoreJobPayments';

const backgroundColor: Record<TypeJobStatus, TypeColor> = {
  AWAITING_AUTHORISATION: 'yellow',
  AWAITING_AUTHORISATION_CCTV: 'yellow',
  AWAITING_AUTHORISATION_OTHER: 'yellow',
  UNALLOCATED: 'yellow',
  IN_PROGRESS: 'blue-light',
  CANCELLED: 'light-gray',
  COMPLETED: 'blue-light',
  TO_CHASE_COMMERCIAL: 'red-light',
  TO_CHASE_DOMESTIC: 'red-light',
  WRITTEN_OFF: 'red-light',
  PARTIALLY_WRITTEN_OFF: 'red-light',
  NO_FIX: 'red-light',
  PAID: 'green-light',
  AWAITING_SURVEY: 'yellow',
  CONTRACTOR_RAISED: 'yellow',
};

const textColor: Record<TypeJobStatus, TypeColor> = {
  AWAITING_AUTHORISATION: 'yellow-dark',
  AWAITING_AUTHORISATION_CCTV: 'yellow-dark',
  AWAITING_AUTHORISATION_OTHER: 'yellow-dark',
  UNALLOCATED: 'dark-gray',
  IN_PROGRESS: 'blue-dark',
  CANCELLED: 'dark-gray',
  COMPLETED: 'blue-dark',
  TO_CHASE_COMMERCIAL: 'red-dark',
  TO_CHASE_DOMESTIC: 'red-dark',
  PAID: 'green-dark',
  AWAITING_SURVEY: 'yellow-dark',
  WRITTEN_OFF: 'dark-gray',
  PARTIALLY_WRITTEN_OFF: 'dark-gray',
  CONTRACTOR_RAISED: 'yellow-dark',
  NO_FIX: 'red-dark'
};

export const getStatus = (job: Job): TypeJobStatus => {
  const payments = useFirestoreJobPayments(job._id)
  if (job.status === 'COMPLETED') {
    if (job.date.toDate() < new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)) {
      return job.isCommercial ? 'TO_CHASE_COMMERCIAL' : 'TO_CHASE_DOMESTIC';
    }
  }
  if(job.status === 'PAID') {
    const creditNotes = payments.ids.filter(e => payments.documents[e].paymentMethod === 'Credit Note');
    if(creditNotes.length > 0 && creditNotes.length === payments.ids.length) {
      return 'WRITTEN_OFF'
    }
    if(creditNotes.length > 0) {
      return 'PARTIALLY_WRITTEN_OFF'
    }
  }

  return job.status;
};

export default function StatusBadge({ job }: { job: Job }) {
  const status = getStatus(job);

  return (
    <Badge
      backgroundColor={backgroundColor[status]}
      textColor={textColor[status]}
    >
      {toStatusFormat(status)}
    </Badge>
  );
}
