import { Box, Line } from '@hd/ui';
import { ChevronRight, CheckCircle } from '@styled-icons/boxicons-regular';
import { StyledIcon } from '@styled-icons/styled-icon';
import * as React from 'react';

interface Props {
  selected: boolean;
}

export default function JobSelectionDivider(props: Props) {
  const { selected } = props;

  const Icon: StyledIcon =
    (selected && CheckCircle) ||
    ChevronRight;

  return (
    <>
      <Box alignSelf="middle">
        <Line borderColor="gray" borderSize="x2" width="10px" />
      </Box>

      <Box alignSelf="middle" textColor="gray">
        <Icon size="24px" />
      </Box>

      <Box alignSelf="middle">
        <Line borderColor="gray" borderSize="x2" width="10px" />
      </Box>
    </>
  );
}
