import * as React from 'react';
import { Box, Card, Image, Link, Page, PageBody, PageFooter, PageHeader, Text } from '@hd/ui';
import { File as FileIcon } from '@styled-icons/boxicons-regular';

const template = [
  {
    name: 'Confined space log',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/Confined%20space%20log.docx',
    description: 'Confined space log'
  },
  {
    name: 'RAMS - Excavation - clearance - cctv',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/RAMS%20-%20Excavation%20-%20clearance%20-%20cctv.docx',
    description: 'RAMS - Excavation - clearance - cctv'
  },
  {
    name: 'RAMS- Confined Spaces',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/RAMS-%20Confined%20Spaces.docx',
    description: 'You need this one if...'
  },
  {
    name: 'RAMS-DRAINAGE REPAIRS excavations',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/RAMS-DRAINAGE%20REPAIRS%20excavations.docx',
    description: 'You need this one if...'
  },
  {
    name: 'RAMS-Lining - jetting - cctv 2023',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/RAMS-Lining%20-%20jetting%20-%20cctv%202023.docx',
    description: ''
  },
  {
    name: 'RAMS-Lining-repairs -picote - jetting - cctv 2023',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/RAMS-Lining-repairs%20-picote%20-%20jetting%20-%20cctv%202023.docx',
    description: ''
  },
  {
    name: 'RAMS-Lining-repairs -picote - jetting - cctv',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/RAMS-Lining-repairs%20-picote%20-%20jetting%20-%20cctv.docx',
    description: ''
  },
  {
    name: 'Rams (ship) - cctv - Cleaning with hospital A and E',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/Rams%20(ship)%20-%20cctv%20-%20Cleaning%20with%20hospital%20A%20and%20E.docx',
    description: ''
  },
  {
    name: 'Rams - cctv - Cleaning + Tanker with hospital',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/Rams%20-%20cctv%20-%20Cleaning%20%2B%20Tanker%20with%20hospital.docx',
    description: ''
  },
  {
    name: 'Risk Assessements/Rams - cctv - Cleaning + Tanker',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/Rams%20-%20cctv%20-%20Cleaning%20%2B%20Tanker.docx',
    description: ''
  },
  {
    name: 'Rams - cctv - Cleaning HMP prisons',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/Rams%20-%20cctv%20-%20Cleaning%20HMP%20prisons.docx',
    description: ''
  },
  {
    name: 'Rams - cctv - Cleaning with hospital A and E - Copy',
    url: 'https://storage.googleapis.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/Rams%20-%20cctv%20-%20Cleaning%20with%20hospital%20A%20and%20E%20-%20Copy.docx',
    description: ''
  },
  {
    name: 'Rams - cctv - jetting',
    url: 'https://storage.cloud.google.com/happy-drains-job-managment.appspot.com/assets/Risk%20Assessements/Rams%20-%20cctv%20-%20jetting.docx',
    description: ''
  },
]

export default function RiskAssessmentTemplates() {
  return (
    <Page
        backgroundColor="light-gray"
        padding="x6">
      <PageBody
          alignChildrenHorizontal="middle"
          flex="vertical"
          maxWidth="600px"
          paddingVertical="x20">
        <Box margin='x4'>
          <Image margin="x10" src={ require('@hd/ui/assets/logo.png') } width="200px" />
          <Text size='x5'>Risk assessment templates</Text>
        </Box>
        <Box flex='vertical' gap='x2'>
          {template.map(template => (
            <Card backgroundColor='light-gray' padding='x2'>
                <Box alignChildrenVertical="middle" flex="horizontal" gap="x3">
                  <Box textColor="gray">
                    <FileIcon size="1.25rem" /> 
                  </Box>
                </Box>
                <Link href={template.url} target='_blank'  download={template.name}><Text strong>{template.name}</Text></Link>
            </Card>))
          }
          </Box>
      </PageBody>
      <PageFooter />
    </Page>
  );
}
