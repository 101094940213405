import * as React from 'react';
import { Card, Text } from '@hd/ui';

export default function CompleteSuccess() {
  return (
    <Card
        backgroundColor="blue-dark"
        margin="x6"
        padding="x6"
        textColor="dark">
      <Text margin="x3" size="x4" strong>Successfully Completed.</Text>
      <Text>Our office has been notified and you will receive an invoice shortly.</Text>
    </Card>
  );
}
