import { JobFile } from '@hd/types';
import { Error, File as FileIcon } from '@styled-icons/boxicons-regular';
import { Box, BoxProps, CheckBox, Link, Text } from '@hd/ui';
import * as React from 'react';
import toFileSizeFormat from '../../utils/toFileSizeFormat';

interface Props extends BoxProps {
  file: File | JobFile;
  isValid: boolean;
  onRemoveFile?: () => void;
  onToggleVisibility?: (visible: boolean) => void;
}

export default function FileListItem(props: Props) {
  const { file, isValid = true, onRemoveFile, onToggleVisibility, ...rest } = props;

  return (
    <Box {...rest} alignChildrenVertical="middle" flex="horizontal" gap="x3">
      <Box textColor="gray">
        {isValid ? <FileIcon size="1.25rem" /> : <Error size="1.25rem" />}
      </Box>

      <Text basis="none" flex="horizontal" gap="x2" grow size="x2">
        <Text basis="none" grow strong>
          <Text display="inline-block" strong>
            {file.name}
          </Text>{' '}
          ({toFileSizeFormat(file.size)} )
        </Text>
        <Text>
          {!(file instanceof File) && file.downloadUrl && (
            <Link onClick={() => window.open(file.downloadUrl, file.name)}>
              View
            </Link>
          )}{' '}
          |{' '}
          {onRemoveFile && (
            <Link onClick={() => onRemoveFile()} textColor="red">
              Delete
            </Link>
          )}
        </Text>
      </Text>
        <Text>
          {!(file instanceof File) && onToggleVisibility && (
            <Box flex='vertical' alignChildrenHorizontal='middle'>
              <CheckBox checked={file.isPublic} onChange={() => {
                console.log(file.isPublic)
                onToggleVisibility(!file.isPublic)
              }}/>
                <Text size='x1' strong>Contractor</Text>
                <Text size='x1' strong>Accessable</Text>
            </Box>
          )}{' '}
        </Text>
    </Box>
  );
}
