import {
  Text,
  Badge,
  Box,
  Button,
  Page,
  PageBack,
  PageBody,
  PageHeader,
  PageTitle,
  Card,
  CardRow,
  Place,
} from '@hd/ui';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormError } from '../../utils/formValidators';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FormErrorMessage from '../FormErrorMessage';
import useFirestoreQuote from '../../utils/useFirestoreQuote';
import QuoteForm from './QuoteForm';
import firebase from 'firebase';
import JobReportFollowUpQuoteEmails from '../Job/JobReportFollowUpQuoteEmails';
import useFirestoreQuoteFiles from '../../utils/useFirestoreQuoteFiles';
import FileListItem from '../FileList/FileListItem';
import { LinkAlt } from '@styled-icons/boxicons-regular';

export default function QuoteView() {
  const { push } = useHistory();
  const { quoteId } = useParams<{ quoteId: string }>();
  const [error, setError] = React.useState<FormError | null>(null);
  const { document: quote, update, save } = useFirestoreQuote(quoteId);
  const files = useFirestoreQuoteFiles(quoteId);
  const [refMenuLink, setRefMenuLink] = React.useState<HTMLElement | null>(null);
  const [isLinkMenuVisible, setShowLinkMenu] = React.useState(false);

  const handleUpdate = async () => {
    await save();
  };

  const convertToJob = async () => {
    const {
      data: { jobId },
    } = await firebase.functions().httpsCallable('convertQuoteToJob')({
      quoteId: quote._id,
    });
    push(`/jobs/${jobId}`);
  };

  const handleCancel = async () => {
    update({
      status: 'CANCELLED',
    });
    await save();
  };

  return (
    <FirebaseDocument {...quote} allowNoDocument={!quoteId}>
      <Page>
        <PageHeader>
          <Box alignChildrenVertical="end" flex="horizontal" gap="x4" grow>
            <Box basis="none" grow>
              <PageBack to="/quotes">Quotes</PageBack>

              <Box alignChildrenVertical="middle" flex="vertical" gap="x4">
                <Box flex="horizontal" alignChildrenHorizontal="between">
                <PageTitle flex="horizontal" gap="x3">
                  {quote.quoteId || 'New quote'}</PageTitle>
                  <Box>
                    <Badge backgroundColor='blue-light'>{quote.status}</Badge>
                  </Box>
              <Button
                  color="blue"
                  level="tertiary"
                  onClick={ () => setShowLinkMenu(true) }
                  ref={ setRefMenuLink }>
                <LinkAlt size="20px" />
                <Text>Links</Text>
              </Button>

              <Place
                  minWidth="reference"
                  onClose={ () => setShowLinkMenu(false) }
                  referenceElement={ refMenuLink }
                  visible={ isLinkMenuVisible }>
                <Card backgroundColor="light">
                  <Box maxHeight="200px" scrollable>
                    { quote.magicId && (
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/quote/cu/${quote.magicId}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">Customer's link</Text>
                      </CardRow>
                    ) }

                    { quote.fromWebInquiry && (
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/web-inquiries/${quote.fromWebInquiry}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">Web inquiry</Text>
                      </CardRow>
                    ) }
                    { quote.toJob && (
                      <CardRow
                          data-at-id="DropdownOption"
                          flex="horizontal"
                          href={`/jobs/${quote.toJob}`}
                          paddingHorizontal="x4"
                          paddingVertical="x3"
                          tag="a"
                          >
                        <Text size="x2">Job</Text>
                      </CardRow>
                    ) }





                  </Box>
                </Card>
              </Place>
              </Box>
            </Box>
          </Box>
        </PageHeader>

        <PageBody>
          {error && <FormErrorMessage error={error} />}
          <QuoteForm quote={quote} update={update} />
          {
            files.ids.length > 0 && 
            <Card padding="x3" margin='x3'>
              <Text>File</Text>
              {
              files.ids.map((id) => ( 
              <CardRow key={id} padding="x3"> <FileListItem file={files.documents[id]} /> </CardRow>
            ))
          }
            </Card>
            
          }

          {quote._id && (
            <Card padding="x3">
              <JobReportFollowUpQuoteEmails quote={quote} save={save} />
            </Card>
          )}
          <Box
            alignChildrenHorizontal="end"
            flex="horizontal"
            gap="x2"
            margin="x4"
          >
            {quote.status === 'FOLLOW_UP_ACCEPTED_UNPAID' && (
              <Button onClick={convertToJob} level="primary" color="blue">
                Override and convert to job
              </Button>
            )}
            <Button color="blue" level="primary" onClick={handleUpdate}>
              Update
            </Button>
            <Button color="red" level="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </PageBody>
      </Page>
    </FirebaseDocument>
  );
}
