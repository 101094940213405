import { CCTVRecommendationProduct, CCTVRecommendationProductAddOn, CCTVRecommendationProductUnit } from '@hd/types';
import {
  Appear,
  Badge,
  Box,
  Button,
  Card,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Text,
  TextInput,
} from '@hd/ui';
import { DateTime } from 'luxon';
import { ChevronDown, ChevronUp, Hash, Plus, Pound, Trash } from '@styled-icons/boxicons-regular';
import * as React from 'react';
import { humanReadableUnitType, humanReadableUnitTypeSingular } from '../../constants/CCTVReccommendationContants';
import ProgressivePriceInput from './ProgressivePriceInput';
import useInputNumber from '../../utils/useInputNumber';
import ProgressivePriceView from './ProgressivePriceView';

const dateToday = DateTime.local().startOf('day').toJSDate();

interface Props {
  product: CCTVRecommendationProduct | CCTVRecommendationProductAddOn;
  unit: CCTVRecommendationProductUnit;
  update: (updates: Partial<CCTVRecommendationProduct>) => void;
}

export default function ProductPriceInput(props: Props) {
  const { unit, update, product } = props;


  const addVolumeDiscount = () => {
      update({
        pricing: product.pricing.map(p => {
          if(p.type === unit.type)  {
            return {
              ...p,
              progressivePrice: [],
            }
          } else return p

        })
      })
  }

  const removeVolumeDiscount = () => {
    update({
      pricing: product.pricing.map(p => {
        if(p.type === unit.type)  {
          return {
            price: unit.price,
            min: unit.min,
            type: unit.type,
            contractorPrice: unit.contractorPrice
          }
        } else return p

      })
    })
  }

  const removeProgressivePrice = (id: string) => {
      update({
        pricing: product.pricing.map(p => {
          if(p.type === unit.type)  {
            return {
              ...p,
              progressivePrice: unit.progressivePrice?.filter(e => e.id !== id)
            }
          } else return p

        })
      })

  }

  const updateMinimum = (min: number) => {
      update({
        pricing: product.pricing.map(p => {
          if(p.type === unit.type)  {
            return {
              ...p,
              min
            }
          } else return p

        })
      })
  }
  const updateContractorP = (contractorPrice: number) => {
      update({
        pricing: product.pricing.map(p => {
          if(p.type === unit.type)  {
            return {
              ...p,
              contractorPrice
            }
          } else return p

        })
      })
  }

  const updateP = (price: number) => {
      update({
        pricing: product.pricing.map(p => {
          if(p.type === unit.type)  {
            return {
              ...p,
              price
            }
          } else return p

        })
      })
  }
  const [min, updateMin] = useInputNumber(unit.min, updateMinimum)
  const [contractorPrice, updateContractorPrice] = useInputNumber(unit.contractorPrice, updateContractorP)
  const [price, updatePrice] = useInputNumber(unit.price, updateP)

  const flatPrice = unit.progressivePrice === undefined

  return (
    <Card backgroundColor="light" margin="x4" padding="x6" flex='vertical' gap='x3'>
      <Appear animation='FadeSlideDown'>
        <Text size='x4'> {humanReadableUnitType[unit.type]} pricing</Text>
        <Box flex="vertical" gap="x6" margin="x6">
          <Box flex='horizontal' alignChildrenHorizontal='around' gap='x6'>
            <FormInput grow maxWidth='200px'>
              <FormInputHeader>
                <FormInputLabel>Minimum</FormInputLabel>
              </FormInputHeader>
              <TextInput value={min} onChange={updateMin} IconPre={Hash} />
            </FormInput>
            {
              unit.type === 'Quantity' && 'requiresAtPosition' in product &&
              <FormInput grow maxWidth='400px' alignChildrenHorizontal='middle' flex='vertical'>
                <FormInputHeader width='145px' alignSelf='middle'>
                  <FormInputLabel>Requires at position</FormInputLabel>
                </FormInputHeader>
                <CheckBox
                  data-at-id="AtPosition"
                  onChange={() => update({
                    requiresAtPosition: !product.requiresAtPosition
                  })}
                  checked={product.requiresAtPosition}
                />
              </FormInput>
            }

            {
              unit.type === 'Meterage' && 'requiresFromToPosition' in product &&
              <FormInput grow maxWidth='400px' alignChildrenHorizontal='middle' flex='vertical'>
                <FormInputHeader width='185px' alignSelf='middle'>
                  <FormInputLabel>Requires from/to position</FormInputLabel>
                </FormInputHeader>
                <Box>
                <CheckBox
                  data-at-id="FromToPosition"
                  onChange={() => update({
                    requiresFromToPosition: !product.requiresFromToPosition
                  })}
                  checked={product.requiresFromToPosition}
                />
</Box>
              </FormInput>
            }

          </Box>



          <Box flex='horizontal' gap='x3'>
            <FormInput grow maxWidth='300px'>
              <FormInputHeader>
                <FormInputLabel>Price</FormInputLabel>
              </FormInputHeader>
              <TextInput value={price} onChange={updatePrice} IconPre={Pound} />
            </FormInput>
            <FormInput grow maxWidth='300px'>
              <FormInputHeader>
                <FormInputLabel>Contractor price</FormInputLabel>
              </FormInputHeader>
              <TextInput value={contractorPrice} onChange={updateContractorPrice} IconPre={Pound} />
            </FormInput>
            <Box flex='vertical' alignChildrenVertical='end'>
              {flatPrice &&
                <Button onClick={addVolumeDiscount}>
                  <Text size='x1'>Use progressive price</Text>
                </Button>
              }
              {
                !flatPrice &&
                <Button onClick={removeVolumeDiscount}>
                  <Text size='x1'>Use flat price</Text>
                </Button>
              }
            </Box>
          </Box>
        </Box>
      </Appear>
      {unit.progressivePrice &&
        <>
          <Text size='x4'>Progressive price table</Text>
          <Appear animation='FadeSlideDown' flex='horizontal' gap='x3' padding='x4' grow backgroundColor='light-gray'>
            <Box flex='vertical' gap='x3' grow>
              <ProgressivePriceView progressivePrice={{
                id: '',
                threshold: 0,
                contractorPrice: unit.contractorPrice,
                price: unit.price,
              }} unit={unit} onRemove={id => removeProgressivePrice(id)} readOnly />
              {unit.progressivePrice.sort((a, b) => a.threshold > b.threshold ? 1 : -1).map(e =>
                <ProgressivePriceView key={e.id} progressivePrice={e} unit={unit} onRemove={id => removeProgressivePrice(id)} />
              )}
              <ProgressivePriceInput product={product} unit={unit} update={update} />
            </Box>
          </Appear>
        </>
      }
    </Card>
  );
}
