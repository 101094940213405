import * as React from 'react';
import {
  Badge,
  Box,
  Button,
  Card,
  CardRow,
  FormInput,
  Text,
  TextInput,
} from '@hd/ui';
import { Job } from '@hd/types';
import { MapPin, Plus } from '@styled-icons/boxicons-regular';
import FirebaseList from '../Firebase/FirebaseList';
import useFirestoreCollection, {
  Options,
} from '../../utils/useFirestoreCollection';
import useKeywordFilter from '../../utils/useKeywordFilter';
import useMergedFilters from '../../utils/useMergedFilters';
import SiteView from '../Sites/SiteView';

interface Props {
  options?: Options<Job>;
  onSelect: (job: Job) => void;
}

export default function AdminJobsList(props: Props) {
  const { onSelect, options } = props;
  const [value, setValue] = React.useState('');
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);
  const data = useFirestoreCollection<Job>('Job', 'jobs', {
    filters: filters,
    limit: 50,
    resolve: true,
  });

  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow>
          <FormInput margin="x3">
            <TextInput
              onChange={(e) => setValue((e.target as HTMLInputElement).value)}
              placeholder="Search notes and quotes"
              value={value}
            />
          </FormInput>
        </Box>

        <Box>
          <Button
            color="blue"
            data-at-id="AddNoteOrQuoteButton"
            level="tertiary"
            onClick={() => setIsAddNoteOrQuoteVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add note/quote</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data} onMore={data.more}>
        <Card>
          {data.ids.map(
            (id) =>
              data.documents[id].child === undefined && (
                <CardRow
                  alignChildrenVertical="middle"
                  data-at-id="ContractorLisItem"
                  flex="horizontal"
                  key={id}
                  onClick={() => onSelect(data.documents[id])}
                  padding="x4"
                >
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Badge backgroundColor="dark-gray" textColor="light-gray">
                      {data.documents[id].jobNumber}
                    </Badge>

                    <Badge backgroundColor="light-gray" flex="horizontal">
                      <MapPin size="1rem" />
                      <SiteView site={data.documents[id].customerSite} />
                    </Badge>
                  </Box>
                </CardRow>
              )
          )}
        </Card>
      </FirebaseList>
    </>
  );
}
