import { CCTVRecommendationProduct, CCTVRecommendationProductAddOn, CCTVRecommendationProductUnit } from '@hd/types';
import {
  Box,
  Button,
  TextInput,
  Text,
  Alert,
  Appear,
  FormInput,
  FormInputHeader,
  FormInputLabel
} from '@hd/ui';
import { v4 } from 'uuid';
import { DateTime } from 'luxon';
import * as React from 'react';
import { humanReadableUnitType } from '../../constants/CCTVReccommendationContants';
import { Hash, Plus, Pound } from '@styled-icons/boxicons-regular';

const dateToday = DateTime.local().startOf('day').toJSDate();

interface Props {
  product: CCTVRecommendationProductAddOn;
  unit: CCTVRecommendationProductUnit;
  update: (updates: Partial<CCTVRecommendationProductAddOn> | Partial<CCTVRecommendationProduct>) => void;
}

export default function ProgressivePriceInput(props: Props) {
  const { unit, update, product } = props;
  const [threshold, setThreshold] = React.useState<number>()
  const [contractorPrice, setContractorPrice] = React.useState<number>()
  const [price, setPrice] = React.useState<number>()
  const [errorMessage, setErrorMesage] = React.useState<string>()


  const addVolumeDiscount = () => {
    const error = getError()
    if(error) {
      setErrorMesage(error)
    } else {
      setErrorMesage(undefined)
      update({
        pricing: product.pricing.map(p => {
          if(p.type === unit.type)  {
            return {
              ...p,
              progressivePrice: [...p.progressivePrice || [], { threshold: threshold!, contractorPrice: contractorPrice!, price: price!, id: v4()}]
            }
          } else return p

        })
      })
      setThreshold(undefined)
      setPrice(undefined)
      setContractorPrice(undefined)

    }
  }

  const getError = () => {
    const thresholds = (unit.progressivePrice || []).map(e => e.threshold)
    console.log(thresholds)
    if(threshold === undefined || threshold < 0) {
      return 'Invalid threshold'
    }
    if(thresholds.includes(threshold)) {
      return "Already using threshold"
    }
    if(price === undefined) {
      return 'Invalid price'
    }
    if(contractorPrice === undefined) {
      return 'Invalid contractor price'
    }
    if(contractorPrice > price) {
      return "Contractor price is greater than price"
    }
  }

  return (<>
    <Box flex='horizontal' gap='x3' grow elevation='x2' padding='x3' borderRadius='x2' backgroundColor='light' alignChildrenVertical='end'>

      <FormInput grow>
        <FormInputHeader>
          <FormInputLabel>After minimum number</FormInputLabel>
        </FormInputHeader>
        <TextInput size='small' min='1' type='number' onChange={(e) => setThreshold(parseFloat((e.target as HTMLInputElement).value))} value={threshold || ''} IconPre={Hash}/>
      </FormInput>

      <FormInput grow>
        <FormInputHeader>
          <FormInputLabel>HD Price</FormInputLabel>
        </FormInputHeader>
        <TextInput size='small' grow min='1' type='number' onChange={(e) => setPrice(parseFloat((e.target as HTMLInputElement).value))} value={price || ''} IconPre={Pound}/>
      </FormInput>

      <FormInput grow>
        <FormInputHeader>
          <FormInputLabel>Contractor price</FormInputLabel>
        </FormInputHeader>
        <TextInput size='small' min='1' grow type='number' onChange={(e) => setContractorPrice(parseFloat((e.target as HTMLInputElement).value))} value={contractorPrice || ''} IconPre={Pound}/>
      </FormInput>
      <Box>
        <Button onClick={addVolumeDiscount} color='blue'><Plus size='1rem'/></Button>
      </Box>
    </Box>
    {
      errorMessage && <Appear animation='FadeSlideDown'>
        <Alert backgroundColor='red-light' textColor='red-dark' borderColor='red-dark'>
          {errorMessage}
        </Alert>
      </Appear>
    }
  </>
  );
}
