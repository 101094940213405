import * as React from 'react';
import { Box, Button, Card, Modal, Text } from '@hd/ui';
import { FormError } from '../utils/formValidators';
import FormErrorMessage from './FormErrorMessage';

interface Props {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  visible: boolean;
  error?: FormError;
}

export default function ConfirmationModal(props: Props) {
  const { message, onCancel, onConfirm, visible, error } = props;

  return (
    <Modal maxWidth="300px" onClose={onCancel} visible={visible}>
      <Card backgroundColor="light" padding="x6">
        <Text>{message} </Text>

        {error && <FormErrorMessage error={error} />}

        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x4"
          margin="x10"
        >
          <Button onClick={onCancel}>Cancel</Button>

          <Button color="red" level="primary" onClick={onConfirm} type="submit">
            Confirm
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
