import { CCTVRecommendationSubcategory } from '@hd/types';
import {
  Box,
  Card,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Text,
  TextInput,
} from '@hd/ui';
import * as React from 'react';
import SubcategorySelectionBox from './SubcategorySelectionBox';
import SubcategorySelectionViewCategory from './SubcategorySelectionViewCategory';
import SubcategorySelectionModalCategories from './SubcategorySelectionModalCategories';
interface Props {
  subcategory: CCTVRecommendationSubcategory;
  update: (updates: Partial<CCTVRecommendationSubcategory>) => void;
}

export default function SubcategoryForm(props: Props) {
  const { subcategory, update } = props;

  return (<>
    <Box flex="horizontal" margin="x8">
      <Box basis="none" flex="vertical" grow>
        <Text margin="x3" strong>
          Category
        </Text>
      <SubcategorySelectionBox
        SelectionModal={SubcategorySelectionModalCategories}
        SelectionView={SubcategorySelectionViewCategory}
        data-at-id="AddCategory"
        onSelect={e => update({
          parentId: e.id,
          cachedParentName: e.name
        })}
        subcategory={subcategory}
      />
      </Box>
    </Box>

    <Card backgroundColor="light" margin="x4" padding="x6">
      <Box flex="horizontal" gap="x6" margin="x6">
        <FormInput grow>
          <FormInputHeader>
            <FormInputLabel>Name</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="SubcategoryName"
            onChange={(e) => update({
              name: (e.target as HTMLInputElement).value
            })}
            value={subcategory.name}
          />
        </FormInput>
      </Box>
      </Card>

  </>
  );
}
