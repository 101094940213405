import { Customer, Site } from '@hd/types';
import { Modal, ModalProps } from '@hd/ui';
import * as React from 'react';
import CustomersList from '../Customers/CustomersList';

interface Props extends ModalProps {
  site: Site;
  onClose: () => void;
  onSelect: (value: Customer) => void;
}

export default function SiteSelectionModalCustomer(props: Props) {
  const {
    site,
    onClose,
    onSelect,
    ...rest
  } = props;

  return (
    <Modal { ...rest }
        maxWidth="600px"
        onClose={ onClose }>
      <CustomersList onSelect={ onSelect } site={ site } />
    </Modal>
  );
}
