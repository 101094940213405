import * as React from 'react';
import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Box, PageLoading, Text } from '@hd/ui';
import useIsUserSignedIn from '../utils/useIsUserSignedIn';
import AppSidebar from './AppSidebar';
import ContractorsIndex from './Contractors';
import CustomersIndex from './Customers';
import JobCreate from './Job/JobCreate';
import QuoteCreate from './Quote/QuoteCreate';
import JobsIndex from './Jobs';
import SitesIndex from './Sites';
import AdminIndex from './Admin';
import QuotesIndex from './Quotes';
import WebInquiriesIndex from './WebInquiries/Index';
import { Bookmark } from '@hd/types';
import RecurringJobsIndex from './RecurringJobs';
import { Bookmark as BookmarkIcon } from '@styled-icons/boxicons-regular';
import ProductsIndex from './Products';
import BookmarkButton from './Bookmark/BookmarkButton';
import firebase from 'firebase';

export const BookmarkContext = React.createContext<{
  bookmarks: Bookmark[];
  addBookmark: (id: Bookmark) => void;
  removeBookmark: (id: string) => void;
}>({
  bookmarks: [],
  addBookmark: (id: Bookmark) => { },
  removeBookmark: (id: string) => { },
});

export const useBookmarkContext = () => React.useContext(BookmarkContext);

export default function Authenticated() {
  const { replace } = useHistory();
  const { isSignedIn, isSigningIn } = useIsUserSignedIn();
  const [bookmarks, setBookmarks] = React.useState<Bookmark[]>([]);

  const addBookmark = (a: Bookmark) => {
    setBookmarks([...bookmarks, a]);
    localStorage.setItem('bookmarks', JSON.stringify([...bookmarks, a]));
  };

  const removeBookmark = (a: string) => {
    setBookmarks(bookmarks.filter((e) => e.id !== a));
    localStorage.setItem(
      'bookmarks',
      JSON.stringify(bookmarks.filter((e) => e.id !== a))
    );
  };

  React.useEffect(() => {
    setBookmarks(JSON.parse(localStorage.getItem('bookmarks') || '[]'));
  }, []);

  React.useEffect(() => {
    if (!isSignedIn && !isSigningIn) {
      replace('/login');
    }
  }, [isSignedIn, isSigningIn, replace]);

  if (isSigningIn) {
    return <PageLoading />;
  }

  return (
    <GoogleMapsWrapper apiKey={'AIzaSyBCivI9vSZU6l52FO0j5uEopk0vMFPFH_8'} libraries={['places']} >
      <BookmarkContext.Provider
        value={{
          bookmarks,
          addBookmark,
          removeBookmark,
        }}
      >
        <Box
          flex="horizontal"
          backgroundColor="blue"
          alignChildrenVertical="middle"
          padding="x1"
          gap="x4"
          elevation="x2"
        >
          <Text strong textColor="light">
            <BookmarkIcon size='1rem' />
          </Text>
          <Box flex='horizontal' alignChildrenHorizontal='between' grow >
            <Box flex="horizontal" gap="x2">
              {bookmarks.length === 0 && <Text textColor='light' size='x1' padding='x1'>No bookmarks yet</Text>}
              {bookmarks.map((e) => (
                <BookmarkButton bookmark={e} key={e.id} />
              ))}
            </Box>
            <Text textColor='light' size='x1' strong>{firebase.auth().currentUser?.email}</Text>
          </Box>
        </Box>
        <Box flex="horizontal" grow>
          <Box flex="vertical" width="320px">
            <AppSidebar />
          </Box>

          <Box flex="vertical" grow style={{ paddingBottom: '100px' }}>
            <Switch>
              <Route component={ContractorsIndex} path="/contractors" />
              <Route component={CustomersIndex} path="/customers" />
              <Route component={JobCreate} path="/job" />
              <Route component={JobsIndex} path="/jobs" />
              <Route component={JobsIndex} path="/crj" />
              <Route component={WebInquiriesIndex} path="/web-inquiries" />
              <Route component={JobsIndex} path="/requires-action" />
              <Route component={JobsIndex} path="/web-inquries" />
              <Route component={QuoteCreate} path="/quote" />
              <Route component={QuotesIndex} path="/quotes" />
              <Route component={SitesIndex} path="/sites" />
              <Route component={AdminIndex} path="/admin" />
              <Route component={ProductsIndex} path="/products" />
              <Route component={RecurringJobsIndex} path="/recurring-jobs" />
              <Redirect to="/jobs" />
            </Switch>
          </Box>
        </Box>
      </BookmarkContext.Provider>
    </GoogleMapsWrapper>
  );
}
