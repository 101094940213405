import { Customer, Job } from '@hd/types';
import { Modal } from '@hd/ui';
import * as React from 'react';
import CustomersList from '../Customers/CustomersList';

interface Props {
  job: Job;
  onClose: () => void;
  onSelect: (value: Customer) => void;
}

export default function JobSelectionModalCustomer(props: Props) {
  const {
    job,
    onClose,
    onSelect,
  } = props;

  return (
    <>
      <Modal
          maxWidth="600px"
          onClose={ onClose }
          visible>

        <CustomersList onSelect={ onSelect } site={ job.customerSite } />
      </Modal>
    </>
  );
}
