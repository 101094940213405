import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Image, Page, PageBody, PageFooter } from '@hd/ui';
import Complete from './Complete';
import CompleteSuccess from './CompleteSuccess';

export default function CompleteIndex() {
  return (
    <Page
        backgroundColor="light-gray"
        padding="x6">
      <PageBody
          alignChildrenHorizontal="middle"
          flex="vertical"
          maxWidth="600px"
          paddingVertical="x20">

        <Image
            margin="x10"
            src={ require('@hd/ui/assets/logo.png') }
            width="200px" />

        <Switch>
          <Route component={ CompleteSuccess } path="/complete/:magicId/success" />
          <Route component={ Complete } />
        </Switch>
      </PageBody>

      <PageFooter />
    </Page>
  );
}
