import { Address } from '@hd/types';

export default function getAddress<T extends Address>(address: undefined | T) {
  if (!address) {
    return '-';
  }

  return [
    address.addressLine1,
    address.addressLine2,
    address.addressLine3,
    address.addressPostcode,
  ].filter((v) => v).join(', ');
}
