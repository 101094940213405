import * as React from 'react';
import { Box, Page, PageBody, PageHeader, PageTitle } from '@hd/ui';
import CustomersList from './CustomersList';
import { useHistory } from 'react-router-dom';

export default function Customers() {
  const { push } = useHistory();

  return (
    <Page>
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Customers</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <CustomersList
            onSelect={ (customer) => push(`/customers/${customer._id}`) }
            options={ { resolve: false } } />
      </PageBody>
    </Page>
  );
}
