import * as React from 'react';
import {
  Badge,
  Box,
  Button,
  Text
} from '@hd/ui';
import { Repair, UnitTypeType } from '@hd/types';
import { Edit, Trash } from '@styled-icons/boxicons-regular';
import CCTVReccomendationModal from './CCTVRecommendationModal';
import ConfirmationModal from '../ConfirmationModal';


type Props = {
  repair: Repair;
  onEdit: (repair: Repair) => void;
  onRemove: (repair: Repair) => void;
  index: number;
  readOnly?: boolean;
}

export default function CCTVReccoemdnationRepairSummary(props: Props) {
  const { onEdit, onRemove, repair, index, readOnly } = props

  const [editing, setEditing] = React.useState<Repair>()
  const [removeVisible, setRemoveVisible] = React.useState(false)

  const displayUnitType = (quantity: number, unitType: UnitTypeType) => {
    switch (unitType) {
      case "HourRate":
        return `${quantity} hours`
      case 'HalfDayRate':
        return `${quantity} Half day`
      case 'DayRate':
        return `${quantity} days`
      case 'Meterage':
        return `${quantity}m`
      case 'Quantity':
        return `Quantity: ${quantity}`
    }
  }

  return (<>
    <Box backgroundColor='light-gray' padding='x3' flex='vertical'>
      <Badge backgroundColor='gray'>{`Repair ${index}:  ${repair.product?.name}`}</Badge>
      <Text size='x1'>{displayUnitType(repair.quantity!, repair.unitType!)}</Text>
      {repair.product?.requiresAtPosition && <Text size='x1'>At: {repair.positionAt?.join(', ')}</Text>}
      {repair.product?.requiresFromToPosition && <Text size='x2'>{repair.positionFrom} -&gt; {repair.positionTo}</Text>}
      {(repair.addOns || []).length > 0 && <Text strong size='x2'>Add ons:</Text>}
      {repair.addOns?.map(addOn => (
        <Box key={`${addOn.id}`}>
          <Text size='x1'>{`* ${addOn.product?.name} ${displayUnitType(addOn.quantity!, addOn.unitType!)}`}</Text>
        </Box>
      ))}
      <Box flex='horizontal'>
        {
          !readOnly && <>
            <Button level='tertiary' color='blue' size='small' onClick={() => setEditing(repair)}><Edit size='1rem' /></Button>
            <Button level='tertiary' size='small' onClick={() => setRemoveVisible(true)}><Trash size='1rem' /></Button>
          </>
        }
      </Box>
      <ConfirmationModal message={'Are you sure?'} onCancel={() => setRemoveVisible(false)} onConfirm={() => {
        onRemove(repair)
      }} visible={removeVisible}></ConfirmationModal>
    </Box>
    {
      editing !== undefined && <CCTVReccomendationModal onAdd={onEdit} onClose={() => setEditing(undefined)} editing={editing} />
    }
  </>

  );

}
