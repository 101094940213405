import firebase from 'firebase/app';
import { JobPayment, PaymentMethod } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreJobPayment(jobId: string, paymentId?: string) {
  return useFirestoreDocument<JobPayment>('Payment', `jobs/${jobId}/payments`, paymentId, {
    value: 0,
    timestamp: firebase.firestore.Timestamp.now(),
    paymentMethod: 'Stripe' as PaymentMethod,
  });
}
