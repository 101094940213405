import { Job } from '@hd/types';
import * as React from 'react';
import CustomerView from '../Customers/CustomerView';

export interface Props {
  job: Job;
}

export default function JobSelectionViewCustomer(props: Props) {
  const { job } = props;

  if (!job.customer) {
    return null;
  }

  return (
    <CustomerView customer={ job.customer } />
  );
}
