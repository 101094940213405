import * as React from 'react';
//import { validateJob, FormError } from '../../utils/formValidators';
import Dropdown from '../Dropdown/Dropdown';
import useFirestoreCollection from '../../utils/useFirestoreCollection';
import { NoteOrQuote } from '@hd/types';

type Props = {
  onSelect: (value: string) => void;
};

export default function NotesAndQuotesDropdown({ onSelect }: Props) {
  const data = useFirestoreCollection<NoteOrQuote>(
    'NoteOrQuote',
    'notesAndQuotes',
    {}
  );

  return (
    <Dropdown
      options={data.ids.map((e) => data.documents[e].name)}
      onChange={(key) =>
        onSelect(
          data.ids
            .map((e) => data.documents[e])
            .filter((e) => e.name === key)[0].value
        )
      }
      value="Click for premade notes"
    />
  );
}
