import * as React from 'react';
import { Text, TextProps } from '@hd/ui';

interface Props extends TextProps {

}

export default function CompleteInfo(props: React.PropsWithChildren<Props>) {
  const { align = 'middle', ...rest } = props;

  return (
    <Text { ...rest }
        align={ align }
        backgroundColor="light-gray"
        borderColor="blue"
        borderSize="x2"
        padding="x3"
        size="x2"
        strong
        textColor="blue" />
  );
}
