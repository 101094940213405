import * as React from 'react';
import { Alert, Badge, Box, Button, Card, CardRow, FormInput, Text, TextInput } from '@hd/ui';
import { CCTVRecommendationProduct } from '@hd/types';
import { Plus, X } from '@styled-icons/boxicons-regular';
import FirebaseList from '../Firebase/FirebaseList';
import useFirestoreCollection, {
  Options,
} from '../../utils/useFirestoreCollection';
import useKeywordFilter from '../../utils/useKeywordFilter';
import useMergedFilters from '../../utils/useMergedFilters';
import ProductAddModal from './ProductAddModal';
import { validateCCTVRecommendationProduct } from '../../utils/formValidators';

interface Props {
  isSearchable?: boolean;
  options?: Options<CCTVRecommendationProduct>;
  onSelect: (jobType: CCTVRecommendationProduct) => void;
}

const groupByCategory = (ids: string[], subcategories: Record<string, CCTVRecommendationProduct>) => {
  const groupedIds: Record<string, string[]> = {};

  for (const id of ids) {
    const subcategory = subcategories[id];

    if (subcategory) {
      const { cachedParentName, parentId, cachedGrandparentName } = subcategory;
      const key = cachedParentName && cachedGrandparentName ? `${cachedGrandparentName} > ${cachedParentName}` :  parentId;

      groupedIds[key] = groupedIds[key] || [];
      groupedIds[key].push(id);
    }
  }

  return groupedIds;
};

export default function ProductsList(props: Props) {
  const { isSearchable, onSelect, options } = props;
  const [value, setValue] = React.useState('');
  const [addProductVisible, setAddProductVisible] =
    React.useState(false);
  const keywordFilters = useKeywordFilter(value);
  const filters = useMergedFilters(keywordFilters, options?.filters);
  console.log(filters)
  const data = useFirestoreCollection<CCTVRecommendationProduct>(
    'CCTVRecommendationProduct',
    'products',
    {
      filters: filters,
      limit: 50,
    }
  );

  const groupedIds = groupByCategory(data.ids, data.documents);

  return (
    <>
      <Card
        backgroundColor="light"
        flex="horizontal"
        gap="x4"
        margin="x3"
        padding="x3"
      >
        <Box grow>
          {isSearchable && (
            <FormInput margin="x3">
              <TextInput
                onChange={(e) => setValue((e.target as HTMLInputElement).value)}
                placeholder="Search products"
                value={value}
              />
            </FormInput>
          )}
        </Box>

        <Box>
          <Button
            color="blue"
            data-at-id="AddProductButton"
            level="tertiary"
            onClick={() => setAddProductVisible(true)}
          >
            <Plus size="1.5rem" />
            <Text>Add product</Text>
          </Button>
        </Box>
      </Card>

      <FirebaseList {...data} onMore={data.more}>
        {Object.entries(groupedIds).map(([category, ids]) => (
          <Box key={category} margin="x10">
            <Box alignChildren="middle" flex="horizontal" gap="x4" margin="x2">
              <Text size="x3" strong>
                {category}
              </Text>

              <Box grow>
                <Text borderColor="gray" borderSides="T" borderSize="x1" />
              </Box>
            </Box>

            <Card>
              {ids.map((id) => (
                <CardRow
                  alignChildrenVertical="middle"
                  data-at-id="ContractorLisItem"
                  flex="horizontal"
                  key={id}
                  onClick={() => onSelect(data.documents[id])}
                  padding="x4"
                >
                      <CardRow>
                    <Text strong>{data.documents[id].name}</Text>
                    <Box grow flex='horizontal' gap='x3'>
                    {data.documents[id].pricing.map(e => (
                      <>
                        {validateCCTVRecommendationProduct(data.documents[id]) && <Alert textColor='red' backgroundColor='red-light' alignChildrenHorizontal='middle' flex='vertical' width='300px'> <X size='2rem' /> 
                        {validateCCTVRecommendationProduct(data.documents[id])?.message}
                        </Alert>}
                        {data.documents[id].requiresAtPosition && <Badge backgroundColor='yellow-dark'>AT</Badge>}
                        {data.documents[id].requiresFromToPosition && <Badge backgroundColor='blue-light'>FROM/TO</Badge>}
                          <Text>{e.type}</Text>
                          <Text>{e.price}</Text>
                        </>
                    ))}
</Box>
                      </CardRow>
                </CardRow>
              ))}
            </Card>
          </Box>
        ))}
      </FirebaseList>

      <ProductAddModal
        onClose={() => setAddProductVisible(false)}
        visible={addProductVisible}
      />
    </>
  );
}
