import * as React from 'react';
import { Box, Button, Card, Modal, Text } from '@hd/ui';
//import useFirestoreContractor from "../../utils/useFirestoreContractor";
//import { validateContractor, FormError } from "../../utils/formValidators";
import FormErrorMessage from '../FormErrorMessage';
import useFirestoreJobTypes from '../../utils/useFirestoreJobTypes';
import JobTypeForm from './JobTypeForm';
import { FormError, validateJobType } from '../../utils/formValidators';
import ProductForm from './ProductForm';
import { UnitType } from './AdminProduct';
import Notifications, {
  NotificationsContext,
} from '../Notifications/Notifications';

interface Props {
  onClose: () => void;
  visible: boolean;
}

export interface ProductHDApp {
  name?: string;
  parentId?: number;
  maxDepth?: number;

  unitQuantity?: UnitType;
  unitMeterage?: UnitType;
  unitDay?: UnitType;
  unitHour?: UnitType;
  unitHalfDay?: UnitType;

  unitQuantityContractor?: UnitType;
  unitMeterageContractor?: UnitType;
  unitDayContractor?: UnitType;
  unitHourContractor?: UnitType;
  unitHalfDayContractor?: UnitType;

  requiresAtPosition?: boolean;
  requiresFromPosition?: boolean;
  requiresToPosition?: boolean;
  singleSectionApplication: boolean;
  multipleSectionApplication: boolean;
  allSectionApplication: boolean;
}

export default function ProductCreateModal(props: Props) {
  const { onClose, visible } = props;
  const { addNotification } = React.useContext(NotificationsContext);

  const [product, setProduct] = React.useState<ProductHDApp>({
    name: undefined,
    parentId: undefined,
    maxDepth: undefined,
    unitQuantity: undefined,
    unitMeterage: undefined,
    unitDay: undefined,
    unitHour: undefined,
    unitHalfDay: undefined,
    requiresAtPosition: false,
    requiresFromPosition: false,
    requiresToPosition: false,
    singleSectionApplication: false,
    multipleSectionApplication: false,
    allSectionApplication: false,
  });
  //const { document, update, save, clear } = useFirestoreJobTypes();
  //const [error, setError] = React.useState<FormError | null>(null);

  const handleSave = async () => {
    const { maxDepth, parentId } = product;

    const body = {
      name: product.name,
      dayRate: product.unitDay,
      parentId: parseInt(parentId),
      halfDayRate: product.unitHalfDay,
      halfDayRateContractor:
        product.unitHalfDay?.price && product.unitHalfDayContractor?.price
          ? {
              ...product.unitHalfDay,
              price: product.unitHalfDayContractor?.price,
            }
          : undefined,
      hourRate: product.unitHour,
      hourRateContractor:
        product.unitHourContractor?.price && product.unitHour
          ? {
              ...product.unitHour,
              price: product.unitHourContractor?.price,
            }
          : undefined,
      quantity: product.unitQuantity,

      quantityContractor:
        product.unitQuantityContractor?.price && product.unitQuantity
          ? {
              ...product.unitQuantity,
              price: product.unitQuantityContractor?.price,
            }
          : undefined,

      meterage: product.unitMeterage,
      meterageContractor:
        product.unitMeterageContractor?.price && product.unitMeterage
          ? {
              ...product.unitMeterage,
              price: product.unitMeterageContractor?.price,
            }
          : undefined,

      maxDepth: maxDepth ? parseInt(maxDepth) : undefined,
      singleSectionApplication: product.singleSectionApplication,
      multipleSectionApplication: product.multipleSectionApplication,
      allSectionApplication: product.allSectionApplication,
      requiresAtPosition: product.requiresAtPosition,
      requiresFromPosition: product.requiresFromPosition,
      requiresToPosition: product.requiresToPosition,
    };
    await fetch(`https://drainreporter.com/createProduct`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-happy-drains':
          '2dabfab0996332832f8adbf62d0eb3472358e96acf17e0d841ce951d66dd552a',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    }).then((e) => console.log(e));

    addNotification('Product created!');
    onClose();
  };

  return (
    <Modal maxWidth="600px" onClose={onClose} visible={visible}>
      <Card backgroundColor="light" padding="x10">
        <Text size="x4">Add Product</Text>

        <ProductForm product={product} update={setProduct} />
        {
          //error && <FormErrorMessage error={error} />}
        }

        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x4"
          margin="x10"
        >
          <Button data-at-id="ModalCancel" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="blue"
            data-at-id="ModalConfirm"
            level="primary"
            onClick={handleSave}
            type="submit"
          >
            Create Product
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
