import { Site } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreCustomerSite(siteId?: string) {
  return useFirestoreDocument<Site>('Site', 'sites', siteId, {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressPostcode: '',
    contactName: '',
    contactPhoneNumber: '',
    name: '',
  });
}
