import * as React from 'react';
import { Box, Button, Card, FormInput, FormInputLabel, FormInputHeader, Modal, TextInput } from '@hd/ui';
import useFirestoreJobNote from '../../utils/useFirestoreJobNote';

interface Props {
  jobId: string;
  onClose: () => void;
  visible: boolean;
}

export default function JobNoteModal(props: Props) {
  const { jobId, onClose, visible } = props;
  const { document, update, save } = useFirestoreJobNote(jobId);

  const handleSave = async() => {
    await save();
    onClose();
  };

  return (
    <Modal
        maxWidth="600px"
        onClose={ onClose }
        visible={ visible }>
      <Card
          backgroundColor="light"
          padding="x10">

        <FormInput margin="x6">
          <FormInputHeader>
            <FormInputLabel>Note</FormInputLabel>
          </FormInputHeader>

          <TextInput
              multiline
              onChange={ (e) => update({ note: (e.target as HTMLInputElement).value }) }
              placeholder="Note..."
              rows={ 10 }
              value={ document.note } />
        </FormInput>

        <Box alignChildrenHorizontal="end" flex="horizontal" gap="x4" margin="x10" >
          <Button onClick={ onClose }>
            Cancel
          </Button>

          <Button
              color="blue"
              level="primary"
              onClick={ handleSave }
              type="submit">
            Create Note
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
