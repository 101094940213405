import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Site from './Site';
import Sites from './Sites';

export default function SitesIndex() {
  return (
    <Switch>
      <Route component={ Site } path="/sites/:siteId" />
      <Route component={ Sites } path="/sites" />
    </Switch>
  );
}
