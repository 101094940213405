import * as React from 'react';
import { Box, Page, PageBody, PageHeader, PageTitle } from '@hd/ui';
import SitesList from './SitesList';
import { useHistory } from 'react-router-dom';

export default function Sites() {
  const { push } = useHistory();

  return (
    <Page>
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Sites</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <SitesList
            isResultsVisibleBeforeSearch
            onSelect={ (site) => push(`/sites/${site._id}`) }
            options={ { limit: 25, resolve: false } } />
      </PageBody>
    </Page>
  );
}
