import { useMemo } from 'react';
import { Job } from '@hd/types';
import useFirestoreCollection, { Options, TypeOrderBy } from './useFirestoreCollection';

export default function useFirestoreJobs(options?: Options<Job>) {
  const orderBys = useMemo(() => {
    if (options?.orderBys?.some(([key]) => key === 'date') || options?.orderBys?.some(([key]) => key === 'complaints') || options?.orderBys?.some(([key]) => key === 'invoiceCreated')) {
      return options.orderBys;
    }

    return [['date', 'desc'], ...(options?.orderBys || [])] as TypeOrderBy<Job>[];
  }, [options?.orderBys]);


  return useFirestoreCollection<Job>('Job', 'jobs', { ...options, orderBys });
}
