import * as React from 'react';
import { Box, BoxProps, Table, TableBody, TableBodyCell, TableBodyRow, TableHeader, TableHeaderCell, TableHeaderRow, Text } from '@hd/ui';
import { DateTime } from 'luxon';
import { FastForward } from '@styled-icons/boxicons-regular';
import { ActivitySubtypes, JobCancelActivity, JobCreateActivity, JobNote, PriceContractorUpdateActivity, PriceCustomerUpdateActivity } from '@hd/types';
import { TypeOrderBy } from '../../utils/useFirestoreCollection';
import useFirestoreActivityLog from '../../utils/useFirestoreActivityLog';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import FirebaseList from '../Firebase/FirebaseList';

interface Props extends BoxProps {
  jobId: string;
}
const orderBys: TypeOrderBy<JobNote>[] = [['timestamp', 'asc']];

export default function JobActivityLog(props: Props) {
  const { jobId, ...rest } = props;
  const data = useFirestoreActivityLog(jobId);

  return (
    <Box {...rest}>
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x2"
        margin="x3">
        <Box>
          <FastForward size="1.5rem" />
        </Box>

        <Box grow>
          <Text strong>Activity log</Text>
        </Box>

      </Box>

      <FirebaseList {...rest} {...data} >
        <Table>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>Event</TableHeaderCell>
              <TableHeaderCell>Description</TableHeaderCell>
              <TableHeaderCell>Initiator</TableHeaderCell>
              <TableHeaderCell>Time</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {
              Object.values(data.documents).map(e => {
                if (isPriceCustomerUpdateActivity(e)) {
                  return <TableBodyRow key={e.time.nanoseconds}>
                    <TableBodyCell>HD price update</TableBodyCell>
                    <TableBodyCell>
                      {`${e.beforePriceCustomer} -> ${e.afterPriceCustomer}`}
                    </TableBodyCell>
                    <TableBodyCell>{e.user}</TableBodyCell>
                    <TableBodyCell>{DateTime.fromJSDate(e.time.toDate()).toFormat('DDD HH:mm')}</TableBodyCell>
                  </TableBodyRow>
                }
                if (isPriceContractorUpdateActivity(e)) {
                  return <TableBodyRow>
                    <TableBodyCell>Contractor price update</TableBodyCell>
                    <TableBodyCell>
                      {`${e.beforePriceContractor} -> ${e.afterPriceContractor}`}
                    </TableBodyCell>
                    <TableBodyCell>{e.user}</TableBodyCell>
                    <TableBodyCell>{DateTime.fromJSDate(e.time.toDate()).toFormat('DDD HH:mm')}</TableBodyCell>
                  </TableBodyRow>
                }
                if (isJobCreatedUpdateActivity(e)) {
                  return <TableBodyRow>
                    <TableBodyCell>Job created</TableBodyCell>
                    <TableBodyCell></TableBodyCell>
                    <TableBodyCell>{e.user}</TableBodyCell>
                    <TableBodyCell>{DateTime.fromJSDate(e.time.toDate()).toFormat('DDD HH:mm')}</TableBodyCell>
                  </TableBodyRow>
                }
                if (isJobCancelledUpdateActivity(e)) {
                  return <TableBodyRow>
                    <TableBodyCell>Job cancelled</TableBodyCell>
                    <TableBodyCell></TableBodyCell>
                    <TableBodyCell>{e.user}</TableBodyCell>
                    <TableBodyCell>{DateTime.fromJSDate(e.time.toDate()).toFormat('DDD HH:mm')}</TableBodyCell>
                  </TableBodyRow>
                }
              })
            }
          </TableBody>
        </Table>
      </FirebaseList>
    </Box>
  );
}

function isPriceCustomerUpdateActivity(activity: ActivitySubtypes): activity is PriceCustomerUpdateActivity {
  return activity.type === 'price_update_customer'
}

function isPriceContractorUpdateActivity(activity: ActivitySubtypes): activity is PriceContractorUpdateActivity {
  return activity.type === 'price_update_contractor'
}

function isJobCreatedUpdateActivity(activity: ActivitySubtypes): activity is JobCreateActivity {
  return activity.type === 'job_created'
}

function isJobCancelledUpdateActivity(activity: ActivitySubtypes): activity is JobCancelActivity {
  return activity.type === 'job_cancelled'
}