import * as React from 'react';
import { Box, Card, CardRow, Place, Text, TextInput } from '@hd/ui';
import { ChevronDown } from '@styled-icons/boxicons-regular';

interface Props<T> {
  onChange: (value: T) => void;
  options: T[];
  value?: T;
  onTextInput?: (s?: string) => void;
}

export default function Dropdown<T extends string>(props: Props<T>) {
  const { options, onChange, value, onTextInput, ...rest } = props;
  const [ref, setRef] = React.useState<HTMLElement | null>(null);
  const [visible, setVisible] = React.useState(false);

  const handleSelect = (v: T) => {
    onChange(v);
    setVisible(false);
  };

  return (
    <>
      <TextInput
        {...rest}
        IconPost={ChevronDown}
        onChange={(e) =>
          onTextInput ? onTextInput((e.target as HTMLInputElement).value) : {}
        }
        onClick={() => setVisible(true)}
        readOnly
        ref={setRef}
        value={value || ''}
      />

      <Place
        minWidth="reference"
        onClose={() => setVisible(false)}
        referenceElement={ref}
        visible={visible}
      >
        <Card backgroundColor="light">
          <Box maxHeight="200px" scrollable>
            {options.map((value) => (
              <CardRow
                data-at-id="DropdownOption"
                flex="horizontal"
                key={value}
                onClick={() => handleSelect(value)}
                paddingHorizontal="x4"
                paddingVertical="x3"
              >
                <Text size="x2">{value}</Text>
              </CardRow>
            ))}
          </Box>
        </Card>
      </Place>
    </>
  );
}
