import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import QuoteView from '../Quote/QuoteView';
import Quotes from './Quotes';

export default function QuotesIndex() {
  return (
    <Switch>
      <Route component={QuoteView} path="/quotes/:quoteId" />
      <Route component={Quotes} path="/quotes" />
    </Switch>
  );
}
