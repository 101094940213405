import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Page, PageBody, PageHeader, PageTitle } from '@hd/ui';
import AdminNotesAndQuotesList from './AdminNotesAndQuotesList';

export default function AdminNotesAndQuotes() {
  const { push } = useHistory();

  return (
    <Page data-at-id="NotesAndQuotesPage">
      <PageHeader>
        <Box alignChildrenVertical="middle" flex="horizontal">
          <Box grow>
            <PageTitle>Notes and Quotes</PageTitle>
          </Box>
        </Box>
      </PageHeader>

      <PageBody>
        <AdminNotesAndQuotesList
          isSearchable
          onSelect={(jobType) => push(`/admin/notes-and-quotes/${jobType._id}`)}
        />
      </PageBody>
    </Page>
  );
}
