import { Customer } from '@hd/types';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreCustomer(id?: string) {
  return useFirestoreDocument<Customer>('Customer', 'customers', id, {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressPostcode: '',
    contactEmails: [],
    contactName: '',
    contactPhoneNumbers: [],
    name: '',
    vatType: 'VAT',
    sageReference: '',
  });
}
