import * as React from 'react';
import {
  Box,
  Button,
  Text,
  TextInput,
  Appear,
  Badge
} from '@hd/ui';
import { Repair, UnitTypeType } from '@hd/types';
import { Step } from '../CCTVRecommendationsRecommendationForm';
import { v4 } from 'uuid';


type Props = {
  activeStepId?: Step;
  onAdd: (repair: Repair) => void;
  update: (updates: Partial<Repair>) => void;
  setStepId: (step?: Step) => void;
  repair: Repair;
  onClose: () => void;
}

const unitTypeToHumanReadable: Record<UnitTypeType, string> = {
  Quantity: 'Quantity',
  Meterage: 'Meters',
  HourRate: 'Hours',
  HalfDayRate: 'Half days',
  DayRate: 'Days'
}

export default function ProductSelectionDetails(props: Props) {
  const { activeStepId,  onAdd, setStepId, repair, onClose, update } = props;
  const { product } = props.repair

  const [errorMessage, setErrorMessage] = React.useState<string>()

  React.useEffect(() => {
    if(product?.pricing.length === 1) {
      update({
        unitType: product?.pricing[0].type
      })
    }

  }, [product])

  React.useEffect(() => {
    const min = product?.pricing.find(e => e.type === repair.unitType)?.min
    if(min !== undefined && repair.quantity !== undefined &&  min > repair.quantity) {
      setErrorMessage(`Too low. Minimum: ${min}`)
    } else {
      setErrorMessage(undefined)
    }
  }, [repair.unitType, repair.quantity])

  if(activeStepId !== 'ProductDetails') {
    return null;
  }

  return (
          <Box flex='vertical' gap='x3'>
            <Text size='x3' strong>{product?.name}</Text>

              <Box flex='vertical' gap='x3' alignChildrenHorizontal='around'>
              { product?.pricing.map(e => e.type).map(e => 
              <Box key={e} flex='vertical' grow alignChildrenHorizontal='middle'>
                  <Button width='100%' padding='x2' onClick={() => update({ unitType: e})} borderSize='x1' alignChildrenHorizontal='middle' level='primary' color={repair.unitType === e ? 'blue' : 'light'}>
                    <Text>{unitTypeToHumanReadable[e]}</Text>
                  </Button>
               { repair.unitType === e && 
                <Appear animation='FadeSlideDown'>
                  <TextInput autoFocus width='100%' type='number' onChange={(e) => update({quantity: parseFloat((e.target as HTMLInputElement).value)})} value={repair.quantity || ''}/>
                  {errorMessage && <Badge backgroundColor='red-light' alignChildrenHorizontal='middle'>{errorMessage}</Badge>}
                  {isNaN(repair.quantity!) && <Badge backgroundColor='blue-light' alignChildrenHorizontal='middle'>Please enter value</Badge>}
                </Appear>
              }
              
              </Box>
              )}
              </Box>



        <Box flex='horizontal' alignChildrenHorizontal='between'>
          <Button onClick={() => setStepId('Menu') }>{'<'}</Button>

          {(product?.requiresAtPosition || product?.requiresFromToPosition) &&
            <Button 
              color='blue'
              onClick={() => setStepId('ProductMetadata')}
              disabled={repair.unitType === undefined || repair.quantity === undefined || errorMessage !== undefined}
              >{'>'}</Button>   }

          {(!product?.requiresAtPosition && !product?.requiresFromToPosition && product?.addOns && product.addOns.length > 0) &&
            <Button 
              color='blue'
              onClick={() => setStepId('ProductAddOns')}
              disabled={repair.unitType === undefined || repair.quantity === undefined || errorMessage !== undefined}
              >{'>'}</Button>   }

          {(!product?.requiresAtPosition && !product?.requiresFromToPosition && (product?.addOns === undefined || product.addOns.length === 0)) &&
            <Button 
              color='blue'
              onClick={() => {
                onAdd(repair)
                onClose()
              }}
              disabled={repair.unitType === undefined || repair.quantity === undefined || errorMessage !== undefined}
            >+</Button>
            }
        </Box>

          </Box>

  );

}
