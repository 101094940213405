import * as React from 'react';
import { Box, Text } from '@hd/ui';
import { Engineer } from '@hd/types';

export interface Props {
  engineer: Engineer;
}

export default function EngineerView(props: Props) {
  const { engineer } = props;

  return (
    <Box>
      <Text strong>{ engineer.name }</Text>
    </Box>
  );
}
