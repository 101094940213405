import { CCTVRecommendationCategory, CCTVRecommendationSubcategory } from '@hd/types';
import {
  Box,
  Card,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Text,
  TextInput,
} from '@hd/ui';
import * as React from 'react';
interface Props {
  category: CCTVRecommendationCategory;
  update: (updates: Partial<CCTVRecommendationCategory>) => void;
}

export default function CategoryForm(props: Props) {
  const { category, update } = props;

  return (

    <Card backgroundColor="light" margin="x4" padding="x6">
      <Box flex="horizontal" gap="x6" margin="x6">
        <FormInput grow>
          <FormInputHeader>
            <FormInputLabel>Name</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="CategoryName"
            onChange={(e) => update({
              name: (e.target as HTMLInputElement).value
            })}
            value={category.name}
          />
        </FormInput>
      </Box>
      </Card>
  );
}
