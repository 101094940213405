import * as React from 'react';
import { Box, Button, Card, Modal, Text } from '@hd/ui';
import { Customer, Site } from '@hd/types';
import useFirestoreCustomer from '../../utils/useFirestoreCustomer';
import { validateCustomer, FormError } from '../../utils/formValidators';
import CustomerForm from './CustomerForm';
import FormErrorMessage from '../FormErrorMessage';
import useFirestoreCustomerSite from '../../utils/useFirestoreCustomerSite';

interface Props {
  onClose: (customer?: Customer) => void;
  visible: boolean;
  site?: Site;
}

export default function CustomerModal(props: Props) {
  const { site, onClose, visible } = props;
  const { document, update, save } = useFirestoreCustomer(site && site.customer && site.customer._id);
  const siteInterface = useFirestoreCustomerSite(site && site._id);
  const [error, setError] = React.useState<FormError | null>(null);

  const handleSave = async() => {
    const error = validateCustomer(document);
    setError(error);

    if (!error) {
      const customer = await save();

      if (site) {
        siteInterface.update({ customer } );
        await siteInterface.save();
      }

      onClose(customer);
    }
  };

  return (
    <Modal
        maxWidth="600px"
        onClose={ () => onClose() }
        visible={ visible }>
      <Card
          backgroundColor="light"
          padding="x10">
        <Text size="x4">Add Customer</Text>

        <CustomerForm document={ document } site={ site } update={ update }/>

        { error && (
          <FormErrorMessage error={ error } />
        ) }

        <Box alignChildrenHorizontal="end" flex="horizontal" gap="x4" margin="x10" >
          <Button onClick={ () => onClose() }>
            Cancel
          </Button>

          <Button
              color="blue"
              data-at-id="ModalConfirm"
              level="primary"
              onClick={ handleSave }
              type="submit">
            Create Customer
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
