import {
  Alert,
  Box,
  Button,
  Card,
  CheckBox,
  FileInput,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Link,
  Modal,
  Text,
  TextInput,
} from '@hd/ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import useFirestoreJob from '../../utils/useFirestoreJob';
import FileList from '../FileList/FileList';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import useFirestoreJobReport from '../../utils/useFirestoreJobReport';
import toDateFormat from '../../utils/toDateFormat';
import NotesAndQuotesDropdown from '../NotesAndQuotes/NotesAndQuotesDropdown';
import { FormError } from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';
import JobReportFollowUpQuote from './JobReportFollowUpQuote';
import { Edit, LinkExternal, Upload } from '@styled-icons/boxicons-regular';
import ConfirmationModal from '../ConfirmationModal';
import resizeImage from '../../utils/ImageCompressor';
import firebase from 'firebase/app';

export default function JobReport({ jobId }: { jobId: string }) {
  const job = useFirestoreJob(jobId);
  const report = useFirestoreJobReport(jobId);
  const { push } = useHistory();

  const [error, setError] = React.useState<FormError | null>(null);
  const [showQuote, setShowQuote] = React.useState(false);
  const [showQuoteDeleteConfirmation, setShowQuoteDeleteConfirmation] =
    React.useState(false);

  const toggleFurtherWork = async () => {
    if (report.document.jobRequiresFurtherWork) {
      if (showQuoteDeleteConfirmation) {
        setShowQuoteDeleteConfirmation(false);
      } else {
        setShowQuoteDeleteConfirmation(true);
        return;
      }
    }
    const futherWork = !report.document.jobRequiresFurtherWork;
    report.update({ jobRequiresFurtherWork: futherWork });
    job.update({ potentialFurtherWork: futherWork });
    job.save();
    report.save();
  };

  const handleSelectFiles = async (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const newFiles = (event.target as HTMLInputElement).files;
    if (newFiles) {
      const ref = firebase.storage().ref();
      const filesArray = [];
      for (const file of newFiles) {
        const compressedBlob = (await resizeImage({
          file: file!,
          maxSize: 500,
        })) as Blob;

        const compressedImage = new File([compressedBlob], file.name, {
          type: compressedBlob.type,
        });

        filesArray.push(compressedImage);
      }
      const addedFiles = []

        for (const file of newFiles) {
          const childRef = ref.child(`/jobs/${job.document._id}/${file.name}`);
          await childRef.put(file);
          console.log(file)
          addedFiles.push({
            _id: '',
            _ref: '',
            name: file.name,
            downloadUrl: await childRef.getDownloadURL(),
            size: file.size,

          })
        }
        console.log(addedFiles)
        report.update({
          files: [...report.document.files, ...addedFiles]
        })
        report.save()
    }
  };

  const handleRemoveFile = async (index: number) => {
    const toDelete = report.document.files[index]
    report.update({
      files: report.document.files.filter(e => e.downloadUrl !== toDelete.downloadUrl)
    })
    report.save()
  };

  return (
    <>
      <FirebaseDocument {...report}>
        {error && <FormErrorMessage error={error} />}
        <Card backgroundColor="light" padding="x6">
          <Box flex="horizontal" gap="x4" margin="x6">
            <Box grow>
              <Text display="inline" strong>
                Engineer:
              </Text>{' '}
              {job.document.contractorEngineer?.name || '-'}
            </Box>

            <Box grow>
              <Text display="inline" strong>
                Submitted:
              </Text>{' '}
              {toDateFormat(report.document.timestampSubmitted, true)}
            </Box>
          </Box>
          {report.document.isNoFix && (
            <Alert
              backgroundColor="red-light"
              textColor="red-dark"
              borderColor="red-dark"
            >
              This job was marked as "No fix"
            </Alert>
          )}
          <Box alignChildrenHorizontal="around" flex="horizontal">
            <Box
              alignChildrenHorizontal="middle"
              borderColor="gray"
              borderRadius="x1"
              borderSize="x1"
              flex="vertical"
              padding="x3"
            >
              <CheckBox
                checked={!!report.document.jobDoneToGuarantee}
                margin="x2"
                onChange={() =>
                  report.update({
                    jobDoneToGuarantee: !report.document.jobDoneToGuarantee,
                  })
                }
              />
              <Text strong>These work are guaranteed</Text>
              <Text>If not, un-tick section and give full detail above.</Text>
            </Box>
            <Box
              alignChildrenHorizontal="middle"
              borderColor="gray"
              borderRadius="x1"
              borderSize="x1"
              flex="vertical"
              padding="x3"
            >
              <CheckBox
                checked={!!report.document.jobRequiresFurtherWork}
                margin="x2"
                onChange={toggleFurtherWork}
              />
              <Text strong>Additional works are required</Text>
              <Text>If so, tick section and please give full detail above</Text>
            </Box>
          </Box>

          {job.isFetching && (
            <>
              <Text strong>Quote</Text>
              <Text>Loading...</Text>
            </>
          )}
          {job.document.followUpQuote && !job.document.usingNewDrainReporter && (
            <>
              <Text strong>Quote</Text>
              <Box
                flex="vertical"
                alignChildrenHorizontal="middle"
                padding="x5"
                borderSize="1"
                borderColor="gray"
                gap="x2"
              >
                <Box flex="horizontal" gap="x4">
                  <Box>
                    <Text strong>Price</Text>
                    <Text>£{job.document.followUpQuote.price}</Text>
                  </Box>

                  <Box>
                    <Text strong>Job type</Text>
                    <Text>{job.document.followUpQuote.jobType}</Text>
                  </Box>
                </Box>
                <Box flex="horizontal" gap="x4">
                  <Button
                    onClick={() => setShowQuote(!showQuote)}
                    level="secondary"
                    color="blue"
                  >
                    <Edit size="1rem" />
                    <Text>Edit Quote</Text>
                  </Button>
                  <Button
                    onClick={() =>
                      window.open(
                        `/quote/cu/${job.document.followUpQuote.magicId}`
                      )
                    }
                  >
                    <LinkExternal size="1rem" />
                    Link to quote
                  </Button>
                </Box>

                <Modal visible={showQuote} onClose={() => setShowQuote(false)}>
                  <JobReportFollowUpQuote
                    job={job.document}
                    onClose={() => setShowQuote(false)}
                  />
                </Modal>
              </Box>
            </>
          )}

          <Box margin="x6">
            <FormInput margin="x3">
              <FormInputHeader>
                <FormInputLabel>Public notes from engineer</FormInputLabel>
              </FormInputHeader>

              <TextInput
                multiline
                onChange={(e) =>
                  report.update({
                    publicNote: (e.target as HTMLInputElement).value,
                  })
                }
                rows={10}
                value={report.document.publicNote}
              />
            </FormInput>

            <FormInput margin="x3">
              <FormInputHeader>
                <FormInputLabel>Private notes (Office only)</FormInputLabel>
              </FormInputHeader>

              <TextInput
                multiline
                readOnly
                rows={10}
                onChange={() => {}}
                value={report.document.privateNote}
              />
            </FormInput>

            <NotesAndQuotesDropdown
              onSelect={(happyDrainsPrivateNote) =>
                report.update({
                  happyDrainsPrivateNote,
                })
              }
            />

            <FormInput margin="x3">
              <FormInputHeader>
                <FormInputLabel>
                  Customer notes from Happy Drains
                </FormInputLabel>
              </FormInputHeader>

              <TextInput
                multiline
                onChange={(e) =>
                  report.update({
                    happyDrainsPrivateNote: (e.target as HTMLInputElement)
                      .value,
                  })
                }
                rows={10}
                value={report.document.happyDrainsPrivateNote}
              />
            </FormInput>

            <Box margin="x6">
              <Text margin="x3" strong>
                Files:
              </Text>
                  <FileInput
                    accept="image/*"
                    color="blue"
                    level='tertiary'
                    margin="x6"
                    multiple
                    onChange={handleSelectFiles}
                  >
                    <Upload size="1.25rem" />
                    <Text>Add evidence</Text>
                  </FileInput>

              <FileList files={report.document.files} onRemoveFile={handleRemoveFile}/>
            </Box>
          </Box>
      { (!job.document.isCCTV && (job.document.status === 'COMPLETED' || job.document.status  === 'AWAITING_AUTHORISATION')) && (
        <Box flex='horizontal' alignChildrenHorizontal='end'>
            <Button  color="blue" level='secondary' onClick={() => {
              job.update({
                status: 'AWAITING_SURVEY',
                isCCTV: true
              })
              job.save()

            }}>
              <Text>Request survey</Text>
            </Button>
  </Box>
      ) }
        </Card>

        <Button
          color="blue"
          level="primary"
          margin="x6"
          onClick={async () => {
            setError(undefined);
            await report.save();
            await job.save();
            push(`/jobs/${jobId}`);
          }}
        >
          Update
        </Button>
      </FirebaseDocument>

      <ConfirmationModal
        visible={showQuoteDeleteConfirmation}
        message="Are you sure? This will delete the quote"
        onConfirm={toggleFurtherWork}
        onCancel={() => setShowQuoteDeleteConfirmation(false)}
      />
    </>
  );
}
